import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import "rxjs/add/operator/toPromise";

import { environment } from "./../../environments/environment";
import { CodigoSituacaoTributariaPis } from "./../core/models/CodigoSituacaoTributariaPis";
import { MoneyHttp } from "app/seguranca/money-http";

export class CodigoSituacaoTributariaPisFiltro {
  descricao: string;
  codigo: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class CodigoSituacaoTributariaPisService {
    codigosSituacaoTributariasPisUrl: string;

  constructor(private http: MoneyHttp) {
    this.codigosSituacaoTributariasPisUrl = `${environment.apiUrl}/codigosSituacaoTributariasPis`;
  }

  pesquisar(filtro: CodigoSituacaoTributariaPisFiltro): Promise<any> {
    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });
    if (filtro.descricao) {
      params = params.append("descricao", filtro.descricao);
    }
    if (filtro.codigo) {
      params = params.append("codigo", filtro.codigo);
    }
    return this.http
      .get<any>(`${this.codigosSituacaoTributariasPisUrl}`, { params })
      .toPromise()
      .then(response => {
        const codigosSituacaoTributariasPis = response.content;

        const resultado = {
          codigosSituacaoTributariasPis,
          total: response.totalElements
        };

        return resultado;
      });
  }

  listarTodas(): Promise<any> {
    return this.http
      .get<any>(`${this.codigosSituacaoTributariasPisUrl}/lista`)
      .toPromise()
      .then(response => response.content);
  }

  adicionar(codigoSituacaoTributariaPis: CodigoSituacaoTributariaPis): Promise<CodigoSituacaoTributariaPis> {
    return this.http.post<CodigoSituacaoTributariaPis>(this.codigosSituacaoTributariasPisUrl, codigoSituacaoTributariaPis).toPromise();
  }

  atualizar(codigosSituacaoTributariasPis: CodigoSituacaoTributariaPis): Promise<CodigoSituacaoTributariaPis> {
    return this.http
      .put<CodigoSituacaoTributariaPis>(`${this.codigosSituacaoTributariasPisUrl}/${codigosSituacaoTributariasPis.id}`, codigosSituacaoTributariasPis)
      .toPromise();
  }

  buscarPorId(id: number): Promise<CodigoSituacaoTributariaPis> {
    return this.http.get<CodigoSituacaoTributariaPis>(`${this.codigosSituacaoTributariasPisUrl}/${id}`).toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.codigosSituacaoTributariasPisUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }
}
