export class Caixa{

    caixaPartidaDobrada: string;
    codigoConta: string;
    contaCorrente: string;
    descricao: string;
    empresa: string;
    grupo: string;
    id: number;
    idCaixa: string;
    letraCaixa: string;
    modop: string;
    objetivoCaixa: string;
    sequencia: number;
    series: string;
    tipo: string;

    constructor(
        
        caixaPartidaDobrada?: string,
        codigoConta?: string,
        contaCorrente?: string,
        descricao?: string,
        empresa?: string,
        grupo?: string,
        id?: number,
        idCaixa?: string,
        letraCaixa?: string,
        modop?: string,
        objetivoCaixa?: string,
        sequencia?: number,
        series?: string,
        tipo?: string,
    ){
        this.caixaPartidaDobrada = caixaPartidaDobrada
        this.codigoConta = codigoConta
        this.contaCorrente = contaCorrente
        this.descricao = descricao
        this.empresa = empresa
        this.grupo = grupo
        this.id = id
        this.idCaixa = idCaixa
        this.letraCaixa = letraCaixa
        this.modop = modop
        this.objetivoCaixa = objetivoCaixa
        this.sequencia = sequencia
        this.series = series
        this.tipo = tipo

    }
}