import { Injectable } from '@angular/core';

import { MoneyHttp } from "app/seguranca/money-http";

import { Estado } from './../core/models/Estado';
import { Cidade } from './../core/models/Cidade';

import { environment } from "./../../environments/environment";

@Injectable()
export class SharedService {

  cidadesUrl: string;
  estadosUrl: string;

  constructor (
    private http: MoneyHttp
  ) { 
    this.estadosUrl = `${environment.apiUrl}/estados`;
    this.cidadesUrl = `${environment.apiUrl}/cidades`;
  }

  listarEstados(): Promise<Estado[]> {
    return this.http.get<Estado[]>(this.estadosUrl).toPromise();
  }

  listarCidades(id: number): Promise<Cidade[]> {
    return this.http.get<Cidade[]>(`${this.cidadesUrl}/${id}`).toPromise();
  }

  buscaCidadePeloNome(nome: string, uf: string): Promise<Cidade> {
    return this.http.get<Cidade>(`${this.cidadesUrl}/${nome}/${uf}`).toPromise();
  }
}
