import { Injectable } from '@angular/core';
import {MoneyHttp} from '../seguranca/money-http';
import {environment} from '../../environments/environment';
import {Conferencia} from '../core/models/Conferencia';

@Injectable()
export class ConferenciaService {

  url: string;

  constructor(private http: MoneyHttp) {
    this.url = `${environment.apiUrl}/conferencia`;
  }

  processarCodigoBarras(conferencia: Conferencia): Promise<Conferencia> {
    return this.http.put<Conferencia>(`${this.url}/processarCodigoBarras`, conferencia).toPromise();
  }

  processarAtualizarEstoqueProdutosConferidos(conferencia: Conferencia): Promise<Conferencia> {
    return this.http.put<Conferencia>(`${this.url}/atualizarEstoqueProdutosConferidos`, conferencia).toPromise();
  }

  concluirConferencia(conferencia: Conferencia): Promise<Conferencia> {
    return this.http.put<Conferencia>(`${this.url}/concluirConferencia`, conferencia).toPromise();
  }

}
