import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as moment from 'moment';

import 'rxjs/add/operator/toPromise';

import {environment} from './../../environments/environment';
import {MoneyHttp} from 'app/seguranca/money-http';

import {Titulo} from 'app/core/models/Titulo';
import {BoletoConf} from '../core/models/BoletoConf';
import {Empresa} from '../core/models/Empresa';
import {Serie} from '../core/models/Serie';
import {Vendedor} from '../core/models/Vendedor';
import {Cliente} from '../core/models/Cliente';
import {TituloCobranca} from '../core/models/TituloCobranca';
import {TituloBaixaRetorno} from '../core/models/TituloBaixaRetorno';

export class ControleTitulo{

  empresa: Empresa;

  dataInicio: Date;
  dataFim: Date;

  mostrarTitulosAbertos: boolean;
  mostrarTitulosFechados: boolean;
  mostrarTitulosCancelados: boolean;
  ocultarTitulosAcaoJudicial: boolean;
  ocultarTitulosCartorio: boolean;
  tipoOrdenacao: string;

  valoresAcima: number;
  diasAtraso: number;
  diasAberto: number;

  clientes: Array<Cliente>;
  grupo: string;

  vendedor: Vendedor;

  boleto: string;

  documentos: Array<string>;
  serie: Serie;

  dataCalculo: Date;
  jurosMensal: number;

  banco: string;

  titulos: Array<Titulo>;
  titulo: Titulo;

  titulosCobranca: Array<TituloCobranca>;
  tituloBaixasRetorno: Array<TituloBaixaRetorno>;


}

export class TituloFiltro {
  codigo: string;
  empresa: string;
  cliente: string;
  emissaoDe: Date;
  emissaoAte: Date;
  serie: string;
  local: string;
  pesquisa: string;
  status: string;
  cancelados: string;
  pagina = 0;
  itensPorPagina = 20;
}

@Injectable()
export class TituloService {

  titulosUrl: string;
  boletoConfUrl: string;

  constructor(private http: MoneyHttp) {
    this.titulosUrl = `${environment.apiUrl}/titulos`;
    this.boletoConfUrl = `${environment.apiUrl}/boletosConfs`;
  }

  pesquisar(filtro: TituloFiltro): Promise<any> {

    const params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString(),
        empresa: filtro.empresa,
        emissaoDe: moment(filtro.emissaoDe).format('YYYY-MM-DD'),
        emissaoAte: moment(filtro.emissaoAte).format('YYYY-MM-DD'),
        pesquisa: filtro.pesquisa,
        codigo: filtro.codigo,
        serie: filtro.serie,
        local: filtro.local,
        status: filtro.status,
        cancelados: filtro.cancelados
      }
    });

    return this.http
      .get<any>(`${this.titulosUrl}?periodo`, {params})
      .toPromise()
      .then(response => {
        const titulos = response.content;

        const resultado = {
          titulos,
          total: response.totalElements
        };

        return resultado;
      });
  }

  pesquisarProCodigo(filtro: TituloFiltro): Promise<any> {

    let titulosUrlPesquisar: string = `${this.titulosUrl}/filter/codigo`;

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString(),
      }
    });

    if (filtro.codigo)
      params = params.append('codigo', filtro.codigo);

    return this.http
      .get<any>(titulosUrlPesquisar, {params})
      .toPromise();
      // .then(response => {
      //   const resultado = {
      //     titulos : response.content,
      //     total: response.totalElements
      //   };
      //   return resultado;
      // });

  }

  listarTodas(): Promise<any> {
    return this.http
      .get<any>(`${this.titulosUrl}/lista`)
      .toPromise()
      .then(response => response.content);
  }

  adicionar(titulo: Titulo): Promise<Titulo> {
    return this.http.post<Titulo>(this.titulosUrl, titulo).toPromise();
  }

  atualizar(titulo: Titulo): Promise<Titulo> {
    return this.http
      .put<Titulo>(`${this.titulosUrl}/${titulo.id}`, titulo)
      .toPromise();
  }

  buscarPorId(id: number): Promise<Titulo> {
    return this.http.get<Titulo>(`${this.titulosUrl}/${id}`).toPromise();
  }

  impressaoAvisoVencimento(contaCedente: string, titulo: Titulo): Promise<Blob> {
    return this.http.get<Blob>(`${this.titulosUrl}/impressaoAvisoVencimento/${contaCedente}/${titulo.id}`,
      {responseType: 'blob' as 'json'}).toPromise();
  }

  listarContasCedentes(): Promise<Array<BoletoConf>> {
    return this.http
      .get<Array<BoletoConf>>(`${this.boletoConfUrl}/lista`)
      .toPromise();
  }


  listarPorPeriodoSemPagamentoAntecipado(empresa: string, cliente: string, de: Date, ate: Date): Promise<Array<Titulo>> {
    let params = new HttpParams({
      fromObject: {}
    });

    if (empresa) {
      params = params.append(
        'empresa', empresa
      );
    }

    if (cliente) {
      params = params.append(
        'codigo', cliente
      );
    }

    if (de) {
      params = params.append(
        'dataDe',
        moment(de).format('YYYY-MM-DD 00:00')
      );
    }

    if (ate) {
      params = params.append(
        'dataAte',
        moment(ate).format('YYYY-MM-DD 23:59')
      );
    }

    return this.http
      .get<Array<Titulo>>(`${this.titulosUrl}/listarPorPeriodoSemPagamentoAntecipado/empresa/codigo/dataDe/dataAte`,
        {params})
      .toPromise()
  }


  buscarTitulosPorDocumentoFiscal(empresa: string, documento: string, serie: string): Promise<Titulo[]> {
    return this.http.get<Titulo[]>(`${this.titulosUrl}/porEmpresaDocumentoSerie/${empresa}/${documento}/${serie}`).toPromise();
  }


  impressaoBoleto(titulo: Titulo, empresa: string): Promise<Blob> {
    return this.http.get<Blob>(`${this.titulosUrl}/impressaoBoleto/${titulo.id}/${empresa}`,
      {responseType: 'blob' as 'json'}).toPromise();
  }

  validarDadosBoleto(titulo: Titulo, empresa: string): Promise<Titulo> {
    return this.http.get<Titulo>(`${this.titulosUrl}/validarDadosBoleto/${titulo.id}/${empresa}`).toPromise();
  }

  pesquisarControleTitulo(controleTitulo: ControleTitulo): Promise<Array<Titulo>> {
    return this.http.put<Array<Titulo>>(`${this.titulosUrl}/consultarControleTitulo`, controleTitulo).toPromise();
  }

  buscarPorDocumento(documento: string): Promise<Titulo> {
    return this.http.get<Titulo>(`${this.titulosUrl}/porDocumento/${documento.replace('/','-')}`).toPromise();
  }


  importarArquivoRetorno(file: File, banco: string, empresa: string, usuario: string): Promise<any> {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    formdata.append('banco', banco);
    formdata.append('empresa', empresa);
    formdata.append('usuario', usuario);
    return this.http.post(`${this.titulosUrl}/processarArquivoRetorno`, formdata).toPromise();
  }


}
