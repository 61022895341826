import {HttpParams, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';

import 'rxjs/add/operator/toPromise';

import {environment} from './../../environments/environment';
import {MoneyHttp} from 'app/seguranca/money-http';

import {Cartao} from './../core/models/Cartao';
import {Empresa} from '../core/models/Empresa';
import {Serie} from '../core/models/Serie';
import {NaturezaOperacao} from '../core/models/NaturezaOperacao';
import {Vendedor} from '../core/models/Vendedor';
import {Tecnico} from '../core/models/Tecnico';
import {ClienteTipo} from '../core/models/ClienteTipo';
import {FormaPagamento} from '../core/models/FormaPagamento';
import {Cliente} from '../core/models/Cliente';
import {Produto} from '../core/models/Produto';
import {Caixa} from '../core/models/Caixa';


export class CartaoFiltro {
  nome: string;
  codigo: string;
  pagina = 0;
  itensPorPagina = 10;
}

export class ControleCartoes{

  dataInicio: Date;
  dataFim: Date;

  empresa: Empresa;
  cliente: Cliente;
  operadora: Cartao;

  caixas: Array<Caixa>
  excetoCaixas: boolean;

  boleto: string;

  boletos: boolean;
  parcelas: boolean;

  caixa: string;

  somenteBoletosSemDocumento: boolean;
  somenteBoletosConciliados: boolean;
  incluirBoletosCancelados: boolean;

  tipoOrdenacao: string;

}



@Injectable()
export class CartaoService {
  cartoesUrl: string;

  constructor(private http: MoneyHttp) {
    this.cartoesUrl = `${environment.apiUrl}/cartoes`;
  }

  pesquisar(filtro: CartaoFiltro): Promise<any> {

    let cartoesUrlPesquisa = `${this.cartoesUrl}/filter/nome`;

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    if (filtro.nome) {

      params = params.append('nome', filtro.nome);

    } else if (filtro.codigo) {

      params = params.append('codigo', filtro.codigo);
      cartoesUrlPesquisa = `${this.cartoesUrl}/filter/codigo`;

    }
    return this.http
      .get<any>(cartoesUrlPesquisa, {params})
      .toPromise()
      .then(response => {
        const cartoes = response.content;
        const resultado = {
          cartoes,
          total: response.totalElements
        };

        return resultado;
      });
  }

  listarTodas(): Promise<Array<Cartao>> {
    return this.http
      .get<Array<Cartao>>(`${this.cartoesUrl}/lista`)
      .toPromise()
      .then(response => response);
  }

  listarTodasPorNome(nome: string): Promise<any> {
    return this.http
      .get<any>(`${this.cartoesUrl}/listaTodas/${nome}`)
      .toPromise()
      .then(response => response);
  }

  adicionar(cartao: Cartao): Promise<Cartao> {
    return this.http.post<Cartao>(this.cartoesUrl, cartao).toPromise();
  }

  atualizar(cartao: Cartao): Promise<Cartao> {
    return this.http
      .put<Cartao>(`${this.cartoesUrl}/${cartao.id}`, cartao)
      .toPromise();
  }

  buscarPorId(id: number): Promise<Cartao> {
    return this.http.get<Cartao>(`${this.cartoesUrl}/${id}`).toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.cartoesUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }

  buscarPorCodigoCartao(codigo: string): Promise<Cartao> {
    return this.http.get<Cartao>(`${this.cartoesUrl}/codigo/${codigo}`).toPromise();
  }


}
