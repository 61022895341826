import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import 'rxjs/add/operator/toPromise';

import { environment } from './../../environments/environment';
import { MoneyHttp } from 'app/seguranca/money-http';

import { Parametro } from './../core/models/Parametro';
import {Titulo} from '../core/models/Titulo';


export class ParametroFiltro {
  empresaNome: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class ParametroService {

  parametrosUrl: string;
  sistemaParametrosValoresUrl: string;

  constructor(private http: MoneyHttp) {
    this.parametrosUrl = `${environment.apiUrl}/parametros`;
    this.sistemaParametrosValoresUrl = `${environment.apiUrl}/sistemaParametrosValores`;
  }

  pesquisar(filtro: ParametroFiltro): Promise<any> {

    const parametrosUrlPesquisar = `${this.parametrosUrl}/filter/empresaNome`;

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    if (filtro.empresaNome) {

      params = params.append('empresaNome', filtro.empresaNome);

    }
    return this.http
    .get<any>(parametrosUrlPesquisar, { params })
    .toPromise()
    .then(response => {
      const parametros = response.content;

      const resultado = {
        parametros,
        total: response.totalElements
      };

      return resultado;
    });
}

  buscar(): Promise<Parametro> {
    return this.http.get<Parametro>(this.parametrosUrl).toPromise();
  }

  adicionar(parametros: Parametro): Promise<Parametro> {
    return this.http.post<Parametro>(this.parametrosUrl, parametros).toPromise();
  }

  atualizar(parametro: Parametro): Promise<Parametro> {
    return this.http.put<Parametro>(`${this.parametrosUrl}/${parametro.id}`, parametro).toPromise();
  }

  buscarPorId(id: number): Promise<Parametro> {
    return this.http.get<Parametro>(`${this.parametrosUrl}/${id}`).toPromise();
  }

  buscarParametros(empresaLetra: string): Promise<Parametro> {
    const params = new HttpParams({
      fromObject: {
        empresaLetra: empresaLetra
      }
    });
    return this.http.get<Parametro>(`${this.parametrosUrl}?empresa`, {params}).toPromise();
  }

  buscarPorNomeParametroEmpresa(empresa: string, parametro): Promise<string> {
    return this.http.get<string>(`${this.sistemaParametrosValoresUrl}/nomeParametroEmpresa/${parametro}/${empresa}`).toPromise();
  }

}
