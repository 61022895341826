export class Vendedor {
  id: number;
  codigo: string;
  nome: string;
  ativo: string;
  atualizarData: Date;
  atualizarOperacao: string;
  cliente: string;
  comissaoVendaCasada: number;
  comissaoVendaSolteira: number;
  empresa: string;
  excluirCompra: string;
  excluirData: Date;
  excluirUsuario: string;
  idUsuario: string;
  incluirCompra: string;
  incluirData: Date;
  incluirUsuario: string;
  modificarCompra: string;
  modificarData: Date;
  modificarOperacao: string;
  modificarUsuario: string;
  setor: string;
  vendasCliente: string;
  vendasComissao: number;
  vendasF1: string;
  vendasF2: string;
  vendasF3: string;
  vendasF4: string;
  vendasF5: string;

  constructor(

    id?: number,
    codigo?: string,
    nome?: string,
    ativo?: string,
    atualizarData?: Date,
    atualizarOperacao?: string,
    cliente?: string,
    comissaoVendaCasada?: number,
    comissaoVendaSolteira?: number,
    empresa?: string,
    excluirCompra?: string,
    excluirData?: Date,
    excluirUsuario?: string,
    idUsuario?: string,
    incluirCompra?: string,
    incluirData?: Date,
    incluirUsuario?: string,
    modificarCompra?: string,
    modificarData?: Date,
    modificarOperacao?: string,
    modificarUsuario?: string,
    setor?: string,
    vendasCliente?: string,
    vendasComissao?: number,
    vendasF1?: string,
    vendasF2?: string,
    vendasF3?: string,
    vendasF4?: string,
    vendasF5?: string

  ) {

    this.id = id,
    this.codigo = codigo,
    this.nome = nome,
    this.ativo = ativo,
    this.atualizarData = atualizarData,
    this.atualizarOperacao = atualizarOperacao,
    this.cliente = cliente,
    this.comissaoVendaCasada = comissaoVendaCasada,
    this.comissaoVendaSolteira = comissaoVendaSolteira,
    this.empresa = empresa,
    this.excluirCompra = excluirCompra,
    this.excluirData = excluirData,
    this.excluirUsuario = excluirUsuario,
    this.idUsuario = idUsuario,
    this.incluirCompra = incluirCompra,
    this.incluirData = incluirData,
    this.incluirUsuario = incluirUsuario,
    this.modificarCompra = modificarCompra,
    this.modificarData = modificarData,
    this.modificarOperacao = modificarOperacao,
    this.modificarUsuario = modificarUsuario,
    this.setor = setor,
    this.vendasCliente = vendasCliente,
    this.vendasComissao = vendasComissao,
    this.vendasF1 = vendasF1,
    this.vendasF2 = vendasF2,
    this.vendasF3 = vendasF3,
    this.vendasF4 = vendasF4,
    this.vendasF5 = vendasF5; 

  }
}
