import { Injectable } from '@angular/core';
import {MoneyHttp} from '../seguranca/money-http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConverterPdfToExcelService {

  private url: string;

  constructor(
    private http: MoneyHttp,
  ) {
    this.url = `${environment.apiUrl}/converterPdfToExcel`;
  }

  converterPdfToExcel(file: File): Promise<Blob> {
    var formdata: FormData = new FormData();
    formdata.append('file', file);
    return this.http.put<Blob>(`${this.url}/converterPdfToExcel`, formdata,{responseType: 'blob' as 'json'}).toPromise();
  }

}
