import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import "rxjs/add/operator/toPromise";

import { environment } from "./../../environments/environment";
import { MoneyHttp } from "app/seguranca/money-http";

import { NaturezaOperacao } from "app/core/models/NaturezaOperacao";
import {SelectItem} from 'primeng/api';

export class NaturezaOperacaoFiltro {
  descricao: string;
  codigo: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class NaturezaOperacaoService {

  naturezasOperacoesUrl: string;

  constructor(private http: MoneyHttp) {
    this.naturezasOperacoesUrl = `${environment.apiUrl}/naturezasOperacoes`;
  }

  pesquisar(filtro: NaturezaOperacaoFiltro): Promise<any> {

    let naturezasOperacoesUrlPesquisar: string = `${this.naturezasOperacoesUrl}/filter/codigo`;

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    if (filtro.codigo) {

      params = params.append("codigo", filtro.codigo);
      
    } else if (filtro.descricao) {

      params = params.append("descricao", filtro.descricao);
      naturezasOperacoesUrlPesquisar = `${this.naturezasOperacoesUrl}/filter/descricao`;

    }
    return this.http
    .get<any>(naturezasOperacoesUrlPesquisar, { params })
    .toPromise()
    .then(response => {
      const naturezasOperacoes = response.content;

      const resultado = {
        naturezasOperacoes,
        total: response.totalElements
      };

      return resultado;
    });
}

  listarTodas(): Promise<Array<NaturezaOperacao>> {
    return this.http
      .get<any>(`${this.naturezasOperacoesUrl}/lista`)
      .toPromise()
      .then(response => response);
  }

  adicionar(NaturezaOperacao: NaturezaOperacao): Promise<NaturezaOperacao> {
    return this.http.post<NaturezaOperacao>(this.naturezasOperacoesUrl, NaturezaOperacao).toPromise();
  }

  atualizar(NaturezaOperacao: NaturezaOperacao): Promise<NaturezaOperacao> {
    return this.http
      .put<NaturezaOperacao>(`${this.naturezasOperacoesUrl}/${NaturezaOperacao.id}`, NaturezaOperacao)
      .toPromise();
  }

  buscarPorId(id: number): Promise<NaturezaOperacao> {
    return this.http.get<NaturezaOperacao>(`${this.naturezasOperacoesUrl}/${id}`).toPromise();
  }

  buscarPorCodigo(codigo: string): Promise<NaturezaOperacao> {
    return this.http.get<NaturezaOperacao>(`${this.naturezasOperacoesUrl}/descricaoNatureza/${codigo}`).toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.naturezasOperacoesUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }


  getNaturezaOperacoes(): Promise<SelectItem[]> {
    return new Promise((resolve, reject) => {
      this.listarTodas().then(ss => {
          resolve(ss.map(naturezaOperacao => {
              return {
                label: `${naturezaOperacao.codigo} - ${naturezaOperacao.descricao}`,
                value: naturezaOperacao.codigo
              }
            })
          );
        }
      ).catch(error => reject(error));
    });
  }

}
