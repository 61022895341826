import { HttpParams, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

import "rxjs/add/operator/toPromise";

import { environment } from "./../../environments/environment";
import { MoneyHttp } from "app/seguranca/money-http";

import { Produto } from "./../core/models/Produto";
import { ProdutoEmpresa } from 'app/core/models/ProdutoEmpresa';
import {VendaProgressiva} from '../core/models/VendaProgressiva';
import {ProdutoPesquisado} from '../core/models/ProdutoPesquisado';

export class ProdutoFiltro {
  nome: string;
  codigo: string;
  aplicacao: string;
  codigoMarca: string;
  descricaoMarca: string;
  correlacao: string;
  original: string;
  novocodigo: string;
  codigoInterno: string;
  codigoFabricante: string;
  codigoAntigo: string;
  observacoes: string;
  globalFilter: string;

  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class ProdutoService {
  produtosUrl: string;
  produtosEmpresasUrl: string;
  produtosUrlPesquisar: string;
  produtoDpUrl: string;
  vendaProgressivaUrl: string;
  produtoPesquisadoUrl: string;

  constructor(private http: MoneyHttp) {
    this.produtosUrl = `${environment.apiUrl}/produtos`;
    this.produtosEmpresasUrl = `${environment.apiUrl}/produtosEmpresas`;
    this.produtoDpUrl = `${environment.apiUrl}/produtosDp`;
    this.vendaProgressivaUrl = `${environment.apiUrl}/vendaProgressiva`;
    this.produtoPesquisadoUrl= `${environment.apiUrl}/produtoPesquisado`;
  }

  pesquisar(filtro: ProdutoFiltro): Promise<any> {

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    params = this.setFiltroPesquisa(filtro, params);

    return this.http
    .get<any>(this.produtosUrlPesquisar, { params })
    .toPromise()
    .then(response => {
      const produtos = response.content;

      const resultado = {
        produtos,
        total: response.totalElements
      };

      return resultado;
    });
}
  similares(filtro: ProdutoFiltro): Promise<any> {

    const produtosUrlSimilares = `${this.produtosUrl}/similares/correlacao/codigo`;

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });
    params = params.append('correlacao', filtro.correlacao);
    params = params.append('codigo', filtro.codigo);
    return this.http
    .get<any>(produtosUrlSimilares, { params})
    .toPromise()
    .then(response => {
           return response;
    });
  }

  promocoes(): Promise<any> {
    const produtosPromocoesUrl = `${this.produtosUrl}/promocoes`;

    let params = new HttpParams({
      fromObject: {
        page: '0',
        size: '50'
      }
    });
    return this.http
    .get<any>(produtosPromocoesUrl, { params})
    .toPromise()
    .then(response => {
      const promocoes = response.content;

      const resultado = {
        promocoes,
        total: response.totalElements
      };
      return resultado;
    });
  }

  listarTodas(): Promise<any> {
    return this.http
      .get<any>(this.produtosUrl)
      .toPromise()
      .then(response => response.content);
  }

  adicionar(produto: Produto): Promise<Produto> {
    return this.http.post<Produto>(this.produtosUrl, produto).toPromise();
  }

  atualizar(produto: Produto): Promise<Produto> {
    return this.http
      .put<Produto>(`${this.produtosUrl}/${produto.id}`, produto)
      .toPromise();
  }

  buscarPorId(id: number): Promise<Produto> {
    return this.http.get<Produto>(`${this.produtosUrl}/${id}`).toPromise();
  }

  next(cod_prd: string): Promise<Produto> {
    const params = new HttpParams({
      fromObject: {
        codigo: cod_prd
      }
    });
    return this.http.get<Produto>(`${this.produtosUrl}?next`, { params }).toPromise();
  }

  previous(cod_prd: string): Promise<Produto> {
    const params = new HttpParams({
      fromObject: {
        codigo: cod_prd
      }
    });
    return this.http.get<Produto>(`${this.produtosUrl}?previous`, { params }).toPromise();
  }

  buscarProdutoPeloCodigo(cod_prd: string): Promise<Produto> {
    const params = new HttpParams({
      fromObject: {
        codigo: cod_prd
      }
    });
    return this.http.get<Produto>(`${this.produtosUrl}?produto`, { params }).toPromise();
  }


  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.produtosUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }

  adicionarImagem(file: File, directory: string): Promise<any> {

    const formdata: FormData = new FormData();

    formdata.append('file', file);

    return this.http.post(`${this.produtosUrl}/upload/${directory}`, formdata).toPromise();
  }

  alterarImagem(file: File, directory: string): Promise<any> {

    const formdata: FormData = new FormData();

    formdata.append('file', file);

    return this.http.put(`${this.produtosUrl}/update/${directory}`, formdata).toPromise();
  }

  deletarImagem(directory: string, fileName: string): Promise<any> {
    return this.http.delete(`${this.produtosUrl}/delete/${directory}/${fileName}`).toPromise();
  }

  buscarImagem(directory: string, fileName: string): Promise<Blob> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'image/jpeg',
      'Accept': 'image/jpeg'
    });
    return this.http.get<Blob>(`${this.produtosUrl}/download/${directory}/${fileName}`,
    {headers: headers, responseType: 'blob'}).toPromise();
  }

  atualizarEstoque(prodempr: ProdutoEmpresa): Promise<ProdutoEmpresa> {
    return this.http
      .put<ProdutoEmpresa>(`${this.produtosEmpresasUrl}/${prodempr.id}`, prodempr)
      .toPromise();
  }

  setFiltroPesquisa(filtro: ProdutoFiltro, params: HttpParams): HttpParams {

    if (filtro.nome && filtro.aplicacao && filtro.descricaoMarca) {
      params = params.append('nome', filtro.nome);
      params = params.append('aplicacao', filtro.aplicacao);
      params = params.append('descricaoMarca', filtro.descricaoMarca);
      this.produtosUrlPesquisar = `${this.produtosUrl}?parametros`;
    } else if (filtro.nome && filtro.aplicacao) {
      params = params.append('nome', filtro.nome);
      params = params.append('aplicacao', filtro.aplicacao);
      this.produtosUrlPesquisar = `${this.produtosUrl}?parametros`;
    } else if (filtro.nome && filtro.descricaoMarca) {
      params = params.append('nome', filtro.nome);
      params = params.append('descricaoMarca', filtro.descricaoMarca);
      this.produtosUrlPesquisar = `${this.produtosUrl}?parametros`;
    } else if (filtro.aplicacao && filtro.descricaoMarca) {
      params = params.append('aplicacao', filtro.aplicacao);
      params = params.append('descricaoMarca', filtro.descricaoMarca);
      this.produtosUrlPesquisar = `${this.produtosUrl}?parametros`;
    } else if (filtro.nome) {
      params = params.append('nome', filtro.nome);
      this.produtosUrlPesquisar = `${this.produtosUrl}/filter/nome`;
    } else if (filtro.codigo) {
      params = params.append('codigo', filtro.codigo);
      this.produtosUrlPesquisar = `${this.produtosUrl}/filter/codigo`;
    } else if (filtro.aplicacao) {
      params = params.append('aplicacao', filtro.aplicacao);
      this.produtosUrlPesquisar = `${this.produtosUrl}?parametros`;
    } else if (filtro.descricaoMarca) {
      params = params.append('descricaoMarca', filtro.descricaoMarca);
      this.produtosUrlPesquisar = `${this.produtosUrl}/filter/descricaoMarca`;
    } else if (filtro.original) {
      params = params.append('original', filtro.original);
      this.produtosUrlPesquisar = `${this.produtosUrl}/filter/original`;
    }
    return params;
  }

  criarEstoqueProdutos(): Promise<any> {
    return this.http
      .get<any>(`${this.produtosUrl}/criarEstoqueProduto`)
      .toPromise()
      .then(() => null);
  }

  adicionarProdutoDp(codigoFornecedor: string, codigoFabricante: string, descricaoFabricante: string, codigo: string): Promise<any> {

    let params = new HttpParams({
      fromObject: {
      }
    });

    params = params.append(
      'codigoFornecedor',  codigoFornecedor);

    params = params.append(
      'codigoFabricante',  codigoFabricante);

    params = params.append(
      'descricaoFabricante',  descricaoFabricante);

    params = params.append(
      'codigo',  codigo);

    return this.http
      .get<any>(`${this.produtoDpUrl}/criar/codigoFornecedor/codigoFabricante/descricaoFabricante/codigo`, {params})
      .toPromise();

  }

  buscarPorCodigo(codigo: string): Promise<Produto> {
    return this.http.get<Produto>(`${this.produtosUrl}/codigo/${codigo}`).toPromise();
  }

  nextByMarca(cod_marca: string, cod_prd: string): Promise<Produto> {
    const params = new HttpParams({
      fromObject: {
        codigo: cod_prd,
        codigoMarca: cod_marca
      }
    });
    return this.http.get<Produto>(`${this.produtosUrl}?nextByMarca`, { params }).toPromise();
  }

  previousByMarca(cod_marca: string, cod_prd: string): Promise<Produto> {
    const params = new HttpParams({
      fromObject: {
        codigo: cod_prd,
        codigoMarca: cod_marca
      }
    });
    return this.http.get<Produto>(`${this.produtosUrl}?previousByMarca`, { params }).toPromise();
  }

  buscarFatorLucroProgressivo(valor: number): Promise<VendaProgressiva> {
    return this.http.get<VendaProgressiva>(`${this.vendaProgressivaUrl}/fatorProgressivoPorFaixa/${valor}`).toPromise();
  }


  criarNovoNumeroCorrelacao(produto: Produto): Promise<Produto> {
    return this.http
      .put<Produto>(`${this.produtosUrl}/criarNovoNumeroCorrelacao/${produto.id}`, produto)
      .toPromise();
  }

  buscarProdutoPesquisadoMarcado(usuario: string): Promise<Array<ProdutoPesquisado>> {
    return this.http.get<Array<ProdutoPesquisado>>(`${this.produtoPesquisadoUrl}/marcadosPorUsuario/${usuario}`).toPromise();
  }

  buscarProdutoPesquisados(usuario: string): Promise<Array<ProdutoPesquisado>> {
    return this.http.get<Array<ProdutoPesquisado>>(`${this.produtoPesquisadoUrl}/pesquisadosPorUsuario/${usuario}`).toPromise();
  }


  buscarPorCodigoMarca(codigo: string): Promise<any> {
    return this.http.get<any>(`${this.produtosUrl}/porCodigoMarca/${codigo}`).toPromise()
      .then(response => {
      const produtos: Array<Produto> = response;

      const resultado = {
        produtos,
        total: produtos.length
      };

      return resultado;
    });
  }

  buscarPorCodigoMarcaOrdenadoPorNome(codigo: string): Promise<any> {
    return this.http.get<any>(`${this.produtosUrl}/porCodigoMarcaOrdemNome/${codigo}`).toPromise()
      .then(response => {
        const produtos: Array<Produto> = response;

        const resultado = {
          produtos,
          total: produtos.length
        };

        return resultado;

    });

  }

  nextByNomeMarca(cod_marca: string, nome_prod: string): Promise<Produto> {
    const params = new HttpParams({
      fromObject: {
        nome: nome_prod,
        codigoMarca: cod_marca
      }
    });
    return this.http.get<Produto>(`${this.produtosUrl}?nextByNomeMarca`, { params }).toPromise();
  }

  previousByNomeMarca(cod_marca: string, nome_prod: string): Promise<Produto> {
    const params = new HttpParams({
      fromObject: {
        nome: nome_prod,
        codigoMarca: cod_marca
      }
    });
    return this.http.get<Produto>(`${this.produtosUrl}?previousByNomeMarca`, { params }).toPromise();
  }


  buscarPorCodigoPeloFiltro(cod_prd: string): Promise<Produto> {
    const params = new HttpParams({
      fromObject: {
        codigo: cod_prd
      }
    });
    return this.http.get<Produto>(`${this.produtosUrl}?porCodigo`, { params }).toPromise();
  }

  importarExcelPrecoVendaList(file: File): Promise<any> {
    var formData: FormData = new FormData();
    formData.append('file', file);

    return this.http.post(`${this.produtosUrl}/importExcelPreco`, formData).toPromise();
  }


  adicionarProdutoPesquisado(produtoPesquisado: ProdutoPesquisado): Promise<ProdutoPesquisado> {
    return this.http.post<ProdutoPesquisado>(this.produtoPesquisadoUrl, produtoPesquisado).toPromise();
  }

  mostrarProdutoPorUsuario(usuario: string): Promise<ProdutoPesquisado> {
    return this.http.get<ProdutoPesquisado>(`${this.produtoPesquisadoUrl}/mostrarProdutoPorUsuario/${usuario}`).toPromise();
  }


  processarCriacaoEstoqueLoja(origem: string , destino: string, usuario: string): Promise<any> {
    const url = `${this.produtosEmpresasUrl}/criarEstoqueLojaNova/origem/destino/usuario`;

    const params = new HttpParams({
      fromObject: {
        origem,
        destino,
        usuario: usuario
      }
    });

    return this.http
      .get<any>(url , {params})
      .toPromise()

  }



}
