import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import "rxjs/add/operator/toPromise";
import { Observable } from "rxjs";

import { environment } from "./../../environments/environment";
import { Cheque } from "./../core/models/Cheque";
import { MoneyHttp } from "app/seguranca/money-http";

export class ChequeFiltro {
    codigo: string;
    pagina = 0;
    itensPorPagina = 10;
}

@Injectable()
export class ChequeService {

    chequesUrl: string;

    constructor(private http: MoneyHttp) {
        this.chequesUrl = `${environment.apiUrl}/cheques`;
    }

    pesquisar(filtro: ChequeFiltro): Promise<any> {

        let chequesUrlPesquisar = `${this.chequesUrl}/filter/codigo`;

        let params = new HttpParams({
            fromObject: {
                page: filtro.pagina.toString(),
                size: filtro.itensPorPagina.toString()
            }
        });


        (filtro.codigo)
        params = params.append('codigo', filtro.codigo);


        return this.http
            .get<any>(chequesUrlPesquisar, { params })
            .toPromise()
            .then(response => {
                const cheque = response.content;

                const resultado = {
                    cheque,
                    total: response.totalElements
                };

                return resultado;
            });
    }

    listarTodas(): Promise<any> {
        return this.http
            .get<any>(`${this.chequesUrl}/lista`)
            .toPromise()
            .then(response => response.content);
    }

    adicionar(cheque: Cheque): Promise<Cheque> {
        this.chequesUrl = `${environment.apiUrl}/cheques`;
        return this.http.post<Cheque>(this.chequesUrl, cheque).toPromise();
      }

    buscarPorId(id: number): Promise<Cheque> {
        return this.http.get<Cheque>(`${this.chequesUrl}/${id}`).toPromise();
    }
}