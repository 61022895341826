export class ClientesDescVendedor {

    id: number;
    codigoCliente: string;
    codigoVendedor: string;

    constructor (
        id?: number,
        codigoCliente?: string,
        codigoVendedor?: string,
        
    ) {
        this.id = id;
        this.codigoCliente = codigoCliente;
        this.codigoVendedor = codigoVendedor;
    }

}