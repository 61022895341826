import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import "rxjs/add/operator/toPromise";

import { environment } from "./../../environments/environment";
import { Cartorio } from "./../core/models/Cartorio";
import { MoneyHttp } from "app/seguranca/money-http";

export class CartorioFiltro {
  nome: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class CartorioService {
  cartoriosUrl: string;

  constructor(private http: MoneyHttp) {
    this.cartoriosUrl = `${environment.apiUrl}/cartorios`;
  }

  pesquisar(filtro: CartorioFiltro): Promise<any> {
    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });
    if (filtro.nome) {
      params = params.append("nome", filtro.nome);
    }
    return this.http
      .get<any>(`${this.cartoriosUrl}`, { params })
      .toPromise()
      .then(response => {
        const cartorios = response.content;

        const resultado = {
          cartorios,
          total: response.totalElements
        };

        return resultado;
      });
  }

  listarTodas(): Promise<any> {
    return this.http
      .get<any>(this.cartoriosUrl)
      .toPromise()
      .then(response => response.content);
  }

  adicionar(fornecedor: Cartorio): Promise<Cartorio> {
    return this.http.post<Cartorio>(this.cartoriosUrl, fornecedor).toPromise();
  }

  atualizar(fornecedor: Cartorio): Promise<Cartorio> {
    return this.http
      .put<Cartorio>(`${this.cartoriosUrl}/${fornecedor.id}`, fornecedor)
      .toPromise();
  }

  buscarPorId(id: number): Promise<Cartorio> {
    return this.http.get<Cartorio>(`${this.cartoriosUrl}/${id}`).toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.cartoriosUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }
}
