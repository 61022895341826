import { Injectable } from '@angular/core';

import { environment } from './../../environments/environment';

import { MoneyHttp } from "app/seguranca/money-http";
import { Observable } from 'rxjs';

import { ClienteFormaPagamento } from 'app/core/models/ClienteFormaPagamento';

@Injectable()
export class ClienteFormaPagamentoService {

  private clienteFormaPagamentoUrl: string;

  constructor (
    private http: MoneyHttp
  ) {
    this.clienteFormaPagamentoUrl =  `${environment.apiUrl}/clienteFormasPagamentos`;
  }

  adicionar(clienteFormaPagamento: ClienteFormaPagamento): Observable<ClienteFormaPagamento> {
    return this.http.post<ClienteFormaPagamento>(this.clienteFormaPagamentoUrl, clienteFormaPagamento);
  }

  atualizar(clienteFormaPagamento: ClienteFormaPagamento): Observable<ClienteFormaPagamento> {
    return this.http.put<ClienteFormaPagamento>(`${this.clienteFormaPagamentoUrl}/${clienteFormaPagamento.id}`, clienteFormaPagamento);
  }

  buscarPeloId(id: number): Observable<ClienteFormaPagamento> {
    return this.http.get<ClienteFormaPagamento>(`${this.clienteFormaPagamentoUrl}/${id}`);
  }

  deletarPeloId(id: number): Observable<any> {
    return this.http.delete<any>(`${this.clienteFormaPagamentoUrl}/${id}`);
  }

  deletarPeloCodigoPagamento(clienteFormPgto: ClienteFormaPagamento): Observable<any> {
    return this.http.delete<any>(`${this.clienteFormaPagamentoUrl}/deletarPeloCodigoPagamento/
      ${clienteFormPgto.codigo}/${clienteFormPgto.codigoPagamento}`);
  }

  listarPeloCodigo(codigo: string): Observable<Array<ClienteFormaPagamento>> {
    return this.http.get<Array<ClienteFormaPagamento>>(`${this.clienteFormaPagamentoUrl}/listarPeloCodigo/${codigo}`);
  }



}
