import {Injectable} from '@angular/core';
import {MoneyHttp} from '../../seguranca/money-http';
import {environment} from '../../../environments/environment';
import {ProdutoOrcamentoOS} from '../../core/models/ProdutoOrcamentoOS';

@Injectable()
export class ProdutoOrcamentoOSService {

  private urlProdutoOrcamentoOS: string;

  constructor(
    private http: MoneyHttp,
  ) {
    this.urlProdutoOrcamentoOS = `${environment.apiUrl}/produtoOrcamentoOS`;
  }

  buscarPorId(id: number): Promise<ProdutoOrcamentoOS> {
    return this.http
      .get<ProdutoOrcamentoOS>(`${this.urlProdutoOrcamentoOS}/${id}`)
      .toPromise();
  }

}
