import { Bloqueio } from 'app/core/models/Bloqueio';
import { Cliente } from 'app/core/models/Cliente';

export class Liberacao {

 cliente: Cliente;
 bloqueio: Bloqueio;
 codigoProduto: string
 dataCancelamento: Date;
 dataHoraLiberacao: Date;
 dataHoraSolicitado: Date;
 documento: string;
 empresa: string;
 id: number;
 liberacaoId: string;
 liberador: string;
 motivoSolicitacao: string;
 observacaoAprovador: string;
 quantidadeAprovada: number;
 quantidadeSolicitada: number;
 serie: string;
 userSolicitado: string;
 usuarioCancelamento: string;
 usuarioSolicitadoConfirmaRetorno: Date;
 valorAprovado: number;
 valorSolicitado: number;
       constructor(

        cliente = new Cliente(),
        bloqueio = new Bloqueio(),
        codigoProduto?: string,
        dataCancelamento?: Date,
        dataHoraLiberacao?: Date,
        dataHoraSolicitado?: Date,
        documento?: string,
        empresa?: string,
        id?: number,
        liberacaoId?: string,
        liberador?: string,
        motivoSolicitacao?: string,
        observacaoAprovador?: string,
        quantidadeAprovada?: number,
        quantidadeSolicitada?: number,
        serie?: string,
        userSolicitado?: string,
        usuarioCancelamento?: string,
        usuarioSolicitadoConfirmaRetorno?: Date,
        valorAprovado?: number,
        valorSolicitado?: number
      )
      {

        this. cliente = cliente;
        this.bloqueio = bloqueio;
        this.codigoProduto = codigoProduto;
        this.dataCancelamento = dataCancelamento;
        this.dataHoraLiberacao = dataHoraLiberacao;
        this.dataHoraSolicitado = dataHoraSolicitado;
        this.documento = documento;
        this.empresa = empresa;
        this.id = id;
        this.liberacaoId = liberacaoId;
        this.liberador = liberador;
        this.motivoSolicitacao = motivoSolicitacao;
        this.observacaoAprovador = observacaoAprovador;
        this.quantidadeAprovada = quantidadeAprovada;
        this.quantidadeSolicitada = quantidadeSolicitada;
        this.serie = serie;
        this.userSolicitado = userSolicitado;
        this.usuarioCancelamento = usuarioCancelamento;
        this.usuarioSolicitadoConfirmaRetorno = usuarioSolicitadoConfirmaRetorno;
        this.valorAprovado =  valorAprovado;
        this.valorSolicitado = valorSolicitado;
    }
}











       




