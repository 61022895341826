import { Title } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";
import { NgModule, LOCALE_ID } from "@angular/core";
import { CommonModule, registerLocaleData } from "@angular/common";

import localePt from "@angular/common/locales/pt";

import { ConfirmationService} from "primeng/api";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { TieredMenuModule } from "primeng/tieredmenu";
import { PanelMenuModule } from "primeng/panelmenu";
import { TabViewModule } from "primeng/tabview";
import {InputTextareaModule} from 'primeng/inputtextarea';
import { ToastyModule } from 'ng2-toasty';
import { JwtHelperService } from '@auth0/angular-jwt';

import { AuthService } from "./../seguranca/auth.service";
import { ErrorHandlerService } from "./error-handler.service";
import { ClienteService } from "./../clientes/cliente.service";
import { ProdutoService } from "./../produtos/produto.service";
import { MarcaService } from "./../marcas/marca.service";
import { GrupoService } from "app/grupo/grupo.service";
import { SubGrupoService } from "app/subgrupo/subgrupo.service";
import { LocalizacaoService } from './../localizacao/localizacao.service';
import { FornecedorService } from "./../fornecedores/fornecedor.service";
import { EstoqueService } from "./../estoque/estoque.service";
import { AcertoEstoqueService } from "./../estoque/acertoEstoque/acertoEstoque.service";
import { TrocaService } from "./../vendas/trocas/troca.service";
import { TransportadoraService } from "./../transportadoras/transportadora.service";
import { PessoaService } from "./../pessoas/pessoa.service";
import { LancamentoService } from "./../lancamentos/lancamento.service";
import { CategoriaService } from "./../categorias/categoria.service";
import { PreVendaService } from "./../vendas/preVenda/preVenda.service";
import { CodigoFiscalService } from "./../codigosFiscais/codigoFiscal.service";
import { VeiculoService } from "./../veiculos/veiculo.service";
import { CodigoSituacaoTributariaIcmsService } from "../codigosSituacaoTributariasIcms/codigoSituacaoTributariaIcms.service";
import { CodigoSituacaoTributariaPisService } from "../codigosSituacaoTributariasPis/codigoSituacaoTributariaPis.service";
import { CartorioService } from "./../cartorios/cartorio.service";
import { CodigoSituacaoTributariaIpiService } from "../codigosSituacaoTributariasIpi/codigoSituacaoTributariaIpi.service";
import { CodigoSituacaoTributariaCofinsService } from "../codigosSituacaoTributariasCofins/codigoSituacaoTributariaCofins.service";
import { FabricanteService } from "./../fabricantes/fabricante.service";
import { ServicoVeiculoService } from "./../servicosVeiculos/servicoVeiculo.service";
import { AliquotaMvaService } from "./../aliquotasMva/aliquotaMva.service";
import { FormaPagamentoService } from "./../formasPagamentos/formaPagamento.service";
import { VendedorService } from "./../vendedores/vendedor.service";
import { BibiService } from "./../shared/modal/modal-bibi/bibi.service";
import { TecnicoService } from "./../tecnicos/tecnico.service";
import { FluxoCaixaService } from "./../fluxoCaixa/fluxoCaixa.service";
import { DescontoProgressivoService } from "./../descontosProgressivos/descontoProgressivo.service";
import { ComissaoProgressivaService } from "./../comissoesProgressivas/comissaoProgressiva.service";
import { FuncionarioService } from "./../funcionarios/funcionario.service";
import { PagamentoAntecipadoService } from "../pagamento-antecipado/pagamento-antecipado.service";
import { PreVenda4Service } from "./../dividaCliente/dividaCliente.service";
import { NotaService } from "./../notas/nota.service";
import { ManagersaasService } from "./../notas/managersaas.service";
import { PerfilAcessoService } from "./../perfilAcesso/perfilAcesso.service";
import { LiberacaoService } from "./../liberacoes/liberacao.service";
import { NaturezaOperacaoService} from "./../naturezasOperacoes/naturezaOperacao.service";
import { BancoService } from "./../bancos/banco.service";
import { PermissaoService } from "./../permissoes/permissao.service";
import { EmpresaService } from "./../empresas/empresa.service";
import { ParametroService } from "./../parametros/parametro.service";
import { UsuarioService } from "./../usuarios/usuario.service";
import { VendaPerdidaService } from "./../vendasPerdidas/vendaPerdida.service";
import { CaixaService } from "./../caixa/caixa.service";
import { MovimentoService } from "./../movimentos/movimento.service";
import { DashboardService } from "./../dashboard/dashboard.service";
import { RelatoriosService } from "./../relatorios/relatorios.service";
import { NaoAutorizadoComponent } from "./nao-autorizado.component";
import { PaginaNaoEncontradaComponent } from "./pagina-nao-encontrada.component";
import { MoneyHttp } from "app/seguranca/money-http";
import { SharedService } from "app/shared/shared.service";
import { UnidadeService } from "app/unidades/unidade.service";
import { NcmShService } from "app/ncmSh/ncmSh.service";
import { PreVendaItemService } from 'app/vendas/preVendaItem/preVendaItem.service';
import { LocalService } from '../local/local.service';
import { CartaoService } from '../cartao/cartao.service';
import { ConfiguracaoLocalService } from '../configuracaoLocal/configuracaoLocal.service';
import { BalancoService } from '../balanco/balanco.service';
import { BalancoProdutoLocalService } from '../balanco/balancoProdutoLocal.service';
import { BalancoContagemService } from '../balanco/balancoContagem.service';
import { TituloService } from '../titulos/titulo.service';
import { ClienteFormaPagamentoService } from 'app/clienteFormaPagamento/clienteFormaPagamento.service';
import { ClientesDescVendedorService } from 'app/clientesDescVendedor/clientesDescVendedor.service';
import { BloqueioService } from './../bloqueio/bloqueio.service';
import {CheckListService} from '../ordemServico/check-list/check-list.service';
import {PlacaVeiculoService} from '../placa-veiculo/placa-veiculo.service';
import {PlacaVeiculoClienteService} from '../placa-veiculo/placa-veiculo-cliente.service';
import {OrcamentoOSService} from '../ordemServico/orcamento-os/orcamento-os.service';
import {ProdutoCautelaService} from '../produtoCautela/produtoCautela.service';
import {ProdutoOrcamentoOSService} from '../ordemServico/orcamento-os/produto-orcamento-os.service';
import {SugestaoCotacaoService} from '../compras/sugestao-cotacao.service';
import {ContaPagarService} from '../contaPagar/contaPagar.service';
import {ChequeService} from '../cheque/cheque.service';
import {Cr1CartaService} from '../cr1Carta/cr1Carta.service';
import { DinheiroCaixaService } from './../dinheiroCaixa/dinheiroCaixa.service';
import {PlanoContaService} from '../plano-conta/planoConta.service';
import {OrdemEstoqueService} from 'app/estoque/ordemEstoque/ordemEstoque.service';
import {BancoRecebimentoService} from 'app/banco-recebimento/banco-recebimento.service';
import {ReposicaoService} from '../estoque/reposicao/reposicao.service';
import {FormaPagamentoParcelaService} from '../formasPagamentos/formaPagamentoParcela.service';
import {ClienteFormaPagamentoParcelaService} from '../formasPagamentos/clienteFormaPagamentoParcela.service';
import {PagamentoAntecipadoChequeService} from '../pagamento-antecipado-cheque/pagamento-antecipado-cheque.service';
import {PagamentoAntecipadoCartaoService} from '../pagamento-antecipado-cartao/pagamento-antecipado-cartao.service';
import {NotaEntradaService} from '../nota-entrada/nota-entrada.service';
import {SerieService} from '../notas/serie.service';
import {EntradaTemporariaService} from '../nota-entrada/entradaTemporaria.service';
import {ManifestoService} from 'app/manifesto-destinatario/manifesto.service';
import {SugestaoCotacaoItemFornecedorService} from '../compras/sugestao-cotacao-item-fornecedor.service';
import {ConverterPdfToExcelService} from '../converter-pdf-to-excel/converter-pdf-to-excel.service';
import {ListaPrecoProdutoFornecedorService} from '../compras/lista-preco-produto-fornecedor.service';
import {ConferenciaService} from '../conferencia/conferencia.service';
import {ConferenciaProdutoService} from '../conferencia/conferenciaProduto.service';
import {ProdutoCodigoBarraService} from 'app/produtos/produto-codigo-barra/produto-codigo-barra.service';
import {TabAnpProdutoService} from 'app/produtos/tabAnpProdutos/tabAnpProduto.service';
import {PagamentoAntecipadoNotaFilialService} from '../pagamento-antecipado-nota-filial/pagamento-antecipado-nota-filial.service';
import {SubstituirNotaEntregaService} from '../substituir-nota-entrega/substituir-nota-entrega.service';
import {ProdutoValorService} from '../estoque/produtoValor.service';
import {PagamentoAntecipadoBancoService} from '../pagamento-antecipado-banco/pagamento-antecipado-banco.service';
import {PagamentoAntecipadoItemService} from '../pagamento-antecipado-item/pagamento-antecipado-item.service';
import {FluxoCaixaControleService} from '../fluxoCaixa/fluxoCaixaControle.service';
import {TituloCobrancaService} from '../shared/modal/modal-titulo-cobranca/tituloCobranca.service';
import {TituloBaixaRetornoService} from '../titulos/tituloBaixaRetorno.service';

registerLocaleData(localePt);

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    TieredMenuModule,
    PanelMenuModule,
    TabViewModule,
    InputTextareaModule,

    ToastyModule.forRoot(),
    ConfirmDialogModule
  ],
  declarations: [
    PaginaNaoEncontradaComponent,
    NaoAutorizadoComponent
  ],
  exports: [ToastyModule, ConfirmDialogModule],
  providers: [
    LancamentoService,
    ClienteService,
    ProdutoService,
    FornecedorService,
    ParametroService,
    ChequeService,
    Cr1CartaService,
    DinheiroCaixaService,
    ProdutoCodigoBarraService,
    CartaoService,
    LiberacaoService,
    PermissaoService,
    ContaPagarService,
    VeiculoService,
    AcertoEstoqueService,
    EmpresaService,
    BibiService,
    MovimentoService,
    LocalService,
    PlanoContaService,
    ServicoVeiculoService,
    PagamentoAntecipadoService,
    MarcaService,
    OrdemEstoqueService,
    PerfilAcessoService,
    CaixaService,
    BancoService,
    BancoRecebimentoService,
    NaturezaOperacaoService,
    AliquotaMvaService,
    CodigoSituacaoTributariaIcmsService,
    CodigoSituacaoTributariaPisService,
    CodigoSituacaoTributariaIpiService,
    CodigoSituacaoTributariaCofinsService,
    ConfiguracaoLocalService,
    GrupoService,
    SubGrupoService,
    FluxoCaixaService,
    UnidadeService,
    TabAnpProdutoService,
    LocalizacaoService,
    FabricanteService,
    PreVendaService,
    PreVenda4Service,
    UsuarioService,
    NotaService,
    ManagersaasService,
    ManifestoService,

    DescontoProgressivoService,
    ComissaoProgressivaService,
    TransportadoraService,
    VendaPerdidaService,
    VendedorService,
    TrocaService,
    FormaPagamentoService,
    CodigoFiscalService,
    FuncionarioService,
    TecnicoService,
    CartorioService,
    EstoqueService,
    TabAnpProdutoService,
    PessoaService,
    CategoriaService,
    TrocaService,
    BalancoService,
    TituloService,
    BalancoProdutoLocalService,
    BalancoContagemService,
    BloqueioService,
    DashboardService,
    RelatoriosService,
    NcmShService,
    PreVendaItemService,
    ClienteFormaPagamentoService,
    ClientesDescVendedorService,
    CheckListService,
    PlacaVeiculoService,
    ProdutoCodigoBarraService,
    PlacaVeiculoClienteService,
    OrcamentoOSService,
    ProdutoCautelaService,
    ProdutoOrcamentoOSService,
    SugestaoCotacaoService,
    SugestaoCotacaoItemFornecedorService,
    FormaPagamentoParcelaService,
    ClienteFormaPagamentoParcelaService,
    PagamentoAntecipadoChequeService,
    PagamentoAntecipadoCartaoService,
    ReposicaoService,
    NotaEntradaService,
    EntradaTemporariaService,
    PagamentoAntecipadoNotaFilialService,
    SerieService,
    ConverterPdfToExcelService,
    ListaPrecoProdutoFornecedorService,
    ConferenciaService,
    ConferenciaProdutoService,
    SubstituirNotaEntregaService,
    ProdutoValorService,
    PagamentoAntecipadoBancoService,
    PagamentoAntecipadoItemService,
    FluxoCaixaControleService,
    TituloCobrancaService,
    TituloBaixaRetornoService,
    SharedService,
    ErrorHandlerService,
    AuthService,
    MoneyHttp,

    ConfirmationService,
    JwtHelperService,
    Title,
    { provide: LOCALE_ID, useValue: "pt" }
  ]
})
export class CoreModule {}
