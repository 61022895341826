import {Produto} from './../core/models/Produto';
import {ProdutoEmpresa} from './../core/models/ProdutoEmpresa';
import {Injectable} from '@angular/core';
import {HttpParams, HttpHeaders} from '@angular/common/http';
import {environment} from './../../environments/environment';
import {MoneyHttp} from 'app/seguranca/money-http';
import {Marca} from '../core/models/Marca';

export class ProdutoEmpresaFiltro {
  empresa: string;
  produto: Produto;
  aplicacao: string;
  marcaIni: string;
  marcaFim: string;
  pedido: string;
  pendencia: string;
  sugestao: string;
  transito: string;

  pagina = 0;
  itensPorPagina = 50;
}

export class MarcaTotais {
  marca: Marca;
  totalPedido: number;
  totalPendencia: number;
  totalTransito: number;
  totalItens: number;
}

@Injectable()
export class EstoqueService {

  estoquesUrl: string;
  produtoEmpresaUrl: string;

  constructor(
    private http: MoneyHttp
  ) {
    this.estoquesUrl = `${environment.apiUrl}/estoques`;
    this.produtoEmpresaUrl = `${environment.apiUrl}/produtosEmpresas`;
  }


  pesquisar(filtro: ProdutoEmpresaFiltro): Promise<any> {

    const url = `${this.produtoEmpresaUrl}/marcas/empresa/codigoInicial/codigoFinal`;

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    params = params.append('empresa', filtro.empresa);
    params = params.append('codigoInicial', filtro.marcaIni);
    params = params.append('codigoFinal', filtro.marcaFim);

    return this.http
      .get<any>(url, {params})
      .toPromise()
      .then(response => {
        const produtosEmpresas = response.content;

        const resultado = {
          produtosEmpresas,
          total: response.totalElements
        };

        return resultado;
      });

  }

  pesquisarPedidoPendencia(filtro: ProdutoEmpresaFiltro): Promise<any> {

    const url = `${this.produtoEmpresaUrl}/marcas/empresa/codigoInicial/codigoFinal/pedido/pendencia`;

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    params = params.append('empresa', filtro.empresa);
    params = params.append('codigoInicial', filtro.marcaIni);
    params = params.append('codigoFinal', filtro.marcaFim);
    params = params.append('pedido' , filtro.pedido);
    params = params.append('pendencia', filtro.pendencia);

    return this.http
      .get<any>(url, {params})
      .toPromise()
      .then(response => {

        const produtos = response.content;
        const produtosEmpresas = [];
        for (const item of produtos) {
            if (item.produto !== null) {
              produtosEmpresas.push(item);
            }
        }

 
        const resultado = {
          produtosEmpresas,
          total: response.totalElements
        };
        

        

console.log(produtosEmpresas);

       

        return resultado;
      });

  }

  zerarMovimentoProdutoEmpresa(filtro: ProdutoEmpresaFiltro): Promise<any> {
    const url = `${this.produtoEmpresaUrl}/zerarMovimento/pedido/pendencia/sugestao/transito/empresa/codigoInicial/codigoFinal`;
    const params = new HttpParams({
      fromObject: {
        pedido: filtro.pedido,
        pendencia: filtro.pendencia,
        sugestao: filtro.sugestao,
        transito: filtro.transito,
        empresa: filtro.empresa,
        codigoInicial: filtro.marcaIni,
        codigoFinal: filtro.marcaFim
      }
    });

    return this.http
      .get<any>(url, {params})
      .toPromise()
      .then(() => null);

  }

  atualizarAcerto(produtoEmpresa: ProdutoEmpresa): Promise<ProdutoEmpresa> {
    return this.http.put<ProdutoEmpresa>(`${this.produtoEmpresaUrl}/${produtoEmpresa.id}`, produtoEmpresa).toPromise();
  }

  calcularPedidoPendencia(empresa: string, marcaIni: string, marcaFim: string): Promise<any> {

    const url = `${this.produtoEmpresaUrl}/calcularPedidoPendencia/empresa/codigoInicial/codigoFinal`;
    const params = new HttpParams({
      fromObject: {
        empresa: empresa,
        codigoInicial: marcaIni,
        codigoFinal: marcaFim
      }
    });

    return this.http
      .get<any>(url, {params})
      .toPromise()
  }


  pesquisarProutosComPedido(filtro: ProdutoEmpresaFiltro): Promise<any> {

    const url = `${this.produtoEmpresaUrl}/produtoComPedidoParaPendencia/empresa/codigoInicial/codigoFinal`;

    let params = new HttpParams({
      fromObject: {
        empresa: filtro.empresa,
        codigoInicial: filtro.marcaIni,
        codigoFinal: filtro.marcaFim
      }
    });

    return this.http
      .get<any>(url, {params})
      .toPromise();
  }

  transferirPedidoParaPendencia(filtro: ProdutoEmpresaFiltro): Promise<any> {
    const url = `${this.produtoEmpresaUrl}/transferirPedidoPendencia/empresa/codigoInicial/codigoFinal`;
    const params = new HttpParams({
      fromObject: {
        empresa: filtro.empresa,
        codigoInicial: filtro.marcaIni,
        codigoFinal: filtro.marcaFim
      }
    });

    return this.http
      .get<any>(url, {params})
      .toPromise()
      .then(() => null);

  }



}
