import { Injectable } from '@angular/core';
import {MoneyHttp} from '../seguranca/money-http';
import {AuthService} from '../seguranca/auth.service';
import {environment} from '../../environments/environment';
import {PlacaVeiculo} from '../core/models/PlacaVeiculo';
import {Cliente} from '../core/models/Cliente';
import {PlacaVeiculoCliente} from '../core/models/PlacaVeiculoCliente';

@Injectable()
export class PlacaVeiculoClienteService {

  private urlPlacaVeiculoCliente: string;

  constructor(
    private http: MoneyHttp,
    private auth: AuthService,
  ) {
    this.urlPlacaVeiculoCliente = `${environment.apiUrl}/placaVeiculoCliente`;
  }

  salvar(placaVeiculo:PlacaVeiculo, cliente: Cliente): Promise<PlacaVeiculoCliente> {

    let placaVeiculoCliente = new PlacaVeiculoCliente();
    placaVeiculoCliente.empresa =  this.auth.jwtPayload.empresa;
    placaVeiculoCliente.incluirData = new Date();
    placaVeiculoCliente.incluirUsuario = this.auth.jwtPayload.login;
    placaVeiculoCliente.cliente = cliente;
    placaVeiculoCliente.placaVeiculo = placaVeiculo

    return this.http.post<PlacaVeiculoCliente>(this.urlPlacaVeiculoCliente, placaVeiculoCliente).toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.urlPlacaVeiculoCliente}/${id}`)
      .toPromise()
      .then(() => null);
  }


}
