import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';

import 'rxjs/add/operator/toPromise';

import {MoneyHttp} from 'app/seguranca/money-http';
import {AuthService} from '../seguranca/auth.service';
import {environment} from '../../environments/environment';
import {ProdutoCautela} from '../core/models/ProdutoCautela';
import {ToastyService} from 'ng2-toasty';
import {Produto} from '../core/models/Produto';
import * as moment from 'moment';

export class ProdutoCautelaFiltro {
 
  retirante: string;
  dataDe: Date;
  dataAte: Date;
  pagina = 0;
  itensPorPagina = 15;
}

@Injectable()
export class ProdutoCautelaService {

  produtoCautelaUrl: string;

  constructor(
    private auth: AuthService,
    private toasty: ToastyService,
    private http: MoneyHttp
  ) {
    this.produtoCautelaUrl = `${environment.apiUrl}/produtosCautelas`;
  }

  produtoCautela(id: string): Promise<any> {

    let params = new HttpParams({
      fromObject: {
        page: '0',
        size: '5'
      }
    });

    params = params.append('id', id);

    return this.http
      .get<any>(`${this.produtoCautelaUrl}/id`, {params})
      .toPromise()
      .then(response => {
        const produtosCautelas = response.content;
        const resultado = {
          produtosCautelas,
          total: response.totalElements
        };
        return resultado;
      });
  }

  // verificarCautelasAbertas(item: ProdutoCautela): Promise<ProdutoCautela> {
  //   return this.buscarCautelasEmAberto(item)
  //     .then(resultado => {
  //       return resultado;
  //     });
  // }


  // buscarCautelasPorEmpresaRetirante(empresa: string, retirante: string): Promise<ProdutoCautela[]> {
  //   return this.http.get<ProdutoCautela[]>(`${this.produtoCautelaUrl}/listarAbertasPorRetirante/${empresa}/${retirante}`).toPromise();
  // }

  // buscarCautelasEmAberto(pc: ProdutoCautela): Promise<ProdutoCautela> {
  //   const params = new HttpParams({
  //     fromObject: {
  //       empresa: pc.empresa,
  //       codigo: pc.codigo,
  //       retirante: pc.retirante
  //     }
  //   });
  //   return this.http.get<ProdutoCautela>(`${this.produtoCautelaUrl}?abertas`, {params}).toPromise();
  // }

  // buscarCautelasEmAbertoUltimaUnidade(pc: ProdutoCautela): Promise<ProdutoCautela> {
  //   const params = new HttpParams({
  //     fromObject: {
  //       empresa: pc.empresa,
  //       codigo: pc.codigo
  //     }
  //   });
  //   return this.http.get<ProdutoCautela>(`${this.produtoCautelaUrl}?ultimaunidade`, {params}).toPromise();
  // }


  reimpressaoCautela(produtoCautela: ProdutoCautela): Promise<Blob> {
    return this.http.get<Blob>(`${this.produtoCautelaUrl}/reimpressaoCautela/${produtoCautela.id}`, {responseType: 'blob' as 'json'}).toPromise();
   }

  // buscarCautelas(orcamento: string): Promise<ProdutoCautela[]> {
  //   return this.http.get<ProdutoCautela[]>(`${this.produtoCautelaUrl}/listarAbertas/${this.auth.jwtPayload.empresa}/${orcamento}`)
  //     .toPromise();
  // }

  buscarCautelasOrcamento(orcamento: string): Promise<ProdutoCautela[]> {
    return this.http.get<ProdutoCautela[]>(`${this.produtoCautelaUrl}/cautelasOrcamento/${this.auth.jwtPayload.empresa}/${orcamento}`)
      .toPromise();
  }


  buscarCautelasEmAbertoTempoExcedido(): Promise<ProdutoCautela> {
    const params = new HttpParams({
      fromObject: {
        empresa: this.auth.jwtPayload.empresa,
        retirante: this.auth.jwtPayload.nome.toUpperCase()
      }
    });
    return this.http.get<ProdutoCautela>(`${this.produtoCautelaUrl}?tempoexcedido`, {params}).toPromise();
  }

  adicionarCautela(produtoCautela: ProdutoCautela): Promise<ProdutoCautela> {
    return this.http.post<ProdutoCautela>(this.produtoCautelaUrl, produtoCautela).toPromise();
  }

  atualizarCautela(produtoCautela: ProdutoCautela): Promise<ProdutoCautela> {
    return this.http.put<ProdutoCautela>(`${this.produtoCautelaUrl}/${produtoCautela.id}`, produtoCautela).toPromise();
  }

  cancelamentoCautela(produtoCautela: ProdutoCautela): Promise<ProdutoCautela> {
    this.produtoCautelaUrl = `${environment.apiUrl}/produtosCautelas`;
    return this.http.put<ProdutoCautela>(`${this.produtoCautelaUrl}/cautelaCancelada/${produtoCautela.id}`, produtoCautela).toPromise();
  }
  

  impressaoCautela(orcamento: string): Promise<Blob> {
    return this.http.get<Blob>(`${this.produtoCautelaUrl}/impressaCautela/${this.auth.jwtPayload.empresa}/${orcamento}`,
      {responseType: 'blob' as 'json'}).toPromise();
  }

  validarTempoMaximoUltimaCautelaEmAberto(produtoCautela: ProdutoCautela): boolean {
    if (produtoCautela !== null) {
      this.toasty.warning('Limite de tempo para fechar a solicitação de produtos retirados na'+
        ' Orçamento : ' + produtoCautela.prevenda + ' esgotado!');
      return false;
    }
    return true;
  }

  validarProdutoCautela(produtosCautelas: Array<ProdutoCautela>, produtoCautela: ProdutoCautela, produto: Produto,
                        maximoProdutoCautelas: number, orcamento: string): boolean {
    if (produtosCautelas.filter(pc => pc.documento == null || pc.documento == undefined).length >= maximoProdutoCautelas) {
      this.toasty.warning('Quantidade maxima de cautelas excedida!');
      return false;
    }

    if (produtosCautelas.find((pc) => pc.codigo === produto.codigo && orcamento === pc.prevenda)) {
      this.toasty.warning('Produto:' + produto.codigo + ' já consta na lista de retirada para analise!');
      return false;
    }

    return true;
  }

  pesquisar(filtro: ProdutoCautelaFiltro): Promise<any> {
    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    if (filtro.dataDe) {
      params = params.append(
        'dataDe',
        moment(filtro.dataDe).format('YYYY-MM-DD')
      );
    }

    if (filtro.dataAte) {
      params = params.append(
        'dataAte',
        moment(filtro.dataAte).format('YYYY-MM-DD')
      );
    }

    if (filtro.dataDe && filtro.dataAte) {
      this.produtoCautelaUrl = `${environment.apiUrl}/produtosCautelas/?periodo`;
    }     

    if (filtro.retirante) {
      params = params.append('retirante', filtro.retirante);
    }

    return this.http
      .get<any>(`${this.produtoCautelaUrl}`, {params})
      .toPromise()
      .then(response => {
        const produtosCautelas = response.content;

        const resultado = {
          produtosCautelas,
          total: response.totalElements
        };

        return resultado;
      });
  }



  montarProdutoCautela(codigo: string, orcamento: string): ProdutoCautela {
    let produtoCautela = new ProdutoCautela();
    produtoCautela = new ProdutoCautela();
    produtoCautela.empresa = this.auth.jwtPayload.empresa;
    produtoCautela.codigo = codigo;
    produtoCautela.datahoraSaida = new Date(Date.parse(Date()));
    produtoCautela.prevenda = orcamento;
    produtoCautela.quantidadeSaida = 1;
    produtoCautela.retirante = this.auth.jwtPayload.nome.toUpperCase();
    return produtoCautela
  }

  validarCautelasPendentesImpressao(produtosCautelas: Array<ProdutoCautela>): boolean {

    for (const cautela of produtosCautelas) {
      if (cautela.datahoraImpressao === null || cautela.datahoraImpressao === undefined)
        return true;
    }

    this.toasty.warning('Não existem itens pendentes de impressão nessa Cautela!');

    return false;
  }


}
