export class Empresa{

    bairro: string;
    cep: string;
    certificadoRegistroArmador: string;
    cidade: string;
    cnae: string;
    cnpj: string;
    codigo: string;
    codigoCliente: string;
    codigoMunicipio: string;
    complemento: string;
    connectionString: string;
    email: string;
    endereco: string;
    estado: string;
    excluirComponente: string;
    excluirData: Date;
    excluirUsuario: string;
    fantasia: string;
    faturaPreVenda: number;
    fax: string;
    fcrep: number;
    fone: string;
    fpoferta: number;
    id: number;
    incluirComponente: string;
    incluirData: Date;
    incluirUsuario: string;
    inscMunicipal: string;
    inscricaoEstadual: string;
    logoMarca: string;
    matrizFilial: string;
    modificarComponente: string;
    modificarData: Date;
    modificarUsuario: string;
    nome: string;
    nomeResumido: string;
    numero: string;
    orgemEditorEstoque: string;
    representanteLegalEmail: string;
    representanteLegalEndereco: string;
    representanteLegalNome: string;
    representanteLegalTelefone: string;
    representateLegalCpf: string;
    rntrc: string;
    site: string;
    suframa: string;
    telefoneNe: string;
    telefoneOs: string;
    trabalho: string;
    validadeCra: Date;

     constructor(

        bairro?: string,
        cep?: string,
        certificadoRegistroArmador?: string,
        cidade?: string,
        cnae?: string,
        cnpj?: string,
        codigo?: string,
        codigoCliente?: string,
        codigoMunicipio?: string,
        complemento?: string,
        connectionString?: string,
        email?: string,
        endereco?: string,
        estado?: string,
        excluirComponente?: string,
        excluirData?: Date,
        excluirUsuario?: string,
        fantasia?: string,
        faturaPreVenda?: number,
        fax?: string,
        fcrep?: number,
        fone?: string,
        fpoferta?: number,
        id?: number,
        incluirComponente?: string,
        incluirData?: Date,
        incluirUsuario?: string,
        inscMunicipal?: string,
        inscricaoEstadual?: string,
        logoMarca?: string,
        matrizFilial?: string,
        modificarComponente?: string,
        modificarData?: Date,
        modificarUsuario?: string,
        nome?: string,
        nomeResumido?: string,
        numero?: string,
        orgemEditorEstoque?: string,
        representanteLegalEmail?: string,
        representanteLegalEndereco?: string,
        representanteLegalNome?: string,
        representanteLegalTelefone?: string,
        representateLegalCpf?: string,
        rntrc?: string,
        site?: string,
        suframa?: string,
        telefoneNe?: string,
        telefoneOs?: string,
        trabalho?: string,
        validadeCra?: Date

     ){

        this.bairro = bairro,
        this.cep = cep,
        this.certificadoRegistroArmador = certificadoRegistroArmador, 
        this.cidade = cidade,
        this.cnae =  cnae,
        this.cnpj =  cnpj,
        this.codigo = codigo,
        this.codigoCliente = codigoCliente, 
        this.codigoMunicipio = codigoMunicipio,
        this.complemento = complemento,
        this.connectionString = connectionString, 
        this.email =  email,
        this.endereco =  endereco,
        this.estado =  estado,
        this.excluirComponente = excluirComponente,  
        this.excluirData =  excluirData,
        this.excluirUsuario =  excluirUsuario,
        this.fantasia =  fantasia,
        this.faturaPreVenda =  faturaPreVenda,
        this.fax =  fax,
        this.fcrep =  fcrep,
        this.fone =  fone,
        this.fpoferta =  fpoferta,
        this.id =  id,
        this.incluirComponente = incluirComponente,  
        this.incluirData =  incluirData,
        this.incluirUsuario =  incluirUsuario
        this.inscMunicipal =  inscMunicipal,
        this.inscricaoEstadual = inscricaoEstadual,
        this.logoMarca =  logoMarca,
        this.matrizFilial =  matrizFilial,
        this.modificarComponente =  modificarComponente,
        this.modificarData =  modificarData,
        this.modificarUsuario =  modificarUsuario,
        this.nome =  nome,
        this.nomeResumido = nomeResumido, 
        this.numero =  numero,
        this.orgemEditorEstoque = orgemEditorEstoque,  
        this.representanteLegalEmail =  representanteLegalEmail,
        this.representanteLegalEndereco =  representanteLegalEndereco,
        this.representanteLegalNome =  representanteLegalNome,
        this.representanteLegalTelefone =  representanteLegalTelefone
        this.representateLegalCpf =  representateLegalCpf,
        this.rntrc =  rntrc,
        this.site =  site,
        this.suframa =  suframa,
        this.telefoneNe =  telefoneNe,
        this.telefoneOs =  telefoneOs,
        this.trabalho =  trabalho,
        this.validadeCra =  validadeCra;
     }

}
    

