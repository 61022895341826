import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {NaoAutorizadoComponent} from './core/nao-autorizado.component';
import {PaginaNaoEncontradaComponent} from './core/pagina-nao-encontrada.component';

const routes: Routes = [
  {
    path: 'lancamentos',
    loadChildren: 'app/lancamentos/lancamentos.module#LancamentosModule'
  },
  {path: 'pessoas', loadChildren: 'app/pessoas/pessoas.module#PessoasModule'},
  {
    path: 'dashboard',
    loadChildren: 'app/dashboard/dashboard.module#DashboardModule'
  },

  {
    path: 'contaPagas',
    loadChildren: 'app/contaPagar/contaPagar.module#ContaPagarModule'
  },

  {
    path: 'manifesto',
    loadChildren: 'app/manifesto-destinatario/manifesto.module#ManifestoModule'
  },

  {
    path: 'bancosRecebimentos',
    loadChildren: 'app/banco-recebimento/banco-recebimento.module#BancoRecebimentoModule'
  },
  {
    path: 'relatorios',
    loadChildren: 'app/relatorios/relatorios.module#RelatoriosModule'
  },
  {
    path: 'clientes',
    loadChildren: 'app/clientes/clientes.module#ClientesModule'
  },
  {
    path: 'bancos',
    loadChildren: 'app/bancos/bancos.module#BancosModule'
  },

  {
    path: 'titulos',
    loadChildren: 'app/titulos/titulos.module#TitulosModule'
  },

  {
    path: 'produtosCodigosBarras',
    loadChildren: 'app/produtos/produto-codigo-barra/produto-codigo-barras.module#ProdutoCodigoBarraModule'
  },

  {
    path: 'tabAnpProdutos',
    loadChildren: 'app/produtos/tabAnpProdutos/tabAnpProdutos.module#TabAnpProdutoModule'
  },

  {
    path: 'produtos',
    loadChildren: 'app/produtos/produtos.module#ProdutosModule'
  },

  {
    path: 'cartoes',
    loadChildren: 'app/cartao/cartoes.module#CartoesModule'
  },

  {
    path: 'ordemEstoque',
    loadChildren: 'app/estoque/ordemEstoque/ordemEstoque.module#OrdemEstoqueModule'
  },

  {
    path: 'caixas',
    loadChildren: 'app/caixa/caixas.module#CaixasModule'
  },

  {
    path: 'fluxosCaixas',
    loadChildren: 'app/fluxoCaixa/fluxosCaixas.module#FluxosCaixasModule'
  },
  {
    path: 'fornecedores',
    loadChildren: 'app/fornecedores/fornecedores.module#FornecedoresModule'
  },
  {path: 'marcas', loadChildren: 'app/marcas/marcas.module#MarcasModule'},
  {
    path: 'transportadoras',
    loadChildren:
      'app/transportadoras/transportadoras.module#TransportadorasModule'
  },
  {
    path: 'vendedores',
    loadChildren: 'app/vendedores/vendedores.module#VendedoresModule'
  },
  {
    path: 'codigosFiscais',
    loadChildren:
      'app/codigosFiscais/codigosFiscais.module#CodigosFiscaisModule'
  },
  {
    path: 'servicosVeiculos',
    loadChildren:
      'app/servicosVeiculos/servicosVeiculos.module#ServicosVeiculosModule'
  },

  {
    path: 'fabricantes',
    loadChildren: 'app/fabricantes/fabricantes.module#FabricantesModule'
  },

  {
    path: 'empresas',
    loadChildren: 'app/empresas/empresas.module#EmpresasModule'
  },

  {
    path: 'veiculos',
    loadChildren: 'app/veiculos/veiculos.module#VeiculosModule'
  },

  {
    path: 'funcionarios',
    loadChildren: 'app/funcionarios/funcionarios.module#FuncionariosModule'
  },
  {
    path: 'cartorios',
    loadChildren: 'app/cartorios/cartorios.module#CartoriosModule'
  },

  {
    path: 'formasPagamentos',
    loadChildren: 'app/formasPagamentos/formasPagamentos.module#FormasPagamentosModule'
  },

  {
    path: 'vendasPerdidas',
    loadChildren: 'app/vendasPerdidas/vendasPerdidas.module#VendasPerdidasModule'
  },

  {
    path: 'codigosSituacaoTributariasIcms',
    loadChildren: 'app/codigosSituacaoTributariasIcms/codigosSituacaoTributariasIcms.module#CodigosSituacaoTributariasIcmsModule'
  },

  {
    path: 'codigosSituacaoTributariasPis',
    loadChildren: 'app/codigosSituacaoTributariasPis/codigosSituacaoTributariasPis.module#CodigosSituacaoTributariasPisModule'
  },

  {
    path: 'codigosSituacaoTributariasCofins',
    loadChildren: 'app/codigosSituacaoTributariasCofins/codigosSituacaoTributariasCofins.module#CodigosSituacaoTributariasCofinsModule'
  },


  {
    path: 'codigosSituacaoTributariasIpi',
    loadChildren: 'app/codigosSituacaoTributariasIpi/codigosSituacaoTributariasIpi.module#CodigosSituacaoTributariasIpiModule'
  },

  {
    path: 'trocas',
    loadChildren: 'app/vendas/trocas/trocas.module#TrocasModule'
  },

  {
    path: 'acerto',
    loadChildren: 'app/estoque/acertoEstoque/acertoEstoque.module#AcertoEstoqueModule'
  },

  {
    path: 'preVendas',
    loadChildren: 'app/vendas/preVenda/preVendas.module#PreVendasModule'
  },

  {
    path: 'devolucao',
    loadChildren: 'app/vendas/preVenda/preVenda-devolucao/preVendas-devolucao.module#PreVendasDevolucaoModule'
  },
  {
    path: 'orcamentoOS',
    loadChildren: 'app/ordemServico/orcamento-os/orcamento-os.module#OrcamentoOSModule'
  },
  {
    path: 'checkList',
    loadChildren: 'app/ordemServico/check-list/check-list.module#CheckListModule'
  },
  {
    path: 'sugestaoCotacao',
    loadChildren: 'app/compras/sugestao-cotacao/sugestao-cotacao.module#SugestaoCotacaoModule'
  },
  {
    path: 'sugestaoPedidoCompra',
    loadChildren: 'app/compras/sugestao-pedido-compra/sugestao-pedido-compra.module#SugestaoPedidoCompraModule'
  },
  {
    path: 'conferencia',
    loadChildren: 'app/conferencia/conferencia.module#ConferenciaModule'
  },

  {
    path: 'reposicao',
    loadChildren: 'app/estoque/reposicao/reposicao.module#ReposicaoModule'
  },
  {
    path: 'notaEntrada',
    loadChildren: 'app/nota-entrada/nota-entrada.module#NotaEntradaModule'
  },
  {
    path: 'faturamentoNotaFiscal',
    loadChildren: 'app/faturamento/faturamento.module#FaturamentoModule'
  },
  {
    path: 'aliquotasMva',
    loadChildren: 'app/aliquotasMva/aliquotasMva.module#AliquotasMvaModule'
  },

  {
    path: 'naturezasOperacoes',
    loadChildren: 'app/naturezasOperacoes/naturezasOperacoes.module#NaturezasOperacoesModule'
  },

  {
    path: 'configuracaoLocal',
    loadChildren: 'app/configuracaoLocal/configuracaoLocal.module#ConfiguracaoLocalModule'
  },

  {
    path: 'perfilAcesso',
    loadChildren: 'app/perfilAcesso/perfilAcesso.module#PerfilAcessoModule'
  },

  {
    path: 'permissoes',
    loadChildren: 'app/permissoes/permissoes.module#PermissoesModule'
  },
  {
    path: 'local',
    loadChildren: 'app/local/local.module#LocalModule'
  },

  {
    path: 'aberturaBalanco',
    loadChildren: 'app/balanco/balanco-abertura/balanco-abertura.module#BalancoAberturaModule'
  },
  {
    path: 'contagemBalanco',
    loadChildren: 'app/balanco/balanco-contagem/balanco-contagem.module#BalancoContagemModule'
  },
  {
    path: 'pagamentosAntecipados',
    loadChildren: 'app/pagamento-antecipado/pagamento-antecipado.module#PagamentoAntecipadoModule'
  },

  {
    path: 'preVenda4',
    loadChildren: 'app/dividaCliente/dividasClientes.module#DividasClientesModule'
  },
  {
    path: 'movimentos',
    loadChildren: 'app/movimentos/movimentos.module#MovimentosModule'
  },


  {
    path: 'substituirNotaEntrega',
    loadChildren: 'app/substituir-nota-entrega/substituir-nota-entrega.module#SubstituirNotaEntregaModule'
  },

  {
    path: 'unidades',
    loadChildren: 'app/unidades/unidades.module#UnidadesModule'
  },

  {
    path: 'notas',
    loadChildren: 'app/notas/notas.module#NotasModule'
  },

  {
    path: 'liberacoes',
    loadChildren: 'app/liberacoes/liberacoes.module#LiberacoesModule'
  },

  {
    path: 'descontosProgressivos',
    loadChildren: 'app/descontosProgressivos/descontosProgressivos.module#DescontosProgressivosModule'
  },

  {
    path: 'comissoesProgressivas',
    loadChildren: 'app/comissoesProgressivas/comissoesProgressivas.module#ComissoesProgressivasModule'
  },

  {
    path: 'parametros',
    loadChildren: 'app/parametros/parametros.module#ParametrosModule'
  },

  {
    path: 'usuarios',
    loadChildren: 'app/usuarios/usuarios.module#UsuariosModule'
  },

  {
    path: 'ncmShs',
    loadChildren: 'app/ncmSh/ncmShs.module#NcmShsModule'
  },
  {
    path: 'produtosCautelas',
    loadChildren: 'app/produtoCautela/produtoCautela.module#ProdutoCautelaCancelamentoModule'
  },
  {
    path: 'tecnicos',
    loadChildren: 'app/tecnicos/tecnicos.module#TecnicosModule'
  },
  {
    path: 'converterPdfToExcel',
    loadChildren: 'app/converter-pdf-to-excel/converter-pdf-to-excel.module#ConverterPdfToExcelModule'
  },
  {
    path: 'alterarCodigoCliente',
    loadChildren: 'app/alterarCodigoCliente/alterarCodigoCliente.module#AlterarCodigoClienteModule'
  },
  {path: 'estoque', loadChildren: 'app/estoque/estoque.module#EstoqueModule'},
  {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  {path: 'nao-autorizado', component: NaoAutorizadoComponent},
  {path: 'pagina-nao-encontrada', component: PaginaNaoEncontradaComponent},

  {path: '**', redirectTo: 'pagina-nao-encontrada'},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
