import {Nota} from './Nota';
import {FaturamentoEspelho} from './dtos/FaturamentoEspelho';

export class Cliente {
  id: number;
  empresa: string;
  codigo: string;
  tipoPessoa: string;
  cnpjCpf: string;
  incluirData: Date;
  nome: string;
  cep: string;
  endereco: string;
  numero: number;
  bairro: string;
  cidade: string;
  estado: string;
  dataNascimento: Date;
  ddd: string;
  telefoneResidencial: string;
  telefoneComercial: string;
  fax: string;
  email: string;
  inscricaoEstadual: string;
  rg: string;
  orgaoEmissorRg: string;
  inscricaoMunicipal: string;
  rfbSituacao: string;
  rfbDataSituacao: Date;
  codigoMunicipio: string;
  spc: string;
  posicao: string;
  grupo: string;
  limite: Float32Array;
  descontoAtacado: string;
  descontoVarejo: string;
  desconto: string;
  percentualDesconto: number;
  suframa: string;
  contribuinte: string;
  revendedor: string;
  produtorRural: string;
  inscricaoEstadualProdutorRural: string;
  comprar: string;
  filial: string;
  codigoConta: number;
  cobranca: string;
  enderecoCobranca: string;
  pagamento: string;
  cidadecobranca: string;
  cepCobranca: string;
  estadocobranca: string;
  representanteLegalNome: string;
  representanteLegalEndereco: string;
  representanteLegalBairro: string;
  representanteLegalEstado: string;
  representanteLegalCidade: string;
  representanteLegalCep: string;
  representanteLegalTelefone: string;
  representanteLegalRg: string;
  representanteLegalCpf: string;
  diasBloqueioAbsoluto: number;
  diasBloqueioVendas: number;
  diasAlertaAbsoluto: number;
  diasBloqueioVendasNotas: number;
  exibirDescontoNe: string;
  exibirdescontoOs: string;
  exibirdescontoDocsFiscal: string;
  boletoCodigoBanco: string;
  boletoNomeBanco: string;
  valorMinimoBoleto: Float32Array;
  diasPrazoNotasFiscalSemFaturar: number;
  serieNotas: string;
  serieTitulos: string;
  serieCaixa: string;
  conhecimentoTransporteClienteIcmsSubstituicaoTributaria: number;
  conhecimentoTransporteFreteLiquido: number;
  conhecimentoTransporteRemetente: number;
  conhecimentoTransporteDestinatario: number;
  conhecimentoTransporteTomador: number;
  conhecimentoTransporteExpedidor: number;
  conhecimentoTransporteEminente: number;
  conhecimentoTransporteRetirante: number;
  nfseIncetivoCultura: string;
  nfseClienteIssSubstituicaoTributaria: string;
  nfseAliquotaIssFixa: string;
  nfsePercentualPis: string;
  nfsePercentualCofins: string;
  nfsePercentualInss: string;
  nfsePercentualIr: string;
  nfsePercentualCsll: string;
  podeAlterarMensagemNotaFiscal: string;
  incluirUsuario: string;
  modificarUsuario: string;
  modificarData: Date;
  imagemPath: string;
  saldoValeCredito;
  notas: Array<Nota>;
  faturamentoEspelho: FaturamentoEspelho;
  emailEnvioDanfeXml;

  constructor(
    id?: number,
    empresa?: string,
    codigo?: string,
    tipoPessoa?: string,
    cnpjCpf?: string,
    incluirData?: Date,
    nome?: string,
    cep?: string,
    endereco?: string,
    numero?: number,
    bairro?: string,
    cidade?: string,
    estado?: string,
    dataNascimento?: Date,
    ddd?: string,
    telefoneResidencial?: string,
    telefoneComercial?: string,
    fax?: string,
    email?: string,
    inscricaoEstadual?: string,
    rg?: string,
    orgaoEmissorRg?: string,
    inscricaoMunicipal?: string,
    rfbSituacao?: string,
    rfbDataSituacao?: Date,
    codigoMunicipio?: string,
    spc?: string,
    posicao?: string,
    grupo?: string,
    limite?: Float32Array,
    descontoAtacado?: string,
    descontoVarejo?: string,
    desconto?: string,
    percentualDesconto?: number,
    suframa?: string,
    contribuinte?: string,
    revendedor?: string,
    produtorRural?: string,
    inscricaoEstadualProdutorRural?: string,
    comprar?: string,
    filial?: string,
    codigoConta?: number,
    cobranca?: string,
    enderecoCobranca?: string,
    pagamento?: string,
    cidadecobranca?: string,
    cepCobranca?: string,
    estadocobranca?: string,
    representanteLegalNome?: string,
    representanteLegalEndereco?: string,
    representanteLegalBairro?: string,
    representanteLegalEstado?: string,
    representanteLegalCidade?: string,
    representanteLegalCep?: string,
    representanteLegalTelefone?: string,
    representanteLegalRg?: string,
    representanteLegalCpf?: string,
    diasBloqueioAbsoluto?: number,
    diasBloqueioVendas?: number,
    diasAlertaAbsoluto?: number,
    diasBloqueioVendasNotas?: number,
    exibirDescontoNe?: string,
    exibirdescontoOs?: string,
    exibirdescontoDocsFiscal?: string,
    boletoCodigoBanco?: string,
    boletoNomeBanco?: string,
    valorMinimoBoleto?: Float32Array,
    diasPrazoNotasFiscalSemFaturar?: number,
    serieNotas?: string,
    serieTitulos?: string,
    serieCaixa?: string,
    conhecimentoTransporteClienteIcmsSubstituicaoTributaria?: number,
    conhecimentoTransporteFreteLiquido?: number,
    conhecimentoTransporteRemetente?: number,
    conhecimentoTransporteDestinatario?: number,
    conhecimentoTransporteTomador?: number,
    conhecimentoTransporteExpedidor?: number,
    conhecimentoTransporteEminente?: number,
    conhecimentoTransporteRetirante?: number,
    nfseIncetivoCultura?: string,
    nfseClienteIssSubstituicaoTributaria?: string,
    nfseAliquotaIssFixa?: string,
    nfsePercentualPis?: string,
    nfsePercentualCofins?: string,
    nfsePercentualInss?: string,
    nfsePercentualIr?: string,
    nfsePercentualCsll?: string,
    podeAlterarMensagemNotaFiscal?: string,
    incluirUsuario?: string,
    modificarUsuario?: string,
    modificarData?: Date,
    imagemPath?: string,
    saldoValeCredito?: number,
    notas?: Array<Nota>,
    faturamentoEspelho?: FaturamentoEspelho,
    emailEnvioDanfeXml?: string
    ) {
    this.id = id;
    this.empresa = empresa;
    this.codigo = codigo;
    this.tipoPessoa = tipoPessoa;
    this.cnpjCpf = cnpjCpf;
    this.incluirData = incluirData;
    this.nome = nome;
    this.cep = cep;
    this.endereco = endereco;
    this.numero = numero;
    this.bairro = bairro;
    this.cidade = cidade;
    this.estado = estado;
    this.dataNascimento = dataNascimento;
    this.ddd = ddd;
    this.telefoneResidencial = telefoneResidencial;
    this.telefoneComercial = telefoneComercial;
    this.fax = fax;
    this.email = email;
    this.inscricaoEstadual = inscricaoEstadual;
    this.rg = rg;
    this.orgaoEmissorRg = orgaoEmissorRg;
    this.inscricaoMunicipal = inscricaoMunicipal;
    this.rfbSituacao = rfbSituacao;
    this.rfbDataSituacao = rfbDataSituacao;
    this.codigoMunicipio = codigoMunicipio;
    this.spc = spc;
    this.posicao = posicao;
    this.grupo = grupo;
    this.limite = limite;
    this.descontoAtacado = descontoAtacado;
    this.descontoVarejo = descontoVarejo;
    this.desconto = desconto;
    this.percentualDesconto = percentualDesconto;
    this.suframa = suframa;
    this.contribuinte = contribuinte;
    this.revendedor = revendedor;
    this.produtorRural = produtorRural;
    this.inscricaoEstadualProdutorRural = inscricaoEstadualProdutorRural;
    this.comprar = comprar;
    this.filial = filial;
    this.codigoConta = codigoConta;
    this.cobranca = cobranca;
    this.enderecoCobranca = enderecoCobranca;
    this.pagamento = pagamento;
    this.cidadecobranca = cidadecobranca;
    this.cepCobranca = cepCobranca;
    this.estadocobranca = estadocobranca;
    this.representanteLegalNome = representanteLegalNome;
    this.representanteLegalEndereco = representanteLegalEndereco;
    this.representanteLegalBairro = representanteLegalBairro;
    this.representanteLegalEstado = representanteLegalEstado;
    this.representanteLegalCidade = representanteLegalCidade;
    this.representanteLegalCep = representanteLegalCep;
    this.representanteLegalTelefone = representanteLegalTelefone;
    this.representanteLegalRg = representanteLegalRg;
    this.representanteLegalCpf = representanteLegalCpf;
    this.diasBloqueioAbsoluto = diasBloqueioAbsoluto;
    this.diasBloqueioVendas = diasBloqueioVendas;
    this.diasAlertaAbsoluto = diasAlertaAbsoluto;
    this.diasBloqueioVendasNotas = diasBloqueioVendasNotas;
    this.exibirDescontoNe = exibirDescontoNe;
    this.exibirdescontoOs = exibirdescontoOs;
    this.exibirdescontoDocsFiscal = exibirdescontoDocsFiscal;
    this.boletoCodigoBanco = boletoCodigoBanco;
    this.boletoNomeBanco = boletoNomeBanco;
    this.valorMinimoBoleto = valorMinimoBoleto;
    this.diasPrazoNotasFiscalSemFaturar = diasPrazoNotasFiscalSemFaturar;
    this.serieNotas = serieNotas;
    this.serieTitulos = serieTitulos;
    this.serieCaixa = serieCaixa;
    this.conhecimentoTransporteClienteIcmsSubstituicaoTributaria = conhecimentoTransporteClienteIcmsSubstituicaoTributaria;
    this.conhecimentoTransporteFreteLiquido = conhecimentoTransporteFreteLiquido;
    this.conhecimentoTransporteRemetente = conhecimentoTransporteRemetente;
    this.conhecimentoTransporteDestinatario = conhecimentoTransporteDestinatario;
    this.conhecimentoTransporteTomador = conhecimentoTransporteTomador;
    this.conhecimentoTransporteExpedidor = conhecimentoTransporteExpedidor;
    this.conhecimentoTransporteEminente = conhecimentoTransporteEminente;
    this.conhecimentoTransporteRetirante = conhecimentoTransporteRetirante;
    this.nfseIncetivoCultura = nfseIncetivoCultura;
    this.nfseClienteIssSubstituicaoTributaria = nfseClienteIssSubstituicaoTributaria;
    this.nfseAliquotaIssFixa = nfseAliquotaIssFixa;
    this.nfsePercentualPis = nfsePercentualPis;
    this.nfsePercentualCofins = nfsePercentualCofins;
    this.nfsePercentualInss = nfsePercentualInss;
    this.nfsePercentualIr = nfsePercentualIr;
    this.nfsePercentualCsll = nfsePercentualCsll;
    this.podeAlterarMensagemNotaFiscal = podeAlterarMensagemNotaFiscal;
    this.incluirUsuario = incluirUsuario;
    this.modificarUsuario = modificarUsuario;
    this.modificarData = modificarData;
    this.imagemPath = imagemPath;
    this.saldoValeCredito = saldoValeCredito;
    this.notas = notas;
    this.faturamentoEspelho = faturamentoEspelho;
    this.emailEnvioDanfeXml = emailEnvioDanfeXml;
  }

}
