import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import "rxjs/add/operator/toPromise";

import { environment } from "./../../environments/environment";
import { PerfilAcesso } from "./../core/models/PerfilAcesso";
import { MoneyHttp } from "app/seguranca/money-http";

export class PerfilAcessoFiltro {
  id: number;
  nome: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class PerfilAcessoService {
  perfilAcessoUrl: string;

  constructor(private http: MoneyHttp) {
    this.perfilAcessoUrl = `${environment.apiUrl}/perfilAcesso`;
  }

  pesquisar(filtro: PerfilAcessoFiltro): Promise<any> {
    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });
    if (filtro.nome) {
      params = params.append("nome", filtro.nome);
    }
    return this.http
      .get<any>(`${this.perfilAcessoUrl}`, { params })
      .toPromise()
      .then(response => {
        const perfilAcesso = response.content;

        const resultado = {
          perfilAcesso,
          total: response.totalElements
        };

        return resultado;
      });
  }

  listarTodas(): Promise<any> {
    return this.http
      .get<any>(`${this.perfilAcessoUrl}/lista`)
      .toPromise()
      .then(response => response.content);
  }

  adicionar(perfilAcesso: PerfilAcesso): Promise<PerfilAcesso> {
    return this.http.post<PerfilAcesso>(this.perfilAcessoUrl, perfilAcesso).toPromise();
  }

  atualizar(perfilAcesso: PerfilAcesso): Promise<PerfilAcesso> {
    return this.http
      .put<PerfilAcesso>(`${this.perfilAcessoUrl}/${perfilAcesso.id}`, perfilAcesso)
      .toPromise();
  }

  buscarPorId(id: number): Promise<PerfilAcesso> {
    return this.http.get<PerfilAcesso>(`${this.perfilAcessoUrl}/${id}`).toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.perfilAcessoUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }

  buscarPeloNome(nome: string): Promise<PerfilAcesso> {
    return this.http.get<PerfilAcesso>(`${this.perfilAcessoUrl}/buscarPeloNome/${nome}`).toPromise();
  }
}
