import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';

import 'rxjs/add/operator/toPromise';

import {environment} from './../../environments/environment';
import {MoneyHttp} from 'app/seguranca/money-http';

import {Fornecedor} from './../core/models/Fornecedor';
import {FornecedorCadastro} from './../core/models/FornecedorCadastro';
import {SelectItem} from 'primeng/api';
import {Reposicao} from '../core/models/Reposicao';
import {AuthService} from '../seguranca/auth.service';

export class FornecedorFiltro {
  nome: string;
  codigo: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class FornecedorService {

  fornecedoresUrl: string;

  constructor(
    private http: MoneyHttp,
    private auth: AuthService,
  ) {
    this.fornecedoresUrl = `${environment.apiUrl}/fornecedores`;
  }

  pesquisar(filtro: FornecedorFiltro): Promise<any> {

    let fornecedoresUrlPesquisar: string = `${this.fornecedoresUrl}/filter/nome`;

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    if (filtro.nome) {

      params = params.append('nome', filtro.nome);

    } else if (filtro.codigo) {

      params = params.append('codigo', filtro.codigo);
      fornecedoresUrlPesquisar = `${this.fornecedoresUrl}/filter/codigo`;

    }

    return this.http
      .get<any>(fornecedoresUrlPesquisar, {params})
      .toPromise()
      .then(response => {
        const fornecedores = response.content;

        const resultado = {
          fornecedores,
          total: response.totalElements
        };

        return resultado;
      });
  }

  listarTodas(): Promise<Array<Fornecedor>> {
    return this.http
      .get<Array<Fornecedor>>(`${this.fornecedoresUrl}/lista`)
      .toPromise()
      .then(response => response);
  }

  adicionar(fornecedorCadastro: FornecedorCadastro): Promise<Fornecedor> {
    return this.http.post<Fornecedor>(this.fornecedoresUrl, fornecedorCadastro).toPromise();
  }

  atualizar(fornecedor: Fornecedor): Promise<Fornecedor> {
    return this.http
      .put<Fornecedor>(`${this.fornecedoresUrl}/${fornecedor.id}`, fornecedor)
      .toPromise();
  }

  buscarPorId(id: number): Promise<Fornecedor> {
    return this.http
      .get<Fornecedor>(`${this.fornecedoresUrl}/${id}`)
      .toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.fornecedoresUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }

  next(cod_forneced: string): Promise<Fornecedor> {
    let params = new HttpParams({
      fromObject: {
        codigo: cod_forneced
      }
    });
    return this.http.get<Fornecedor>(`${this.fornecedoresUrl}?next`, {params}).toPromise();
  }
  
  previous(cod_forneced: string): Promise<Fornecedor> {
    let params = new HttpParams({
      fromObject: {
        codigo: cod_forneced
      }
    });
    return this.http.get<Fornecedor>(`${this.fornecedoresUrl}?previous`, {params}).toPromise();
  }

  getFornecedores(): Promise<SelectItem[]> {
    return new Promise((resolve, reject) => {
      this.listarTodas().then(fornes => {
          resolve(fornes.map(fornecedor => {
              return {
                label: `${fornecedor.codigo} - ${fornecedor.cnpj} - ${fornecedor.nome}`,
                value: fornecedor.codigo
              }
            })
          );
        }
      ).catch(error => reject(error));
    });
  }

  importarXML(file: File): Promise<any> {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    return this.http.post(`${this.fornecedoresUrl}/processarXmlNotaEntradaFornecedor`, formdata).toPromise();
  }

  atualizarFornecedorApartirXml(fornecedor: Fornecedor): Promise<Fornecedor> {
    return this.http.put<Fornecedor>(`${this.fornecedoresUrl}/atualizarFornecedorXml/${this.auth.jwtPayload.empresa}` , fornecedor).toPromise();
  }

  listarTodasPorNome(nome: string): Promise<any> {
    return this.http
      .get<any>(`${this.fornecedoresUrl}/listaTodas/${nome}`)
      .toPromise()
      .then(response => response);
  }


  buscarPorCnpj(cnpj: string): Promise<Fornecedor> {
    return this.http
      .get<Fornecedor>(`${this.fornecedoresUrl}/buscarPorCnpj/${cnpj}`)
      .toPromise();
  }

  buscarPorCodigo(codigo: string): Promise<Fornecedor> {
    return this.http
      .get<Fornecedor>(`${this.fornecedoresUrl}/codigo/${codigo}`)
      .toPromise();
  }


}
