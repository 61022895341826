import {FluxoCaixa} from './../core/models/FluxoCaixa';
import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';

import 'rxjs/add/operator/toPromise';

import {environment} from '../../environments/environment';
import {MoneyHttp} from 'app/seguranca/money-http';
import * as moment from 'moment';
import {EstornoCaixa} from 'app/core/models/EstornoCaixa';
import {PagamentoAntecipado} from '../core/models/PagamentoAntecipado';
import {Titulo} from '../core/models/Titulo';
import {Nota} from '../core/models/Nota';
import {Cliente} from '../core/models/Cliente';
import {Caixa} from '../core/models/Caixa';
import {BancoRecebimento} from '../core/models/BancoRecebimento';
import {Cr1Carta} from '../core/models/Cr1Carta';
import {Cheque} from '../core/models/Cheque';

export class FluxoFiltro {
  empresa: string;
  idCaixa: string;
  documento: string;
  serie: string;
  emissaoDe: Date;
  idpgto: string;
  emissaoAte: Date;
  pagina = 0;
  itensPorPagina = 20;
}

export class FluxoCaixaDocumentosPagarValeCredito{
  caixa: Caixa;
  empresa: string;
  cliente:Cliente;
  pagamentoAntecipado: PagamentoAntecipado;
  usuario: string;
  documento:string;
  serie:string;
}

export class ControleFluxoCaixa {

  empresa: string;
  caixa: string;
  formaPagamento: string;
  caixaOperacao: Caixa;
  inclData: Date;
  inclUser: string;
  pagamentoBancoRecebimentos: Array<BancoRecebimento>;
  pagamentoCartoes: Array<Cr1Carta>;
  pagamentoAntecipados: Array<PagamentoAntecipado>;
  pagamentoCheques: Array<Cheque>;
  valorTotalCartao: number;
  valorTotalRecebimentoBanco: number;
  valorTotalCheque: number;
  valorTotalDinheiro: number;
  valorTotalValeCredito: number;
  valorTotalAPagar: number;

  valorRecebido: number;
  valorTotal: number;

  fluxosCaixas = new Array<FluxoCaixa>();
  fluxosCaixasLancados = new Array<FluxoCaixa>();

  troco: number;
  falta: number;

  fechamentoCaixa: boolean;

  tipoEstorno: string = 'doc';
  documentoSelecionado: FluxoCaixa;
  motivoEstorno:string;

  estornosCaixa = new Array<EstornoCaixa>();

}


@Injectable()
export class FluxoCaixaService {
  fluxoCaixasUrl: string;
  estornoCaixaUrl: string;

  constructor(private http: MoneyHttp) {
    this.fluxoCaixasUrl = `${environment.apiUrl}/fluxosCaixas`;
    this.estornoCaixaUrl = `${environment.apiUrl}/estornoCaixas`
  }

  pesquisar(filtro: FluxoFiltro): Promise<any> {
    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    if (filtro.empresa) {
      params = params.append('empresa', filtro.empresa);
    }

    if (filtro.documento) {
      params = params.append('documento', filtro.documento);
      this.fluxoCaixasUrl = `${environment.apiUrl}/fluxosCaixas/filter/documento`;
    }

    if (filtro.emissaoDe) {
      params = params.append(
        'emissaoDe',
        moment(filtro.emissaoDe).format('YYYY-MM-DD')
      );
    }

    if (filtro.emissaoDe) {
      params = params.append(
        'emissaoAte',
        moment(filtro.emissaoAte).format('YYYY-MM-DD')
      );
    }


    if (filtro.emissaoDe && filtro.emissaoAte) {
      this.fluxoCaixasUrl = `${environment.apiUrl}/fluxosCaixas?parametros`;
    }

    return this.http
      .get<any>(`${this.fluxoCaixasUrl}`, {params})
      .toPromise()
      .then(response => {
        const fluxoCaixa = response.content;

        const resultado = {
          fluxoCaixa,
          total: response.totalElements
        };

        return resultado;
      });
  }

  listarTodas(): Promise<any> {
    return this.http
      .get<any>(this.fluxoCaixasUrl)
      .toPromise()
      .then(response => response.content);
  }

  adicionar(fluxosCaixa: Array<FluxoCaixa>): Promise<FluxoCaixa> {
    this.fluxoCaixasUrl = `${environment.apiUrl}/fluxosCaixas`;
    return this.http.post<FluxoCaixa>(this.fluxoCaixasUrl, fluxosCaixa).toPromise();
  }

  atualizar(fluxoCaixa: FluxoCaixa): Promise<FluxoCaixa> {
    return this.http
      .put<FluxoCaixa>(`${this.fluxoCaixasUrl}/${fluxoCaixa.id}`, fluxoCaixa)
      .toPromise();
  }

  buscarPorId(id: number): Promise<FluxoCaixa> {
    return this.http
      .get<FluxoCaixa>(`${this.fluxoCaixasUrl}/${id}`)
      .toPromise()
      .then(response => {
        const fluxoCaixa = response;

        this.converterStringsParaDatas([fluxoCaixa]);

        return fluxoCaixa;
      });
  }

  private converterStringsParaDatas(fluxoCaixas: FluxoCaixa[]) {
    for (const fluxoCaixa of fluxoCaixas) {
      fluxoCaixa.emissao = moment(
        fluxoCaixa.emissao,
        'YYYY-MM-DD'
      ).toDate();

    }
  }


  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.fluxoCaixasUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }


  impressaoEncerramentoCaixa(empresa: string, caixa: string, data: Date, idCaixa: string, obs: string): Promise<Blob> {

    let params = new HttpParams({});
    params = params.append('empresa', empresa);
    params = params.append('caixa', caixa);
    params = params.append('idCaixa', idCaixa);
    params = params.append('inicio', moment(data).format('YYYY-MM-DD 00:00'));
    params = params.append('fim', moment(data).format('YYYY-MM-DD 23:59'));
    params = params.append('observacao', obs);

    const options = {
      params,
      responseType: 'blob' as 'json'
    }

    return this.http.get<Blob>(`${this.fluxoCaixasUrl}/impressaoEncerramentoCaixa`,
      options).toPromise();
  }

  documentoLancado(empresa: string, idCaixa: string, serie: string, documento:string): Promise<FluxoCaixa> {
    const params = new HttpParams({
      fromObject: {
        empresa: empresa,
        idCaixa: idCaixa,
        serie: serie,
        documento: documento

      }
    });

    return this.http.get<FluxoCaixa>(`${this.fluxoCaixasUrl}?documentoLancado`, {params}).toPromise();
  }

  buscarLancamentosCaixa(empresa: string, idCaixa: string, pagina: number): Promise<any> {
    this.fluxoCaixasUrl = `${environment.apiUrl}/fluxosCaixas`;

    let params = new HttpParams({
      fromObject: {
        page: pagina.toString(),
        size: '6'
      }
    });

    params = params.append('empresa', empresa);
    params = params.append('idCaixa', idCaixa);

    return this.http
      .get<any>(`${this.fluxoCaixasUrl}/lancadasCaixa`, {params})
      .toPromise()
      .then(response => {
        const fluxosCaixasLancados = response.content;

        const resultado = {
          fluxosCaixasLancados,
          total: response.totalElements
        };
        return resultado;
      });
  }

  buscarLancamentosEstornados(empresa: string, caixa: string): Promise<EstornoCaixa[]> {
    return this.http.get<EstornoCaixa[]>(`${this.estornoCaixaUrl}/${empresa}/${caixa}`).toPromise();
  }

  estornarDocumentoLancado(estorno: EstornoCaixa): Promise<EstornoCaixa[]> {
    this.estornoCaixaUrl = `${environment.apiUrl}/estornoCaixas`;
    return this.http.post<EstornoCaixa[]>(this.estornoCaixaUrl, estorno).toPromise();
  }

  impressaoReciboPagamentoFluxo(filtro: FluxoFiltro): Promise<Blob> {
    this.fluxoCaixasUrl = `${environment.apiUrl}/fluxosCaixas`;
    const params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString(),
        idpgto: filtro.idpgto
      }
    });
    return this.http.get<Blob>(`${this.fluxoCaixasUrl}?impressaoReciboPagamentoFluxo`,
      {params, responseType: 'blob' as 'json'}).toPromise();
  }

  prepararLancamentoCaixa(caixa: Caixa, empresa: string, documento:string , serie:string, cliente:Cliente,
                          pagamentoAntecipado:PagamentoAntecipado, usuario:string): Promise<Array<FluxoCaixa>> {

    let fluxoCaixaDocumentosPagarValeCredito = new FluxoCaixaDocumentosPagarValeCredito();
    fluxoCaixaDocumentosPagarValeCredito.caixa = caixa;
    fluxoCaixaDocumentosPagarValeCredito.empresa = empresa;;
    fluxoCaixaDocumentosPagarValeCredito.documento = documento;
    fluxoCaixaDocumentosPagarValeCredito.serie = serie;
    fluxoCaixaDocumentosPagarValeCredito.cliente = cliente;
    fluxoCaixaDocumentosPagarValeCredito.pagamentoAntecipado = pagamentoAntecipado;
    fluxoCaixaDocumentosPagarValeCredito.usuario = usuario;

    return this.http
        .put<Array<FluxoCaixa>>(`${this.fluxoCaixasUrl}/fluxoCaixaDocumentosPagarValeCredito`, fluxoCaixaDocumentosPagarValeCredito)
        .toPromise();
  }

  abrirCaixaFluxo(controleFluxoCaixa: ControleFluxoCaixa): Promise<ControleFluxoCaixa> {
    return this.http.post<ControleFluxoCaixa>(`${this.fluxoCaixasUrl}/abrirCaixa`, controleFluxoCaixa).toPromise();
  }



  executarLancamentoCaixa(controleFluxoCaixa: ControleFluxoCaixa): Promise<ControleFluxoCaixa> {
    return this.http.post<ControleFluxoCaixa>(`${this.fluxoCaixasUrl}/executarLancamentoCaixa`, controleFluxoCaixa).toPromise();
  }

  calcularPagamentos(controleFluxoCaixa: ControleFluxoCaixa): Promise<ControleFluxoCaixa> {
    return this.http.put<ControleFluxoCaixa>(`${this.fluxoCaixasUrl}/calcularPagamentos`, controleFluxoCaixa).toPromise();
  }

  prepararLancamentoAvulso(fluxoCaixa: FluxoCaixa){
    return this.http.put<FluxoCaixa>(`${this.fluxoCaixasUrl}/prepararLancamentoAvulso`, fluxoCaixa).toPromise();
  }

  executarFechamentoParcial(controleFluxoCaixa: ControleFluxoCaixa): Promise<ControleFluxoCaixa> {
    return this.http.put<ControleFluxoCaixa>(`${this.fluxoCaixasUrl}/executarFechamentoParcial`, controleFluxoCaixa).toPromise();
  }

  executarEstornoCaixa(controleFluxoCaixa: ControleFluxoCaixa): Promise<ControleFluxoCaixa> {
    return this.http.put<ControleFluxoCaixa>(`${this.fluxoCaixasUrl}/executarEstornoCaixa`, controleFluxoCaixa).toPromise();
  }


  buscarPorIdPagamento(idpgto: string): Promise<Array<FluxoCaixa>> {
    this.fluxoCaixasUrl = `${environment.apiUrl}/fluxosCaixas`;

    let params = new HttpParams({
      fromObject: {
      }
    });

    params = params.append('idPagamento', idpgto);

    return this.http
      .get<Array<FluxoCaixa>>(`${this.fluxoCaixasUrl}/porIdPagamento`, {params})
      .toPromise()
  }


  buscarPorCaixaDoDia(caixa: string): Promise<Array<FluxoCaixa>> {
    this.fluxoCaixasUrl = `${environment.apiUrl}/fluxosCaixas`;

    let params = new HttpParams({
      fromObject: {
      }
    });

    params = params.append('caixa', caixa);

    return this.http
      .get<Array<FluxoCaixa>>(`${this.fluxoCaixasUrl}/porCaixaDoDia`, {params})
      .toPromise()
  }


}


