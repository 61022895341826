import { Component, OnInit } from '@angular/core';

@Component({
  template: `
    <div class="container">
      <h1 class="text-center">Página não encontrada</h1>
    <div>
  `,
  styles: []
})
export class PaginaNaoEncontradaComponent {
}
