import { Injectable } from '@angular/core';
import {MoneyHttp} from '../../seguranca/money-http';
import {environment} from '../../../environments/environment';
import {HttpParams} from '@angular/common/http';
import * as moment from 'moment';
import {Reposicao} from '../../core/models/Reposicao';
import {OrcamentoOS} from '../../core/models/OrcamentoOS';

export class ReposicaoFiltro{
  origem: string;
  dataDe: Date;
  dataAte: Date;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class ReposicaoService {

  private url: string;

  constructor(
    private http: MoneyHttp,
  ) {
    this.url = `${environment.apiUrl}/reposicao`;
  }

  buscarPorId(id: number): Promise<Reposicao> {
    return this.http
      .get<Reposicao>(`${this.url}/${id}`)
      .toPromise()
      .then(response => {
        return response;
      });
  }

  pesquisarPeriodo(filtro: ReposicaoFiltro): Promise<any> {

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    if (filtro.origem) {
      params = params.append('origem', filtro.origem);
    }

    if (filtro.dataDe) {
      params = params.append(
        'dataDe',
        moment(filtro.dataDe).format('YYYY-MM-DD 00:00')
      );
    }

    if (filtro.dataAte) {
      params = params.append(
        'dataAte',
        moment(filtro.dataAte).format('YYYY-MM-DD 23:59')
      );
    }

    return this.http
      .get<any>(`${this.url}/periodo/origem/dataDe/dataAte`, {params})
      .toPromise()
      .then(response => {
        const resultado = {
          reposicoes: response.content,
          total: response.totalElements
        };
        return resultado;
      });
  }


  processarReposicao(reposicao: Reposicao): Promise<Reposicao> {
    return this.http.put<Reposicao>(`${this.url}/gerarReposicao` , reposicao).toPromise();
  }

  gerarNE(reposicao: Reposicao): Promise<Reposicao> {
    return this.http.put<Reposicao>(`${this.url}/documentoNe`,
      reposicao).toPromise();
  }


}
