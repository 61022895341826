import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import "rxjs/add/operator/toPromise";

import { environment } from "../../../environments/environment";
import { MoneyHttp } from "app/seguranca/money-http";

import { OrdemEstoque } from "app/core/models/OrdemEstoque"



export class OrdemEstoqueFiltro {
  oeDescricao: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class OrdemEstoqueService {
  ordemEstoqueUrl: string;

  constructor(private http: MoneyHttp) {
    this.ordemEstoqueUrl = `${environment.apiUrl}/ordemEstoque`;
  }
  pesquisar(filtro: OrdemEstoqueFiltro): Promise<any> {
    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });
    if (filtro.oeDescricao) {
      params = params.append("oeDescricao", filtro.oeDescricao);
    }
    return this.http
      .get<any>(`${this.ordemEstoqueUrl}`, { params })
      .toPromise()
      .then(response => {
        const ordemEstoque = response.content;

        const resultado = {
          ordemEstoque,
          total: response.totalElements
        };

        return resultado;
      });
  }

  listarTodas(): Promise<any> {
    return this.http
      .get<any>(this.ordemEstoqueUrl)
      .toPromise()
      .then(response => response.content);
  }

  adicionar(ordemEstoque: OrdemEstoque): Promise<OrdemEstoque> {
    return this.http
      .post<OrdemEstoque>(this.ordemEstoqueUrl, ordemEstoque)
      .toPromise();
  }

  atualizar(ordemEstoque: OrdemEstoque): Promise<OrdemEstoque> {
    return this.http
      .put<OrdemEstoque>(
        `${this.ordemEstoqueUrl}/${ordemEstoque.id}`,
        ordemEstoque
      )
      .toPromise();
  }

  buscarPorId(id: number): Promise<OrdemEstoque> {
    return this.http
      .get<OrdemEstoque>(`${this.ordemEstoqueUrl}/${id}`)
      .toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.ordemEstoqueUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }
}
