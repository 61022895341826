import { Bloqueio } from './../core/models/Bloqueio';
import {Injectable} from '@angular/core';
import {environment} from './../../environments/environment';
import {MoneyHttp} from 'app/seguranca/money-http';


@Injectable()
export class BloqueioService {

    bloqueioUrl: string;

    constructor(
        private http: MoneyHttp
    ) {
      this.bloqueioUrl =  `${environment.apiUrl}/bloqueios`;
    }

    buscarBloqueioPeloCodigo(codigo: string) {
        return this.http.get<Bloqueio>(`${this.bloqueioUrl}/filter/${codigo}`).toPromise();
    }

}