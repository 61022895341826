import {Injectable} from '@angular/core';

import 'rxjs/add/operator/toPromise';

import {MoneyHttp} from 'app/seguranca/money-http';

import {TituloBaixaRetorno} from '../core/models/TituloBaixaRetorno';
import {environment} from '../../environments/environment';


@Injectable()
export class TituloBaixaRetornoService{

  url: string;

  constructor(private http: MoneyHttp) {
    this.url = `${environment.apiUrl}/tituloBaixaRetorno`;
  }


  adicionar(tituloBaixaRetorno: TituloBaixaRetorno): Promise<TituloBaixaRetorno> {
    return this.http.post<TituloBaixaRetorno>(this.url, tituloBaixaRetorno).toPromise();
  }

  atualizar(tituloBaixaRetorno: TituloBaixaRetorno): Promise<TituloBaixaRetorno> {
    return this.http
      .put<TituloBaixaRetorno>(`${this.url}/${tituloBaixaRetorno.id}`, tituloBaixaRetorno)
      .toPromise();
  }

  buscarPorId(id: number): Promise<TituloBaixaRetorno> {
    return this.http.get<TituloBaixaRetorno>(`${this.url}/${id}`).toPromise();
  }


  listarTodasPorDocumento(documento: string): Promise<Array<TituloBaixaRetorno>> {
    return this.http.get<Array<TituloBaixaRetorno>>(`${this.url}/porDocumento/${documento}`).toPromise();
  }

}
