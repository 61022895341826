export class Bloqueio {
  id: number;
  codigoBloqueio: string;
  desbloqueado: string;
  ativar: string;
  acao: string;
  descacao: string;
  permitirInserirItemBloqueadoPrevenda: string;
  senhaLiberacao: string;
  
  constructor(
    id?: number,
    codigoBloqueio?: string,
    desbloqueado?: string,
    ativar?: string,
    acao?: string,
    descacao?: string,
    permitirInserirItemBloqueadoPrevenda?: string,
    senhaLiberacao?: string
  ) {
    this.id = id;
    this.codigoBloqueio = codigoBloqueio;
    this.desbloqueado = desbloqueado;
    this.ativar = ativar;
    this.acao = acao;
    this.descacao = descacao;
    this.permitirInserirItemBloqueadoPrevenda = permitirInserirItemBloqueadoPrevenda;
    this.senhaLiberacao = senhaLiberacao;
  }

}