import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';

import 'rxjs/add/operator/toPromise';

import {environment} from './../../environments/environment';
import {Empresa} from './../core/models/Empresa';
import {MoneyHttp} from 'app/seguranca/money-http';
import {SelectItem} from 'primeng/api';

export class EmpresaFiltro {
  nome: string;
  codigo: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class EmpresaService {

  empresasUrl: string;

  constructor(private http: MoneyHttp) {
    this.empresasUrl = `${environment.apiUrl}/empresas`;
  }

  pesquisar(filtro: EmpresaFiltro): Promise<any> {

    let empresasUrlPesquisar: string = `${this.empresasUrl}/filter/nome`;

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    if (filtro.nome) {

      params = params.append('nome', filtro.nome);

    } else if (filtro.codigo) {

      params = params.append('codigo', filtro.codigo);
      empresasUrlPesquisar = `${this.empresasUrl}/filter/codigo`;

    }
    return this.http
      .get<any>(empresasUrlPesquisar, {params})
      .toPromise()
      .then(response => {
        const empresas = response.content;

        const resultado = {
          empresas,
          total: response.totalElements
        };

        return resultado;
      });
  }

  listarTodas(): Promise<Array<Empresa>> {
    return this.http
      .get<Array<Empresa>>(`${this.empresasUrl}/lista`)
      .toPromise()
      .then(response => response);
  }

  adicionar(empresa: Empresa): Promise<Empresa> {
    return this.http.post<Empresa>(this.empresasUrl, empresa).toPromise();
  }

  atualizar(empresa: Empresa): Promise<Empresa> {
    return this.http
      .put<Empresa>(`${this.empresasUrl}/${empresa.id}`, Empresa)
      .toPromise();
  }

  buscarPorId(id: number): Promise<Empresa> {
    return this.http.get<Empresa>(`${this.empresasUrl}/${id}`).toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.empresasUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }

  buscarPorCodigo(codigo: string): Promise<Empresa> {
    return this.http.get<Empresa>(`${this.empresasUrl}/codigo/${codigo}`).toPromise();
  }

  getEmpresas(): Promise<SelectItem[]> {
    return new Promise((resolve, reject) => {
      this.listarTodas().then(emps => {
          resolve(emps.map(empresa => {
              return {
                label: `${empresa.codigo}`,
                value: empresa.codigo
              }
            })
          );
        }
      ).catch(error => reject(error));
    });
  }

}
