export class NotaMsg{

    dataHora: Date;
    documento: string;
    empresa: string;
    id: number;
    mensagem: string;
    serie: string;
    usuario: string;
    usuarioLogin: string;
  
    constructor(

     dataHora?: Date,
     documento?: string,
     empresa?: string,
     id?: number,
     mensagem?: string,
     serie?: string,
     usuario?: string,
     usuarioLogin?: string
    ){

     this.dataHora = dataHora;
     this.documento = documento;
     this.empresa = empresa;
     this.id = id;
     this.mensagem = mensagem;
     this.serie = serie;
     this.usuario = usuario;
     this.usuarioLogin = usuarioLogin;
    }

}
