import {Injectable} from '@angular/core';

import 'rxjs/add/operator/toPromise';

import {environment} from './../../environments/environment';
import {MoneyHttp} from 'app/seguranca/money-http';
import {PagamentoAntecipadoNotaFilial} from '../core/models/PagamentoAntecipadoNotaFilial';
import {PagamentoAntecipadoItem} from '../core/models/PagamentoAntecipadoItem';


@Injectable()
export class PagamentoAntecipadoItemService {

  pagamentosAntecipadosItemUrl: string;

  constructor(private http: MoneyHttp) {
    this.pagamentosAntecipadosItemUrl = `${environment.apiUrl}/pagamentosAntecipadosItems`;
  }


  adicionar(pagamentoAntecipadoItem: PagamentoAntecipadoItem): Promise<PagamentoAntecipadoItem> {
    return this.http.post<PagamentoAntecipadoItem>(this.pagamentosAntecipadosItemUrl, pagamentoAntecipadoItem)
      .toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.pagamentosAntecipadosItemUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }


}
