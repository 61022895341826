import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import "rxjs/add/operator/toPromise";

import { environment } from "../../environments/environment";
import { ComissaoProgressiva } from "../core/models/ComissaoProgressiva";
import { MoneyHttp } from "app/seguranca/money-http";

export class ComissaoProgressivaFiltro {
  codigo: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class ComissaoProgressivaService {

  comissoesProgressivasUrl: string;

  constructor(private http: MoneyHttp) {
    this.comissoesProgressivasUrl = `${environment.apiUrl}/comissoesProgressivas`;
  }

  pesquisar(filtro: ComissaoProgressivaFiltro): Promise<any> {

    let comissoesProgressivasUrlPesquisar: string = `${this.comissoesProgressivasUrl}/codigo`;

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    if (filtro.codigo) {

      params = params.append("codigo", filtro.codigo);
      
      
    }
    return this.http
    .get<any>(comissoesProgressivasUrlPesquisar, { params })
    .toPromise()
    .then(response => {
      const comissoesProgressivas= response.content;

      const resultado = {
        comissoesProgressivas,
        total: response.totalElements
      };

      return resultado;
    });
}

  listarTodas(): Promise<any> {
    return this.http
      .get<any>(`${this.comissoesProgressivasUrl}/lista`)
      .toPromise()
      .then(response => response.content);
  }

  adicionar(ComissaoProgressiva: ComissaoProgressiva): Promise<ComissaoProgressiva> {
    return this.http.post<ComissaoProgressiva>(this.comissoesProgressivasUrl, ComissaoProgressiva).toPromise();
  }

  atualizar(ComissaoProgressiva: ComissaoProgressiva): Promise<ComissaoProgressiva> {
    return this.http
      .put<ComissaoProgressiva>(`${this.comissoesProgressivasUrl}/${ComissaoProgressiva.id}`, ComissaoProgressiva)
      .toPromise();
  }

  buscarPorId(id: number): Promise<ComissaoProgressiva> {
    return this.http.get<ComissaoProgressiva>(`${this.comissoesProgressivasUrl}/${id}`).toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.comissoesProgressivasUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }

}
