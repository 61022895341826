import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import "rxjs/add/operator/toPromise";

import { environment } from "./../../environments/environment";
import { PreVenda4 } from "./../core/models/PreVenda4";
import { MoneyHttp } from "app/seguranca/money-http";

export class PreVenda4Filtro {
  sequencia: string;
    pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class PreVenda4Service {

  dividaClienteUrl: string;

  constructor(private http: MoneyHttp) {
    this.dividaClienteUrl = `${environment.apiUrl}/preVenda4`;
  }

  pesquisar(filtro: PreVenda4Filtro): Promise<any> {

    let dividaClienteUrlPesquisar: string = `${this.dividaClienteUrl}/filter/sequencia`;

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    if (filtro.sequencia) {

      params = params.append("sequencia", filtro.sequencia);
      
    } 
    return this.http
    .get<any>(dividaClienteUrlPesquisar, { params })
    .toPromise()
    .then(response => {
      const dividaCliente = response.content;

      const resultado = {
        dividaCliente,
        total: response.totalElements
      };

      return resultado;
    });
}

  listarTodas(): Promise<any> {
    return this.http
      .get<any>(`${this.dividaClienteUrl}/lista`)
      .toPromise()
      .then(response => response.content);
  }

  adicionar(PreVenda4: PreVenda4): Promise<PreVenda4> {
    return this.http.post<PreVenda4>(this.dividaClienteUrl, PreVenda4).toPromise();
  }

  atualizar(PreVenda4: PreVenda4): Promise<PreVenda4> {
    return this.http
      .put<PreVenda4>(`${this.dividaClienteUrl}/${PreVenda4.id}`, PreVenda4)
      .toPromise();
  }

  buscarPorId(id: number): Promise<PreVenda4> {
    return this.http.get<PreVenda4>(`${this.dividaClienteUrl}/${id}`).toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.dividaClienteUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }

}
