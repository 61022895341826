import { Injectable } from "@angular/core";
import {HttpParams} from '@angular/common/http';

import "rxjs/add/operator/toPromise";

import { environment } from "./../../environments/environment";
import { MoneyHttp } from "app/seguranca/money-http";
import {FormaPagamentoParcela} from '../core/models/FormaPagamentoParcela';

@Injectable()
export class FormaPagamentoParcelaService{

  formasPagamentoPagamentoUrl: string;

  constructor(private http: MoneyHttp) {
    this.formasPagamentoPagamentoUrl = `${environment.apiUrl}/formasPagamentosParcelas`;
  }

  listarTodas(): Promise<any> {
    return this.http
      .get<any>(`${this.formasPagamentoPagamentoUrl}/lista`)
      .toPromise()
      .then(response => response);
  }


  obterFormaPagamentoParcelaPorCodigoValor(codigo: string, codpgto: string, valor:number):Promise<FormaPagamentoParcela> {
    console.log(`${this.formasPagamentoPagamentoUrl}/buscarPorCodigoValor/${codigo}/${codpgto}/${valor}`);
    return this.http.get<FormaPagamentoParcela>(
      `${this.formasPagamentoPagamentoUrl}/buscarPorCodigoValor/${codigo}/${codpgto}/${valor}`).toPromise();
  }

  formaPagamentoParcelaCliente(codigoPagamento: string): Promise<any> {

    let params = new HttpParams({
      fromObject: {
        page: '0',
        size: '5'
      }
    });
    params = params.append('codigoPagamento', codigoPagamento);
    return this.http
      .get<any>(`${this.formasPagamentoPagamentoUrl}/codigoPagamento`, {params})
      .toPromise()
      .then(response => {
        const formaPagamentoParcela = response.content;
        const resultado = {
          formaPagamentoParcela,
          total: response.totalElements
        };
        return resultado;
      });
  }


}
