import { Injectable } from '@angular/core';
import {MoneyHttp} from '../seguranca/money-http';
import {environment} from '../../environments/environment';
import {BalancoProdutoLocal} from '../core/models/BalancoProdutoLocal';

@Injectable()
export class BalancoProdutoLocalService {

  balancoProdutoLocalURL: string;

  constructor(private http: MoneyHttp) {
    this.balancoProdutoLocalURL= `${environment.apiUrl}/balancoProdutoLocal`;
  }

  atualizar(balancoProdutoLocal: BalancoProdutoLocal): Promise<BalancoProdutoLocal> {
    return this.http
      .put<BalancoProdutoLocal>(`${this.balancoProdutoLocalURL}/${balancoProdutoLocal.id}`, balancoProdutoLocal)
      .toPromise();
  }

}
