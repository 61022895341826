import { DinheiroCaixa } from './../core/models/DinheiroCaixa';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import 'rxjs/add/operator/toPromise';
import { Observable } from 'rxjs';

import { environment } from './../../environments/environment';
import { MoneyHttp } from 'app/seguranca/money-http';

@Injectable()
export class DinheiroCaixaService {

    dinheiroCaixaUrl: string;

    constructor(private http: MoneyHttp) {
        this.dinheiroCaixaUrl = `${environment.apiUrl}/dinheiroCaixas`;
    }

    adicionar(dinheiro: DinheiroCaixa): Promise<DinheiroCaixa> {
        return this.http.post<DinheiroCaixa>(this.dinheiroCaixaUrl, dinheiro).toPromise();
    }

}
