import { Injectable } from '@angular/core';
import { MoneyHttp } from 'app/seguranca/money-http';
import {HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {ProdutoValor} from '../core/models/ProdutoValor';


@Injectable()
export class ProdutoValorService {

  url: string;

  constructor(
    private http: MoneyHttp
  ) {
    this.url = `${environment.apiUrl}/produtosValores`;
  }


  listarHistoricoPrecos(codigo: string): Promise<Array<ProdutoValor>> {

    let params = new HttpParams({
      fromObject: {
        page: '1',
        size: '10'
      }
    });

    params = params.append('codigo', codigo);

    return this.http
      .get<any>(`${this.url}/historico/codigo`,
        {params})
      .toPromise();
  }



}
