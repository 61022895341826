import {Component} from '@angular/core';
import { AuthService } from 'app/seguranca/auth.service';

@Component({
    selector: 'app-footer',
    template: `
        <div class="layout-footer clearfix">
            <a href="dashboard.xhtml">
                <img alt="logo-colored" src="assets/layout/images/logo.png" *ngIf="auth.logoEmpresa('M')"/>
                <img alt="logo-colored" src="assets/layout/images/logo_cfreio.png" *ngIf="auth.logoEmpresa('C')"/>
            </a>

            <span class="footer-text-right">
                <span style="margin-right: 500px">versão 4.28.01.22</span>
                <span class="material-icons">copyright</span>
                <span>Todos os direitos reservados</span>
            </span>
        </div>
    `
})
export class AppFooterComponent {

    constructor(private auth: AuthService) {}

    logoEmpresa(empresa: string) {
        return this.auth.jwtPayload.empresa === empresa;
    }
}
