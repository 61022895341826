import {Cliente} from './Cliente';
import {PlacaVeiculo} from './PlacaVeiculo';

export class PlacaVeiculoCliente {

  id: number;
  empresa: string;
  incluirData: Date;
  incluirUsuario: string;
  placaVeiculo: PlacaVeiculo;
  cliente: Cliente;

  constructor(
    id?: number,
    empresa?: string,
    incluirData?: Date,
    incluirUsuario?: string,
    placaVeiculo?: PlacaVeiculo,
    cliente?: Cliente
  ) {
    this.id = id;
    this.empresa = empresa;
    this.incluirData = incluirData;
    this.incluirUsuario = incluirUsuario;
    this.placaVeiculo = placaVeiculo;
    this.cliente = cliente;
  }

}
