import {Injectable} from '@angular/core';
import {MoneyHttp} from '../../seguranca/money-http';
import {environment} from '../../../environments/environment';
import {OrcamentoOS} from '../../core/models/OrcamentoOS';
import {HttpParams} from '@angular/common/http';
import * as moment from 'moment';
import {StatusOrcamentoOS} from '../../core/models/interfaces/StatusOrcamento';

export class OrcamentoOSFiltro {
  empresa: string;
  dataDe: Date;
  dataAte: Date;
  atendente: any;
  cliente: any
  statusOrcamentoOS: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class OrcamentoOSService {

  private urlOrcamentoOS: string;

  aberto: string = 'AB';
  fechado: string = 'FE';
  constructor(
    private http: MoneyHttp,
  ) {
    this.urlOrcamentoOS = `${environment.apiUrl}/orcamentoOS`;
  }

  buscarPorId(id: number): Promise<OrcamentoOS> {
    return this.http
      .get<OrcamentoOS>(`${this.urlOrcamentoOS}/${id}`)
      .toPromise()
      .then(response => {
        return response;
      });
  }

  salvar(orcamentoOS: OrcamentoOS): Promise<OrcamentoOS> {
    if (!orcamentoOS.id)
      return this.adicionar(orcamentoOS);
    else
      return this.atualizar(orcamentoOS);
  }

  adicionar(orcamentoOS: OrcamentoOS): Promise<OrcamentoOS> {
    return this.http.post<OrcamentoOS>(this.urlOrcamentoOS, orcamentoOS).toPromise();
  }

  atualizar(orcamentoOS: OrcamentoOS): Promise<OrcamentoOS> {
    return this.http.put<OrcamentoOS>(`${this.urlOrcamentoOS}/${orcamentoOS.id}`, orcamentoOS).toPromise();
  }

  pesquisarPeriodo(filtro: OrcamentoOSFiltro): Promise<any> {

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    if (filtro.empresa) {
      params = params.append('empresa', filtro.empresa);
    }

    if (filtro.dataDe) {
      params = params.append(
        'dataDe',
        moment(filtro.dataDe).format('YYYY-MM-DD 00:00')
      );
    }

    if (filtro.dataAte) {
      params = params.append(
        'dataAte',
        moment(filtro.dataAte).format('YYYY-MM-DD 23:59')
      );
    }

    if (filtro.atendente) {
      params = params.append(
        'idAtendente',  filtro.atendente
      );
    }

    return this.http
      .get<any>(`${this.urlOrcamentoOS}/periodo/empresa/dataDe/dataAte/atendente`, {params})
      .toPromise()
      .then(response => {
        const resultado = {
          orcasOS: response.content,
          total: response.totalElements
        };
        return resultado;
      });
  }

  pesquisarControleOS(filtro: OrcamentoOSFiltro): Promise<any> {

    let orcamentoOSUrlPesquisar = `${this.urlOrcamentoOS}/cliente/empresa/cliente`;

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    if (filtro.cliente) {
      params = params.append('idCliente', filtro.cliente);
    } 

     if (filtro.empresa) {
      params = params.append('empresa', filtro.empresa);
    }

    if (filtro.dataDe) {
      params = params.append(
        'dataDe',
        moment(filtro.dataDe).format('YYYY-MM-DD 00:00')
      );
      orcamentoOSUrlPesquisar = `${this.urlOrcamentoOS}/periodo/empresa/dataDe/dataAte/statusOrcamento/atendente`;
    
      }
    if (filtro.dataAte) {
      params = params.append(
        'dataAte',
        moment(filtro.dataAte).format('YYYY-MM-DD 23:59')
      );
      }

      if (filtro.statusOrcamentoOS) {
        params = params.append( 'statusOrcamento', filtro.statusOrcamentoOS);
      }

    if (filtro.atendente) {
      params = params.append(
        'idAtendente',  filtro.atendente
      );
      
    }

   
   

    return this.http
    .get<any>(orcamentoOSUrlPesquisar, {params})
      .toPromise()
      .then(response => {
        const resultado = {
          orcasOS: response.content,
          total: response.totalElements
        };
        return resultado;
      });
  }

   listarOrcamentosOSPorClientes(cliente_id: number, placa_id: number, empresa: string): Promise<any> {

    let params = new HttpParams({
      fromObject: {
        page: '0',
        size: '20'
      }
    });

    params = params.append('empresa', empresa);
    params = params.append('cliente_id', cliente_id.toString());
    params = params.append('placa_id', placa_id.toString());

   return this.http           
   .get<any>(`${this.urlOrcamentoOS}?clientePlaca`, {params})
   .toPromise()
   .then(response => {
      const orcaOs = response.content;
      const resultado = {
        orcaOs,
        total: response.totalElements
      };

      return resultado;

    });

   }

  gerarNEOS(origem: string, destino: string, orcamentoOS: OrcamentoOS, reGeraNeOSCanceladas: boolean): Promise<OrcamentoOS> {
    return this.http.put<OrcamentoOS>(`${this.urlOrcamentoOS}/documentoNeOs/${origem}/${destino}/${reGeraNeOSCanceladas}`,
      orcamentoOS).toPromise();
  }

  gerarOS(orcamentoOS: OrcamentoOS): Promise<OrcamentoOS> {
    return this.http.put<OrcamentoOS>(`${this.urlOrcamentoOS}/gerarOS/${orcamentoOS.id}`, orcamentoOS).toPromise();
  }

  impressaoOrcamento(orcamentoOS: OrcamentoOS, imprimeCodigoInterno: boolean): Promise<Blob> {
    return this.http.get<Blob>(`${this.urlOrcamentoOS}/impressaoOrcamentoOS/${imprimeCodigoInterno}/${orcamentoOS.id}`,
      {responseType: 'blob' as 'json'}).toPromise();
  }

  impressaoOrdemServico(orcamentoOS: OrcamentoOS, imprimeCodigoInterno: boolean): Promise<Blob> {
    return this.http.get<Blob>(`${this.urlOrcamentoOS}/impressaoOrdemServico/${imprimeCodigoInterno}/${orcamentoOS.id}`,
      {responseType: 'blob' as 'json'}).toPromise();
  }

  impressaoOrdemServicoTecnicos(orcamentoOS: OrcamentoOS): Promise<Blob> {
    return this.http.get<Blob>(`${this.urlOrcamentoOS}/impressaoOrdemServicoTecnicos/${orcamentoOS.id}`,
      {responseType: 'blob' as 'json'}).toPromise();
  }


  recalcularOrcamento(orcamentoOS: OrcamentoOS): Promise<OrcamentoOS> {
    return this.http.put<OrcamentoOS>(`${this.urlOrcamentoOS}/recalcular/${orcamentoOS.id}`, orcamentoOS).toPromise();
  }

  recalcularOrcamentoPrecoLimite(orcamentoOS: OrcamentoOS): Promise<OrcamentoOS> {
    return this.http.put<OrcamentoOS>(`${this.urlOrcamentoOS}/recalcularPrecoLimite/${orcamentoOS.id}`, orcamentoOS).toPromise();
  }


  processarCancelamentoDocumentosNeOS(orcamentoOS: OrcamentoOS): Promise<OrcamentoOS> {
    return this.http.put<OrcamentoOS>(`${this.urlOrcamentoOS}/cancelarDocumentoNeOs/${orcamentoOS.id}`, orcamentoOS).toPromise();
  }

  getListaStatusOrcamentoOS(): Promise<Array<StatusOrcamentoOS>> {
    return this.http
      .get<Array<StatusOrcamentoOS>>(`${environment.apiUrl}/statusOrcamentoOS/lista`)
      .toPromise()
      .then(response => {
        return response;
      });
  }

}
