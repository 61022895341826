import {Injectable} from '@angular/core';
import {MoneyHttp} from '../seguranca/money-http';
import {environment} from '../../environments/environment';
import {ListaPrecoProdutoFornecedor} from '../core/models/ListaPrecoProdutoFornecedor';

@Injectable()
export class ListaPrecoProdutoFornecedorService{

  private url: string;

  constructor(
    private http: MoneyHttp,
  ) {
    this.url = `${environment.apiUrl}/listaPrecoProdutoFornecedor`;
  }

  importarListaPrecoFornecedor(file: File): Promise<Array<ListaPrecoProdutoFornecedor>> {
    var formdata: FormData = new FormData();
    formdata.append('file', file);
    return this.http.put<Array<ListaPrecoProdutoFornecedor>>(`${this.url}/importarListaPrecoProdutoFornecedor`, formdata).toPromise();
  }

}
