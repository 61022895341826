import { Injectable } from "@angular/core";

import "rxjs/add/operator/toPromise";

import { environment } from "./../../environments/environment";
import { MoneyHttp } from "app/seguranca/money-http";


@Injectable()
export class ClienteFormaPagamentoParcelaService{

  clienteFormasPagamentoPagamentoUrl: string;

  constructor(private http: MoneyHttp) {
    this.clienteFormasPagamentoPagamentoUrl = `${environment.apiUrl}/clientesFormasPagamentoParcelas`;
  }

  listarTodas(): Promise<any> {
    return this.http
      .get<any>(`${this.clienteFormasPagamentoPagamentoUrl}/lista`)
      .toPromise()
      .then(response => response);
  }

}
