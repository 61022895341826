import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { ToastyService } from 'ng2-toasty';
import {LazyLoadEvent, ConfirmationService } from 'primeng/components/common/api';
import {Component} from '@angular/core';
import {AppComponent} from './app.component';
import { Output, EventEmitter, ElementRef } from '@angular/core';
import { LogoutService } from './seguranca/logout.service';
import { Dialog } from 'primeng/dialog';
import { ErrorHandlerService } from './core/error-handler.service';
import { AuthService } from './seguranca/auth.service';
import { LiberacaoService, LiberacaoFiltro } from 'app/liberacoes/liberacao.service';
import { Liberacao } from './core/models/Liberacao';
import { ProdutoCautela } from './core/models/ProdutoCautela';
import { Vendedor } from './core/models/Vendedor';
import { VendedorService, VendedorFiltro } from 'app/vendedores/vendedor.service';
import { Nota } from './core/models/Nota';
import { Cliente } from 'app/core/models/Cliente';
import { ClienteService } from 'app/clientes/cliente.service';
import { NotaService } from './notas/nota.service';
import { NotaMsg } from './core/models/NotaMsg';
import { PreVendaService } from 'app/vendas/preVenda/preVenda.service';
import * as moment from 'moment';
import { LiberacaoUsuario } from './core/models/LiberacaoUsuario';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styles: [`
        .row-nota-msg {
            background-color: rgba(252, 76, 243) !important;
            color: #000000 !important;
        }
    `
    ]
})
export class AppTopBarComponent {

  @Output() notaOutput: EventEmitter<Nota>;

    totalRegistros = 0;
    liberacoes = new Array<Liberacao>();
    liberacoesUsuarios = new Array<LiberacaoUsuario>();
    liberado = new Liberacao();
    cancelado = new Liberacao();
    selectLiberacao = new Liberacao();
    notasSelecionadas = new Array<Nota>();
    selectNota = new Nota();
    selectNotaMsg = new NotaMsg();
    vendedores = new Array<Vendedor>();
    filtro = new LiberacaoFiltro();
    filtroVendedor = new VendedorFiltro();
    produtosCautelas = new Array<ProdutoCautela>();
    produtoCautela = new ProdutoCautela();
    vendedor = new Vendedor();
    notasMsg = new Array<NotaMsg>();
    notaMsg: NotaMsg;
    filtroNotaMsg: NotaMsg;
    notas = new Array<Nota>();
    nota = new Nota();
    totalNotas: number;
    modalCautelasAbertasVisible: boolean;
    modalNotaAbertoVisible: boolean;
    modalControleLiberacaoVisible: boolean;
    loading: boolean;
    exbindoFormularioMensagemNota = false;
    impressaoCautelaCancelamento = false;
    minDateValue: Date;
    cliente = new Cliente();
    clienteNome: string;
    modalClienteVisible: boolean;
    notasMsgPendente = new Array<NotaMsg>();
    mensagemCancelaPendente: string;
    exibindoGradePendendeCancela = false;
    val1: string;

    constructor(
        public app: AppComponent,
        public auth: AuthService,
        private liberacaoService: LiberacaoService,
        private vendedorService: VendedorService,
        private preVendaService: PreVendaService,
        private logoutService: LogoutService,
        private clienteService: ClienteService,
        private confirmation: ConfirmationService,
        private toasty: ToastyService,
        private notaService: NotaService,
        private errorHandler: ErrorHandlerService,
        private router: Router
    ) {
      this.selectNota = new Nota();
      this.notaOutput = new EventEmitter<Nota>();
      this.impressaoCautelaCancelamento = false;
      this.setDatas();
      if (this.auth.jwtPayload !== undefined) {
        this.filtro.empresa = this.auth.jwtPayload.empresa;
        this.filtrarBloqueiosPorUsuario();
      }
     // let timerId = setInterval(() => this.verificarPendeciasLiberadas(), 5000);
    }

    logout() {
        this.logoutService.logout()
          .then(() => {
            this.router.navigate(['/login']);
          })
          .catch(erro => this.errorHandler.handle(erro));
    }

    verificarPendeciasLiberadas() {
        this.liberacoes = new Array<Liberacao>();
        this.filtro.solicitante = this.auth.jwtPayload.login.toUpperCase();
        this.filtro.data = new Date(Date.parse(Date()));
        this.filtro.status = 'aprovadas';
        this.liberacaoService
          .buscarLiberacoesPorData(this.filtro)
          .then(resultado => {
            resultado.forEach(libera => {
              this.liberacoes.push(libera);
            });
          });
      }

      pesquisarCautelasAbertas(modalCautelasAbertas: Dialog) {
        modalCautelasAbertas.style = { 'max-width': '90vw', 'max-height': '100vh', 'margin-top': '-200', 'margin-left': '0' };
        modalCautelasAbertas.modal = true;
        this.modalCautelasAbertasVisible = true;
      }

      cautelasAbertasFeedback(produtoCautela: ProdutoCautela) {
        if (produtoCautela) {
          this.produtoCautela = produtoCautela;
        }
      }

      buscarCautelasProdutosAbertas() {
        this.loading = true;
        this.produtosCautelas.length = 0;
        this.preVendaService
          .buscarCautelasPorEmpresaRetirante(this.auth.jwtPayload.empresa , this.auth.jwtPayload.nome.toUpperCase())
          .then(resultado => {
            resultado.forEach(pc => {
              this.produtoCautela = pc;
              this.produtosCautelas.push(this.produtoCautela);
            });
            this.loading = false;
          });
      }

      pesquisarCliente(modalCliente: Dialog) {
        modalCliente.style = {'max-width': '70vw', 'max-height': '70vh'};
        modalCliente.modal = true;
        this.modalClienteVisible = true;
      }

      clienteFeedback(cliente: Cliente) {
        if (cliente) {

          this.nota.cliente = cliente
          this.clienteNome = cliente.nome;

        } else {
          this.clienteNome = null;
        }
        this.modalClienteVisible = false;
      }

      pesquisarPorCodigo() {
        this.cliente.codigo = this.filtro.cliente;

        if (this.cliente.codigo !== undefined && this.cliente.codigo !== '') {
          this.clienteService.buscarPorCodigo(this.cliente.codigo)
            .then(cliente => {
              this.cliente = cliente;
              this.clienteNome = cliente.nome;
            })
            .catch(msgErr =>
              this.toasty.error(msgErr.error.message ? msgErr.error.message : msgErr.error[0].mensagemDesenvolvedor)
            );
        }
      }

      pesquisarNotaAberto(modalNotaAberto: Dialog) {
        modalNotaAberto.style = { 'max-width': '90vw', 'max-height': '100vh', 'margin-top': '-200', 'margin-left': '0' };
        modalNotaAberto.modal = true;
        this.modalNotaAbertoVisible = true;
      }

      buscarNotasPorVendedor() {
        this.selectNota = new Nota();
        this.filtroVendedor.nome = this.auth.jwtPayload.nome;
        this.vendedorService.buscarPorNomeUsuarioVendedor(this.filtroVendedor.nome)
        .then(resultado => {
           if (resultado !== null){
           this.vendedor = resultado;
           this.filtroVendedor.nome = this.vendedor.nome;
           this.buscarNotasAbertas();
          }
       })
        .catch(erro => this.errorHandler.handle(erro));
      }

      buscarNotasAbertas() {
        this.loading = true;
        this.notas.length = 0;
        this.totalNotas = 0;
        this.notaService.buscarNotasEmAbertoPorVendedor(this.auth.jwtPayload.empresa, this.vendedor.codigo)
        .then(resultado => {
          this.notas = resultado;
          for (const nota of this.notas) {
            this.totalNotas = parseFloat((this.totalNotas + nota.valor).toFixed(2));
            this.notaService.buscarNotasMensagem(nota.empresa, nota.documento)
              .then(MSGS => {
                nota.notasMsg = MSGS;
                this.notas.find(item => item.id === nota.id).notasMsg = nota.notasMsg;
            })
          }
          this.verificarTempoNotasPendentesCancelamento();
          this.loading = false;
        }).catch(erro => this.errorHandler.handle(erro));
      }

      verificarTempoNotasPendentesCancelamento() {
        this.mensagemCancelaPendente = 'Tempo para notas pendentes de cancelamento esgotado, providencie o cancelamento!';
        this.notaService.buscarTmpMaximoNotasPendentesCancelamento(this.auth.jwtPayload.empresa, this.auth.jwtPayload.login)
           .then(resultado => {
              this.notasMsgPendente = resultado;
              if (this.notasMsgPendente !== null && this.notasMsgPendente.length > 0) {
                this.modalNotaAbertoVisible = false;
                this.exibindoGradePendendeCancela = true;
              }
           })
           .catch(erro => this.errorHandler.handle(erro));
      }

      verificarQtdeNotasPendentesCancelamento() {
        this.mensagemCancelaPendente = 'Quantidade máxima de notas pendentes de cancelamento esgotada, providencie o cancelamento!';
        this.notaService.buscarQtdeNotasPendentesCancelamento(this.auth.jwtPayload.empresa, this.auth.jwtPayload.login)
           .then(resultado => {
              this.notasMsgPendente = resultado;
              if (this.notasMsgPendente !== null && this.notasMsgPendente.length >= 0) {
                this.modalNotaAbertoVisible = false;
                this.exibindoGradePendendeCancela = true;
              } else {
                this.impressaoCautelaCancelamento = true;
                this.prepararNovaMensagemNota();
              }
           })
           .catch(erro => this.errorHandler.handle(erro));
      }

      buscarMensagemNotas(empresa: string, documento: string) {
        this.notaService.buscarNotasMensagem(empresa, documento)
        .then(resultado => {
          this.notasMsg = resultado;
          this.notas.find(item => item.id === this.selectNota.id).notasMsg = this.notasMsg;
        })
      }

      prepararNovaMensagemNota() {
        if (this.selectNota.documento) {
          this.exbindoFormularioMensagemNota = true;
          this.filtroNotaMsg = new NotaMsg();
          this.filtroNotaMsg.empresa = this.selectNota.empresa;
          this.filtroNotaMsg.documento = this.selectNota.documento;
          this.filtroNotaMsg.serie = this.selectNota.serie;
          this.filtroNotaMsg.usuario = this.auth.jwtPayload.nome;
          this.filtroNotaMsg.usuarioLogin = this.auth.jwtPayload.login;
        } else {
          this.toasty.warning('Por Favor selecione uma nota!')
        }
      }

      confirmarMensagemNota(frm: FormControl) {

        if (this.filtroNotaMsg.mensagem === undefined || this.filtroNotaMsg.mensagem === null || this.filtroNotaMsg.mensagem.length < 15) {
          this.toasty.warning('Mensagem não pode ser nula ou conter menos que 15 letras!');
          return;
        }

        if (this.impressaoCautelaCancelamento) {
          this.confimarImpressaoCautelaCancelamentoNota();
          return;
        }

        this.adicionarMensagemNota();
      }

      adicionarMensagemNota() {
        this.notaService.adicionarMensagem(this.filtroNotaMsg)
        .then(notaMsg => {
          this.buscarMensagemNotas(notaMsg.empresa, notaMsg.documento);
          this.toasty.success('Mensagem adicionada com sucesso!');
          if (this.impressaoCautelaCancelamento) {
            this.imprimirCautelaCancelamentoNotaEmAberto(notaMsg);
          }
      })
      .catch(erro => this.errorHandler.handle(erro));

        this.exbindoFormularioMensagemNota = false;
      }

      confimarImpressaoCautelaCancelamentoNota() {
        this.confirmation.confirm(
          {
            message: 'Confirma a emissão da cautela de solicitação de cancelamento da NE: ' + this.selectNota.documento + ' com data de: ' + moment(this.selectNota.emissao).format('DD/MM/YYYY HH:mm') + ' ?',
            accept: () => {
              this.impressaoCautelaCancelamento = true;
              const msg = 'MotivoCan:' + this.filtroNotaMsg.mensagem;
              this.filtroNotaMsg.mensagem = msg;
              this.adicionarMensagemNota();
            },
            reject: () => {
              this.impressaoCautelaCancelamento = false;
              this.adicionarMensagemNota();
            }
          }
        );
      }

      imprimirCautelaEmAberto(produtoCautela: ProdutoCautela) {
          this.preVendaService.reimpressaoCautela(produtoCautela)
            .then(preVendaPDF => {
              const url = window.URL.createObjectURL(preVendaPDF);
              window.open(url);
            })
            .catch(erro => this.errorHandler.handle(erro));
      }

      imprimirCautelaCancelamentoNotaEmAberto(notaMsg: NotaMsg) {
        this.impressaoCautelaCancelamento = false;
        this.notaService.impressaoCautelaCancelamentoNota(notaMsg)
          .then(cautelaCancNotaPDF => {
            const url = window.URL.createObjectURL(cautelaCancNotaPDF);
            window.open(url);
            this.selectNota = new Nota();
          })
          .catch(erro => this.errorHandler.handle(erro));
    }

    cancelarNota(nota: Nota) {
      if (this.selectNota.documento){
        this.verificarQtdeNotasPendentesCancelamento();
      } else {
        this.toasty.warning('Por Favor selecione uma nota!')
      }
    }

    onRowSelectNota() {
      this.buscarMensagemNotas(this.selectNota.empresa, this.selectNota.documento);
   }

   pesquisarControleLiberacao(modalControleLiberacao: Dialog) {
    this.val1 = 'Option 1';
    this.selecione('Pendentes');
    modalControleLiberacao.style = { 'max-width': '90vw', 'max-height': '100vh'};
    modalControleLiberacao.modal = true;
    this.modalControleLiberacaoVisible = true;
  }

  selecione(evento: string) {
    this.filtro.status = evento;
    this.liberacaoService.buscarLiberacoesPorEvento(this.filtro)
      .then(resultado => {
        this.liberacoes = resultado;
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  onRowSelectPedidoLiberacao() {
   // console.log(this.selectLiberacao);
  }


  pesquisarLiberacao(pagina = 0) {
    this.filtro.pagina = pagina;
    this.liberacaoService.pesquisar(this.filtro)
      .then(resultado => {
        this.totalRegistros = resultado.total;
        this.liberacoes = resultado.liberacoes;
      })
      .catch(erro => this.errorHandler.handle(erro));
  }

  filtrarBloqueiosPorUsuario() {
    this.liberacaoService.buscarLiberacaoUsuariosPorId(this.auth.jwtPayload.codbloqueio)
     .then(bloqueios => {
        this.liberacoesUsuarios = bloqueios;

    })
    .catch(erro => this.errorHandler.handle(erro));
  }

  confirmarLiberacao() {
    this.selectLiberacao.liberador = this.auth.jwtPayload.login;
    this.liberacaoService.atualizar(this.selectLiberacao)
    .then(libera => {
      this.liberado = libera; 
      const index = this.liberacoes.indexOf(this.liberado);
      this.liberacoes.splice(index, 1)
      this.val1 = 'Option 1';
      this.selecione('Pendentes');
    })
    .catch(erro => this.errorHandler.handle(erro));
  }

  cancelarLiberacao() {
    this.selectLiberacao.usuarioCancelamento = this.auth.jwtPayload.login;
    this.liberacaoService.atualizar(this.selectLiberacao)
    .then(cancela => {
    this.cancelado = cancela;  
    const index = this.liberacoes.indexOf(this.cancelado);
    this.liberacoes.splice(index, 1);
    this.val1 = 'Option 1';
    this.selecione('Pendentes');
    }) 
    .catch(erro => this.errorHandler.handle(erro));
  }

  aoMudarPagina(event: LazyLoadEvent) {
    const pagina = event.first / event.rows;
    if (this.totalRegistros > 0) {
      this.pesquisarLiberacao(pagina);
    }
  }

  setDatas() {
    this.filtro.dataDe = new Date();
    this.filtro.dataAte = new Date();
    this.filtro.dataAte.setDate(this.filtro.dataDe.getDate() + 1);
    this.minDateValue = new Date(new Date().getTime() - 120 * 60 * 60 * 1000);
  }


}
