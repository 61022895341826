import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';

import 'rxjs/add/operator/toPromise';
import {Observable} from 'rxjs';

import {environment} from './../../environments/environment';
import {Cr1Carta} from './../core/models/Cr1Carta';
import {MoneyHttp} from 'app/seguranca/money-http';
import {Nota} from '../core/models/Nota';
import {ControleNota} from '../notas/nota.service';
import {ControleCartoes} from '../cartao/cartao.service';
import {Cr2Carta} from '../core/models/Cr2Carta';

export class Cr1CartaFiltro {
  cartao: string;
  codigo: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class Cr1CartaService {

  cr1CartasUrl: string;
  cr2CartasUrl: string;

  constructor(private http: MoneyHttp) {
    this.cr1CartasUrl = `${environment.apiUrl}/cr1Cartas`;
    this.cr2CartasUrl = `${environment.apiUrl}/cr2Cartas`;
  }

  pesquisar(filtro: Cr1CartaFiltro): Promise<any> {

    let cr1CartasUrlPesquisar = `${this.cr1CartasUrl}/filter/cartao`;

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    if (filtro.cartao) {
      params = params.append('cartao', filtro.cartao);
    } else if (filtro.codigo) {
      params = params.append('codigo', filtro.codigo);
      cr1CartasUrlPesquisar = `${this.cr1CartasUrl}/filter/codigo`;
    }

    return this.http
      .get<any>(cr1CartasUrlPesquisar, {params})
      .toPromise()
      .then(response => {
        const cr1Cartas = response.content;

        const resultado = {
          cr1Cartas,
          total: response.totalElements
        };

        return resultado;
      });
  }

  listarTodas(): Promise<any> {
    return this.http
      .get<any>(`${this.cr1CartasUrl}/lista`)
      .toPromise()
      .then(response => response.content);
  }

  adicionar(cr1Cartas: Array<Cr1Carta>): Promise<Cr1Carta> {
    return this.http.post<Cr1Carta>(this.cr1CartasUrl, cr1Cartas).toPromise();
  }

  buscarPorId(id: number): Promise<Cr1Carta> {
    return this.http.get<Cr1Carta>(`${this.cr1CartasUrl}/${id}`).toPromise();
  }


  pesquisarControleNota(conroleCartoes: ControleCartoes): Promise<Array<Cr1Carta>> {
    return this.http.put<Array<Cr1Carta>>(`${this.cr1CartasUrl}/consultarControleCartoes`, conroleCartoes).toPromise();
  }


  listarParcelasBoletoAutorizacao(boleto: string, autorizacao: string): Promise<Array<Cr2Carta>> {

    let params = new HttpParams({
      fromObject: {
        boleto: boleto,
        autorizacao: autorizacao
      }
    });

    return this.http
      .get<Array<Cr2Carta>>(`${this.cr2CartasUrl}/listarParcelasBoletoAutorizacao/boleto/autorizacao`, {params})
      .toPromise()
  }

  atualizar(cr1Carta: Cr1Carta): Promise<Cr1Carta> {
    return this.http
      .put<Cr1Carta>(`${this.cr1CartasUrl}/${cr1Carta.id}`, cr1Carta)
      .toPromise();
  }

  cancelarBoleto(cr1Carta: Cr1Carta): Promise<Cr1Carta> {
    return this.http
      .put<Cr1Carta>(`${this.cr1CartasUrl}/cancelarBoleto`, cr1Carta)
      .toPromise();
  }


}
