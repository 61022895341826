import {Injectable} from '@angular/core';
import {MoneyHttp} from '../seguranca/money-http';
import {environment} from '../../environments/environment';
import {SugestaoCotacao} from '../core/models/SugestaoCotacao';
import {HttpParams} from '@angular/common/http';
import * as moment from 'moment';
import {AuthService} from '../seguranca/auth.service';
import {ToastyService} from 'ng2-toasty';
import {SugestaoCotacaoItem} from '../core/models/SugestaoCotacaoItem';
import {Fornecedor} from '../core/models/Fornecedor';

export class SugestaoCotacaoFiltro {
  dataDe: Date;
  dataAte: Date;
  pagina = 0;
  totalRegistros: number;
  itensPorPagina = 10;
}

export class SugestaoCotacaoFornecedorItem{
  sugestaoCotacao: SugestaoCotacao;
  sugestaoCotacaoItem: SugestaoCotacaoItem;
  fornecedor: Fornecedor;
  todosItens: boolean = false;
}

@Injectable()
export class SugestaoCotacaoService {

  private url: string;

  constructor(
    private http: MoneyHttp,
    private auth: AuthService,
    private toasty: ToastyService
  ) {
    this.url = `${environment.apiUrl}/sugestaoCotacao`;
  }

  processarSugestaoCotacao(sugestaoCotacao: SugestaoCotacao): Promise<SugestaoCotacao> {
    return this.http.put<SugestaoCotacao>(`${this.url}/gerarSugestaoCotacao`, sugestaoCotacao).toPromise();
  }

  pesquisar(filtro: SugestaoCotacaoFiltro): Promise<any> {

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    params = params.append('empresa', this.auth.jwtPayload.empresa);

    if (filtro.dataDe) {
      params = params.append(
        'dataDe',
        moment(filtro.dataDe).format('YYYY-MM-DD 00:00')
      );
    }

    if (filtro.dataAte) {
      params = params.append(
        'dataAte',
        moment(filtro.dataAte).format('YYYY-MM-DD 23:59')
      );
    }

    return this.http
      .get<any>(`${this.url}/periodo/empresa/dataDe/dataAte`,
        {params})
      .toPromise()
      .then(response => {
        const resultado = {
          pedidos: response,
          total: response.totalElements
        };
        return resultado;
      });

  }

  atualizar(sugestaoCotacao:SugestaoCotacao): Promise<SugestaoCotacao> {
    return this.http.put<SugestaoCotacao>(`${this.url}/${sugestaoCotacao.id}`, sugestaoCotacao).toPromise();
  }

  buscarPorId(id: number): Promise<SugestaoCotacao> {
    return this.http
      .get<SugestaoCotacao>(`${this.url}/${id}`)
      .toPromise()
      .then(response => {
        return response;
      });
  }

  processarFornecedoresCotacao(sugestaoCotacaoFornecedorItem: SugestaoCotacaoFornecedorItem): Promise<SugestaoCotacao> {
    return this.http.put<SugestaoCotacao>(`${this.url}/criarFornecedoresCotacao`, sugestaoCotacaoFornecedorItem).toPromise();
  }

  excluirFornecedorSugestaoCotacao(sugestaoCotacaoFornecedorItem: SugestaoCotacaoFornecedorItem){
    return this.http.put<SugestaoCotacao>(`${this.url}/excluirFornecedorSugestaoCotacao`, sugestaoCotacaoFornecedorItem).toPromise();
  }

  lancarListaPrecoProdutoFornecedor(sugestaoCotacao:SugestaoCotacao): Promise<SugestaoCotacao> {
    return this.http.put<SugestaoCotacao>(`${this.url}/lancarListaPrecoProdutoFornecedor`,
      sugestaoCotacao).toPromise();
  }

}
