import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import 'rxjs/add/operator/toPromise';
import { Observable } from 'rxjs';

import { environment } from './../../environments/environment';
import { MoneyHttp } from 'app/seguranca/money-http';

import { FormaPagamento } from 'app/core/models/FormaPagamento';
import { FormaPagamentoCadastro } from 'app/core/models/FormaPagamentoCadastro';
import { Form } from '@angular/forms';
import {SelectItem} from 'primeng/api';

export class FormaPagamentoFiltro {
  descricao: string;
  codigo: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class FormaPagamentoService {

  formasPagamentosUrl: string;

  constructor(private http: MoneyHttp) {
    this.formasPagamentosUrl = `${environment.apiUrl}/formasPagamentos`;
  }

  pesquisar(filtro: FormaPagamentoFiltro): Promise<any> {

    let formasPagamentosUrlPesquisar = `${this.formasPagamentosUrl}/filter/descricao`;

    let params = new HttpParams({
      fromObject: {
        page:  filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    if (filtro.descricao) {
      params = params.append('descricao', filtro.descricao);
    } else if (filtro.codigo) {
      params = params.append('codigo', filtro.codigo);
      formasPagamentosUrlPesquisar = `${this.formasPagamentosUrl}/filter/codigo`;
    }

    return this.http
      .get<any>(formasPagamentosUrlPesquisar, { params })
      .toPromise()
      .then(response => {
        const formasPagamentos = response.content;

        const resultado = {
          formasPagamentos,
          total: response.totalElements
        };

        return resultado;
      });
  }

  listarTodas(): Promise<any> {
    return this.http
      .get<any>(`${this.formasPagamentosUrl}/lista`)
      .toPromise()
      .then(response => response);
  }

  adicionar(formaPagamentoCadastro: FormaPagamentoCadastro): Promise<FormaPagamento> {
    return this.http.post<FormaPagamento>(this.formasPagamentosUrl, formaPagamentoCadastro).toPromise();
  }

  atualizar(formaPagamento: FormaPagamento): Promise<FormaPagamento> {
    return this.http
      .put<FormaPagamento>(`${this.formasPagamentosUrl}/${formaPagamento.id}`, FormaPagamento)
      .toPromise();
  }

  buscarPorId(id: number): Promise<FormaPagamento> {
    return this.http.get<FormaPagamento>(`${this.formasPagamentosUrl}/${id}`).toPromise();
  }

  buscarPeloCodigo(codigo: string): Observable<FormaPagamento> {
    return this.http.get<FormaPagamento>(`${this.formasPagamentosUrl}/buscarPeloCodigo/${codigo}`);
  }

  buscarPorCodigo(codigo: string): Promise<FormaPagamento> {
    return this.http.get<FormaPagamento>(`${this.formasPagamentosUrl}/descricaoPagamento/${codigo}`).toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.formasPagamentosUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }

  getFormasPagamentos(): Promise<SelectItem[]> {
    return new Promise((resolve, reject) => {
      this.listarTodas().then(ss => {
          resolve(ss.map(formaPagamento => {
              return {
                label: `${formaPagamento.codigo} - ${formaPagamento.descricao}`,
                value: formaPagamento.codigo
              }
            })
          );
        }
      ).catch(error => reject(error));
    });
  }



}
