import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as moment from 'moment';

import "rxjs/add/operator/toPromise";

import { environment } from "./../../environments/environment";
import { MoneyHttp } from "app/seguranca/money-http";

import {  ContaPagar } from "app/core/models/ContaPagar";
import { ContaPagarCusto } from "app/core/models/ContaPagarCusto";
import { ContaPagarDTO } from "app/core/models/dtos/ContaPagarDTO";

export class ContaPagarFiltro {
  codigoCaixa: string;
  empresa: string;
  caixa: string;
  emissaoDe: Date;
  emissaoAte: Date;
  dataFinal: Date;
  serie: string;
  codigoFornecedor: string;
  codigoGrupo: string;
  codigoPlano: string;
  pesquisa: string;
  periodo: string;
  diversos: string;
  opcoes: string;
  validadas: string;
  baixadaCaixa: string;
  documento: string;
  tipoCaixa: string;
  fornecedor: string;
  pagina = 0;
  itensPorPagina = 7;
}

@Injectable()
export class ContaPagarService {
 
  contaPagasUrl: string;
  contaPagarCustoUrl: string;

  constructor(private http: MoneyHttp) {
    this.contaPagasUrl = `${environment.apiUrl}/contaPagas`;
    this.contaPagarCustoUrl = `${environment.apiUrl}/contasPagarCustos`;
  }

  pesquisar(filtro: ContaPagarFiltro): Promise<any> {

    const params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString(),
        empresa: filtro.empresa,
        emissaoDe: moment(filtro.emissaoDe).format('YYYY-MM-DD'),
        emissaoAte: moment(filtro.emissaoAte).format('YYYY-MM-DD'),
        pesquisa: filtro.pesquisa,
        codigoCaixa: filtro.codigoCaixa,
        serie: filtro.serie,
        diversos: filtro.diversos,
        opcoes: filtro.opcoes,
        validadas: filtro.validadas,
        baixadaCaixa: filtro.baixadaCaixa,
        documento: filtro.documento,
        codigoFornecedor: filtro.codigoFornecedor,
        codigoGrupo: filtro.codigoGrupo,
        codigoPlano: filtro.codigoPlano  
 
      }
    });

    return this.http
    .get<any>(`${this.contaPagasUrl}?periodo`, { params })
    .toPromise()
    .then(response => {
      const contaPagas = response.content;

      this.converterStringsParaDatas([contaPagas]);

      const resultado = {
        contaPagas,
        total: response.totalElements
      };

      return resultado;
    });

  }

  private converterStringsParaDatas(contas: ContaPagar[]) {
    for (const conta of contas) {
      conta.emissao = moment(
        conta.emissao,
        'YYYY-MM-DD'
      ).toDate();

      if (conta.pagamento === null)  {
        console.log(conta.pagamento);
          conta.pagamento = new Date();
      }

      conta.vencimento = moment(
          conta.vencimento,
           'YYYY-MM-DD'
          ).toDate();
    }
  }  

  listarTodas(): Promise<any> {
    return this.http
      .get<any>(`${this.contaPagasUrl}/lista`)
      .toPromise()
      .then(response => response.content);
  }

  adicionar(contaPagar: ContaPagar): Promise<ContaPagar> {
    return this.http.post<ContaPagar>(this.contaPagasUrl, contaPagar).toPromise();
  }

  adicionarContasCustos(contaPagarDTO: ContaPagarDTO): Promise<ContaPagarDTO> {
    return this.http.post<ContaPagarDTO>(`${this.contaPagasUrl}/adicionarContaCustos`, contaPagarDTO).toPromise();
  }

  atualizar(contaPagar: ContaPagar): Promise<ContaPagar> {
    return this.http
      .put<ContaPagar>(`${this.contaPagasUrl}/${contaPagar.id}`, contaPagar)
      .toPromise();
  }

  atualizarContaPaga(contaPagar: ContaPagar): Promise<ContaPagar> {
    return this.http
      .put<ContaPagar>(`${this.contaPagasUrl}/efetuaPagamento/${contaPagar.id}`, contaPagar)
      .toPromise();
  }

  buscarPorId(id: number): Promise<ContaPagar> {
    return this.http.get<ContaPagar>(`${this.contaPagasUrl}/${id}`).toPromise();
  }

  buscarPorIdContaCusto(id: number): Promise<ContaPagarDTO> {
    return this.http.get<ContaPagarDTO>(`${this.contaPagasUrl}/buscarContaCustos/${id}`).toPromise();
  }


  impressaoContaPagar(filtro: ContaPagarFiltro): Promise<Blob> {
    this.contaPagasUrl = `${environment.apiUrl}/contaPagas`;

    const params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString(),
        empresa: filtro.empresa,
        codigoCaixa: filtro.codigoCaixa,
        emissaoDe: moment(filtro.emissaoDe).format('YYYY-MM-DD'),
        emissaoAte: moment(filtro.emissaoAte).format('YYYY-MM-DD'),
  
 
      }
    });

    return this.http.get<Blob>(`${this.contaPagasUrl}?impressaoContaPagar`, {params , responseType: 'blob' as 'json'}).toPromise();
  }


  adicionarContaPagarCusto(contaPagarCusto: ContaPagarCusto): Promise<ContaPagarCusto> {
    return this.http.post<ContaPagarCusto>(this.contaPagarCustoUrl, contaPagarCusto).toPromise();
  }

  calcularTotaisContas(filtro: ContaPagarFiltro): Promise<Number> {

    const params = new HttpParams({
      fromObject: {
        empresa: filtro.empresa,
        emissaoDe: moment(filtro.emissaoDe).format('YYYY-MM-DD'),
        emissaoAte: moment(filtro.emissaoAte).format('YYYY-MM-DD'),
        pesquisa: filtro.pesquisa,
        codigoCaixa: filtro.codigoCaixa,
        serie: filtro.serie,
        diversos: filtro.diversos,
        opcoes: filtro.opcoes,
        validadas: filtro.validadas,
        baixadaCaixa: filtro.baixadaCaixa,
        documento: filtro.documento,
        codigoFornecedor: filtro.codigoFornecedor,
        codigoGrupo: filtro.codigoGrupo,
        codigoPlano: filtro.codigoPlano  
 
      }
    });

    return this.http
    .get<Number>(`${this.contaPagasUrl}?calcularTotal`, { params, responseType: 'number' as 'json' })
    .toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.contaPagasUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }

  excluirCusto(id: number): Promise<void> {
    return this.http
      .delete(`${this.contaPagarCustoUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }

}
