import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import "rxjs/add/operator/toPromise";

import { environment } from "../../environments/environment";
import { DescontoProgressivo } from "../core/models/DescontoProgressivo";
import { MoneyHttp } from "app/seguranca/money-http";

export class DescontoProgressivoFiltro {
  codigoValor: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class DescontoProgressivoService {

  descontosProgressivosUrl: string;

  constructor(private http: MoneyHttp) {
    this.descontosProgressivosUrl = `${environment.apiUrl}/descontosProgressivos`;
  }

  pesquisar(filtro: DescontoProgressivoFiltro): Promise<any> {

    let descontoProgressivoUrlPesquisar: string = `${this.descontosProgressivosUrl}/codigoValor`;

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    if (filtro.codigoValor) {

      params = params.append("codigoValor", filtro.codigoValor);
      
      
    }
    return this.http
    .get<any>(descontoProgressivoUrlPesquisar, { params })
    .toPromise()
    .then(response => {
      const descontosProgressivos = response.content;

      const resultado = {
        descontosProgressivos,
        total: response.totalElements
      };

      return resultado;
    });
}

  listarTodas(): Promise<any> {
    return this.http
      .get<any>(`${this.descontosProgressivosUrl}/lista`)
      .toPromise()
      .then(response => response.content);
  }

  adicionar(DescontoProgressivo: DescontoProgressivo): Promise<DescontoProgressivo> {
    return this.http.post<DescontoProgressivo>(this.descontosProgressivosUrl, DescontoProgressivo).toPromise();
  }

  atualizar(DescontoProgressivo: DescontoProgressivo): Promise<DescontoProgressivo> {
    return this.http
      .put<DescontoProgressivo>(`${this.descontosProgressivosUrl}/${DescontoProgressivo.id}`, DescontoProgressivo)
      .toPromise();
  }

  buscarPorId(id: number): Promise<DescontoProgressivo> {
    return this.http.get<DescontoProgressivo>(`${this.descontosProgressivosUrl}/${id}`).toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.descontosProgressivosUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }

}
