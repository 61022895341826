import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MoneyHttp } from "app/seguranca/money-http";
import { environment } from './../../environments/environment';
import { SubGrupo } from './../core/models/SubGrupo';

export class SubGrupoFiltro {
  codigo: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class SubGrupoService {

  subgrupoUrl: string;

  constructor(
    private http: MoneyHttp
  ) { 
    this.subgrupoUrl = `${environment.apiUrl}/subGrupos`;
  }

  adicionar(subgrupo: SubGrupo): Promise<SubGrupo> {
    return this.http.post<SubGrupo>(this.subgrupoUrl, subgrupo).toPromise();
  }

  atualizar(subgrupo: SubGrupo): Promise<SubGrupo> {
    return this.http
      .put<SubGrupo>(`${this.subgrupoUrl}/${subgrupo.id}`, subgrupo)
      .toPromise();
  }

  buscarPorId(id: number): Promise<SubGrupo> {
    return this.http.get<SubGrupo>(`${this.subgrupoUrl}/${id}`).toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.subgrupoUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }

  listarTodas(): Promise<SubGrupo[]> {
    return this.http
      .get<SubGrupo[]>(`${this.subgrupoUrl}/lista`)
      .toPromise();
  }

  pesquisar(filtro: SubGrupoFiltro): Promise<any> {

    let subgrupoUrlPesquisar: string = `${this.subgrupoUrl}/filter/codigo`;

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });
    
    if (filtro.codigo) {
      params = params.append("codigo", filtro.codigo);
    }

    return this.http
      .get<any>(subgrupoUrlPesquisar, { params })
      .toPromise()
      .then(response => {
        const subGrupos = response.content;

        const resultado = {
          subGrupos,
          total: response.totalElements
        };

        return resultado;
      });
  }

}
