import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MoneyHttp } from "app/seguranca/money-http";
import { environment } from './../../environments/environment';
import { Localizacao } from 'app/core/models/Localizacao';

export class LocalizacaoFiltro {
  descricao: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class LocalizacaoService {

  localizacaoUrl: string;

  constructor(
    private http: MoneyHttp
  ) {
    this.localizacaoUrl = `${environment.apiUrl}/localizacoes`;
  }

  adicionar(localizacao: Localizacao): Promise<Localizacao> {
    return this.http.post<Localizacao>(this.localizacaoUrl, localizacao).toPromise();
  }

  atualizar(localizacao: Localizacao): Promise<Localizacao> {
    return this.http
      .put<Localizacao>(`${this.localizacaoUrl}/${localizacao.id}`, localizacao)
      .toPromise();
  }

  buscarPorId(id: number): Promise<Localizacao> {
    return this.http.get<Localizacao>(`${this.localizacaoUrl}/${id}`).toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.localizacaoUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }

  listarTodas(): Promise<Localizacao[]> {
    return this.http
      .get<Localizacao[]>(`${this.localizacaoUrl}/lista`)
      .toPromise();
  }

  pesquisar(filtro: LocalizacaoFiltro): Promise<any> {

    let localizacaoUrlPesquisar: string = `${this.localizacaoUrl}/filter/descricao`;

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    if (filtro.descricao) {
      params = params.append("descricao", filtro.descricao);
    }

    return this.http
      .get<any>(localizacaoUrlPesquisar, { params })
      .toPromise()
      .then(response => {
        const localizacoes = response.content;

        const resultado = {
          localizacoes,
          total: response.totalElements
        };

        return resultado;
      });
  }

}
