import {Injectable} from '@angular/core';
import {MoneyHttp} from '../seguranca/money-http';
import {environment} from '../../environments/environment';
import {AuthService} from '../seguranca/auth.service';
import {ToastyService} from 'ng2-toasty';
import {SugestaoCotacaoItemFornecedor} from '../core/models/SugestaoCotacaoItemFornecedor';

@Injectable()
export class SugestaoCotacaoItemFornecedorService{

  private url: string;

  constructor(
    private http: MoneyHttp,
    private auth: AuthService,
    private toasty: ToastyService
  ) {
    this.url = `${environment.apiUrl}/sugestaoCotacaoItemFornecedor`;
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.url}/${id}`)
      .toPromise()
      .then(() => null);
  }

  atualizar(sugestaoCotacaoItemFornecedor:SugestaoCotacaoItemFornecedor): Promise<SugestaoCotacaoItemFornecedor> {
    return this.http.put<SugestaoCotacaoItemFornecedor>(`${this.url}/${sugestaoCotacaoItemFornecedor.id}`,
      sugestaoCotacaoItemFornecedor).toPromise();
  }


}
