import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import "rxjs/add/operator/toPromise";

import { environment } from "./../../environments/environment";
import { MoneyHttp } from "app/seguranca/money-http";

import { BancoRecebimento } from "app/core/models/BancoRecebimento";
import * as moment from 'moment';
import {PagamentoAntecipado} from '../core/models/PagamentoAntecipado';
import {PagamentoAntecipadoFiltro} from '../pagamento-antecipado/pagamento-antecipado.service';

export class BancoRecebimentoFiltro {
  idLancamento: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class BancoRecebimentoService {
  bancosRecebimentosUrl: string;

  constructor(private http: MoneyHttp) {
    this.bancosRecebimentosUrl = `${environment.apiUrl}/bancosRecebimentos`;
  }

  pesquisar(filtro: BancoRecebimentoFiltro): Promise<any> {
    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });
    if (filtro.idLancamento) {
      params = params.append("idLancamento", filtro.idLancamento);
    }
    return this.http
      .get<any>(`${this.bancosRecebimentosUrl}`, { params })
      .toPromise()
      .then(response => {
        const bancosRecebimentos = response.content;

        const resultado = {
          bancosRecebimentos,
          total: response.totalElements
        };

        return resultado;
      });
  }

  listarTodas(): Promise<Array<BancoRecebimento>> {
    return this.http
      .get<Array<BancoRecebimento>>(`${this.bancosRecebimentosUrl}/lista`)
      .toPromise()
  }

  adicionar(bancoRecebimento: BancoRecebimento): Promise<BancoRecebimento> {
    return this.http
      .post<BancoRecebimento>(this.bancosRecebimentosUrl, bancoRecebimento)
      .toPromise();
  }

  atualizar(bancoRecebimento: BancoRecebimento): Promise<BancoRecebimento> {
    return this.http
      .put<BancoRecebimento>(
        `${this.bancosRecebimentosUrl}/${bancoRecebimento.id}`,
        bancoRecebimento
      )
      .toPromise();
  }

  buscarPorId(id: number): Promise<BancoRecebimento> {
    return this.http
      .get<BancoRecebimento>(`${this.bancosRecebimentosUrl}/${id}`)
      .toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.bancosRecebimentosUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }

  listaPorPeriodo(de: Date, ate: Date): Promise<Array<BancoRecebimento>> {
    let params = new HttpParams({
      fromObject: {
      }
    });

    if (de) {
      params = params.append(
        'dataDe',
        moment(de).format('YYYY-MM-DD 00:00')
      );
    }

    if (ate) {
      params = params.append(
        'dataAte',
        moment(ate).format('YYYY-MM-DD 23:59')
      );
    }

    return this.http
      .get<Array<BancoRecebimento>>(`${this.bancosRecebimentosUrl}/listaPorPeriodo/dataDe/dataAte`,
        {params})
      .toPromise()
  }

  listaPorPorCaixa(caixa: string): Promise<Array<BancoRecebimento>> {
    let params = new HttpParams({
      fromObject: {
      }
    });

    params = params.append("caixa", caixa);

    return this.http
      .get<Array<BancoRecebimento>>(`${this.bancosRecebimentosUrl}/listaPorCaixa/caixa`,
        {params})
      .toPromise()
  }

  pesquisarPeriodo(incluirDataDe: Date, incluirDataAte: Date, cliente: string, comprovante: string ): Promise<Array<BancoRecebimento>> {
    let params = new HttpParams({
      fromObject: {
      }
    });

    if (incluirDataDe) {
      params = params.append('dataDe', moment(incluirDataDe).format('YYYY-MM-DD 00:00'));
    }

    if (incluirDataAte) {
      params = params.append('dataAte', moment(incluirDataAte).format('YYYY-MM-DD 23:59'));
    }

    if (cliente){
      params = params.append('cliente', cliente);
    }

    if (comprovante){
      params = params.append('comprovante', comprovante);
    }

    let url = '';
    if (comprovante) {
      url  = `${environment.apiUrl}/bancosRecebimentos/comprovante/comprovante`;
    }else if (cliente && incluirDataDe && incluirDataAte) {
      url = `${environment.apiUrl}/bancosRecebimentos/clientePeriodo/dataDe/dataAte/cliente`;
    }

    return this.http
      .get<Array<BancoRecebimento>>(`${url}`, {params})
      .toPromise();

  }

}
