import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import 'rxjs/add/operator/toPromise';

import { environment } from './../../environments/environment';
import { MoneyHttp } from 'app/seguranca/money-http';

import { Tecnico } from 'app/core/models/Tecnico';
import * as moment from 'moment';
import {SelectItem} from 'primeng/api';

export class TecnicoFiltro {
  nome: string;
  codigoTecnico: string;
  emissaoDe: Date;
  emissaoAte: Date;
  empresa: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class TecnicoService {
  tecnicosUrl: string;

  constructor(private http: MoneyHttp) {
    this.tecnicosUrl = `${environment.apiUrl}/tecnicos`;
  }

  pesquisar(filtro: TecnicoFiltro): Promise<any> {

    let tecnicosUrlPesquisar = `${this.tecnicosUrl}/filter/nome`;

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    if (filtro.nome) {
      params = params.append('nome', filtro.nome);
    } else if (filtro.codigoTecnico) {
      params = params.append('codigoTecnico', filtro.codigoTecnico);
      tecnicosUrlPesquisar = `${this.tecnicosUrl}/filter/codigoTecnico`;
    }

    return this.http
    .get<any>(tecnicosUrlPesquisar, { params })
    .toPromise()
    .then(response => {
      const tecnicos = response.content;

      const resultado = {
        tecnicos,
        total: response.totalElements
      };

      return resultado;
    });
}

  listarTodas(): Promise<any> {
    return this.http
      .get<any>(`${this.tecnicosUrl}/lista`)
      .toPromise()
      .then(response => response);
  }

  adicionar(tecnico: Tecnico): Promise<Tecnico> {
    return this.http.post<Tecnico>(this.tecnicosUrl, tecnico).toPromise();
  }

  atualizar(tecnico: Tecnico): Promise<Tecnico> {
    return this.http
      .put<Tecnico>(`${this.tecnicosUrl}/${tecnico.id}`, tecnico)
      .toPromise();
  }

  buscarPorId(id: number): Promise<Tecnico> {
    return this.http.get<Tecnico>(`${this.tecnicosUrl}/${id}`).toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.tecnicosUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }

  getTecnicos(tecnico: string) {
    return this.http
      .get<any>(`${this.tecnicosUrl}/tecnicos/${tecnico}`)
      .toPromise()
      .then(response => {
        return response;
      });
  }

  next(cod_tecn: string): Promise<Tecnico> {
    let params = new HttpParams({
      fromObject: {
        codigoTecnico: cod_tecn
      }
    });
    return this.http.get<Tecnico>(`${this.tecnicosUrl}?next`, {params}).toPromise();
  }
  
  previous(cod_tecn: string): Promise<Tecnico> {
    let params = new HttpParams({
      fromObject: {
        codigoTecnico: cod_tecn
      }
    });
    return this.http.get<Tecnico>(`${this.tecnicosUrl}?previous`, {params}).toPromise();
  }

  listarMovimentoTecnicoFechadoPago(filtro: TecnicoFiltro) {
    return this.http
        .get<any[]>(`${this.tecnicosUrl}/filtrar/filtraPorMovimentoFechadoEpagos?emissaoDe=${moment(filtro.emissaoDe).format('YYYY-MM-DD')}&emissaoAte=${moment(filtro.emissaoAte).format('YYYY-MM-DD')}&coEmpresa=${filtro.empresa}&coTecnico=${filtro.codigoTecnico}`)
        .toPromise()
        .then(response => {
           return response;
        });
  }

  imprimirMovimentosTecnicosFechadosEPagos(filtro: TecnicoFiltro, totValores: boolean): Promise<Blob> {
    return this.http
        .get<Blob>(`${this.tecnicosUrl}/relatorio/filtraPorMovimentoFechadoEpagos?emissaoDe=${moment(filtro.emissaoDe).format('YYYY-MM-DD')}&emissaoAte=${moment(filtro.emissaoAte).format('YYYY-MM-DD')}&coEmpresa=${filtro.empresa}&coTecnico=${filtro.codigoTecnico}&totalizar=${totValores}`,{responseType: 'blob' as 'json'}).toPromise()
        .then(response => {
           return response;
        });
  }

  buscarPorCodigo(codigoTecnico: string): Promise<Tecnico> {
    return this.http.get<Tecnico>(`${this.tecnicosUrl}/codigoTecnico/${codigoTecnico}`).toPromise();
  }

  getTecnicosSelect(): Promise<SelectItem[]> {
    return new Promise((resolve, reject) => {
      this.listarTodas().then(ss => {
          resolve(ss.map(tecnico => {
              return {
                label: `${tecnico.codigoTecnico} - ${tecnico.nome}`,
                value: tecnico.codigoTecnico
              }
            })
          );
        }
      ).catch(error => reject(error));
    });
  }
}
