import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MoneyHttp } from "app/seguranca/money-http";
import { environment } from './../../environments/environment';
import { Grupo } from 'app/core/models/Grupo';

export class GrupoFiltro {
  codigo: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class GrupoService {

  grupoUrl: string;

  constructor(
    private http: MoneyHttp
  ) {
    this.grupoUrl = `${environment.apiUrl}/grupos`;
  }

  adicionar(grupo: Grupo): Promise<Grupo> {
    return this.http.post<Grupo>(this.grupoUrl, grupo).toPromise();
  }

  atualizar(grupo: Grupo): Promise<Grupo> {
    return this.http
      .put<Grupo>(`${this.grupoUrl}/${grupo.id}`, grupo)
      .toPromise();
  }

  buscarPorId(id: number): Promise<Grupo> {
    return this.http.get<Grupo>(`${this.grupoUrl}/${id}`).toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.grupoUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }

  listarTodas(): Promise<Grupo[]> {
    return this.http
      .get<Grupo[]>(`${this.grupoUrl}/lista`)
      .toPromise();
  }

  pesquisar(filtro: GrupoFiltro): Promise<any> {

    let grupoUrlPesquisa: string = `${this.grupoUrl}/filter/codigo`;

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });
    
    if (filtro.codigo) {
      params = params.append("codigo", filtro.codigo);
    }

    return this.http
      .get<any>(grupoUrlPesquisa, { params })
      .toPromise()
      .then(response => {
        const grupos = response.content;

        const resultado = {
          grupos,
          total: response.totalElements
        };

        return resultado;
      });
  }



}
