import {Injectable} from '@angular/core';
import {MoneyHttp} from '../seguranca/money-http';
import {environment} from '../../environments/environment';
import {PlacaVeiculo} from '../core/models/PlacaVeiculo';
import {Cliente} from '../core/models/Cliente';
import {AuthService} from '../seguranca/auth.service';


import 'rxjs/add/operator/toPromise';
import {HttpParams, HttpHeaders} from '@angular/common/http';

export class PlacaVeiculoFiltro {
  placa: string;
  veiculo: string;
  marca: string;
  motor: string;
  cor: string;
  cilindros: number;
  ano: string;
  combustivel: number;
  clientes: Array<Cliente>;
  pagina = 0;
  itensPorPagina = 20;
}

@Injectable()
export class PlacaVeiculoService {

  

  private urlPlacaVeiculo: string;

  

  constructor(
    private http: MoneyHttp,
    private auth: AuthService,
  ) {
    this.urlPlacaVeiculo = `${environment.apiUrl}/placaVeiculo`;
  }

  pesquisar(filtro: PlacaVeiculoFiltro): Promise<any> {
    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    })

    if (filtro.placa) {
      params = params.append('placa', filtro.placa);
      
    }

    return this.http
    .get<any>(`${this.urlPlacaVeiculo}`, {params})
    .toPromise()
    .then(response => {
      const placaVeiculo = response.content;

      const resultado = {
        placaVeiculo,
        total: response.totalElements
      };

      return resultado;
    });
}



  getPlacas(placa: string) {
    return this.http
      .get<any>(`${this.urlPlacaVeiculo}/placas/${placa}`)
      .toPromise()
      .then(response => {
        return response;
      });

      
  }

  salvar(placaVeiculo:PlacaVeiculo): Promise<PlacaVeiculo> {
    if (!placaVeiculo.id)
      return this.adicionar(placaVeiculo);
    else
      this.buscarPorId(placaVeiculo.id).then(pv => {
        pv.placa = placaVeiculo.placa;
        pv.veiculo = placaVeiculo.veiculo;
        pv.cilindros = placaVeiculo.cilindros;
        pv.ano = placaVeiculo.ano;
        pv.combustivel = placaVeiculo.combustivel;
        pv.potencia = placaVeiculo.potencia;
        placaVeiculo = pv;
      }).catch();
    return this.atualizar(placaVeiculo);
  }

  adicionar(plavaVeiculo: PlacaVeiculo): Promise<PlacaVeiculo> {
    return this.http.post<PlacaVeiculo>(this.urlPlacaVeiculo, plavaVeiculo).toPromise();
  }

  atualizar(placaVeiculo: PlacaVeiculo): Promise<PlacaVeiculo> {
    return this.http.put<PlacaVeiculo>(`${this.urlPlacaVeiculo}/${placaVeiculo.id}`, placaVeiculo).toPromise();
  }

  buscarPorId(id: number): Promise<PlacaVeiculo> {
    return this.http.get<PlacaVeiculo>(`${this.urlPlacaVeiculo}/${id}`).toPromise();
  }

  getPlaca(placa: string) {
    return this.http
      .get<any>(`${this.urlPlacaVeiculo}/placa/${placa}`)
      .toPromise()
      .then(response => {
        return response;
      });
  }

}
