import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import "rxjs/add/operator/toPromise";

import { environment } from "../../../environments/environment";
import { MoneyHttp } from "app/seguranca/money-http";

import { ProdutoCodigoBarra } from "app/core/models/ProdutoCodigoBarra";

export class ProdutoCodigoBarraFiltro {
  codigo: string;
  codigoBarras: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class ProdutoCodigoBarraService {
  produtoCodigoBarrasUrl: string;

  constructor(private http: MoneyHttp) {
    this.produtoCodigoBarrasUrl = `${environment.apiUrl}/produtosCodigosBarras`;
  }

  pesquisar(filtro: ProdutoCodigoBarraFiltro): Promise<any> {

    let produtoCodigoBarraUrlPesquisar = `${this.produtoCodigoBarrasUrl}/filter/codigo`;

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    if (filtro.codigo) {
      params = params.append('codigo', filtro.codigo);
    } else if (filtro.codigoBarras) {
      params = params.append('codigoBarras', filtro.codigoBarras);
      produtoCodigoBarraUrlPesquisar = `${this.produtoCodigoBarrasUrl}/filter/codigoBarra`;
    }

    return this.http
    .get<any>(produtoCodigoBarraUrlPesquisar, { params })
    .toPromise()
    .then(response => {
      const produtoCodigoBarras = response.content;

      const resultado = {
        produtoCodigoBarras,
        total: response.totalElements
      };

      return resultado;
    });
}

  listarTodas(): Promise<any> {
    return this.http
      .get<any>(`${this.produtoCodigoBarrasUrl}/lista`)
      .toPromise()
      .then(response => response.content);
  }

  adicionar(produtocodigoBarra: ProdutoCodigoBarra): Promise<ProdutoCodigoBarra> {
    return this.http.post<ProdutoCodigoBarra>(this.produtoCodigoBarrasUrl, produtocodigoBarra).toPromise();
  }

  atualizar(produtoCodigoBarra: ProdutoCodigoBarra): Promise<ProdutoCodigoBarra> {
    return this.http
      .put<ProdutoCodigoBarra>(
        `${this.produtoCodigoBarrasUrl}/${produtoCodigoBarra.id}`,
        produtoCodigoBarra      )
      .toPromise();
  }

  buscarPorId(id: number): Promise<ProdutoCodigoBarra> {
    return this.http
      .get<ProdutoCodigoBarra>(`${this.produtoCodigoBarrasUrl}/${id}`)
      .toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.produtoCodigoBarrasUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }
}
