import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import "rxjs/add/operator/toPromise";

import { environment } from "./../../environments/environment";
import { CodigoSituacaoTributariaCofins } from "./../core/models/CodigoSituacaoTributariaCofins";
import { MoneyHttp } from "app/seguranca/money-http";

export class CodigoSituacaoTributariaCofinsFiltro {
  descricao: string;
  codigo: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class CodigoSituacaoTributariaCofinsService {
    codigosSituacaoTributariasCofinsUrl: string;

  constructor(private http: MoneyHttp) {
    this.codigosSituacaoTributariasCofinsUrl = `${environment.apiUrl}/codigosSituacaoTributariasCofins`;
  }

  pesquisar(filtro: CodigoSituacaoTributariaCofinsFiltro): Promise<any> {
    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });
    if (filtro.descricao) {
      params = params.append("descricao", filtro.descricao);
    }
    if (filtro.codigo) {
      params = params.append("codigo", filtro.codigo);
    }
    return this.http
      .get<any>(`${this.codigosSituacaoTributariasCofinsUrl}`, { params })
      .toPromise()
      .then(response => {
        const codigosSituacaoTributariasCofins = response.content;

        const resultado = {
          codigosSituacaoTributariasCofins,
          total: response.totalElements
        };

        return resultado;
      });
  }

  listarTodas(): Promise<any> {
    return this.http
      .get<any>(`${this.codigosSituacaoTributariasCofinsUrl}/lista`)
      .toPromise()
      .then(response => response.content);
  }

  adicionar(codigoSituacaoTributariaCofins: CodigoSituacaoTributariaCofins): Promise<CodigoSituacaoTributariaCofins> {
    return this.http
      .post<CodigoSituacaoTributariaCofins>(this.codigosSituacaoTributariasCofinsUrl, codigoSituacaoTributariaCofins)
      .toPromise();
  }

  atualizar(codigosSituacaoTributariasCofins: CodigoSituacaoTributariaCofins): Promise<CodigoSituacaoTributariaCofins> {
    return this.http
      .put<CodigoSituacaoTributariaCofins>(`${this.codigosSituacaoTributariasCofinsUrl}/${codigosSituacaoTributariasCofins.id}`, codigosSituacaoTributariasCofins)
      .toPromise();
  }

  buscarPorId(id: number): Promise<CodigoSituacaoTributariaCofins> {
    return this.http.get<CodigoSituacaoTributariaCofins>(`${this.codigosSituacaoTributariasCofinsUrl}/${id}`).toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.codigosSituacaoTributariasCofinsUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }
}
