import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import "rxjs/add/operator/toPromise";
import { Observable } from "rxjs";

import { environment } from "./../../environments/environment";
import { PagamentoAntecipadoCartao } from "./../core/models/PagamentoAntecipadoCartao";
import { MoneyHttp } from "app/seguranca/money-http";

export class PagamentoAntecipadoCartaoFiltro {
    pagamentoId: string;
    pagina = 0;
    itensPorPagina = 10;
}

@Injectable()
export class PagamentoAntecipadoCartaoService {

    pagamentoAntecipadocartoesUrl: string;

    constructor(private http: MoneyHttp) {
        this.pagamentoAntecipadocartoesUrl = `${environment.apiUrl}/pagamentosAntecipadosCartoes`;
    }

    pesquisar(filtro: PagamentoAntecipadoCartaoFiltro): Promise<any> {

        let pagamentoAntecipadocartoesUrlPesquisar = `${this.pagamentoAntecipadocartoesUrl}/filter/pagamentoId`;

        let params = new HttpParams({
            fromObject: {
                page: filtro.pagina.toString(),
                size: filtro.itensPorPagina.toString()
            }
        });


        (filtro.pagamentoId)
        params = params.append('pagamentoId', filtro.pagamentoId);


        return this.http
            .get<any>(pagamentoAntecipadocartoesUrlPesquisar, { params })
            .toPromise()
            .then(response => {
                const pagamentoAntecipadoCartao = response.content;

                const resultado = {
                    pagamentoAntecipadoCartao,
                    total: response.totalElements
                };

                return resultado;
            });
    }

    listarTodas(): Promise<any> {
        return this.http
            .get<any>(`${this.pagamentoAntecipadocartoesUrl}/lista`)
            .toPromise()
            .then(response => response.content);
    }

    adicionar(pagamentoAntecipadoCartao: PagamentoAntecipadoCartao): Promise<PagamentoAntecipadoCartao> {
        this.pagamentoAntecipadocartoesUrl = `${environment.apiUrl}/pagamentosAntecipadosCartoes`;
        return this.http.post<PagamentoAntecipadoCartao>(this.pagamentoAntecipadocartoesUrl, pagamentoAntecipadoCartao).toPromise();
      }

    buscarPorId(id: number): Promise<PagamentoAntecipadoCartao> {
        return this.http.get<PagamentoAntecipadoCartao>(`${this.pagamentoAntecipadocartoesUrl}/${id}`).toPromise();
    }


  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.pagamentoAntecipadocartoesUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }


}
