import {Injectable} from '@angular/core';
import {MoneyHttp} from '../seguranca/money-http';
import {environment} from '../../environments/environment';
import {HttpParams} from '@angular/common/http';

import {ConfiguracaoLocal} from '../core/models/ConfiguracaoLocal';

export class ConfiguracaoLocalFiltro {
  bairro: number;
  rua: number;
  edificio: number;
  bloco: number;
  andar: number;
  apartamento: number;
  endereco = '';

  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class ConfiguracaoLocalService {

  configuracaoLocalURL: string;

  constructor(private http: MoneyHttp) {
    this.configuracaoLocalURL = `${environment.apiUrl}/configuracaoLocal`;
  }

  pesquisar(filtro: ConfiguracaoLocalFiltro): Promise<any> {

    let configuracaoLocalUrlPesquisar: string = `${this.configuracaoLocalURL}/listar`;

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    if (filtro.bairro && filtro.rua && filtro.edificio && !filtro.bloco && !filtro.andar) {
      params = params.append('bairro', filtro.bairro.toString());
      params = params.append('rua', filtro.rua.toString());
      params = params.append('edificio', filtro.edificio.toString());
      configuracaoLocalUrlPesquisar = `${this.configuracaoLocalURL}/filter/bairro/rua/edificio`;
    } else if (filtro.bairro && filtro.rua && filtro.edificio && filtro.bloco && !filtro.andar) {
      params = params.append('bairro', filtro.bairro.toString());
      params = params.append('rua', filtro.rua.toString());
      params = params.append('edificio', filtro.edificio.toString());
      params = params.append('bloco', filtro.bloco.toString());
      configuracaoLocalUrlPesquisar = `${this.configuracaoLocalURL}/filter/bairro/rua/edificio/bloco`;
    } else if (filtro.bairro && filtro.rua && filtro.edificio && filtro.bloco && filtro.andar && !filtro.apartamento) {
      params = params.append('bairro', filtro.bairro.toString());
      params = params.append('rua', filtro.rua.toString());
      params = params.append('edificio', filtro.edificio.toString());
      params = params.append('bloco', filtro.bloco.toString());
      params = params.append('andar', filtro.andar.toString());
      configuracaoLocalUrlPesquisar = `${this.configuracaoLocalURL}/filter/bairro/rua/edificio/bloco/andar`;
    } else if (filtro.bairro && filtro.rua && filtro.edificio && filtro.bloco && filtro.andar && filtro.apartamento) {
      params = params.append('bairro', filtro.bairro.toString());
      params = params.append('rua', filtro.rua.toString());
      params = params.append('edificio', filtro.edificio.toString());
      params = params.append('bloco', filtro.bloco.toString());
      params = params.append('andar', filtro.andar.toString());
      params = params.append('apartamento', filtro.apartamento.toString());
      configuracaoLocalUrlPesquisar = `${this.configuracaoLocalURL}/filter/bairro/rua/edificio/bloco/andar/apartamento`;
    }else {
      params = params.append('endereco', filtro.endereco);
    }

    return this.http
      .get<any>(configuracaoLocalUrlPesquisar, {params})
      .toPromise()
      .then(response => {
        const configuracaoLocais = response.content;
        const resultado = {
          configuracaoLocais,
          total: response.totalElements
        };
        return resultado;
      });

  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.configuracaoLocalURL}/${id}`)
      .toPromise()
      .then(() => null);
  }

  atualizar(configuracaoLocal: ConfiguracaoLocal): Promise<ConfiguracaoLocal> {
    return this.http
      .put<ConfiguracaoLocal>(`${this.configuracaoLocalURL}/${configuracaoLocal.id}`, configuracaoLocal)
      .toPromise();
  }

  buscarPorId(id: number): Promise<ConfiguracaoLocal> {
    return this.http.get<ConfiguracaoLocal>(`${this.configuracaoLocalURL}/${id}`).toPromise();
  }

}
