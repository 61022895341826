import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import "rxjs/add/operator/toPromise";
import { Observable } from "rxjs";

import { environment } from "./../../environments/environment";
import { PlanoConta } from "./../core/models/PlanoConta";
import { MoneyHttp } from "app/seguranca/money-http";
import {promise} from 'selenium-webdriver';

export class PlanoContaFiltro {
  descricao: string;
  codigoResultado: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class PlanoContaService {

  planoContasUrl: string;

  constructor(private http: MoneyHttp) {
    this.planoContasUrl = `${environment.apiUrl}/planosContas`;
  }

  pesquisar(filtro: PlanoContaFiltro): Promise<any> {

    let planoContasUrlPesquisar: string = `${this.planoContasUrl}/filter/descricao`;

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    if (filtro.descricao) {
      params = params.append('descricao', filtro.descricao);
    } 
    else if (filtro.codigoResultado) {
      params = params.append('codigoResultado', filtro.codigoResultado);
      planoContasUrlPesquisar = `${this.planoContasUrl}/filter/codigoResultado`;
    } 
    
    return this.http
    .get<any>(planoContasUrlPesquisar, { params })
    .toPromise()
    .then(response => {
      const planoContas = response.content;

      const resultado = {
        planoContas,
        total: response.totalElements
      };

      return resultado;
    });
}

  listarTodas(): Promise<any> {
    return this.http
      .get<any>(`${this.planoContasUrl}/lista`)
      .toPromise()
      .then(response => response);
  }

  adicionar(PlanoConta: PlanoConta): Promise<PlanoConta> {
    return this.http.post<PlanoConta>(this.planoContasUrl, PlanoConta).toPromise();
  }

  atualizar(PlanoConta: PlanoConta): Promise<PlanoConta> {
    return this.http
      .put<PlanoConta>(`${this.planoContasUrl}/${PlanoConta.id}`, PlanoConta)
      .toPromise();
  }

  buscarPorId(id: number): Promise<PlanoConta> {
    return this.http.get<PlanoConta>(`${this.planoContasUrl}/${id}`).toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.planoContasUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }

  buscarPeloCodigo(codigoResultado: string): Promise<PlanoConta> {
    return this.http
      .get<PlanoConta>(`${this.planoContasUrl}/codigo/${codigoResultado}`).toPromise();
  }

}

