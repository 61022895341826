import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import "rxjs/add/operator/toPromise";
import { Observable } from "rxjs";

import { environment } from "./../../environments/environment";
import { PagamentoAntecipadoCheque } from "./../core/models/PagamentoAntecipadoCheque";
import { MoneyHttp } from "app/seguranca/money-http";

export class PagamentoAntecipadoChequeFiltro {
    pagamentoId: string;
    pagina = 0;
    itensPorPagina = 10;
}

@Injectable()
export class PagamentoAntecipadoChequeService {

    pagamentoAntecipadochequesUrl: string;

    constructor(private http: MoneyHttp) {
        this.pagamentoAntecipadochequesUrl = `${environment.apiUrl}/pagamentosAntecipadosCheques`;
    }

    pesquisar(filtro: PagamentoAntecipadoChequeFiltro): Promise<any> {

        let pagamentoAntecipadochequesUrlPesquisar = `${this.pagamentoAntecipadochequesUrl}/filter/pagamentoId`;

        let params = new HttpParams({
            fromObject: {
                page: filtro.pagina.toString(),
                size: filtro.itensPorPagina.toString()
            }
        });


        (filtro.pagamentoId)
        params = params.append('pagamentoId', filtro.pagamentoId);


        return this.http
            .get<any>(pagamentoAntecipadochequesUrlPesquisar, { params })
            .toPromise()
            .then(response => {
                const pagamentoAntecipadocheque = response.content;

                const resultado = {
                    pagamentoAntecipadocheque,
                    total: response.totalElements
                };

                return resultado;
            });
    }

    listarTodas(): Promise<any> {
        return this.http
            .get<any>(`${this.pagamentoAntecipadochequesUrl}/lista`)
            .toPromise()
            .then(response => response.content);
    }

    adicionar(pagamentoAntecipadocheque: PagamentoAntecipadoCheque): Promise<PagamentoAntecipadoCheque> {
        this.pagamentoAntecipadochequesUrl = `${environment.apiUrl}/pagamentosAntecipadosCheques`;
        return this.http.post<PagamentoAntecipadoCheque>(this.pagamentoAntecipadochequesUrl, pagamentoAntecipadocheque).toPromise();
      }

    buscarPorId(id: number): Promise<PagamentoAntecipadoCheque> {
        return this.http.get<PagamentoAntecipadoCheque>(`${this.pagamentoAntecipadochequesUrl}/${id}`).toPromise();
    }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.pagamentoAntecipadochequesUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }


}
