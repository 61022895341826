import { Parts } from 'app/core/models/Parts';

export class Vehicle {

    Brand: string;
    Model: string;
    Displacements: string;
    Pontency: string;
    YearManufacture: string;
    ModelYear: string;
    Color: string;
    Fuel: string;
    Chassi: string;
    MotorNumber: string;
    Parts: Array<Parts>;

    constructor(

        Brand?: string,
        Model?: string,
        Displacements?: string,
        Pontency?: string,
        YearManufacture?: string,
        ModelYear?: string,
        Color?: string,
        Fuel?: string,
        Chassi?: string,
        MotorNumber?: string,
        Parts = new Array<Parts>()
    ){
        this.Brand = Brand;
        this.Model = Model;
        this.Displacements = Displacements;
        this.Pontency = Pontency;
        this.YearManufacture = YearManufacture;
        this.ModelYear = ModelYear;
        this.Color = Color;
        this.Fuel = Fuel;
        this.Chassi = Chassi;
        this.MotorNumber = MotorNumber;
        this.Parts = Parts;
    }
}