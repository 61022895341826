import {Injectable} from '@angular/core';

import 'rxjs/add/operator/toPromise';

import {environment} from './../../environments/environment';
import {MoneyHttp} from 'app/seguranca/money-http';
import {PagamentoAntecipadoBanco} from '../core/models/PagamentoAntecipadoBanco';

@Injectable()
export class PagamentoAntecipadoBancoService {

  url: string;

  constructor(private http: MoneyHttp) {
    this.url = `${environment.apiUrl}/pagamentosAntecipadoBancos`;
  }

  listarTodas(): Promise<Array<PagamentoAntecipadoBanco>> {
    return this.http
      .get<Array<PagamentoAntecipadoBanco>>(`${this.url}/lista`)
      .toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.url}/${id}`)
      .toPromise()
      .then(() => null);
  }


}
