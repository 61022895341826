import { Injectable } from '@angular/core';
import {MoneyHttp} from '../seguranca/money-http';
import {environment} from '../../environments/environment';
import {EntradaTemporaria} from '../core/models/EntradaTemporaria';
import {Produto} from '../core/models/Produto';

@Injectable()
export class EntradaTemporariaService{

  url: string;

  constructor(private http: MoneyHttp) {
    this.url = `${environment.apiUrl}/entradasTemporarias`;
  }

  excluir(et: EntradaTemporaria){
    return this.http
      .delete(`${this.url}/${et.id}`)
      .toPromise();

  }

  atualizar(entradaTemporaria: EntradaTemporaria): Promise<EntradaTemporaria> {
    return this.http.put<EntradaTemporaria>(`${this.url}/${entradaTemporaria.id}`, entradaTemporaria).toPromise();
  }


  buscarUltimaEntradaProduto(codigo: number): Promise<EntradaTemporaria>{
    return this.http.get<EntradaTemporaria>(`${this.url}/ultimaEntradaProduto/${codigo}`).toPromise();
  }
}
