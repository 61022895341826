import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as moment from 'moment';

import 'rxjs/add/operator/toPromise';

import {environment} from './../../environments/environment';
import {MoneyHttp} from 'app/seguranca/money-http';

import {Movimento} from './../core/models/Movimento';
import {MovimentoEditado} from '../core/models/MovimentoEditado';

export class MovimentoFiltro {
  documento: string;
  empresa: string;
  codigoTecnico: string;
  codigo: string;
  emissaoDe: Date;
  emissaoAte: Date;
  pesquisa: string;
  status: string;
  usuarioLogado: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class MovimentoService {
  movimentosUrl: string;
  movimentosEditadoUrl: string;

  constructor(private http: MoneyHttp) {
    this.movimentosUrl = `${environment.apiUrl}/movimentos`;
    this.movimentosEditadoUrl = `${environment.apiUrl}/movimentosEditados`;
  }

  adicionar(movimento: Movimento): Promise<Movimento> {
    return this.http.post<Movimento>(this.movimentosUrl, movimento).toPromise();
  }


  pesquisar(filtro: MovimentoFiltro): Promise<any> {

    const movimentosUrlPesquisa = `${this.movimentosUrl}/filter/documento`;

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    if (filtro.documento) {

      params = params.append('documento', filtro.documento);

    }

    return this.http
      .get<any>(movimentosUrlPesquisa, {params})
      .toPromise()
      .then(response => {
        const movimentos = response.content;

        const resultado = {
          movimentos,
          total: response.totalElements
        };

        return resultado;
      });
  }

  pesquisarConsultaMovimento(filtro: MovimentoFiltro): Promise<any> {

    const params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString(),
        empresa: filtro.empresa,
        emissaoDe: moment(filtro.emissaoDe).format('YYYY-MM-DD'),
        emissaoAte: moment(filtro.emissaoAte).format('YYYY-MM-DD'),
        codigo: filtro.codigo,
        status: filtro.status,
      }
    });
    return this.http
      .get<any>(`${this.movimentosUrl}?consulta`, {params})
      .toPromise()
      .then(response => {
        const movimentos = response.content;
        const resultado = {
          movimentos,
          total: response.totalElements
        };
        return resultado;
      });
  }

  impressaoMovimentoAcerto(filtro: MovimentoFiltro): Promise<Blob> {
    this.movimentosUrl = `${environment.apiUrl}/movimentos`;
    const params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString(),
        empresa: filtro.empresa,
        status: filtro.status,
        emissaoDe: moment(filtro.emissaoDe).format('YYYY-MM-DD'),
        emissaoAte: moment(filtro.emissaoAte).format('YYYY-MM-DD'),
        usuarioLogado: filtro.usuarioLogado
      }
    });
    return this.http.get<Blob>(`${this.movimentosUrl}?impressaoMovimentoAcerto`, {params, responseType: 'blob' as 'json'}).toPromise();
  }


  listarTodas(): Promise<any> {
    return this.http
      .get<any>(`${this.movimentosUrl}/lista`)
      .toPromise()
      .then(response => response.content);
  }

  atualizar(movimento: Movimento): Promise<Movimento> {
    return this.http
      .put<Movimento>(`${this.movimentosUrl}/${movimento.id}`, movimento)
      .toPromise();
  }

  buscarPorId(id: number): Promise<Movimento> {
    return this.http.get<Movimento>(`${this.movimentosUrl}/${id}`).toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.movimentosUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }


  listarMovimentacoes(empresa: string, codigo: string, data: Date, tipos: Array<any>, pagina: string): Promise<any> {

    if (data == null || data == undefined) {
      data = new Date();
      data.setDate(data.getDate() - 1800);
    }

    let params = new HttpParams({
      fromObject: {
        page: pagina,
        size: '5'
      }
    });

    params = params.append('empresa', empresa);
    params = params.append('codigo', codigo);
    params = params.append('data', moment(data).format('YYYY-MM-DD 00:00'));
    params = params.append('status', (tipos) ? tipos.toString() : '');

    return this.http.get<any>(`${this.movimentosUrl}/movimentacao/empresa/codigo/data/status`, {params}).toPromise()

  }


  listarMovimentacoesPorDoc(empresa: string, documento: string, serie: string): Promise<Array<Movimento>> {

    let params = new HttpParams({
      fromObject: {
        page: '1',
        size: '10'
      }
    });

    params = params.append('empresa', empresa);
    params = params.append('documento', documento);
    params = params.append('serie', serie);

    return this.http
      .get<any>(`${this.movimentosUrl}/movimentacaoPorDocumento/empresa/documento/serie`,
        {params})
      .toPromise();
  }

  listarMovimentacoesPorMarca(empresa: string, dataInicio: Date, dataFim: Date, marca: string): Promise<Array<Movimento>> {

    let params = new HttpParams({
      fromObject: {
        page: '1',
        size: '10'
      }
    });

    params = params.append('empresa', empresa);
    params = params.append('dataInicio', moment(dataInicio).format('YYYY-MM-DD 00:00'));
    params = params.append('dataFim', moment(dataFim).format('YYYY-MM-DD 23:59'));
    params = params.append('marca', marca);

    return this.http
      .get<any>(`${this.movimentosUrl}/movimentacaoPorMarca/empresa/dataInicio/dataFim/marca`,
        {params})
      .toPromise();

  }

  adicionarMovimentoEditado(movimentoEditado: MovimentoEditado): Promise<MovimentoEditado> {
    return this.http.post<MovimentoEditado>(this.movimentosEditadoUrl, movimentoEditado).toPromise();
  }

  atualizarMovimentoEditado(movimentoEditado: MovimentoEditado): Promise<MovimentoEditado> {
    return this.http
      .put<MovimentoEditado>(`${this.movimentosEditadoUrl}/${movimentoEditado.id}`, movimentoEditado)
      .toPromise();
  }

  excluirMovimentoEditado(movimentoEditado: MovimentoEditado): Promise<any> {
    return this.http
      .delete(`${this.movimentosEditadoUrl}/${movimentoEditado.id}`, movimentoEditado)
      .toPromise();
  }

  listarMovimentacoesEditadoPorDoc(documento: string, serie: string): Promise<Array<MovimentoEditado>> {

    let params = new HttpParams({
      fromObject: {
        page: '1',
        size: '10'
      }
    });

    params = params.append('documento', documento);
    params = params.append('serie', serie);

    return this.http
      .get<Array<MovimentoEditado>>(`${this.movimentosEditadoUrl}/movimentacaoPorDocumento/documento/serie`,
        {params})
      .toPromise();
  }

}
