import { Injectable } from '@angular/core';

import { environment } from './../../environments/environment';

import { MoneyHttp } from "app/seguranca/money-http";
import 'rxjs/add/operator/toPromise';
import { Observable } from 'rxjs';

import { ClientesDescVendedor } from 'app/core/models/ClientesDescVendedor';

@Injectable()
export class ClientesDescVendedorService {

  private clientesDescVendedorUrl: string;

  constructor (
    private http: MoneyHttp
  ) {
    this.clientesDescVendedorUrl =  `${environment.apiUrl}/clientesDescVendedores`;
  }

  adicionar(clientesDescVendedor: ClientesDescVendedor): Observable<ClientesDescVendedor> {
    return this.http.post<ClientesDescVendedor>(this.clientesDescVendedorUrl, clientesDescVendedor);
  }

  atualizar(clientesDescVendedor: ClientesDescVendedor): Observable<ClientesDescVendedor> {
    return this.http.put<ClientesDescVendedor>(`${this.clientesDescVendedorUrl}/${clientesDescVendedor.id}`, ClientesDescVendedor);
  }

  buscarPeloId(id: number): Observable<ClientesDescVendedor> {
    return this.http.get<ClientesDescVendedor>(`${this.clientesDescVendedorUrl}/${id}`);
  }

  deletarPeloId(id: number): Observable<any> {
    return this.http.delete<any>(`${this.clientesDescVendedorUrl}/${id}`);
  }

  deletarPeloCodigoVendedor(clienteVendedor: ClientesDescVendedor): Observable<any> {
    return this.http.delete<any>(`${this.clientesDescVendedorUrl}/deletarPeloCodigoVendedor/
      ${clienteVendedor.codigoCliente}/${clienteVendedor.codigoVendedor}`);
  }

  listarPeloCodigo(codigoCliente: string): Observable<Array<ClientesDescVendedor>> {
    return this.http.get<Array<ClientesDescVendedor>>(`${this.clientesDescVendedorUrl}/listarPeloCodigo/${codigoCliente}`);
  }

  buscarVendedorAutorizado(codigoCliente: string, codigoVendedor: string): Promise<ClientesDescVendedor> {
    return this.http.get<ClientesDescVendedor>(`${this.clientesDescVendedorUrl}/vendedor/
      ${codigoCliente}/${codigoVendedor}`).toPromise();
  }
}
