import { Injectable } from '@angular/core';

import 'rxjs/operator/toPromise';
import * as moment from 'moment';

import { environment } from './../../environments/environment';
import { MoneyHttp } from 'app/seguranca/money-http';

@Injectable()
export class DashboardService {

  lancamentosUrl: string;
  vendedoresUrl: string;
  produtosUrl: string;

  constructor(private http: MoneyHttp) {
    this.lancamentosUrl = `${environment.apiUrl}/lancamentos`;
    this.vendedoresUrl = `${environment.apiUrl}/vendedores`;
    this.produtosUrl = `${environment.apiUrl}/produtos`;
  }

  lancamentosPorCategoria(): Promise<Array<any>> {
    return this.http.get<Array<any>>(`${this.lancamentosUrl}/estatisticas/por-categoria`)
      .toPromise();
  }

  lancamentosPorDia(): Promise<Array<any>> {
    return this.http.get<Array<any>>(`${this.lancamentosUrl}/estatisticas/por-dia`)
      .toPromise()
      .then(response => {
        const dados = response;
        this.converterStringsParaDatas(dados);

        return dados;
      });
  }

  totaisVendasDevolucoesPorMeses(dtInicio: string, dtFim: string, coEmpresa: string, nomeVendedor: string): Promise<Array<any>> {
    return this.http.get<Array<any>>(`${this.vendedoresUrl}/resumoTotaisMensal?dtInicio=${moment(dtInicio).format('YYYY-MM-DD')}&dtFim=${moment(dtFim).format('YYYY-MM-DD')}&coEmpresa=${coEmpresa}&nomeVendedor=${nomeVendedor}`)
      .toPromise()
      .then(response => {
        const dados = response;
        return dados;
     });
  }

  totaisVendasDevolucoesPorAno(dtInicio: string, dtFim: string, coEmpresa: string, nomeVendedor: string): Promise<Array<any>> {
    return this.http.get<Array<any>>(`${this.vendedoresUrl}/resumoTotaisAnual?dtInicio=${moment(dtInicio).format('YYYY-MM-DD')}&dtFim=${moment(dtFim).format('YYYY-MM-DD')}&coEmpresa=${coEmpresa}&nomeVendedor=${nomeVendedor}`)
      .toPromise()
      .then(response => {
        const dados = response;
        return dados;
     });
  }

  totaisVendedorPorMes(dtInicio: string, dtFim: string, coEmpresa: string): Promise<Array<any>> {
    return this.http.get<Array<any>>(`${this.vendedoresUrl}/vendasTotaisMensal?dtInicio=${moment(dtInicio).format('YYYY-MM-DD')}&dtFim=${moment(dtFim).format('YYYY-MM-DD')}&coEmpresa=${coEmpresa}`)
      .toPromise()
      .then(response => {
        const dados = response;
        return dados;
     });
  }

  totaisVendedorPorDia(dtInicio: string, dtFim: string, coEmpresa: string): Promise<Array<any>> {
    return this.http.get<Array<any>>(`${this.vendedoresUrl}/vendasTotaisDiario?dtInicio=${moment(dtInicio).format('YYYY-MM-DD')}&dtFim=${moment(dtFim).format('YYYY-MM-DD')}&coEmpresa=${coEmpresa}`)
      .toPromise()
      .then(response => {
        const dados = response;
        return dados;
     });
  }

  totaisProdutosValores(empresa: string, marcaIni: string, marcaFim: string): Promise<any> {
    return this.http.get<any>(`${this.produtosUrl}/valoresTotaisAcumulados?empresa=${empresa}&marcaIni=${marcaIni}&marcaFim=${marcaFim}`)
      .toPromise()
      .then(response => {
        const dados = response;
        return dados;
     });
  }

  private converterStringsParaDatas(dados: Array<any>) {
    for (const dado of dados) {
      dado.dia = moment(dado.dia, 'YYYY-MM-DD').toDate();
    }
  }
}
