import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import "rxjs/add/operator/toPromise";

import { environment } from "./../../environments/environment";
import { MoneyHttp } from "app/seguranca/money-http";

import { ServicoVeiculo } from "app/core/models/ServicoVeiculo";
import { ServicoVeiculoCadastro } from "app/core/models/ServicoVeiculoCadastro";

export class ServicoVeiculoFiltro {
  descricaoServico: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class ServicoVeiculoService {
  servicosVeiculosUrl: string;

  constructor(private http: MoneyHttp) {
    this.servicosVeiculosUrl = `${environment.apiUrl}/servicosVeiculos`;
  }

  pesquisar(filtro: ServicoVeiculoFiltro): Promise<any> {
    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });
    if (filtro.descricaoServico) {
      params = params.append("descricaoServico", filtro.descricaoServico);
    }
    return this.http
      .get<any>(`${this.servicosVeiculosUrl}`, { params })
      .toPromise()
      .then(response => {
        const servicosVeiculos = response.content;

        const resultado = {
          servicosVeiculos,
          total: response.totalElements
        };

        return resultado;
      });
  }

  listarTodas(): Promise<any> {
    return this.http
      .get<any>(`${this.servicosVeiculosUrl}/lista`)
      .toPromise()
      .then(response => response.content);
  }

  adicionar(servicoVeiculo: ServicoVeiculo): Promise<ServicoVeiculo> {
    return this.http.post<ServicoVeiculo>(this.servicosVeiculosUrl, servicoVeiculo).toPromise();
  }

  atualizar(servicoVeiculo: ServicoVeiculo): Promise<ServicoVeiculo> {
    return this.http
      .put<ServicoVeiculo>(`${this.servicosVeiculosUrl}/${servicoVeiculo.id}`, servicoVeiculo)
      .toPromise();
  }

  buscarPorId(id: number): Promise<ServicoVeiculo> {
    return this.http.get<ServicoVeiculo>(`${this.servicosVeiculosUrl}/${id}`).toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.servicosVeiculosUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }

  getServicos(servico: string) {
    return this.http
      .get<any>(`${this.servicosVeiculosUrl}/servicos/${servico}`)
      .toPromise()
      .then(response => {
        return response;
      });
  }

}
