import { PreVenda } from 'app/core/models/PreVenda';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/toPromise';

import { environment } from '../../environments/environment';
import { Liberacao } from './../core/models/Liberacao';
import { LiberacaoUsuario } from 'app/core/models/LiberacaoUsuario';
import { MoneyHttp } from 'app/seguranca/money-http';
import * as moment from 'moment';
import {Cliente} from '../core/models/Cliente';
import {Bloqueio} from '../core/models/Bloqueio';

export class LiberacaoFiltro {
  empresa: string;
  documento: string;
  data: Date;
  dataDe: Date;
  dataAte: Date;
  solicitante: string;
  pagina = 0;
  itensPorPagina = 10;
  status: string;
  codbloqueio: string;
  cliente: string;
  vendedor = []

}


@Injectable()
export class LiberacaoService {
  liberacoesUrl: string;
  liberacaoUserUrl: string;

  constructor(private http: MoneyHttp) {
    this.liberacoesUrl = `${environment.apiUrl}/liberacoes`;
    this.liberacaoUserUrl = `${environment.apiUrl}/liberacoesUsuarios`;
  }


  pesquisarLiberacao(filtro: LiberacaoFiltro): Promise<any> {

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });
    if (filtro.documento) {
      params = params.append('documento', filtro.documento);
    }
    if (filtro.data) {
      params = params.append(
        'data',
        moment(filtro.data).format('YYYY-MM-DD')
      );
    }
    return this.http
      .get<any>(`${this.liberacoesUrl}/filter/documento`, { params })
      .toPromise()
      .then(response => {
        const liberacoes = response.content;

        const resultado = {
          liberacoes,
          total: response.totalElements
        };

        return resultado;
      });
  }

  pesquisar(filtro: LiberacaoFiltro): Promise<any> {
    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    if (filtro.documento) {
      params = params.append('documento', filtro.documento);
    }  

    if (filtro.dataDe) {
      params = params.append(
        'dataDe',
        moment(filtro.dataDe).format('YYYY-MM-DD')
      );
    }

    if (filtro.dataAte) {
      params = params.append(
        'dataAte',
        moment(filtro.dataAte).format('YYYY-MM-DD')
      );
    }

 
    return this.http
      .get<any>(`${this.liberacoesUrl}/filter/documento`, { params })
      .toPromise()
      .then(response => {
        const liberacoes = response.content;

        const resultado = {
          liberacoes,
          total: response.totalElements
        };

        return resultado;
      });
  }


   listarTodas(): Promise<any> {
    return this.http
      .get<any>(this.liberacoesUrl)
      .toPromise()
      .then(response => response.content);
  }

  adicionar(liberacao: Liberacao): Promise<Liberacao> {
    return this.http.post<Liberacao>(this.liberacoesUrl, liberacao).toPromise();
  }

  atualizar(liberacao: Liberacao): Promise<Liberacao> {
    return this.http
      .put<Liberacao>(`${this.liberacoesUrl}/${liberacao.id}`, liberacao)
      .toPromise();
  }

  buscarPorId(id: number): Promise<Liberacao> {
    return this.http.get<Liberacao>(`${this.liberacoesUrl}/${id}`).toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.liberacoesUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }

  buscarLiberacoesPorPrevenda(filtro: LiberacaoFiltro): Promise<Liberacao[]> {
    return this.http.get<Liberacao[]>(`${this.liberacoesUrl}/filter/documento/${filtro.status}/${filtro.documento}`).toPromise();
  }

  buscarLiberacoesPorEvento(filtro: LiberacaoFiltro): Promise<Liberacao[]> {
    const dt = moment(filtro.dataDe, 'YYYY-MM-DD', true).format();
    return this.http.get<Liberacao[]>(`${this.liberacoesUrl}/filter/eventos/${dt}/${filtro.status}`).toPromise();
  }

  buscarLiberacoesPorData(filtro: LiberacaoFiltro): Promise<Liberacao[]> {
    const dt = moment(filtro.data, 'YYYY-MM-DD', true).format();
    return this.http.get<Liberacao[]>(`${this.liberacoesUrl}/filter/usuario/${filtro.status}/${filtro.solicitante}/${dt}`).toPromise();
  }

  buscarLiberacoesPorPreVendaClienteBloqueio(filtro: LiberacaoFiltro): Promise<Liberacao> {
    const params = new HttpParams({
      fromObject: {
        empresa: filtro.empresa,
        documento: filtro.documento,
        cliente: filtro.cliente,
        usuarioSolicitante: filtro.solicitante,
        codigoBloqueio: filtro.codbloqueio
      }
    });
    return this.http.get<Liberacao>(`${this.liberacoesUrl}?bloqueio_cliente`, {params}).toPromise();
  }


  definirRetornoUsuario(liberacao: Liberacao): Promise<Liberacao> {
    return this.http
      .put<Liberacao>(`${this.liberacoesUrl}/definirRetornoUsuario/${liberacao.id}`, liberacao)
      .toPromise();
  }

  buscarLiberacaoUsuariosPorId(codigoUsuario: string): Promise<LiberacaoUsuario[]> {
    return this.http.get<LiberacaoUsuario[]>(`${this.liberacaoUserUrl}/codigoUsuario/${codigoUsuario}`).toPromise();
  }

  criarLiberacaoApartirDuplicatasComJuros(empresa: string, documento:string, serie:string, usuario: string, cliente: Cliente,
                                           jurosCalculado: number, jurosAcobrar: number, bloqueio: Bloqueio){

    let liberacao = new Liberacao();
    liberacao.empresa = empresa;
    liberacao.userSolicitado = usuario;
    liberacao.documento = documento;
    liberacao.serie = serie;
    liberacao.cliente = cliente;
    liberacao.valorSolicitado = jurosAcobrar;
    liberacao.bloqueio = bloqueio;
    liberacao.motivoSolicitacao = 'Juros Calc.: '+jurosCalculado+' Juros Usuario: '+ jurosAcobrar+ ' / CLIENTE NÃO PG JUROS';
    this.adicionar(liberacao);

  }

  criarLiberacaoApartirTroca(liberacao: Liberacao): Promise<Liberacao> {
    return this.http.put<Liberacao>(`${this.liberacoesUrl}/solicitacaoPrecoAbaixoTroca`, liberacao).toPromise();
  }

}


