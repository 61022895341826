import {NotaMsg} from './../core/models/NotaMsg';
import {Nota} from './../core/models/Nota';
import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';

import 'rxjs/add/operator/toPromise';

import {environment} from '../../environments/environment';
import {MoneyHttp} from 'app/seguranca/money-http';
import * as moment from 'moment';
import {Cliente} from '../core/models/Cliente';
import {Produto} from '../core/models/Produto';
import {Empresa} from '../core/models/Empresa';
import {ClienteTipo} from '../core/models/ClienteTipo';
import {Serie} from '../core/models/Serie';
import {NaturezaOperacao} from '../core/models/NaturezaOperacao';
import {Vendedor} from '../core/models/Vendedor';
import {Tecnico} from '../core/models/Tecnico';
import {FormaPagamento} from '../core/models/FormaPagamento';
import {Movimento} from '../core/models/Movimento';

export class VincularNotas {
  nota: Nota;
  notas: Nota[];
  empresa: string;
  usuario: string;
}

export class NotaFiltro {
  empresa: string;
  codigoVendedor: string;
  nomeVendedor: string;
  cliente: string;
  documento: string;
  numero: string;
  tipo: string;
  serie: string;
  emissaoDe: Date;
  emissaoAte: Date;
  valor: number;
  pagina = 0;
  itensPorPagina = 20;
}

export class NotaSelecionada {
  selecionado: boolean;
  nota: Nota;
}

export class ControleNota {

  empresas: Array<Empresa>;
  series: Array<Serie>;
  natOpers: Array<NaturezaOperacao>;
  vendedores: Array<Vendedor>;
  tecnicos: Array<Tecnico>;
  tipoClientes: Array<ClienteTipo>;
  condicaoPagamentos: Array<FormaPagamento>;
  clientes: Array<Cliente>;
  produtos: Array<Produto>;

  excectoEmpresas: boolean;
  excetoSeries: boolean;
  excetoNatOpers: boolean;
  excetoVendedores: boolean;
  excetoTecnicos: boolean;
  excetoTipoClientes: boolean;
  excetoCondicaoPagamento: boolean;
  excetoClientes: boolean;
  excetoProdutos: boolean;

  documento: string;
  serie: string;

  dataInicio: Date;
  dataFim: Date;

  valoresAcima: number;
  valoresAbaixo: number;

  incluirTransferenciaGarantuaConsumo: boolean;
  somenteNotasAbertas: boolean;
  somenteNotasFechadas: boolean;
  incluirNotasCanceladas: boolean;
  somenteNotasCanceladas: boolean;
  somenteNotasLancadasCaixa: boolean;
  somenteNotasForaEstado: boolean;
  incluirDenega: boolean;
  somenteDenega: boolean;

  tipoOrdenacao: string;

  dataInicioDia: Date;
  dataFimDia: Date;

  dataInicioMes: Date;
  dataFimMes: Date;
  periodoDia: boolean;

}

export class NotaMarkup {

  documento: string;
  serie: string;
  valorFinal: number;
  cliente: Cliente;
  crep: number;
  valorVenda: number;
  markup: number;

  produtos: Array<Movimento>;

}

export class TotalVendasPeriodo {

  periodo: string;
  totalPecas: number;
  totalServicos: number;


}

export class Troca {

  empresa: string;
  usuario: string;

  notaTroca: Nota;

  itensEstoque: Array<Movimento>;

  debitoTroca: number;
  creditoTroca: number;
  resultaTroca: number;

  resultado: string;

  motivoTroca: string = '';
}

@Injectable()
export class NotaService {
  notasUrl: string;
  notasMsgUrl: string;

  constructor(private http: MoneyHttp) {
    this.notasUrl = `${environment.apiUrl}/notas`;
    this.notasMsgUrl = `${environment.apiUrl}/notasMsg`;
  }

  pesquisar(filtro: NotaFiltro): Promise<any> {
    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    if (filtro.empresa) {
      params = params.append('empresa', filtro.empresa);
    }

    if (filtro.cliente) {
      params = params.append('cliente', filtro.cliente);
    }

    if (filtro.documento) {
      params = params.append('documento', filtro.documento);

      if (filtro.serie) {
        params = params.append(
          'serie', filtro.serie
        );
      }

      this.notasUrl = `${environment.apiUrl}/notas/filter/documento`;
    }

    if (filtro.emissaoDe) {
      params = params.append(
        'emissaoDe',
        moment(filtro.emissaoDe).format('YYYY-MM-DD')
      );
    }

    if (filtro.emissaoAte) {
      params = params.append(
        'emissaoAte',
        moment(filtro.emissaoAte).format('YYYY-MM-DD')
      );
    }

    if (filtro.codigoVendedor) {
      params = params.append(
        'codigoVendedor', filtro.codigoVendedor
      );
    }


    if (filtro.emissaoDe && filtro.emissaoAte) {
      this.notasUrl = `${environment.apiUrl}/notas?parametros`;
    }

    return this.http
      .get<any>(`${this.notasUrl}`, {params})
      .toPromise()
      .then(response => {
        const notas = response.content;

        const resultado = {
          notas,
          total: response.totalElements
        };

        return resultado;
      });
  }

  pesquisarDocumentosFiscais(filtro: NotaFiltro): Promise<any> {
    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    if (filtro.empresa) {
      params = params.append('empresa', filtro.empresa);
    }

    if (filtro.serie) {
      params = params.append('series', filtro.serie);
    }

    if (filtro.emissaoDe) {
      params = params.append(
        'emissaoDe',
        moment(filtro.emissaoDe).format('YYYY-MM-DD')
      );
    }

    if (filtro.emissaoAte) {
      params = params.append(
        'emissaoAte',
        moment(filtro.emissaoAte).format('YYYY-MM-DD')
      );
    }


    if (filtro.emissaoDe && filtro.emissaoAte) {
      this.notasUrl = `${environment.apiUrl}/notas?pesquisaDocumentos`;
    }

    return this.http
      .get<any>(`${this.notasUrl}`, {params})
      .toPromise()
      .then(response => {
        const notas = response.content;

        const resultado = {
          notas,
          total: response.totalElements
        };
        this.notasUrl = `${environment.apiUrl}/notas`;
        return resultado;
      });
  }

  listarTodas(): Promise<any> {
    return this.http
      .get<any>(this.notasUrl)
      .toPromise()
      .then(response => response.content);
  }

  adicionar(nota: Nota): Promise<Nota> {
    this.notasUrl = `${environment.apiUrl}/notas`;
    return this.http.post<Nota>(this.notasUrl, nota).toPromise();
  }

  atualizar(nota: Nota): Promise<Nota> {
    return this.http
      .put<Nota>(`${this.notasUrl}/${nota.id}`, nota)
      .toPromise();
  }

  buscarPorId(id: number): Promise<Nota> {
    return this.http
      .get<Nota>(`${this.notasUrl}/${id}`)
      .toPromise()
      .then(response => {
        const nota = response;

        this.converterStringsParaDatas([nota]);

        return nota;
      });
  }

  private converterStringsParaDatas(notas: Nota[]) {
    for (const nota of notas) {
      nota.emissao = moment(
        nota.emissao,
        'YYYY-MM-DD'
      ).toDate();

    }
  }

  notaMsg(empresa: string, documento: string): Promise<any> {
    return this.http.get<NotaMsg[]>(`${this.notasMsgUrl}/mensagem/${empresa}/${documento}`).toPromise()
  }


  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.notasUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }

  buscarNotasAvistaSemEntradaCaixa(empresa: string, vendedor: string): Promise<Nota[]> {
    return this.http.get<Nota[]>(`${this.notasUrl}/avistasemcaixa/${empresa}/${vendedor}`).toPromise();
  }

  buscarQtdeNotasPendentesCancelamento(empresa: string, login: string): Promise<NotaMsg[]> {
    return this.http.get<NotaMsg[]>(`${this.notasMsgUrl}/quantidadeCancelamento/${empresa}/${login}`).toPromise();
  }

  buscarTmpMaximoNotasPendentesCancelamento(empresa: string, login: string): Promise<NotaMsg[]> {
    return this.http.get<NotaMsg[]>(`${this.notasMsgUrl}/tempoCancelamento/${empresa}/${login}`).toPromise();
  }

  buscarNotasMensagem(empresa: string, documento: string): Promise<NotaMsg[]> {
    return this.http.get<NotaMsg[]>(`${this.notasMsgUrl}/mensagem/${empresa}/${documento}`).toPromise();
  }

  adicionarMensagem(notaMsg: NotaMsg): Promise<NotaMsg> {
    return this.http.post<NotaMsg>(this.notasMsgUrl, notaMsg).toPromise();
  }

  buscarNotasEmAbertoPorVendedor(empresa: string, vendedor: string): Promise<Nota[]> {
    return this.http.get<Nota[]>(`${this.notasUrl}/abertasporvendedor/${empresa}/${vendedor}`).toPromise();
  }

  buscarTodasNotasNaoLancandasCaixa(empresa: string, codigo: string, filtro: NotaFiltro): Promise<any> {

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString(),
      }
    });

    params = params.append('empresa', empresa);
    params = params.append('codigo', codigo);

    return this.http
      .get<any>(`${this.notasUrl}/naolancandascaixa`, {params})
      .toPromise()
      .then(response => {
        const resultado = {
          nota: response.content,
          total: response.totalElements
        };
        return resultado;
      });
  }


  buscarNotaPorDocumentoSerie(filtro: NotaFiltro): Promise<Nota> {
    return this.http.get<Nota>(`${this.notasUrl}/neGerada/${filtro.documento}/${filtro.serie}`).toPromise();
  }

  buscarNotasPorDocumentoFiscal(empresa: string, numero: string, tipo: string): Promise<Nota[]> {
    return this.http.get<Nota[]>(`${this.notasUrl}/porDocumentoFiscal/${empresa}/${numero}/${tipo}`).toPromise();
  }

  buscarNotasLancamentoCaixaPorPeriodo(filtro: NotaFiltro): Promise<Nota[]> {
    const params = new HttpParams({
      fromObject: {
        empresa: filtro.empresa,
        serie: filtro.serie,
        emissaoDe: moment(filtro.emissaoDe).format('YYYY-MM-DD'),
        emissaoAte: moment(filtro.emissaoAte).format('YYYY-MM-DD')
      }
    });

    return this.http.get<Nota[]>(`${this.notasUrl}?lancamentoCaixa`, {params}).toPromise();
  }

  buscarNotasAbertasNoCaixaPorSerie(filtro: NotaFiltro): Promise<any> {
    const params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString(),
        empresa: filtro.empresa,
        serie: filtro.serie,
        emissaoDe: moment(filtro.emissaoDe).format('YYYY-MM-DD'),
        cliente: filtro.cliente
      }
    });

    return this.http
      .get<any>(`${this.notasUrl}?abertasCaixa`, {params})
      .toPromise()
      .then(response => {
        const notas = response.content;

        const resultado = {
          notas,
          total: response.totalElements
        };

        return resultado;
      });
  }

  gerarDocumentoFiscal(empresa: string, documentos: string, serie: string, usuario: string): Promise<Nota> {
    return this.http
      .put<Nota>(`${this.notasUrl}/${empresa}/${documentos}/${serie}/${usuario}`, '')
      .toPromise()
      .then(response => {
        const nota = response;

        this.converterStringsParaDatas([nota]);

        return nota;
      });
  }

  impressaoCautelaCancelamentoNota(notaMsg: NotaMsg): Promise<Blob> {
    return this.http.get<Blob>(`${this.notasMsgUrl}/impressaoCautelaCancelamentoNota/${notaMsg.id}`,
      {responseType: 'blob' as 'json'}).toPromise();
  }

  buscarNotasFaturar(empresa: string, cliente: string, vendedor: string, natOper: string, formaPg: string, documentos: string) {
    return this.http.get<any>(`${this.notasUrl}/notasAfaturar/${empresa}/${cliente}/${vendedor}/${natOper}/${formaPg}/${documentos}`)
      .toPromise();
  }

  buscarNotasPorDocumento(filtro: Nota): Promise<Nota> {
    const params = new HttpParams({
      fromObject: {

        serie: filtro.serie,
        empresa: filtro.empresa,
        documento: filtro.documento

      }
    });

    return this.http.get<Nota>(`${this.notasUrl}?documentoParaCancelamento`, {params}).toPromise();
  }

  cancelar(nota: Nota): Promise<Nota> {
    return this.http
      .put<Nota>(`${this.notasUrl}/cancelar/${nota.id}`, nota)
      .toPromise();
  }

  buscarPorDocumentoSerie(documento: string, serie: string): Promise<Nota> {
    this.notasUrl = `${environment.apiUrl}/notas`;

    const params = new HttpParams({
      fromObject: {
        documento,
        serie
      }
    });

    return this.http
      .get<Nota>(`${this.notasUrl}/buscarDocumento/documento/serie`, {params})
      .toPromise();
  }


  pesquisarVariosFiltros(filtro: NotaFiltro): Promise<any> {

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    if (filtro.empresa) {
      params = params.append('empresa', filtro.empresa);
    }

    if (filtro.cliente) {
      params = params.append('cliente', filtro.cliente);
    }

    if (filtro.documento) {
      params = params.append('documento', filtro.documento);
    }

    if (filtro.emissaoDe) {
      params = params.append(
        'emissaoDe',
        moment(filtro.emissaoDe).format('YYYY-MM-DD')
      );
    }

    if (filtro.emissaoAte) {
      params = params.append(
        'emissaoAte',
        moment(filtro.emissaoAte).format('YYYY-MM-DD')
      );
    }

    if (filtro.codigoVendedor) {
      params = params.append(
        'codigoVendedor', filtro.codigoVendedor
      );
    }

    params = params.append(
      'serie', filtro.serie
    );

    this.notasUrl = `${environment.apiUrl}/notas?filtro`;

    return this.http
      .get<any>(`${this.notasUrl}`, {params})
      .toPromise()
      .then(response => {
        const notas = response.content;

        const resultado = {
          notas,
          total: response.totalElements
        };

        return resultado;
      });

  }

  listarPorPeriodoSemPagamentoAntecipado(empresa: string, cliente: string, de: Date, ate: Date): Promise<Array<Nota>> {
    let params = new HttpParams({
      fromObject: {}
    });

    if (empresa) {
      params = params.append(
        'empresa', empresa
      );
    }

    if (cliente) {
      params = params.append(
        'codigo', cliente
      );
    }

    if (de) {
      params = params.append(
        'dataDe',
        moment(de).format('YYYY-MM-DD 00:00')
      );
    }

    if (ate) {
      params = params.append(
        'dataAte',
        moment(ate).format('YYYY-MM-DD 23:59')
      );
    }

    return this.http
      .get<Array<Nota>>(`${this.notasUrl}/listarPorPeriodoSemPagamentoAntecipado/empresa/codigo/dataDe/dataAte`,
        {params})
      .toPromise()
  }


  pesquisarControleNota(controleNota: ControleNota): Promise<Array<Nota>> {
    return this.http.put<Array<Nota>>(`${this.notasUrl}/consultarControleNota`, controleNota).toPromise();
  }

  listarDocumentosPorClienteEmpresa(empresa: string, cliente: string): Promise<Array<Nota>> {
    let params = new HttpParams({
      fromObject: {}
    });

    if (empresa) {
      params = params.append(
        'empresa', empresa
      );
    }

    if (cliente) {
      params = params.append(
        'codigo', cliente
      );
    }

    return this.http
      .get<Array<Nota>>(`${this.notasUrl}/listarPorDocumentoClienteEmpresa/empresa/codigo`,
        {params})
      .toPromise()
  }


  vincularNotas(vincularNotas: VincularNotas): Promise<Array<Nota>> {
    return this.http.put<Array<Nota>>(`${this.notasUrl}/vincularNotas`, vincularNotas).toPromise();
  }


  listarNotasPorDocumentoTitulo(empresa: string, documento: string): Promise<Array<Nota>> {
    let params = new HttpParams({
      fromObject: {}
    });

    if (empresa) {
      params = params.append(
        'empresa', empresa
      );
    }

    if (documento) {
      params = params.append(
        'documento', documento
      );
    }

    return this.http
      .get<Array<Nota>>(`${this.notasUrl}/listarNotasPorDocumentoTitulo/empresa/documento`,
        {params})
      .toPromise()
  }


  totalizarVendasPeriodo(controleNota: ControleNota): Promise<Array<TotalVendasPeriodo>> {
    return this.http.put<Array<TotalVendasPeriodo>>(`${this.notasUrl}/totalizarVendasPeriodo`, controleNota).toPromise();
  }


  salvarNotaEditada(nota: Nota): Promise<Nota> {
    return this.http.put<Nota>(`${environment.apiUrl}/notas/salvarNotaEditada`, nota).toPromise();
  }


  buscarPorDocumentoSerieNotaEditada(documento: string, serie: string): Promise<Nota> {
    this.notasUrl = `${environment.apiUrl}/notas`;

    const params = new HttpParams({
      fromObject: {
        documento,
        serie
      }
    });

    return this.http
      .get<Nota>(`${this.notasUrl}/buscarDocumentoNotaEditada/documento/serie`, {params})
      .toPromise();
  }


  finalizarNotaEditada(nota: Nota): Promise<Nota> {
    return this.http.put<Nota>(`${this.notasUrl}/finalizarNotaEditada`, nota).toPromise();
  }


  buscarPorDocumentoParaTroca(documento: string, serie: string, motivo: string, usuario: string): Promise<Nota> {
    this.notasUrl = `${environment.apiUrl}/notas`;

    const params = new HttpParams({
      fromObject: {
        documento,
        serie,
        motivo,
        usuario
      }
    });

    return this.http
      .get<Nota>(`${this.notasUrl}/buscarDocumentoParaTroca/documento/serie/motivo/usuario`, {params})
      .toPromise();
  }

  impressaoTroca(documento: string, serie: string): Promise<Blob> {
    this.notasUrl = `${environment.apiUrl}/notas`;
    return this.http.get<Blob>(`${this.notasUrl }/impressaoTroca/${documento}/${serie}`
      , {responseType: 'blob' as 'json'}).toPromise();
  }

  concluirTroca(troca: Troca): Promise<Nota> {
    return this.http.put<Nota>(`${this.notasUrl}/concluirTroca`, troca).toPromise();
  }

}





