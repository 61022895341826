import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import "rxjs/add/operator/toPromise";

import { environment } from "../../environments/environment";
import { NcmSh } from "../core/models/NcmSh";
import { MoneyHttp } from "app/seguranca/money-http";

export class NcmShFiltro {
  codigoNcm: string;
  descricao: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class NcmShService {

  ncmShsUrl: string;

  constructor(private http: MoneyHttp) {
    this.ncmShsUrl = `${environment.apiUrl}/ncmShs`;
  }

  pesquisar(filtro: NcmShFiltro): Promise<any> {

    let  ncmShsUrlPesquisar: string = `${this. ncmShsUrl}/filter/codigo`;

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    if (filtro.codigoNcm) {

      params = params.append("codigoNcm", filtro.codigoNcm);
      
    } else if (filtro.descricao) {

      params = params.append("descricao", filtro.descricao);
      ncmShsUrlPesquisar = `${this.ncmShsUrl}/filter/descricao`;

    }
    return this.http
    .get<any>(ncmShsUrlPesquisar, { params })
    .toPromise()
    .then(response => {
      const ncmShs = response.content;

      const resultado = {
        ncmShs,
        total: response.totalElements
      };

      return resultado;
    });
}

  listarTodas(): Promise<any> {
    return this.http
      .get<any>(`${this.ncmShsUrl}/lista`)
      .toPromise()
      .then(response => response.content);
  }

  adicionar(NcmSh: NcmSh): Promise<NcmSh> {
    return this.http.post<NcmSh>(this.ncmShsUrl, NcmSh).toPromise();
  }

  atualizar(NcmSh: NcmSh): Promise<NcmSh> {
    return this.http
      .put<NcmSh>(`${this.ncmShsUrl}/${NcmSh.id}`, NcmSh)
      .toPromise();
  }

  buscarPorId(id: number): Promise<NcmSh> {
    return this.http.get<NcmSh>(`${this.ncmShsUrl}/${id}`).toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.ncmShsUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }

}
