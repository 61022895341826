import { AppBreadcrumbComponent } from './../app.breadcrumb.component';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import 'rxjs/add/operator/toPromise';

import { environment } from './../../environments/environment';
import { Caixa } from './../core/models/Caixa';
import { Caixa3 } from './../core/models/Caixa3';
import { FluxoCaixa } from './../core/models/FluxoCaixa';
import { MoneyHttp } from 'app/seguranca/money-http';
import { FluxoCaixaControle } from 'app/core/models/FluxoCaixaControle';
import * as moment from 'moment';
import { Cr1Carta } from 'app/core/models/Cr1Carta';
import {ControleFluxoCaixa} from '../fluxoCaixa/fluxoCaixa.service';
import {TotalCaixa} from '../core/models/TotalCaixa';
import {Empresa} from '../core/models/Empresa';
import {Cliente} from '../core/models/Cliente';
import {Cartao} from '../core/models/Cartao';

export class CaixaFiltro {
  empresa: string;
  usuario: string;
  descricao: string;
  letraCaixa: string;
  objetivoCaixa: string;
  grupo: string;
  pagina = 0;
  itensPorPagina = 100;
  emissaoDe: Date;
  emissaoAte: Date;

}

export class FluxoCaixaFiltro {
  idCaixa: string;
  empresa: string;
  caixa: string;
  emissaoDe: Date;
  emissaoAte: Date;
  serie: string;
  documento: string;
  pesquisa: string;
  observacao: string;
  pagina = 0;
  itensPorPagina = 20;
}

export class Cr1CartaFiltro {
  empresa: string;
  caixa: string;
  emissaoDe: Date;
  emissaoAte: Date;
  serie: string;
  pagina = 0;
  itensPorPagina = 20;
}

export class FluxoCaixaControleFiltro {
  empresa: string;
  grupo: string;
  caixas: Array<Caixa>;
  emissaoDe: Date;
  emissaoAte: Date;
  observacao1: string;
  observacao2: string;
  tipoOrdenacao: string;
  excetoCaixas: string;

}

@Injectable()
export class CaixaService {

  caixas: Array<Caixa>;

  caixasUrl: string;
  caixasUsuarioUrl: string;
  fluxoCaixaControleUrl: string;
  fluxoCaixaUrl: string;
  caixaHistoricosUrl: string;
  cr1CartaUrl: string;
  totalCaixasUrl:string;

  constructor(private http: MoneyHttp) {
    this.caixasUrl = `${environment.apiUrl}/caixas`;
    this.caixasUsuarioUrl = `${environment.apiUrl}/caixa-usuarios`;
    this.fluxoCaixaControleUrl = `${environment.apiUrl}/fluxosCaixasControles`;
    this.caixaHistoricosUrl = `${environment.apiUrl}/caixa-historicos`;
    this.cr1CartaUrl = `${environment.apiUrl}/cr1Cartas`;
    this.totalCaixasUrl = `${environment.apiUrl}/totaisCaixas`;
  }

  pesquisar(filtro: CaixaFiltro): Promise<any> {

    let caixasUrlPesquisar = `${this.caixasUrl}/filter/descricao`;

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    if (filtro.descricao) {

      params = params.append('descricao', filtro.descricao);

    } else if (filtro.letraCaixa) {

      params = params.append('letraCaixa', filtro.letraCaixa);
      caixasUrlPesquisar = `${this.caixasUrl}/filter/letraCaixa`;

    }

    return this.http
    .get<any>(caixasUrlPesquisar, { params })
    .toPromise()
    .then(response => {
      const caixas = response.content;

      const resultado = {
        caixas,
        total: response.totalElements
      };

      return resultado;
    });
}

caixaPorGrupo(filtro: CaixaFiltro): Promise<any> {

  const caixasUrl = `${this.caixasUrl}/filter/empresa/grupo`;

  let params = new HttpParams({
    fromObject: {
      page: filtro.pagina.toString(),
      size: filtro.itensPorPagina.toString()
    }
  });
  params = params.append('empresa', filtro.empresa);
  params = params.append('grupo', filtro.grupo);
  return this.http
  .get<any>(caixasUrl, { params})
  .toPromise()
  .then(response => {
    const caixas = response.content;
    const resultado = {
      caixas,
      total: response.totalElements
    };
    return resultado;
  });
}

objetivo(filtro: CaixaFiltro): Promise<any> {

  const caixasUrlObjetivo = `${this.caixasUrl}/objetivo/objetivoCaixa/letraCaixa`;

  let params = new HttpParams({
    fromObject: {
      page: filtro.pagina.toString(),
      size: filtro.itensPorPagina.toString()
    }
  });
  params = params.append('objetivoCaixa', filtro.objetivoCaixa);
  params = params.append('letraCaixa', filtro.letraCaixa);
  return this.http
  .get<any>(caixasUrlObjetivo, { params})
  .toPromise()
  .then(response => {
    const objetivo = response.content;
    const resultado = {
      objetivo,
      total: response.totalElements
    };
    return resultado;
  });
}

  listarTodas(): Promise<any> {
    return this.http
      .get<any>(`${this.caixasUrl}/lista`)
      .toPromise()
      .then(response => response.content);
  }

  adicionar(caixa: Caixa): Promise<Caixa> {
    return this.http.post<Caixa>(this.caixasUrl, caixa).toPromise();
  }

  atualizar(caixa: Caixa): Promise<Caixa> {
    return this.http
      .put<Caixa>(`${this.caixasUrl}/${caixa.id}`, Caixa)
      .toPromise();
  }

  buscarPorId(id: number): Promise<Caixa> {
    return this.http.get<Caixa>(`${this.caixasUrl}/${id}`).toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.caixasUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }

  listarCaixasPorEmpresaUsuario(filtro: CaixaFiltro) {

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    const caixasUrlPesquisar = `${this.caixasUsuarioUrl}/filter/empresa/usuario`;

    params = params.append('empresa', filtro.empresa);
    params = params.append('usuario', filtro.usuario);

    return this.http
    .get<any>(caixasUrlPesquisar, { params })
    .toPromise()
    .then(response => {
      const usuarioCaixas = response.content;

      const resultado = {
        usuarioCaixas,
        total: response.totalElements
      };

      return resultado;
    });

  }

  adicionarFluxoCaixaControle(fluxoCaixaControle: FluxoCaixaControle): Promise<FluxoCaixaControle> {
    return this.http.post<FluxoCaixaControle>(this.fluxoCaixaControleUrl, fluxoCaixaControle).toPromise();
  }

  abrirCaixaFluxoControle(fluxoCaixaControle: FluxoCaixaControle): Promise<FluxoCaixaControle> {
    return this.http.post<FluxoCaixaControle>(`${this.fluxoCaixaControleUrl}/abrirCaixa`, fluxoCaixaControle).toPromise();
  }

  adicionarFluxoCaixa(fluxoCaixa: FluxoCaixa): Promise<FluxoCaixa> {
    return this.http.post<FluxoCaixa>(`${this.fluxoCaixaUrl}`, fluxoCaixa).toPromise();
  }


  listarFluxoCaixaControle(): Promise<FluxoCaixaControle[]> {
    return this.http.get<FluxoCaixaControle[]>(`${this.fluxoCaixaControleUrl}/pendentesAbertos`).toPromise();
  }

  adicionarHistoricoEntradasSaidas(historico: Caixa3): Promise<Caixa3> {
    return this.http.post<Caixa3>(this.caixaHistoricosUrl, historico).toPromise();
  }

  listarHistoricoEntradasSaidas(letra: string): Promise<Array<Caixa3>> {
    const dt1 = moment(new Date(), 'YYYY-MM-DD', true).format();

    const params = new HttpParams({
      fromObject: {
        letraCaixa: letra,
        dataHora: dt1
      }
    });

    return this.http.get<Array<Caixa3>>(`${this.caixaHistoricosUrl}?entrada-saida`, {params}).toPromise();
  }


  impressaoResumoCaixa(filtro: FluxoCaixaControleFiltro): Promise<Blob> {

    let caixas = '';
    for (const caixa of filtro.caixas) {
      caixas = caixas.concat("|"+caixa.letraCaixa);
    }

    let params = new HttpParams({});
    params = params.append('empresa', filtro.empresa);
    params = params.append('grupo', filtro.grupo);
    params = params.append('caixas', caixas);
    params = params.append('emissaoDe', moment(filtro.emissaoDe).format('DD-MM-YYYY 00:00:00'));
    params = params.append('emissaoAte', moment(filtro.emissaoAte).format('DD-MM-YYYY 00:00:00'));
    params = params.append('observacao1', filtro.observacao1);
    params = params.append('observacao2', filtro.observacao2);

    const options = {
      params,
      responseType: 'blob' as 'json'
    }

    return this.http.get<Blob>(`${this.fluxoCaixaControleUrl}/resumoCaixa`,
      options).toPromise();

  }

  impressaoCr1Carta(filtro: FluxoCaixaControleFiltro): Promise<Blob> {

    this.cr1CartaUrl = `${environment.apiUrl}/cr1Cartas`;

    let caixas = '';
    for (const caixa of filtro.caixas) {
      caixas = caixas.concat("|"+caixa.letraCaixa);
    }

    let params = new HttpParams({});
    params = params.append('empresa', filtro.empresa);
    params = params.append('grupo', filtro.grupo);
    params = params.append('caixas', caixas);
    params = params.append('emissaoDe', moment(filtro.emissaoDe).format('DD-MM-YYYY 00:00:00'));
    params = params.append('emissaoAte', moment(filtro.emissaoAte).format('DD-MM-YYYY 23:59:00'));

    const options = {
      params,
      responseType: 'blob' as 'json'
    }

    return this.http.get<Blob>(`${this.cr1CartaUrl}/impressaoCartao`,
      options).toPromise();

  }

  pesquisarCaixaPorGrupo(filtro: FluxoCaixaControleFiltro): Promise<any> {

    const caixasUrl = `${this.caixasUrl}/caixasPorGrupo/empresa/grupo`;

    let params = new HttpParams({
      fromObject: {
      }
    });

    params = params.append('empresa', filtro.empresa);
    params = params.append('grupo', filtro.grupo);
    return this.http
      .get<any>(caixasUrl, { params})
      .toPromise()
  }

  pesquisarMensagemTotalCaixa(filtro: FluxoCaixaControleFiltro): Promise<TotalCaixa> {

    let params = new HttpParams({});
    params = params.append('empresa', filtro.empresa);
    params = params.append('dia', moment(new Date()).format('DD-MM-YYYY 00:00:00'));
    params = params.append('grupo', filtro.grupo);

    return this.http
      .get<TotalCaixa>(`${this.totalCaixasUrl}/porEmpresaCaixa/empresa/dia/grupo`, { params})
      .toPromise()
  }


  buscarPorLetraCaixa(letra: string, empresa: string): Promise<Caixa> {

    const caixasUrl = `${this.caixasUrl}/letraCaixa/empresa/codigo`;

    let params = new HttpParams({
      fromObject: {
      }
    });

    params = params.append('empresa', empresa);
    params = params.append('codigo', letra);

    return this.http
      .get<Caixa>(caixasUrl, { params})
      .toPromise()
  }


  listaConferenciaCaixa(filtro: FluxoCaixaControleFiltro): Promise<Array<FluxoCaixaControle>> {

    let caixas = '';
    for (const caixa of filtro.caixas) {
      caixas = caixas.concat("|"+caixa.letraCaixa);
    }

    let params = new HttpParams({});
    params = params.append('empresa', filtro.empresa);
    params = params.append('grupo', filtro.grupo);
    params = params.append('caixas', caixas);
    params = params.append('emissaoDe', moment(filtro.emissaoDe).format('DD-MM-YYYY 00:00:00'));
    params = params.append('emissaoAte', moment(filtro.emissaoAte).format('DD-MM-YYYY 00:00:00'));
    params = params.append('tipoOrdenacao', filtro.tipoOrdenacao);
    params = params.append('excetoCaixas', filtro.excetoCaixas);

    const options = {
      params
    }

    return this.http.get<Array<FluxoCaixaControle>>(`${this.fluxoCaixaControleUrl}/conferenciaCaixa`,
      options).toPromise();

  }


}


