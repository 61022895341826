import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import "rxjs/add/operator/toPromise";

import { environment } from "./../../environments/environment";
import { MoneyHttp } from "app/seguranca/money-http";

import { Funcionario } from "./../core/models/Funcionario";

export class FuncionarioFiltro {
  nome: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class FuncionarioService {
  funcionariosUrl: string;

  constructor(private http: MoneyHttp) {
    this.funcionariosUrl = `${environment.apiUrl}/funcionarios`;
  }

  pesquisar(filtro: FuncionarioFiltro): Promise<any> {
    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });
    if (filtro.nome) {
      params = params.append("nome", filtro.nome);
    }
    return this.http
      .get<any>(`${this.funcionariosUrl}`, { params })
      .toPromise()
      .then(response => {
        const funcionarios = response.content;

        const resultado = {
          funcionarios,
          total: response.totalElements
        };

        return resultado;
      });
  }

  listarTodas(): Promise<any> {
    return this.http
      .get<any>(`${this.funcionariosUrl}/lista`)
      .toPromise()
      .then(response => response.content);
  }

  adicionar(funcionario: Funcionario): Promise<Funcionario> {
    return this.http
      .post<Funcionario>(this.funcionariosUrl, funcionario)
      .toPromise();
  }

  atualizar(funcionario: Funcionario): Promise<Funcionario> {
    return this.http
      .put<Funcionario>(
        `${this.funcionariosUrl}/${funcionario.id}`,
        funcionario
      )
      .toPromise();
  }

  buscarPorId(id: number): Promise<Funcionario> {
    return this.http
      .get<Funcionario>(`${this.funcionariosUrl}/${id}`)
      .toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.funcionariosUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }
}
