import { Injectable } from '@angular/core';

import 'rxjs/add/operator/toPromise';

import { HttpParams } from '@angular/common/http';
import { MoneyHttp } from 'app/seguranca/money-http';

import { environment } from './../../../environments/environment';

import { PreVendaItem } from './../../core/models/PreVendaItem';

export class PreVendaItemFiltro {
  empresa: string;
  sequencia: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class PreVendaItemService {

  preVendaItemUrl: string;

  constructor(private http: MoneyHttp) {
    this.preVendaItemUrl = `${environment.apiUrl}/preVenda2`;
  }

  pesquisar(filtro: PreVendaItemFiltro): Promise<any> {
    let preVendaItemFiltroUrl: string = this.preVendaItemUrl;
    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });
    if (filtro.sequencia && filtro.empresa) {
      params = params.append('empresa', filtro.empresa);
      params = params.append('sequencia', filtro.sequencia);
      preVendaItemFiltroUrl = `${this.preVendaItemUrl}/filter/empresa/sequencia`;
    }
    return this.http
      .get<any>(preVendaItemFiltroUrl, { params })
      .toPromise()
      .then(response => {
        const preVendaItens = response.content;

        const resultado = {
          preVendaItens,
          total: response.totalElements
        };

        return resultado;
      });
  }

  listarTodas(): Promise<any> {
    return this.http
      .get<any>(this.preVendaItemUrl)
      .toPromise()
      .then(response => response.content);
  }

  adicionar(preVendaItem: PreVendaItem): Promise<PreVendaItem> {
    return this.http.post<PreVendaItem>(this.preVendaItemUrl, preVendaItem).toPromise();
  }

  atualizar(preVendaItem: PreVendaItem): Promise<PreVendaItem> {
    return this.http
      .put<PreVendaItem>(`${this.preVendaItemUrl}/${preVendaItem.id}`, preVendaItem)
      .toPromise();
  }

  buscarPorId(id: number): Promise<PreVendaItem> {
    return this.http.get<PreVendaItem>(`${this.preVendaItemUrl}/${id}`).toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.preVendaItemUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }

}
