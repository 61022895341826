import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import "rxjs/add/operator/toPromise";

import { environment } from "./../../environments/environment";
import { MoneyHttp } from "app/seguranca/money-http";

import { Transportadora } from "app/core/models/Transportadora";
import { TransportadoraCadastro } from "app/core/models/TransportadoraCadastro";

export class TransportadoraFiltro {
  nome: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class TransportadoraService {
  transportadorasUrl: string;

  constructor(private http: MoneyHttp) {
    this.transportadorasUrl = `${environment.apiUrl}/transportadoras`;
  }
  pesquisar(filtro: TransportadoraFiltro): Promise<any> {
    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });
    if (filtro.nome) {
      params = params.append("nome", filtro.nome);
    }
    return this.http
      .get<any>(`${this.transportadorasUrl}`, { params })
      .toPromise()
      .then(response => {
        const transportadoras = response.content;

        const resultado = {
          transportadoras,
          total: response.totalElements
        };

        return resultado;
      });
  }

  listarTodas(): Promise<any> {
    return this.http
      .get<any>(`${this.transportadorasUrl}/lista`)
      .toPromise()
      .then(response => response.content);
  }

  listarTodasPorNome(nome: string): Promise<Array<Transportadora>> {
    return this.http
      .get<Array<Transportadora>>(`${this.transportadorasUrl}/lista/${nome}`)
      .toPromise()
      .then(response => response);
  }

  adicionar(transportadora: Transportadora): Promise<Transportadora> {
    return this.http
      .post<Transportadora>(this.transportadorasUrl, transportadora)
      .toPromise();
  }

  atualizar(transportadora: Transportadora): Promise<Transportadora> {
    return this.http
      .put<Transportadora>(
        `${this.transportadorasUrl}/${transportadora.id}`,
        transportadora
      )
      .toPromise();
  }

  buscarPorId(id: number): Promise<Transportadora> {
    return this.http
      .get<Transportadora>(`${this.transportadorasUrl}/${id}`)
      .toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.transportadorasUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }
}
