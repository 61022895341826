import { HttpParams, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

import "rxjs/add/operator/toPromise";

import { environment } from "./../../environments/environment";
import { Fabricante } from "./../core/models/Fabricante";
import { MoneyHttp } from "app/seguranca/money-http";

export class FabricanteFiltro {
  codigo: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class FabricanteService {
  fabricantesUrl: string;

  constructor(private http: MoneyHttp) {
    this.fabricantesUrl = `${environment.apiUrl}/fabricantes`;
  }

  pesquisar(filtro: FabricanteFiltro): Promise<any> {
    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });
    if (filtro.codigo) {
      params = params.append("codigo", filtro.codigo);
    }
    return this.http
      .get<any>(`${this.fabricantesUrl}`, { params })
      .toPromise()
      .then(response => {
        const fabricantes = response.content;

        const resultado = {
          fabricantes,
          total: response.totalElements
        };

        return resultado;
      });
  }

  listarTodas(): Promise<any> {
    return this.http
      .get<any>(this.fabricantesUrl)
      .toPromise()
      .then(response => response.content);
  }

  adicionar(fabricante: Fabricante): Promise<Fabricante> {
    return this.http.post<Fabricante>(this.fabricantesUrl, fabricante).toPromise();
  }

  atualizar(fabricante: Fabricante): Promise<Fabricante> {
    return this.http
      .put<Fabricante>(`${this.fabricantesUrl}/${fabricante.id}`, fabricante)
      .toPromise();
  }

  buscarPorId(id: number): Promise<Fabricante> {
    return this.http.get<Fabricante>(`${this.fabricantesUrl}/${id}`).toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.fabricantesUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }
}
