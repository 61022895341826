import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import "rxjs/add/operator/toPromise";

import { environment } from "./../../environments/environment";
import { Permissao } from "./../core/models/Permissao";
import { MoneyHttp } from "app/seguranca/money-http";

export class PermissaoFiltro {
  codigo: number;
  descricao: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class PermissaoService {
  permissoesUrl: string;

  constructor(private http: MoneyHttp) {
    this.permissoesUrl = `${environment.apiUrl}/permissoes`;
  }

  pesquisar(filtro: PermissaoFiltro): Promise<any> {
    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });
    if (filtro.descricao) {
      params = params.append("descricao", filtro.descricao);
    }
    return this.http
      .get<any>(`${this.permissoesUrl}`, { params })
      .toPromise()
      .then(response => {
        const permissoes = response.content;

        const resultado = {
          permissoes,
          total: response.totalElements
        };

        return resultado;
      });
  }

  listarTodas(): Promise<Permissao[]> {
    return this.http
      .get<Permissao[]>(`${this.permissoesUrl}/lista`)
      .toPromise();
  }

  adicionar(permissao: Permissao): Promise<Permissao> {
    return this.http.post<Permissao>(this.permissoesUrl, permissao).toPromise();
  }

  atualizar(permissao: Permissao): Promise<Permissao> {
    return this.http
      .put<Permissao>(`${this.permissoesUrl}/${permissao.codigo}`, permissao)
      .toPromise();
  }

  buscarPorCodigo(codigo: number): Promise<Permissao> {
    return this.http.get<Permissao>(`${this.permissoesUrl}/${codigo}`).toPromise();
  }

  excluir(codigo: number): Promise<void> {
    return this.http
      .delete(`${this.permissoesUrl}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  buscarPeloNome(descricao: string): Promise<Permissao> {
    return this.http.get<Permissao>(`${this.permissoesUrl}/buscarPelaDescricao/${descricao}`).toPromise();
  }


}
