import {Injectable} from '@angular/core';
import {MoneyHttp} from '../seguranca/money-http';
import {environment} from '../../environments/environment';

import {Marca} from '../core/models/Marca';
import {Balanco} from '../core/models/Balanco';
import {HttpParams} from '@angular/common/http';

export class BalancoFiltro {
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class BalancoService {

  balancoURL: string;

  constructor(private http: MoneyHttp) {
    this.balancoURL = `${environment.apiUrl}/balanco`;
  }

  gerarBalancoPorMarca(marca: Marca): Promise<Balanco> {
    return this.http.post<Balanco>(this.balancoURL, marca).toPromise();
  }

  pesquisar(filtro: BalancoFiltro): Promise<any> {
    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    return this.http
      .get<any>(`${this.balancoURL}/listaUltimosAbertos`, {params})
      .toPromise()
      .then(response => {
        const balancos = response.content;
        const resultado = {
          balancos,
          total: response.totalElements
        };
        return resultado;
      });

  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.balancoURL}/${id}`)
      .toPromise()
      .then(() => null);
  }

  buscarPorId(id: number): Promise<Balanco> {
    return this.http.get<Balanco>(`${this.balancoURL}/${id}`).toPromise();
  }

  impressao(balanco: Balanco): Promise<Blob> {
    return this.http.get<Blob>(`${this.balancoURL}/impressao/${balanco.id}`, {responseType: 'blob' as 'json'}).toPromise();
  }

  gerarLoteAtualizarEstoque(id: number): Promise<Balanco> {
    return this.http.get<Balanco>(`${this.balancoURL}/gerarLote/${id}`).toPromise();
  }

}
