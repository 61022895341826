import {Injectable} from '@angular/core';
import {MoneyHttp} from '../seguranca/money-http';
import {environment} from '../../environments/environment';
import {ConferenciaProduto} from '../core/models/ConferenciaProduto';

@Injectable()
export class ConferenciaProdutoService {

  url: string;

  constructor(private http: MoneyHttp) {
    this.url = `${environment.apiUrl}/conferenciaProduto`;
  }

  obterListaPorConferenciaOrdenadoPorDataDesc(idConferencia: number) {
    return this.http
      .get<Array<ConferenciaProduto>>(`${this.url}/${idConferencia}`)
      .toPromise()
      .then(response => {
        return response;
      });
  }

}
