import {Injectable} from '@angular/core';

import 'rxjs/add/operator/toPromise';

import {environment} from './../../environments/environment';
import {MoneyHttp} from 'app/seguranca/money-http';
import {PagamentoAntecipadoNotaFilial} from '../core/models/PagamentoAntecipadoNotaFilial';


@Injectable()
export class PagamentoAntecipadoNotaFilialService {

  pagamentosAntecipadosNotasFilialUrl: string;

  constructor(private http: MoneyHttp) {
    this.pagamentosAntecipadosNotasFilialUrl = `${environment.apiUrl}/pagamentosAntecipadosNotasFilial`;
  }


  adicionar(pagamentoAntecipadoNotaFilial: PagamentoAntecipadoNotaFilial): Promise<PagamentoAntecipadoNotaFilial> {
    return this.http.post<PagamentoAntecipadoNotaFilial>(this.pagamentosAntecipadosNotasFilialUrl, pagamentoAntecipadoNotaFilial)
      .toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.pagamentosAntecipadosNotasFilialUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }

}
