import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import "rxjs/add/operator/toPromise";

import { environment } from "./../../environments/environment";
import { MoneyHttp } from "app/seguranca/money-http";

import { Veiculo } from "./../core/models/Veiculo";
import { VeiculoCadastro } from "app/core/models/VeiculoCadastro";

export class VeiculoFiltro {
  descricao: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class VeiculoService {
  veiculosUrl: string;

  constructor(private http: MoneyHttp) {
    this.veiculosUrl = `${environment.apiUrl}/veiculos`;
  }

  pesquisar(filtro: VeiculoFiltro): Promise<any> {
    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });
    if (filtro.descricao) {
      params = params.append("descricao", filtro.descricao);
    }
    return this.http
      .get<any>(`${this.veiculosUrl}`, { params })
      .toPromise()
      .then(response => {
        const veiculos = response.content;

        const resultado = {
          veiculos,
          total: response.totalElements
        };

        return resultado;
      });
  }

  listarTodas(): Promise<any> {
    return this.http
      .get<any>(`${this.veiculosUrl}/lista`)
      .toPromise()
      .then(response => response.content);
  }

  adicionar(veiculoCadastro: VeiculoCadastro): Promise<Veiculo> {
    return this.http.post<Veiculo>(this.veiculosUrl, veiculoCadastro).toPromise();
  }

  atualizar(Veiculo: Veiculo): Promise<Veiculo> {
    return this.http
      .put<Veiculo>(`${this.veiculosUrl}/${Veiculo.id}`, Veiculo)
      .toPromise();
  }

  buscarPorId(id: number): Promise<Veiculo> {
    return this.http.get<Veiculo>(`${this.veiculosUrl}/${id}`).toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.veiculosUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }
}
