import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  OnDestroy,
  ElementRef,
  Renderer,
  ViewChild
} from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';
import { Location } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { MenuItem, ScrollPanel } from 'primeng/primeng';
import { AppComponent } from './app.component';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
  styleUrls: ['./app.menu.component.css'],
  preserveWhitespaces: true
})
export class AppMenuComponent implements OnInit, AfterViewInit {

  @Input() reset: boolean;

  model: any[];

  @ViewChild('scrollPanel') layoutMenuScrollerViewChild: ScrollPanel;

  constructor(public app: AppComponent) {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.layoutMenuScrollerViewChild.moveBar();
    }, 100);
  }

  ngOnInit() {
    this.model = [
      { label: 'Dashboard', icon: 'dashboard', routerLink: ['/'] },
      {
        label: '1-Cadastro', icon: 'unarchive',
        items: [
          {
            label: '1.1-Clientes', icon: 'group',
            items: [
              {
                label: '1.1.1-Cadastro/listagens', routerLink: ['/clientes'],
                icon: 'list'
              },
              { label: '1.1.2-Alterar Código de Cliente', routerLink: ['/alterarCodigoCliente'],
                icon: 'loop'
              },
              {
                label: '1.1.3-Dívidas de Cliente', routerLink: ['/preVenda4'],
                icon: 'drafts'
              },
            ]
          },
          {
          label: '1.2-Produtos', icon: 'info',
          items: [

          {
            label: '1.2.1-Cadastro Produtos', routerLink: ['/produtos'],
            icon: 'store'
          },
          {
            label: '1.2.2-Cadastro e Pesquisa Código Barra', routerLink: ['/produtosCodigosBarras'],
            icon: 'line_weight'
          },
        ]
      },

          {
            label: '1.3-Fornecedores',
            routerLink: ['/fornecedores'],
            icon: 'shuffle'
          },
          {
            label: '1.4-Marcas',
            routerLink: ['/marcas'],
            icon: 'folder'
          },
          {
            label: '1.5-Códigos Fiscais CFOP',
            routerLink: ['/codigosFiscais'],
            icon: 'attachment'
          },
          {
            label: '1.6-Vendedores',
            routerLink: ['/vendedores'],
            icon: 'wc'
          },
          {
            label: '1.7-Transportadoras',
            routerLink: ['/transportadoras'],
            icon: 'subway'
          },

          {
            label: '1.8-Sistemas', icon: 'power',
            items: [

              {
                label: '1.8.3-Caixas',
                routerLink: ['/caixas'],
              },
              {
                label: '1.8.5-Cartões',
                routerLink: ['/cartoes'],
              },
              {
                label: '1.8.6-Empresa',
                routerLink: ['/empresas'],
                icon: 'business'
              },
              { label: '1.8.4-Série/Tipo de documento' },
              {
                label: '1.8.9-Parametro',
                routerLink: ['/parametros'],
                icon: 'phonelink'
              },
              {
                label: '1.8.11-Controle de Liberação',
                routerLink: ['/liberacoes'],
                icon: 'block'
              },
              { label: '1.8.12-Boletos Bancários' },

              {
                label: '1.8.13-Alterar Minha Senha',
                routerLink: ['/usuarios/senha'],
                icon: 'info'
              },

              { label: '1.8.16-Lucro Progressivo' },

              {
                label: '1.8.19-Forma de Pagamento',
                routerLink: ['/formasPagamentos'],
                icon: 'payment'
              },

              {
                label: '1.8.20-Desconto Progressivo',
                routerLink: ['/descontosProgressivos']

              },
              {
                label: '1.8.21-Comissão Progressiva',
                routerLink: ['/comissoesProgressivas']
              },


            ]

          },
          {
            label: '1.10-Diversos', icon: 'nature',
            items: [
              {
                label: '1.10.1Montadoras e Veiculos', routerLink: ['/veiculos'],
                icon: 'computer'
              },
              {
                label: '1.10.2-Converter PDF/XLS', routerLink: ['/converterPdfToExcel'],
                icon: 'loop'
              },

              {
                label: '1.10.3-Fabricantes', routerLink: ['/fabricantes'],
                icon: 'portrait'
              },

              {
                label: '1.10.6-Unidades', routerLink: ['/unidades'],
                icon: 'drafts'
              },
              {
                label: '1.10.7-CST ICMS', routerLink: ['/codigosSituacaoTributariasIcms'],
                icon: 'layers'
              },
              {
                label: '1.10.8-CST IPI', routerLink: ['/codigosSituacaoTributariasIpi'],
                icon: 'layers'
              },
              {
                label: '1.10.10-CST PIS', routerLink: ['/codigosSituacaoTributariasPis'],
                icon: 'layers'
              },
              {
                label: '1.10.11-CST CONFINS', routerLink: ['/codigosSituacaoTributariasCofins'],
                icon: 'layers'
              },
              { label: '1.10.12-CSOSN' },
              {
                label: '1.10.13-Aliquota de Mva', routerLink: ['/aliquotasMva'],
                icon: 'web'
              },
              {
                label: '1.10.16-Venda Perdida', routerLink: ['/vendasPerdidas'],
                icon: 'cloud'

              },
              {
                label: '1.10.17-Natureza Operação', routerLink: ['/naturezasOperacoes'],
                icon: 'dock'
              },

              {
                label: '1.10.19-Tabela de Produtos ANP', routerLink: ['/tabAnpProdutos'],
                icon: 'star'
              },


            ]
          },
          {
            label: '1.11-Ncm-Sh', routerLink: ['/ncmShs'],
            icon: 'grain'

          },
          {
            label: '1.13-Serviços',
            routerLink: ['/servicosVeiculos'],
            icon: 'filter'
          },

          {
            label: '1.16-Técnicos',
            routerLink: ['/tecnicos'],
            icon: 'building'
          },

          {
            label: '1.17-Banco',
            routerLink: ['/bancos'],
            icon: 'monetization_on'
          },

          {
            label: '1.20-Depto.Pessoal',
            items: [
              {
                label: '1.20.1- Funcionarios',
                routerLink: ['/funcionarios'],
                icon: 'work'
              },
            ]
          },
          {
            label: '1.21-Usuarios',
            routerLink: ['/usuarios'],
            icon: 'accessibility'
          },

          {
            label: '1.22-Perfil de Acesso',
            routerLink: ['/perfilAcesso'],
            icon: 'streetview'
          },
          {
            label: '1.23-Permissões',
            routerLink: ['/permissoes'],
            icon: 'lock'
          },
        ]
      },
      {
        label: '2-Venda', icon: 'shopping_cart',
        items: [
          {
            label: '2.1-Pré-Venda',
            routerLink: ['/preVendas'],
            icon: 'shop'
          },
          {
            label: '2.1.1-Devolução',
            routerLink: ['/devolucao'],
            icon: 'transfer_within_a_station'
          },
          {
            label: '2.1.2-Ordem Serviço', icon: 'card_membership',
            items: [
              {
                label: '2.1.3-CheckList',
                routerLink: ['/checkList'],
                icon: 'content_paste'
              },
              {
                label: '2.1.4-Orçamento',
                routerLink: ['/orcamentoOS'],
                icon: 'tap_and_play'
              },
              {
                label: '2.1.5-Controle Orçamento',
                routerLink: ['/orcamentoOS/controle'],
                icon: 'tap'
              },
            ]
          },

          {
            label: '2.2-Trocas',
            routerLink: ['/trocas/trocaCompleta'],
            icon: 'loop'
          },
          { label: '2.3-Consulta Rápida de Estoque', icon: 'timer' },

          {
            label: '2.4-Consulta Movimentação',
            routerLink: ['/movimentos'],
            icon: 'equalizer'
          },

          {
            label: '2.5-Relatórios de Vendas',
            icon: 'folder_open',
            items: [

              {
                label: '2.5.10-Comissão dos Técnicos',
                routerLink: ['/movimentos/novo'],
                icon: 'content_paste'
              },
              {
                label: '2.5.10-Comissão dos Vendedores',
                routerLink: ['/notas/comissao'],
                icon: 'content_paste'
              },
            ]
          },

          {
            label: '2.7-Substituição de N.E',
            routerLink: ['/substituirNotaEntrega'],
            icon: 'loop'
          },

          {
            label: '2.8-Reimpressão de N.E',
            routerLink: ['/preVendas/reimpressao'],
            icon: 'print'
          },

          {
            label: '2.13-Reimpressão de O.S',
            routerLink: ['/orcamentoOS/reimpresao'],
            icon: 'print'
          },
          { label: '2.14-Registro de Entrada/Saida veículos' },
          {
            label: '2.15-Controle de Entrega de Mercadoria',
            routerLink: ['/estoque/controle']

          },
          {
            label: '2.17-Controle de Cautelas/Comandas',
            routerLink: ['/produtosCautelas/novo'],
            icon: 'tablet'
          },
          { label: '2.18-Notas em Aberto por Vendedor' },
          { label: '2.19-Consulta Cautelas em Aberto por Vendedor' },
        ]
      },
      {
        label: '3-Estoque', icon: 'camera',
        items: [
          {
            label: '3.1-Alteração Geral de Códigos de Produtos',
            icon: 'input'
          },
          {
            label: '3.2-Alteração de Código Pré-programada',
            icon: 'create'
          },
          {
            label: '3.3-Operação de Entrada',
            icon: 'gavel',
            items: [
              {
                label: '3.3.1-Entrada de Produtos',
                icon: 'pi pi-fw pi-refresh',
                routerLink: ['/notaEntrada']
              },
              {
                label: '3.3.2-Manifetação do Destinatario',
                icon: 'pi pi-fw pi-user'
              },
              {
                label: '3.3.3-Conferêcia de Produtos',
                icon: 'pi pi-fw pi-book',
                routerLink: ['/conferencia']

              },
              { label: '3.3.4-Cálculo do Preço de Venda' },
              { label: '3.3.5-Reajuste do Fator de Desconto' },
              {
                label: '3.3.6-Manutenção de Código',
                icon: 'pi pi-fw pi-refresh',
                routerLink: ['/estoque/codigoBarra']

              },
            ]
          },
          {
            label: '3.4-Editor de Estoque',
            icon: 'dvr',
            items: [
              {
                label: '3.4.1-Editor Estoque/Produto',
                icon: 'colorize',
                routerLink: ['/estoque/editorEstoque']
              },
              { label: '3.4.2-Pedido Automatico 2017', icon: 'pi pi-fw pi-refresh' },
              {
                label: 'Transferência Automática entre lojas',
                icon: 'pi pi-fw pi-refresh'
              },
              {
                label: '3.4.4-Reposição',
                icon: 'comments',
                routerLink: ['/reposicao']
              },
              {
                label: '3.4.5-Produto Por Andar',
                icon: 'pi pi-fw pi-refresh',
                routerLink: ['/ordemEstoque']
              },
              {
                label: '3.4.6-Balanço',
                icon: 'more',
                items: [
                  { label: '3.4.6.1-Abertura Balanço', routerLink: ['/aberturaBalanco'] },
                  { label: '3.5.6.2-Contagem Balanço', routerLink: ['/contagemBalanco'] },
                ]
              },
            ]
          },

          {
            label: '3.5-Cálculo de Pedido e Pendência',
            icon: 'pi pi-fw pi-refresh',
          },

          {
            label: '3.6-Configurações', icon: 'settings',
            items: [
              { label: '3.6.1-Local', routerLink: ['/local'] },
              { label: '3.6.2-Configuração Local', routerLink: ['/configuracaoLocal'] },
            ]
          },

          {
            label: '3.7-Cálculo de Preço de Venda',
            icon: 'teste',
          },

          {
            label: '3.8-Reajuste Fator Desconto',
            icon: 'teste',
          },

          {
            label: '3.9-Reajuste Automatico de Custos',
            icon: 'teste',
          },

          {
            label: '3.10-Acerto de Estoque',
            icon: 'sort_alt',
            routerLink: ['/acerto/acertoEstoque']
          },

          {
            label: '3.11-Relatórios',
            icon: 'assistant',
            items: [

              { label: '3.11.2-Estoque Padrão(PEDIDO AUTOMÁTICO' },
              { label: '3.11.5-Listagem de Produtos para Confêrencia MANUAL DE ESTOQUE' },
              {
                label: '3.11.11-Relatorio de Rentabilidade por Marca/Fabricante por Periodo',
                icon: 'trending_up',
                routerLink: ['/movimentos/rentabilidade'],
              },

            ]
          },
          { label: '3.13-Fechamento Mensal de Vendas' },
          { label: '3.21-Zerar Pedido/Pendência Sugestão' },
          { label: '3.22-Alteração de Código Individual do Produto' },

          {
            label: '3.24-Códigos de Barras e Locações',
            icon: 'pi pi-fw pi-refresh',
            routerLink: ['/estoque/barraLocacao']

          },
        ]
      },
      {
        label: '4-Faturamento', icon: 'trending_up',
        items: [
          {
            label: '4.1-Emissão de Nota Fiscal',
            icon: 'devices_other',
            routerLink: ['/notas/novo']
          },
          {
            label: '4.3-Fluxo de Caixa',
            icon: 'repeat',
            routerLink: ['/fluxosCaixas']
          },
          {
            label: '4.5-Faturar Notas->DP/NP',
            icon: 'refresh',
            routerLink: ['/faturamentoNotaFiscal']
          },
          {
            label: '4.6-Cancelar Notas de Entrada e Saída/Titulos',
            icon: 'devices_other',
            routerLink: ['/notas/cancelamento']
          },
          { label: '4.9-Correção de Notas',
            icon: 'devices_other',
            routerLink: ['/notas/correcao']
          },
          { label: '4.10-Correção de Titulos' },
          {
            label: '4.20-Nota Editada',
            icon: 'devices_other',
            routerLink: ['/notas/editada']
          },

        ]
      },
      {
        label: '5-Controle', icon: 'library_books',
        items: [
          {
            label: '5.1-Controle de Caixa',
            icon: 'add_to_queue',
            routerLink: ['/caixas/controle']
          },
          { label: '5.2-Fechamento de Caixa', icon: 'close' },


          {
            label: '5.3-Controle de Notas',
            icon: 'contact_mail',
            routerLink: ['/notas/nota']
          },

          {
            label: '5.4-Controle de Titulos',
            icon: 'refresh',
            routerLink: ['/titulos']
          },

          {
            label: '5.6-Controle de Cartões de Crédito',
            icon: 'business',
            routerLink: ['/cartoes/controle']
          },

          {
            label: '5.8-Controle de Notas Fiscais Eletrônicas',
            icon: 'markunread_mailbox',
            routerLink: ['/notas']
          },


          {
            label: '5.10-Controle de Vale Créditos', incon: 'layers',
            items: [
              {
                label: '5.10.1-Controle Geral de VCs',
                icon: 'refresh',
                routerLink: ['/pagamentosAntecipados']
              },

              {
                label: '5.10.2-Controle Individual de VCs',
                icon: 'refresh',
                routerLink: ['/pagamentosAntecipados/individual']
              }
            ]
          },
          { label: '5.11-Conferência/Fechamento caixa', incon: 'layers', routerLink: ['/caixas/fechamento'] },
          { label: '5.14-Controle de Recebimentos Via Banco', incon: 'layers', routerLink: ['/bancosRecebimentos'] },
        ]
      },
      {
        label: '6-Pagar', icon: 'filter',
        items: [
          {  label: '6.1-Manutenção de Contas', 
             icon: 'book',
             routerLink: ['/contaPagas', 'novo'] 
          },
          {
            label: '6.2-Diário de Pagamento',
            icon: 'calendar-plus',
            routerLink: ['/contaPagas']
          },
          { label: '6.3-Entrada de Notas de Serviço', icon: 'refresh' }
        ]
      },

      {
        label: '7-Compras', icon: 'shopping_cart',
        items: [
          {
            label: '7.1-Sugestão P. de Compra',
            routerLink: ['/sugestaoPedidoCompra'],
            icon: 'create'
          },
          {
            label: '7.2-Cotação Pedido de Compra',
            routerLink: ['/sugestaoCotacao'],
            icon: 'shop'
          },

          {
            label: '7.3-Manifesto de Destinatario',
            routerLink: ['/manifesto'],
            icon: 'check'
          },
        ]
      },
      {
        label: '8-Configurações', icon: 'settings',
        items: [
          {
            label: 'Modo Menu', icon: 'menu',
            items: [
              {
                label: 'Menu Estático', icon: 'view_quilt', command: (event) => {
                  this.app.layoutMode = 'static';
                }
              },
              {
                label: 'Menu Sobreposto', icon: 'flip_to-front', command: (event) => {
                  this.app.layoutMode = 'overlay';
                }
              },
              {
                label: 'Menu Horinzontal', icon: 'border_horizontal', command: (event) => {
                  this.app.layoutMode = 'horizontal';
                }
              },
              {
                label: 'Menu Claro', icon: 'label_outline', command: (event) => {
                  this.app.darkMenu = false;
                }
              },
              {
                label: 'Menu Escuro', icon: 'label', command: (event) => {
                  this.app.darkMenu = true;
                }
              },
              {
                label: 'Orientação', icon: 'format_align_right',
                items: [
                  {
                    label: 'ESQ', icon: 'format_align_left', command: (event) => {
                      this.app.isRTL = false;
                    }
                  },
                  {
                    label: 'DIR', icon: 'format_align_right', command: (event) => {
                      this.app.isRTL = true;
                    }
                  }
                ]
              }
            ]

          },
          {
            label: 'Cores', icon: 'palette',
            items: [
              {
                label: 'Layout Paleta', icon: 'palette',
                items: [
                  {
                    label: 'Plano', icon: 'format_paint',
                    items: [
                      {
                        label: 'Azul - Âmbar', icon: 'brush', command: (event) => {
                          this.changeLayout('blue');
                        }
                      },
                      {
                        label: 'Azul Light - Azul Cinza', icon: 'brush',
                        command: (event) => {
                          this.changeLayout('lightblue');
                        }
                      },
                      {
                        label: 'Ciano - Âmbar', icon: 'brush', command: (event) => {
                          this.changeLayout('cyan');
                        }
                      },
                      {
                        label: 'Verde - Vermelho', icon: 'brush', command: (event) => {
                          this.changeLayout('teal');
                        }
                      },
                      {
                        label: 'Verde - Castanho', icon: 'brush', command: (event) => {
                          this.changeLayout('green');
                        }
                      },
                      {
                        label: 'Verde Luz  - Roxo', icon: 'brush', command: (event) => {
                          this.changeLayout('lightgreen');
                        }
                      },
                      {
                        label: 'Lima - Azul Cinza', icon: 'brush', command: (event) => {
                          this.changeLayout('lime');
                        }
                      },
                      {
                        label: 'Amarelo - Verde', icon: 'brush', command: (event) => {
                          this.changeLayout('yellow');
                        }
                      },
                      {
                        label: 'Âmbar - Rosa', icon: 'brush', command: (event) => {
                          this.changeLayout('amber');
                        }
                      },
                      {
                        label: 'Laranja - Indigo', icon: 'brush', command: (event) => {
                          this.changeLayout('orange');
                        }
                      },
                      {
                        label: 'Laranja Escuro - Ciano', icon: 'brush', command: (event) => {
                          this.changeLayout('deeporange');
                        }
                      },
                      {
                        label: 'Castanho - Ciano', icon: 'brush', command: (event) => {
                          this.changeLayout('brown');
                        }
                      },
                      {
                        label: 'Azul Cinza -verde', icon: 'brush', command: (event) => {
                          this.changeLayout('bluegrey');
                        }
                      },
                      {
                        label: 'Indigo - Rosa', icon: 'brush', command: (event) => {
                          this.changeLayout('indigo');
                        }
                      },
                      {
                        label: 'Rosa - Âmbar', icon: 'brush', command: (event) => {
                          this.changeLayout('pink');
                        }
                      },
                      {
                        label: 'Roxo - Laranja', icon: 'brush', command: (event) => {
                          this.changeLayout('deeppurple');
                        }
                      },
                      {
                        label: 'Cinza - Indigo', icon: 'brush', command: (event) => {
                          this.changeLayout('grey');
                        }
                      }
                    ]
                  },
                  {
                    label: 'Especial', icon: 'format_paint',
                    items: [
                      {
                        label: 'Caixa D`agua', icon: 'brush', command: (event) => {
                          this.changeLayout('reflection');
                        }
                      },
                      {
                        label: 'Temperamental', icon: 'brush', command: (event) => {
                          this.changeLayout('moody');
                        }
                      },
                      {
                        label: 'Paisagem', icon: 'brush', command: (event) => {
                          this.changeLayout('cityscape');
                        }
                      },
                      {
                        label: 'Nublado', icon: 'brush', command: (event) => {
                          this.changeLayout('cloudy');
                        }
                      },
                      {
                        label: 'Tempestade', icon: 'brush', command: (event) => {
                          this.changeLayout('storm');
                        }
                      },
                      {
                        label: 'Palma', icon: 'brush', command: (event) => {
                          this.changeLayout('palm');
                        }
                      },
                      {
                        label: 'Firmeza', icon: 'brush', command: (event) => {
                          this.changeLayout('flatiron');
                        }
                      }
                    ]
                  },
                ]
              },
              {
                label: 'Temas', icon: 'brush', badge: '5',
                items: [
                  {
                    label: 'Indigo - Rosa', icon: 'brush', command: (event) => {
                      this.changeTheme('indigo');
                    }
                  },
                  {
                    label: 'Rosa - Amber', icon: 'brush', command: (event) => {
                      this.changeTheme('pink');
                    }
                  },
                  {
                    label: 'Roxo - Rosa', icon: 'brush', command: (event) => {
                      this.changeTheme('purple');
                    }
                  },
                  {
                    label: 'Roxo - Orange', icon: 'brush', command: (event) => {
                      this.changeTheme('deeppurple');
                    }
                  },
                  {
                    label: 'Azul - Âmbar', icon: 'brush', command: (event) => {
                      this.changeTheme('blue');
                    }
                  },
                  {
                    label: 'Azul Luz - Azul Cinza', icon: 'brush', command: (event) => {
                      this.changeTheme('lightblue');
                    }
                  },
                  {
                    label: 'Ciano - Âmbar', icon: 'brush', command: (event) => {
                      this.changeTheme('cyan');
                    }
                  },
                  {
                    label: 'Verde - Vermelho', icon: 'brush', command: (event) => {
                      this.changeTheme('teal');
                    }
                  },
                  {
                    label: 'Verde - Castanho', icon: 'brush', command: (event) => {
                      this.changeTheme('green');
                    }
                  },
                  {
                    label: 'Verde Luz - Roxo', icon: 'brush', command: (event) => {
                      this.changeTheme('lightgreen');
                    }
                  },
                  {
                    label: 'Lima - Azul Cinza', icon: 'brush', command: (event) => {
                      this.changeTheme('lime');
                    }
                  },
                  {
                    label: 'Amarelo - Verde', icon: 'brush', command: (event) => {
                      this.changeTheme('yellow');
                    }
                  },
                  {
                    label: 'Âmbar - Rosa', icon: 'brush', command: (event) => {
                      this.changeTheme('amber');
                    }
                  },
                  {
                    label: 'Orange - Indigo', icon: 'brush', command: (event) => {
                      this.changeTheme('orange');
                    }
                  },
                  {
                    label: 'Laranja Escuro - Ciano', icon: 'brush', command: (event) => {
                      this.changeTheme('deeporange');
                    }
                  },
                  {
                    label: 'Castanho - Ciano', icon: 'brush', command: (event) => {
                      this.changeTheme('brown');
                    }
                  },
                  {
                    label: 'Azul Cinza - Verde', icon: 'brush', command: (event) => {
                      this.changeTheme('bluegrey');
                    }
                  },
                  {
                    label: 'Cinza - Indigo', icon: 'brush', command: (event) => {
                      this.changeTheme('grey');
                    }
                  }
                ]
              }
            ]
          }

        ]
      },

      {
        label: '9-Ajuda', icon: 'find_in_page',
        url: 'https://bitbucket.org/conape/projetos/wiki/Home'
      }
    ];
  }

  changeTheme(theme) {
    const themeLink: HTMLLinkElement = <HTMLLinkElement>document.getElementById('theme-css');
    themeLink.href = 'assets/theme/theme-' + theme + '.css';
  }

  changeLayout(theme) {
    const layoutLink: HTMLLinkElement = <HTMLLinkElement>document.getElementById('layout-css');
    layoutLink.href = 'assets/layout/css/layout-' + theme + '.css';
  }
}

@Component({
  /* tslint:disable:component-selector */
  selector: '[app-submenu]',
  /* tslint:enable:component-selector */
  template: `
    <ng-template
      ngFor
      let-child
      let-i="index"
      [ngForOf]="root ? item : item.items"
    >
      <li
        [ngClass]="{ 'active-menuitem': isActive(i) }"
        [class]="child.badgeStyleClass"
      >
        <a
          [href]="child.url || '#'"
          (click)="itemClick($event, child, i)"
          *ngIf="!child.routerLink"
          [attr.tabindex]="!visible ? '-1' : null"
          [attr.target]="child.target"
          (mouseenter)="onMouseEnter(i)"
          class="ripplelink"
        >
          <i class="material-icons">{{ child.icon }}</i>
          <span class="menuitem-text">{{ child.label }}</span>
          <i class="material-icons layout-submenu-toggler" *ngIf="child.items"
          >keyboard_arrow_down</i
          >
          <span class="menuitem-badge" *ngIf="child.badge">{{
            child.badge
            }}</span>
        </a>

        <a
          (click)="itemClick($event, child, i)"
          *ngIf="child.routerLink"
          [routerLink]="child.routerLink"
          routerLinkActive="active-menuitem-routerlink"
          [routerLinkActiveOptions]="{ exact: true }"
          [attr.tabindex]="!visible ? '-1' : null"
          [attr.target]="child.target"
          (mouseenter)="onMouseEnter(i)"
          class="ripplelink"
        >
          <i class="material-icons">{{ child.icon }}</i>
          <span class="menuitem-text">{{ child.label }}</span>
          <i class="material-icons layout-submenu-toggler" *ngIf="child.items"
          >>keyboard_arrow_down</i
          >
          <span class="menuitem-badge" *ngIf="child.badge">{{
            child.badge
            }}</span>
        </a>
        <ul
          app-submenu
          [item]="child"
          *ngIf="child.items && isActive(i)"
          [visible]="isActive(i)"
          [reset]="reset"
          [parentActive]="isActive(i)"
          [@children]="
            app.isHorizontal() && root
              ? isActive(i)
                ? 'visible'
                : 'hidden'
              : isActive(i)
              ? 'visibleAnimated'
              : 'hiddenAnimated'
          "
        ></ul>
      </li>
    </ng-template>
  `,
  animations: [
    trigger('children', [
      state(
        'void',
        style({
          height: '0px'
        })
      ),
      state(
        'hiddenAnimated',
        style({
          height: '0px'
        })
      ),
      state(
        'visibleAnimated',
        style({
          height: '*'
        })
      ),
      state(
        'visible',
        style({
          height: '*',
          'z-index': 100
        })
      ),
      state(
        'hidden',
        style({
          height: '0px',
          'z-index': '*'
        })
      ),
      transition(
        'visibleAnimated => hiddenAnimated',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
      ),
      transition(
        'hiddenAnimated => visibleAnimated',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
      ),
      transition(
        'void => visibleAnimated, visibleAnimated => void',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
      )
    ])
  ]
})
export class AppSubMenuComponent {
  @Input() item: MenuItem;

  @Input() root: boolean;

  @Input() visible: boolean;

  _reset: boolean;

  _parentActive: boolean;

  activeIndex: number;

  constructor(
    public app: AppComponent,
    public router: Router,
    public location: Location,
    public appMenu: AppMenuComponent
  ) {
  }

  itemClick(event: Event, item: MenuItem, index: number) {
    if (this.root) {
      this.app.menuHoverActive = !this.app.menuHoverActive;
      event.preventDefault();
    }

    // avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    // activate current item and deactivate active sibling if any
    if (item.routerLink || item.items || item.command || item.url) {
      this.activeIndex = (this.activeIndex as number) === index ? -1 : index;
    }

    // execute command
    if (item.command) {
      item.command({ originalEvent: event, item: item });
    }

    // prevent hash change
    if (item.items || (!item.url && !item.routerLink)) {
      setTimeout(() => {
        this.appMenu.layoutMenuScrollerViewChild.moveBar();
      }, 450);
      event.preventDefault();
    }

    // hide menu
    if (!item.items) {
      if (this.app.isMobile()) {
        this.app.sidebarActive = false;
        this.app.mobileMenuActive = false;
      }

      if (this.app.isHorizontal()) {
        this.app.resetMenu = true;
      } else {
        this.app.resetMenu = false;
      }

      this.app.menuHoverActive = !this.app.menuHoverActive;
    }
  }

  onMouseEnter(index: number) {
    if (
      this.root &&
      this.app.menuHoverActive &&
      this.app.isHorizontal() &&
      !this.app.isMobile() &&
      !this.app.isTablet()
    ) {
      this.activeIndex = index;
    }
  }

  isActive(index: number): boolean {
    return this.activeIndex === index;
  }

  @Input()
  get reset(): boolean {
    return this._reset;
  }

  set reset(val: boolean) {
    this._reset = val;

    if (this._reset && (this.app.isHorizontal() || this.app.isOverlay())) {
      this.activeIndex = null;
    }
  }

  @Input()
  get parentActive(): boolean {
    return this._parentActive;
  }

  set parentActive(val: boolean) {
    this._parentActive = val;

    if (!this._parentActive) {
      this.activeIndex = null;
    }
  }
}
