import { Injectable } from '@angular/core';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import * as moment from 'moment';

import {MoneyHttp} from '../../seguranca/money-http';
import {CheckList} from '../../core/models/CheckList';

export class CheckListFiltro {
  empresa: string;
  dataDe: Date;
  dataAte: Date;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class CheckListService {

  private urlCheckList: string;

  constructor(
    private http: MoneyHttp,
  ) {
    this.urlCheckList= `${environment.apiUrl}/checkList`;
  }

  buscarPorId(id: number): Promise<CheckList> {
    return this.http
      .get<CheckList>(`${this.urlCheckList}/${id}`)
      .toPromise()
      .then(response => {
        return response;
      });
  }

  adicionar(checkList: CheckList): Promise<CheckList> {
    return this.http.post<CheckList>(this.urlCheckList, checkList).toPromise();
  }

  atualizar(checkList: CheckList): Promise<CheckList> {
    return this.http.put<CheckList>(`${this.urlCheckList}/${checkList.id}`, checkList).toPromise();
  }

  pesquisarPeriodo(filtro: CheckListFiltro): Promise<any> {

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    if (filtro.empresa) {
      params = params.append('empresa', filtro.empresa);
    }

    if (filtro.dataDe) {
      params = params.append(
        'dataDe',
        moment(filtro.dataDe).format('YYYY-MM-DD 00:00')
      );
    }

    if (filtro.dataAte) {
      params = params.append(
        'dataAte',
        moment(filtro.dataAte).format('YYYY-MM-DD 23:59')
      );
    }

    return this.http
      .get<any>(`${this.urlCheckList}/periodo/empresa/dataDe/dataAte`, {params})
      .toPromise()
      .then(response => {
        const resultado = {
          checks: response.content,
          total: response.totalElements
        };
        return resultado;
      });
  }

  adicionarImagem(file: File, directory: string): Promise<any> {

    const formdata: FormData = new FormData();

    formdata.append('file', file);

    return this.http.post(`${this.urlCheckList}/upload/${directory}`, formdata).toPromise();
  }

  deletarImagem(directory: string, fileName: string): Promise<any> {
    return this.http.delete(`${this.urlCheckList}/delete/${directory}/${fileName}`).toPromise();
  }

  alterarImagem(file: File, directory: string): Promise<any> {

    const formdata: FormData = new FormData();

    formdata.append('file', file);

    return this.http.put(`${this.urlCheckList}/update/${directory}`, formdata).toPromise();
  }


  buscarImagem(directory: string, fileName: string): Promise<Blob> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'image/jpeg',
      'Accept': 'image/jpeg'
    });
    return this.http.get<Blob>(`${this.urlCheckList}/download/${directory}/${fileName}`,
      {headers: headers, responseType: 'blob'}).toPromise();
  }

  impressao(checkList: CheckList): Promise<Blob> {
      return this.http.get<Blob>(`${this.urlCheckList}/impressao/${checkList.id}`, {responseType: 'blob' as 'json'}).toPromise();
  }

  buscarAbertoPorId(id: number): Promise<CheckList> {
    return this.http
      .get<CheckList>(`${this.urlCheckList}/aberto/${id}`)
      .toPromise()
      .then(response => {
        return response;
      });
  }

  listarTodosPorAtendente(atendente:number): Promise<Array<CheckList>> {
    return this.http
      .get<Array<CheckList>>(`${this.urlCheckList}/listaPorAtendente/${atendente}`)
      .toPromise();
  }

}
