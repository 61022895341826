 export class ProdutoCautela{
    canceladoPor: string;
    codigo: string;
    conferenteCancelamento: string;
    conferenteEntrega: string;
    datahoraCancelamento: Date;
    datahoraImpressao: Date;
    datahoraRetorno: Date;
    datahoraSaida: Date;
    documento: string;
    entregador: string;
    empresa; string;
    id: number;
    impressao: string;
    motoqueiroEntrega: string;
    prevenda: string;
    quantidadeRetornada: number;
    quantidadeSaida: number;
    retirante: string;
    serie: string;
    usuarioImpressao: string;
    vendedorRecebeu: string;

    constructor(

        canceladoPor?: string,
        codigo?: string,
        conferenteCancelamento?: string,
        conferenteEntrega?: string,
        datahoraCancelamento?: Date,
        datahoraImpressao?: Date,
        datahoraRetorno?: Date,
        datahoraSaida?: Date,
        documento?: string,
        entregador?: string,
        empresa?: string,
        id?: number,
        impressao?: string,
        motoqueiroEntrega?: string,
        prevenda?: string,
        quantidadeRetornada?: number,
        quantidadeSaida?: number,
        retirante?: string,
        serie?: string,
        usuarioImpressao?: string,
        vendedorRecebeu?: string

    ){
        this.canceladoPor = canceladoPor;
        this.codigo = codigo;
        this.conferenteCancelamento = conferenteCancelamento;
        this.conferenteEntrega =  conferenteEntrega;
        this.datahoraCancelamento =  datahoraCancelamento;
        this.datahoraImpressao =  datahoraImpressao;
        this.datahoraRetorno =  datahoraRetorno;
        this.datahoraSaida =  datahoraSaida;
        this.documento =  documento;
        this.entregador =  entregador;
        this.empresa = empresa;
        this.id = id;
        this.impressao = impressao;
        this.motoqueiroEntrega =  motoqueiroEntrega;
        this.prevenda =  prevenda;
        this.quantidadeRetornada = quantidadeRetornada;
        this.quantidadeSaida =  quantidadeSaida;
        this.retirante =  retirante;
        this.serie =  serie;
        this.usuarioImpressao = usuarioImpressao;
        this.vendedorRecebeu =  vendedorRecebeu;

    }
 }