import { Injectable } from '@angular/core';
import {MoneyHttp} from '../seguranca/money-http';
import {environment} from '../../environments/environment';
import {SubstituirNotaEntrega} from '../core/models/SubstituirNotaEntrega';

@Injectable()
export class SubstituirNotaEntregaService {

  url: string;

  constructor(private http: MoneyHttp) {
    this.url = `${environment.apiUrl}/substituirNotaEntrega`;
  }

  buscarPorNotaEntrega(idNota: number): Promise<SubstituirNotaEntrega> {
    return this.http.get<SubstituirNotaEntrega>(`${this.url}/buscarPorNota/${idNota}`).toPromise();
  }

  substituirNE(substituirNotaEntrega): Promise<SubstituirNotaEntrega> {
    return this.http.put<SubstituirNotaEntrega>(`${this.url}/substiruirNE`, substituirNotaEntrega).toPromise();
  }
}
