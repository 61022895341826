import {HttpParams, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';

import 'rxjs/add/operator/toPromise';

import {environment} from './../../environments/environment';
import {MoneyHttp} from 'app/seguranca/money-http';

import {Marca} from './../core/models/Marca';
import {MarcaCadastro} from './../core/models/MarcaCadastro';
import {SelectItem} from 'primeng/api';


export class MarcaFiltro {
  descricao: string;
  codigo: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class MarcaService {
  marcasUrl: string;

  constructor(private http: MoneyHttp) {
    this.marcasUrl = `${environment.apiUrl}/marcas`;
  }

  pesquisar(filtro: MarcaFiltro): Promise<any> {

    let marcasUrlPesquisa: string = `${this.marcasUrl}/filter/descricao`;

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    if (filtro.descricao) {

      params = params.append('descricao', filtro.descricao);

    } else if (filtro.codigo) {

      params = params.append('codigo', filtro.codigo);
      marcasUrlPesquisa = `${this.marcasUrl}/filter/codigo`;

    }
    return this.http
      .get<any>(marcasUrlPesquisa, {params})
      .toPromise()
      .then(response => {
        const marcas = response.content;

        const resultado = {
          marcas,
          total: response.totalElements
        };

        return resultado;
      });
  }

  listarTodas(): Promise<Array<Marca>> {
    return this.http
      .get<Array<Marca>>(`${this.marcasUrl}/lista`)
      .toPromise()
      .then(response => response);
  }

  listarTodasPorDescricao(descricao: string): Promise<any> {
    return this.http
      .get<any>(`${this.marcasUrl}/listaTodas/${descricao}`)
      .toPromise()
      .then(response => response);
  }

  adicionar(marcaCadastro: MarcaCadastro): Promise<Marca> {
    return this.http.post<Marca>(this.marcasUrl, marcaCadastro).toPromise();
  }

  atualizar(marca: Marca): Promise<Marca> {
    return this.http
      .put<Marca>(`${this.marcasUrl}/${marca.id}`, marca)
      .toPromise();
  }

  buscarPorId(id: number): Promise<Marca> {
    return this.http.get<Marca>(`${this.marcasUrl}/${id}`).toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.marcasUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }

  next(cod_marc: string): Promise<Marca> {
    let params = new HttpParams({
      fromObject: {
        codigo: cod_marc
      }
    });
    return this.http.get<Marca>(`${this.marcasUrl}?next`, {params}).toPromise();
  }
  
  previous(cod_marc: string): Promise<Marca> {
    let params = new HttpParams({
      fromObject: {
        codigo: cod_marc
      }
    });
    return this.http.get<Marca>(`${this.marcasUrl}?previous`, {params}).toPromise();
  }

  montarSelectItem(): Promise<Array<SelectItem>> {
    return new Promise((resolve, reject) => {
      this.listarTodas()
        .then(marcas => {
          resolve(marcas.map(marca => {
            return {
              value: marca,
              label: `${marca.codigo} - ${marca.descricao}`,
            }
          }));
        }).catch(msgErr => reject(msgErr))
    });
  }

  buscarPorCodigo(codigo: string): Promise<Marca> {
    return this.http.get<Marca>(`${this.marcasUrl}/codigo/${codigo}`).toPromise();
  }

}
