import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as moment from 'moment';

import 'rxjs/add/operator/toPromise';

import {environment} from '../../environments/environment';
import {PagamentoAntecipado} from '../core/models/PagamentoAntecipado';
import {MoneyHttp} from 'app/seguranca/money-http';
import {OrcamentoOS} from '../core/models/OrcamentoOS';

export class PagamentoAntecipadoFiltro {
  cliente: string;
  codigo: string;
  incluirDataDe: Date;
  incluirDataAte: Date;
  pagamentoId: string;
  nome: string;
  pagina = 0;
  itensPorPagina = 20;
}

@Injectable()
export class PagamentoAntecipadoService {

  pagamentoAntecipadosUrl: string;

  constructor(private http: MoneyHttp) {
    this.pagamentoAntecipadosUrl = `${environment.apiUrl}/pagamentosAntecipados`;
  }

  pesquisar(filtro: PagamentoAntecipadoFiltro): Promise<any> {


    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString(),
      }
    });


    let pagamentoAntecipadoUrlPesquisar: string;

    if (filtro.cliente) {
      params = params.append('cliente', filtro.cliente);
      pagamentoAntecipadoUrlPesquisar = `${environment.apiUrl}/pagamentosAntecipados/?clienteVc`;
    } else if (filtro.pagamentoId) {
      params = params.append('pagamentoId', filtro.pagamentoId);
      pagamentoAntecipadoUrlPesquisar = `${this.pagamentoAntecipadosUrl}/filter/pagamentoId`;
    }

    return this.http
      .get<any>(pagamentoAntecipadoUrlPesquisar, {params})
      .toPromise()
      .then(response => {
        const pagamentoAntecipado = response.content;
        const resultado = {
          pagamentoAntecipado,
          total: response.totalElements
        };

        return resultado;
      });
  }

  pesquisarFiltro(filtro: PagamentoAntecipadoFiltro): Promise<any> {
    let params = new HttpParams({
      fromObject: {
      }
    });

    if (filtro.incluirDataDe) {
      params = params.append('incluirDataDe', moment(filtro.incluirDataDe).format('YYYY-MM-DD'));
    }

    if (filtro.incluirDataAte) {
      params = params.append('incluirDataAte', moment(filtro.incluirDataAte).format('YYYY-MM-DD'));
    }

    if (filtro.cliente){
      params = params.append('cliente', filtro.cliente);
    }

    if (filtro.pagamentoId){
      params = params.append('pagamentoId', filtro.pagamentoId);
    }

    let url = '';
    if (filtro.pagamentoId) {
      url  = `${environment.apiUrl}/pagamentosAntecipados/?peloPagtoId`;
    }else if (filtro.cliente && filtro.incluirDataDe && filtro.incluirDataAte) {
      url = `${environment.apiUrl}/pagamentosAntecipados/?clientePeriodo`;
    }

    return this.http
      .get<Array<PagamentoAntecipado>>(`${url}`, {params})
      .toPromise();

  }


  pesquisarCreditoPorCliente(filtro: PagamentoAntecipadoFiltro): Promise<any> {

    let pagamentoAntecipadoUrlPesquisar: string = `${this.pagamentoAntecipadosUrl}/filter/cliente`;

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString(),
      }
    });

    if (filtro.codigo)
      params = params.append('cliente', filtro.cliente);

    return this.http
      .get<any>(pagamentoAntecipadoUrlPesquisar, {params})
      .toPromise()
      .then(response => {
        const resultado = {
          pagamentoAntecipados: response.content,
          total: response.totalElements
        };

        return resultado;
      });
  }

  listarTodas(): Promise<any> {
    return this.http
      .get<any>(`${this.pagamentoAntecipadosUrl}/lista`)
      .toPromise()
      .then(response => response.content);
  }

  adicionar(PagamentoAntecipado: PagamentoAntecipado): Promise<PagamentoAntecipado> {
    return this.http.post<PagamentoAntecipado>(this.pagamentoAntecipadosUrl, PagamentoAntecipado).toPromise();
  }

  atualizar(PagamentoAntecipado: PagamentoAntecipado): Promise<PagamentoAntecipado> {
    return this.http
      .put<PagamentoAntecipado>(`${this.pagamentoAntecipadosUrl}/${PagamentoAntecipado.id}`, PagamentoAntecipado)
      .toPromise();
  }

  buscarPorId(id: number): Promise<PagamentoAntecipado> {
    return this.http.get<PagamentoAntecipado>(`${this.pagamentoAntecipadosUrl}/${id}`).toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.pagamentoAntecipadosUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }

  impressao(pagamentoAntecipado: PagamentoAntecipado): Promise<Blob> {
    return this.http.get<Blob>(`${this.pagamentoAntecipadosUrl}/impressao/${pagamentoAntecipado.id}/${pagamentoAntecipado.empresa}`,
      {responseType: 'blob' as 'json'}).toPromise();
  }


  buscarPagamentosAntecipadosVinculados(cliente: string, id: string): Promise<Array<PagamentoAntecipado>> {
    return this.http.get<Array<PagamentoAntecipado>>(`${this.pagamentoAntecipadosUrl}/vinculados/${cliente}/${id}`).toPromise();
  }

  desvincularTudo(pa: PagamentoAntecipado): Promise<PagamentoAntecipado> {
    return this.http.put<PagamentoAntecipado>(`${this.pagamentoAntecipadosUrl}/desvincularTudo`, pa).toPromise();
  }


  cancelarValeCredito(pa: PagamentoAntecipado): Promise<PagamentoAntecipado> {
    return this.http.put<PagamentoAntecipado>(`${this.pagamentoAntecipadosUrl}/cancelar`, pa).toPromise();
  }

  buscarPorIdPgto(idpgto: string): Promise<Array<PagamentoAntecipado>> {
    return this.http.get<Array<PagamentoAntecipado>>(`${this.pagamentoAntecipadosUrl}/porIdpgto/${idpgto}`).toPromise();
  }

}
