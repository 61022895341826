import { Component, OnDestroy } from '@angular/core';
import { AppComponent } from './app.component';
import { Router } from '@angular/router';
import { BreadcrumbService } from './breadcrumb.service';
import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/primeng';
import { LogoutService } from './seguranca/logout.service';
import { ErrorHandlerService } from './core/error-handler.service';
import { AuthService } from './seguranca/auth.service';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './app.breadcrumb.component.html'
})
export class AppBreadcrumbComponent implements OnDestroy {

    subscription: Subscription;

    items: MenuItem[];

    constructor(public breadcrumbService: BreadcrumbService,
        private logoutService: LogoutService,
        private errorHandler: ErrorHandlerService,
        private router: Router) {
        this.subscription = breadcrumbService.itemsHandler.subscribe(response => {
            this.items = response;
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    logout() {
        this.logoutService
          .logout()
          .then(() => {
            this.router.navigate(['/login']);
          })
          .catch(erro => this.errorHandler.handle(erro));
      }
}
