import { Injectable } from '@angular/core';
import {HttpParams} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { MoneyHttp } from 'app/seguranca/money-http';
import { ManifestoDestinatario } from './../core/models/ManifestoDestinatario';
import { RetornoSefaz } from 'app/core/RetornoSefaz';
import * as moment from 'moment';

export class ManifestoFiltro {
  empresa: string;
  chave: string;
  evento: string;
  emissaoDe: Date;
  emissaoAte: Date;
  pagina = 0;
  itensPorPagina = 20;
}

@Injectable()
export class ManifestoService {

  manifestoUrl: string;
  nfeUrl: string;
  ambiente = 'P';

  constructor(
    private http: MoneyHttp
  ) {
    this.manifestoUrl = `${environment.apiUrl}/manifestodestino`;
    this.nfeUrl = `${environment.apiUrl}/nfe`;
  }

  pesquisarPeriodo(filtro: ManifestoFiltro): Promise<any> {

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    if (filtro.empresa) {
      params = params.append('empresa', filtro.empresa);
    }

    if (filtro.emissaoDe) {
      params = params.append(
        'emissaoDe',
        moment(filtro.emissaoDe).format('YYYY-MM-DD')
      );
    }

    if (filtro.emissaoAte) {
      params = params.append(
        'emissaoAte',
        moment(filtro.emissaoAte).format('YYYY-MM-DD')
      );
    }

      return this.http.get<any>(`${this.manifestoUrl}?periodo`, {params}).toPromise()
        .then(response => {

        const notas = response.content;

        const resultado = {
          notas,
          total: response.totalElements
        };

        return resultado;
      });
  }

  buscarPorId(id: number): Promise<ManifestoDestinatario> {
    return this.http
      .get<ManifestoDestinatario>(`${this.manifestoUrl}/${id}`)
      .toPromise()
      .then(response => {
        const manifesto = response;

        return manifesto;
      });
  }
  
  sincronizarNFeDestinadas(empresa: string): Promise<RetornoSefaz> {
   return this.http.post<RetornoSefaz>(`${this.manifestoUrl}/sincronizar/${empresa}/${this.ambiente}`, '').toPromise();
  }

  enviarManifestacaoDestinatario(empresa: string, chave: string, evento: string): Promise<RetornoSefaz> {
    return this.http.post<RetornoSefaz>(`${this.manifestoUrl}/enviar/${empresa}/${chave}/${evento}/${this.ambiente}`, '').toPromise();
  }

  consultarNFeSincronizadas(empresa: string, emissao: Date): Promise<RetornoSefaz> {
    return this.http.get<RetornoSefaz>(`${this.manifestoUrl}/consultar/${empresa}/${this.ambiente}`).toPromise();
  }

  consultarNFeSincronizadasPelaChave(empresa: string, chave: string): Promise<RetornoSefaz> {
    return this.http.get<RetornoSefaz>(`${this.manifestoUrl}/consultaManifestada/${empresa}/${chave}/${this.ambiente}`).toPromise();
  }

  imprimirDanfeRecebidoTerceriros(chaveNfe: string, cce: boolean): Promise<any> {
    chaveNfe = '35200306877055000160550010000115721443563733';
    return this.http.get<any>(`${this.nfeUrl}/consultaUrlPdf/${chaveNfe}/${this.ambiente}/${cce}`).toPromise();
  }
}
