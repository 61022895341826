import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import "rxjs/add/operator/toPromise";

import { environment } from "./../../environments/environment";
import { MoneyHttp } from "app/seguranca/money-http";

import { CodigoSituacaoTributariaIcms } from "./../core/models/CodigoSituacaoTributariaIcms";
import { CodigoSituacaoTributariaIcmsCadastro } from './../core/models/CodigoSituacaoTributariaIcmsCadastro';


export class CodigoSituacaoTributariaIcmsFiltro {
  descricao: string;
  codigo: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class CodigoSituacaoTributariaIcmsService {
    codigosSituacaoTributariasIcmsUrl: string;

  constructor(private http: MoneyHttp) {
    this.codigosSituacaoTributariasIcmsUrl = `${environment.apiUrl}/codigosSituacaoTributariasIcms`;
  }

  pesquisar(filtro: CodigoSituacaoTributariaIcmsFiltro): Promise<any> {
    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });
    if (filtro.descricao) {
      params = params.append("descricao", filtro.descricao);
    }
    if (filtro.codigo) {
      params = params.append("codigo", filtro.codigo);
    }
    return this.http
      .get<any>(`${this.codigosSituacaoTributariasIcmsUrl}`, { params })
      .toPromise()
      .then(response => {
        const codigosSituacaoTributariasIcms = response.content;

        const resultado = {
          codigosSituacaoTributariasIcms,
          total: response.totalElements
        };

        return resultado;
      });
  }

  listarTodas(): Promise<any> {
    return this.http
      .get<any>(`${this.codigosSituacaoTributariasIcmsUrl}/lista`)
      .toPromise()
      .then(response => response.content);
  }

  adicionar(codigoSituacaoTributariaIcmsCadastro: CodigoSituacaoTributariaIcmsCadastro): Promise<CodigoSituacaoTributariaIcms> {
    return this.http.post<CodigoSituacaoTributariaIcms>(this.codigosSituacaoTributariasIcmsUrl, codigoSituacaoTributariaIcmsCadastro).toPromise();
  }

  atualizar(codigosSituacaoTributariasIcms: CodigoSituacaoTributariaIcms): Promise<CodigoSituacaoTributariaIcms> {
    return this.http
      .put<CodigoSituacaoTributariaIcms>(`${this.codigosSituacaoTributariasIcmsUrl}/${codigosSituacaoTributariasIcms.id}`, codigosSituacaoTributariasIcms)
      .toPromise();
  }

  buscarPorId(id: number): Promise<CodigoSituacaoTributariaIcms> {
    return this.http.get<CodigoSituacaoTributariaIcms>(`${this.codigosSituacaoTributariasIcmsUrl}/${id}`).toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.codigosSituacaoTributariasIcmsUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }
}
