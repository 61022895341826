import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import 'rxjs/add/operator/toPromise';

import { environment } from './../../environments/environment';
import { Usuario } from './../core/models/Usuario';
import { PerfilAcesso } from 'app/core/models/PerfilAcesso';
import { MoneyHttp } from 'app/seguranca/money-http';

export class UsuarioFiltro {
  nome: string;
  login: string;
  senha: string;
  novaSenha: string;
  confirmaSenha: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class UsuarioService {
  usuariosUrl: string;
  perfilAcessoURL: string;

  constructor(private http: MoneyHttp) {
    this.usuariosUrl = `${environment.apiUrl}/usuarios`;
    this.perfilAcessoURL = `${environment.apiUrl}/perfilAcesso`;
  }

  pesquisar(filtro: UsuarioFiltro): Promise<any> {
    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });
    if (filtro.nome) {
      params = params.append('nome', filtro.nome);
    }
    return this.http
      .get<any>(`${this.usuariosUrl}`, { params })
      .toPromise()
      .then(response => {
        const usuarios = response.content;

        const resultado = {
          usuarios,
          total: response.totalElements
        };

        return resultado;
      });
  }

  listarTodas(): Promise<any> {
    return this.http
      .get<any>(`${this.usuariosUrl}/lista`)
      .toPromise()
      .then(response => response.content);
  }

  adicionar(usuario: Usuario): Promise<Usuario> {
    return this.http.post<Usuario>(this.usuariosUrl, usuario).toPromise();
  }

  atualizar(usuario: Usuario): Promise<Usuario> {
    return this.http
      .put<Usuario>(`${this.usuariosUrl}/${usuario.codigo}`, usuario)
      .toPromise();
  }

  aterarSenha(filtro: UsuarioFiltro): Promise<Usuario> {
    const params = new HttpParams({
      fromObject: {
        nome: filtro.nome,
        login: filtro.login,
        senha: filtro.senha,
        novaSenha: filtro.novaSenha,
        confirmaSenha: filtro.confirmaSenha
      }
    });
    return this.http
      .put<Usuario>(`${this.usuariosUrl}?alterarSenha`, params)
      .toPromise();
  }
  buscarPorId(codigo: number): Promise<Usuario> {
    return this.http.get<Usuario>(`${this.usuariosUrl}/${codigo}`).toPromise();
  }

  excluir(codigo: number): Promise<void> {
    return this.http
      .delete(`${this.usuariosUrl}/${codigo}`)
      .toPromise()
      .then(() => null);
  }

  buscarPeloNome(nome: string): Promise<Usuario> {
    return this.http.get<Usuario>(`${this.usuariosUrl}/buscarPeloNome/${nome}`).toPromise();
  }

  buscarPerfilAcessos(): Promise<PerfilAcesso[]> {
    return this.http.get<PerfilAcesso[]>(`${this.perfilAcessoURL}/listar`).toPromise();
  }

}
