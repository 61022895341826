import { RetornoSefaz } from './../core/RetornoSefaz';
import {Injectable} from '@angular/core';

import 'rxjs/add/operator/toPromise';
import {HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {MoneyHttp} from 'app/seguranca/money-http';
import * as moment from 'moment';
import {NotaDevolucao} from '../core/models/NotaDevolucao';
import {TotalVendasPeriodo} from './nota.service';

export class NfeFilter {
  id: string;
  tipo: string;
  modo: string;
  cpfNaNota: string;
  ambiente: string;
  observacoesContrib: string;
  modoFrete: string;
  codigoTransportadora: string;
  peso: string;
  volume: string;
  notasDevolucao: Array<NotaDevolucao>;
}


@Injectable()
export class ManagersaasService {

  urlNfce: string;
  urlNfe: string;
  urlNfse: string;

  constructor(private http: MoneyHttp) {

    this.urlNfce = `${environment.apiUrl}/nfce`;
    this.urlNfe = `${environment.apiUrl}/nfe`;
    this.urlNfse = `${environment.apiUrl}/nfse`;

  }

  enviarNfce(id: number, tipo: string, modo: number, ambiente: string): Promise<RetornoSefaz> {
    return this.http.get<RetornoSefaz>(`${this.urlNfce}/${id}/${tipo}/${ambiente}`).toPromise();
  }

  enviarNfe(id: number, tipo: string, modo: number, cpfNaNota: string, ambiente: string, observacoes: string, modoFrete:
    string, codigoTransportadora: string, peso: string, volume: string, notasDevolucao: Array<NotaDevolucao>): Promise<RetornoSefaz> {
  
    // const params = new HttpParams({
    //   fromObject: {
    //     id: id.toString(),
    //     tipo: tipo.toString(),
    //     modo: modo.toString(),
    //     cpfNaNota: cpfNaNota,
    //     ambiente: ambiente,
    //     observacoesContrib: observacoes,
    //     modoFrete: modoFrete,
    //     codigoTransportadora: codigoTransportadora,
    //     peso: peso,
    //     volume: volume,
    //     notasDevolucao: notasDevolucao,
    //   }
    // });


    let  nfeFilter = new  NfeFilter();
    nfeFilter.id = id.toString();
    nfeFilter.tipo = tipo ? tipo : '';
    nfeFilter.modo = modo ? modo.toString() : '';
    nfeFilter.cpfNaNota = cpfNaNota ? cpfNaNota : '';
    nfeFilter.ambiente = ambiente ? ambiente : '';
    nfeFilter.observacoesContrib = observacoes ? observacoes : '';
    nfeFilter.modoFrete = modoFrete ? modoFrete : '';
    nfeFilter.codigoTransportadora = codigoTransportadora ? codigoTransportadora : '';
    nfeFilter.peso = peso ? peso : '';
    nfeFilter.volume = volume ? volume : '';
    nfeFilter.notasDevolucao = notasDevolucao;

    return this.http.put<RetornoSefaz>(`${this.urlNfe}/enviarNF`, nfeFilter).toPromise();
  }

  enviarNfse(id: number, tipo: string, modo: number, ambiente: string, observacoes: string): Promise<RetornoSefaz> {
    const params = new HttpParams({
      fromObject: {
        id: id.toString(),
        tipo: tipo.toString(),
        modo: modo.toString(),
        ambiente: ambiente,
        observacoes: observacoes
      }
    });
    return this.http.get<RetornoSefaz>(`${this.urlNfse}?enviarNFS`, {params}).toPromise();
  }

  resolverNfe(id: number, tipo: string, modo: number, ambiente: string): Promise<RetornoSefaz> {
    return this.http.get<RetornoSefaz>(`${this.urlNfe}/resolver/${id}/${ambiente}`).toPromise();
  }

  resolverNfce(id: number, tipo: string, modo: number, ambiente: string): Promise<RetornoSefaz> {
    return this.http.get<RetornoSefaz>(`${this.urlNfce}/resolver/${id}/${ambiente}`).toPromise();
  }

  
  resolverNfse(id: number, tipo: string, modo: number, ambiente: string): Promise<RetornoSefaz> {
    return this.http.get<RetornoSefaz>(`${this.urlNfse}/resolver/${id}/${ambiente}`).toPromise();
  }

  consultarNfce(id: number, tipo: string, modo: number, ambiente: string): Promise<RetornoSefaz> {
   return this.http.get<RetornoSefaz>(`${this.urlNfce}/consulta/${id}/${ambiente}`).toPromise();
  }

 consultarNfe(id: number, tipo: string, modo: number, ambiente: string): Promise<RetornoSefaz> {
  return this.http.get<RetornoSefaz>(`${this.urlNfe}/consulta/${id}/${ambiente}`).toPromise();
 }

 consultarNfse(id: number, ambiente: string): Promise<RetornoSefaz> {
  return this.http.get<RetornoSefaz>(`${this.urlNfse}/consulta/${id}/${ambiente}`).toPromise();
 }

 imprimirNfce(chave: string, tipo: string, modo: number, ambiente: string): Promise<RetornoSefaz> {
  return this.http.get<RetornoSefaz>(`${this.urlNfce}/consultaUrlPdf/${chave}/${ambiente}`).toPromise();
}

imprimirNfe(chave: string, tipo: string, modo: number, ambiente: string, cce: boolean): Promise<RetornoSefaz> {
  return this.http.get<RetornoSefaz>(`${this.urlNfe}/consultaUrlPdf/${chave}/${ambiente}/${cce}`).toPromise();
}

imprimirNfse(id: number, tipo: string, ambiente: string): Promise<RetornoSefaz> {
  return this.http.get<RetornoSefaz>(`${this.urlNfse}/consultaUrlPdf/${id}/${tipo}/${ambiente}`).toPromise();
}

statusServico(empresa: string, ambiente: string): Promise<RetornoSefaz> {
  return this.http.get<RetornoSefaz>(`${this.urlNfce}/consultaStatusServico/${empresa}/${ambiente}`).toPromise();
}

cancelarNfce(usuario: string, id: number, tipo: string, justificativa: string, modo: number, ambiente: string): Promise<RetornoSefaz> {
  return this.http.post<RetornoSefaz>(`${this.urlNfce}/cancelamento/${id}/${justificativa}/${usuario}/${ambiente}`, '').toPromise();
}

cancelarNfe(usuario: string, id: number, tipo: string, justificativa: string, modo: number, ambiente: string): Promise<RetornoSefaz> {
  return this.http.post<RetornoSefaz>(`${this.urlNfe}/cancelamento/${id}/${justificativa}/${usuario}/${ambiente}`, '').toPromise();
}

cancelarNfse(id: number, ambiente: string): Promise<RetornoSefaz> {
  return this.http.post<RetornoSefaz>(`${this.urlNfse}/cancelamento/${id}/${ambiente}`, '').toPromise();
}

impressaoCancelamentoNFce(id: number): Promise<Blob> {
  return this.http.get<Blob>(`${this.urlNfce}/reciboCancelamento/${id}`, {responseType: 'blob' as 'json'}).toPromise();
}

impressaoCancelamentoNFe(id: number): Promise<Blob> {
  return this.http.get<Blob>(`${this.urlNfe}/reciboCancelamento/${id}`, {responseType: 'blob' as 'json'}).toPromise();
}

retornaXmlNFCe(id: number, ambiente: string): Promise<RetornoSefaz> {
  return this.http.get<RetornoSefaz>(`${this.urlNfce}/xml/${id}/${ambiente}`).toPromise();
 }

 retornaXmlNFe(id: number, ambiente: string): Promise<RetornoSefaz> {
  return this.http.get<RetornoSefaz>(`${this.urlNfe}/xml/${id}/${ambiente}`).toPromise();
 }

 retornaListaXmlNFCe(empresa: string, emissaoDe: Date, emissaoAte: Date, ambiente: string): Promise<RetornoSefaz> {
   const dtInicial = moment(emissaoDe).format('YYYY-MM-DD');
   const dtFinal = moment(emissaoAte).format('YYYY-MM-DD');

  return this.http.post<RetornoSefaz>(`${this.urlNfce}/exportar/${empresa}/${dtInicial}/${dtFinal}/${ambiente}`, '').toPromise();
 }

 retornaListaXmlNFe(empresa: string, emissaoDe: Date, emissaoAte: Date, ambiente: string): Promise<RetornoSefaz> {
  const dtInicial = moment(emissaoDe).format('YYYY-MM-DD');
  const dtFinal = moment(emissaoAte).format('YYYY-MM-DD');

 return this.http.post<RetornoSefaz>(`${this.urlNfe}/exportar/${empresa}/${dtInicial}/${dtFinal}/${ambiente}`, '').toPromise();
}

retornaListaXmlNFSe(empresa: string, emissaoDe: Date, emissaoAte: Date, ambiente: string): Promise<RetornoSefaz> {
  const dtInicial = moment(emissaoDe).format('YYYY-MM-DD');
  const dtFinal = moment(emissaoAte).format('YYYY-MM-DD');

 return this.http.post<RetornoSefaz>(`${this.urlNfse}/exportar/${empresa}/${dtInicial}/${dtFinal}/${ambiente}`, '').toPromise();
}

descartarNfeRejeitada(id: number, ambiente: string): Promise<RetornoSefaz> {
  return this.http.post<RetornoSefaz>(`${this.urlNfe}/descarta/${id}/${ambiente}`, '').toPromise();
}

descartarNfceRejeitada(id: number, ambiente: string): Promise<RetornoSefaz> {
 return this.http.post<RetornoSefaz>(`${this.urlNfce}/descarta/${id}/${ambiente}`, '').toPromise();
}

descartarNFSeRejeitada(id: number, ambiente: string): Promise<RetornoSefaz> {
 return this.http.post<RetornoSefaz>(`${this.urlNfse}/descarta/${id}/${ambiente}`, '').toPromise();
}


}
