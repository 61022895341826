import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import "rxjs/add/operator/toPromise";

import { environment } from "./../../environments/environment";
import { MoneyHttp } from "app/seguranca/money-http";

import { CodigoFiscal } from "./../core/models/CodigoFiscal";
import { CodigoFiscalCadastro } from './../core/models/CodigoFiscalCadastro';


export class CodigoFiscalFiltro {
  descricao: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class CodigoFiscalService {
  codigosFiscaisUrl: string;

  constructor(private http: MoneyHttp) {
    this.codigosFiscaisUrl = `${environment.apiUrl}/codigosFiscais`;
  }
  pesquisar(filtro: CodigoFiscalFiltro): Promise<any> {
    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });
    if (filtro.descricao) {
      params = params.append("descricao", filtro.descricao);
    }
    return this.http
      .get<any>(`${this.codigosFiscaisUrl}`, { params })
      .toPromise()
      .then(response => {
        const codigosFiscais = response.content;

        const resultado = {
          codigosFiscais,
          total: response.totalElements
        };

        return resultado;
      });
  }

  listarTodas(): Promise<any> {
    return this.http
      .get<any>(this.codigosFiscaisUrl)
      .toPromise()
      .then(response => response.content);
  }

  adicionar(codigoFiscalCadastro: CodigoFiscalCadastro): Promise<CodigoFiscal> {
    return this.http
      .post<CodigoFiscal>(this.codigosFiscaisUrl, codigoFiscalCadastro)
      .toPromise();
  }

  atualizar(codigoFiscal: CodigoFiscal): Promise<CodigoFiscal> {
    return this.http
      .put<CodigoFiscal>(
        `${this.codigosFiscaisUrl}/${codigoFiscal.id}`,
        codigoFiscal
      )
      .toPromise();
  }

  buscarPorId(id: number): Promise<CodigoFiscal> {
    return this.http
      .get<CodigoFiscal>(`${this.codigosFiscaisUrl}/${id}`)
      .toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.codigosFiscaisUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }
}
