import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import "rxjs/add/operator/toPromise";
import { Observable } from "rxjs";

import { environment } from "./../../environments/environment";
import { VendaPerdida } from "./../core/models/VendaPerdida";
import { MoneyHttp } from "app/seguranca/money-http";

export class VendaPerdidaFiltro {
  descricao: string;
  codigo: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class VendaPerdidaService {

  vendaPerdidasUrl: string;

  constructor(private http: MoneyHttp) {
    this.vendaPerdidasUrl = `${environment.apiUrl}/vendasPerdidas`;
  }

  pesquisar(filtro: VendaPerdidaFiltro): Promise<any> {

    let vendaPerdidasUrlPesquisar: string = `${this.vendaPerdidasUrl}/filter/descricao`;

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    if (filtro.descricao) {
      params = params.append('descricao', filtro.descricao);
    } 
    else if (filtro.codigo) {
      params = params.append('codigo', filtro.codigo);
      vendaPerdidasUrlPesquisar = `${this.vendaPerdidasUrl}/filter/codigo`;
    } 
    
    return this.http
    .get<any>(vendaPerdidasUrlPesquisar, { params })
    .toPromise()
    .then(response => {
      const vendaPerdidaes = response.content;

      const resultado = {
        vendaPerdidaes,
        total: response.totalElements
      };

      return resultado;
    });
}

  listarTodas(): Promise<any> {
    return this.http
      .get<any>(`${this.vendaPerdidasUrl}/lista`)
      .toPromise()
      .then(response => response.content);
  }

  adicionar(VendaPerdida: VendaPerdida): Promise<VendaPerdida> {
    return this.http.post<VendaPerdida>(this.vendaPerdidasUrl, VendaPerdida).toPromise();
  }

  atualizar(VendaPerdida: VendaPerdida): Promise<VendaPerdida> {
    return this.http
      .put<VendaPerdida>(`${this.vendaPerdidasUrl}/${VendaPerdida.id}`, VendaPerdida)
      .toPromise();
  }

  buscarPorId(id: number): Promise<VendaPerdida> {
    return this.http.get<VendaPerdida>(`${this.vendaPerdidasUrl}/${id}`).toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.vendaPerdidasUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }
}