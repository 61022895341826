import {Injectable} from '@angular/core';
import {BalancoProdutoLocal} from '../core/models/BalancoProdutoLocal';
import {Local} from '../core/models/Local';
import {ToastyService} from 'ng2-toasty';

import {Balanco} from '../core/models/Balanco';
import * as moment from 'moment';

@Injectable()
export class BalancoContagemService {

  constructor(private toasty: ToastyService) {
  }

  checarCodigoLocal(codigoBarra: string): boolean {
    if (codigoBarra.length === 17) {
      if (codigoBarra.substring(2, 3) === '.' &&
        codigoBarra.substring(5, 6) === '.' &&
        codigoBarra.substring(8, 9) === '.' &&
        codigoBarra.substring(11, 12) === '.' &&
        codigoBarra.substring(14, 15) === '.') {
        return true;
      }
    }
    return false;
  }

  obterBalancoProdutoLocal(codigoBarra: string, balanco: Balanco): BalancoProdutoLocal {
    let balancoProdutoLocal: BalancoProdutoLocal;
    balancoProdutoLocal = balanco.balancoProdutoLocalList.find(p =>
      p.produto.codigoEan === codigoBarra ||
      p.produto.codigo === codigoBarra
    );
    return balancoProdutoLocal;
  }

  processarContagem(local: Local, codigoBarra: string, balanco: Balanco, contagem:number): BalancoProdutoLocal {
    if (local.endereco) {
      let balancoProdutoLocal: BalancoProdutoLocal = this.obterBalancoProdutoLocal(codigoBarra, balanco);
      if (balancoProdutoLocal) {

        if (this.validarContagem(local, balancoProdutoLocal))
          return null;

        balancoProdutoLocal.data = new Date();
        balancoProdutoLocal.contagem = contagem == null ? (balancoProdutoLocal.contagem + 1) : contagem;
        balancoProdutoLocal.local = local;

        return balancoProdutoLocal

      } else {
        this.toasty.error('Produto não pertence a este balanço!');
      }
    } else {
      this.toasty.error('Deve-se primeiro SCANEAR o código Barra LOCAL ENDEREÇO!');
    }
  }

  validarContagem(local: Local, balancoProdutoLocal): boolean {

    if (balancoProdutoLocal.local && balancoProdutoLocal.local.endereco != local.endereco) {
      this.toasty.error(`Produto conferido no endereço: ${balancoProdutoLocal.local.endereco}`);
      return true;
    }

    return false
  }

}
