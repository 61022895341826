import { Injectable } from '@angular/core';
import {MoneyHttp} from '../seguranca/money-http';
import {environment} from '../../environments/environment';
import {HttpParams} from '@angular/common/http';
import * as moment from 'moment';
import {NotaEntrada} from '../core/models/NotaEntrada';
import {AuthService} from '../seguranca/auth.service';
import {Fornecedor} from '../core/models/Fornecedor';
import {NotaDevolucao} from '../core/models/NotaDevolucao';


export class NotaEntradaFiltro {
  chaveAcesso: string;
  empresa: string[];
  documento: string;
  serie: string[];
  fornecedor: string[];
  dataDe: Date;
  dataAte: Date;
  pagina = 0;
  itensPorPagina = 10;
  forneced: Fornecedor;

}

@Injectable()
export class NotaEntradaService {

  url: string;
  urlNotaDevolucao: string;

  constructor(
    private auth: AuthService,
    private http: MoneyHttp,
  ) {
    this.url = `${environment.apiUrl}/notasEntradas`;
    this.urlNotaDevolucao = `${environment.apiUrl}/notasDevolucoes`;
  }


  pesquisar(filtro: NotaEntradaFiltro): Promise<any> {

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    params = params.append('empresaUsuario', this.auth.jwtPayload.empresa);
    params = params.append('usuario', this.auth.jwtPayload.login);

    if (filtro.chaveAcesso) {
      params = params.append('chave', filtro.chaveAcesso);
    }

    if (filtro.empresa && filtro.empresa.length > 0) {
      params = params.append('empresa', filtro.empresa[0]);
    }

    if (filtro.documento) {
      params = params.append('documento', filtro.documento);
    }

    if (filtro.serie && filtro.serie.length > 0) {
      params = params.append('serie', filtro.serie[0]);
    }

    if (filtro.forneced && filtro.forneced.codigo) {
      params = params.append(
        'fornecedor',  filtro.forneced.codigo);
    }

    if (filtro.dataDe) {
      params = params.append(
        'dataDe',
        moment(filtro.dataDe).format('YYYY-MM-DD 00:00')
      );
    }

    if (filtro.dataAte) {
      params = params.append(
        'dataAte',
        moment(filtro.dataAte).format('YYYY-MM-DD 23:59')
      );
    }

    return this.http
      .get<any>(`${this.url}/pesquisar/empresaUsuario/chave/empresa/documento/serie/fornecedor/dataDe/dataAte/usuario`,
        {params})
      .toPromise()
      .then(response => {
        const resultado = {
          notas: response,
          total: response.totalElements
        };
        return resultado;
      });
  }

  buscarPorId(id: number): Promise<NotaEntrada> {
    return this.http
      .get<NotaEntrada>(`${this.url}/${id}`)
      .toPromise()
      .then(response => {
        return response;
      });
  }

  calcularNota(notaEntrada:NotaEntrada): Promise<NotaEntrada> {
    return this.http.put<NotaEntrada>(`${this.url}/calcularNota` , notaEntrada).toPromise();
  }

  efetuarEntrada(notaEntrada:NotaEntrada): Promise<NotaEntrada> {
    notaEntrada.incluirUsuario = this.auth.jwtPayload.login;
    notaEntrada.incluirData = new Date();
    return this.http.put<NotaEntrada>(`${this.url}/efetuarEntrada`, notaEntrada).toPromise();
  }


  atualizarNota(notaEntrada:NotaEntrada): Promise<NotaEntrada> {
    return this.http.put<NotaEntrada>(`${this.url}/atualizarNota`, notaEntrada).toPromise();
  }

  impressaoDanfe(file: File): Promise<Blob> {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    return this.http.post<Blob>(`${this.url}/impressaoDanfe`, formdata, {responseType: 'blob' as 'json'}).toPromise();
  }

  atualizar(notaEntrada:NotaEntrada): Promise<NotaEntrada> {
    return this.http.put<NotaEntrada>(`${this.url}/${notaEntrada.id}`, notaEntrada).toPromise();
  }

  transformarNotaNE(notaEntrada:NotaEntrada): Promise<NotaEntrada> {
    return this.http.put<NotaEntrada>(`${this.url}/transformarNotaNE/${this.auth.jwtPayload.empresa}`, notaEntrada).toPromise();
  }

  adicionarNotaDevolucao(notaDevolucao: NotaDevolucao): Promise<NotaDevolucao> {
    return this.http.post<NotaDevolucao>(this.urlNotaDevolucao, notaDevolucao).toPromise();
  }

  excluirNotaDevolucao(id: number): Promise<any> {
    return this.http
      .delete(`${this.urlNotaDevolucao}/${id}`)
      .toPromise();
  }

  pesquisarPorCnpj(cnpj: string): Promise<Array<NotaEntrada>> {

    let params = new HttpParams({
      fromObject: {
      }
    });

    params = params.append('cnpj', cnpj);

    return this.http
      .get<Array<NotaEntrada>>(`${this.url}/pesquisarPorCnpj/cnpj`,
        {params})
      .toPromise();
  }


}
