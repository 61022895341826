import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import "rxjs/add/operator/toPromise";

import { environment } from "../../environments/environment";
import { Unidade } from "../core/models/Unidade";
import { MoneyHttp } from "app/seguranca/money-http";

export class UnidadeFiltro {
  codigo: string;
  descricao: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class UnidadeService {

  unidadesUrl: string;

  constructor(private http: MoneyHttp) {
    this.unidadesUrl = `${environment.apiUrl}/unidades`;
  }

  pesquisar(filtro: UnidadeFiltro): Promise<any> {

    let unidadesUrlPesquisar: string = `${this.unidadesUrl}/filter/descricao`;

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    if (filtro.descricao) {

      params = params.append("descricao", filtro.descricao);
      
    } else if (filtro.codigo) {

      params = params.append("codigo", filtro.codigo);
      unidadesUrlPesquisar = `${this.unidadesUrl}/filter/codigo`;

    }
    return this.http
    .get<any>(unidadesUrlPesquisar, { params })
    .toPromise()
    .then(response => {
      const unidades = response.content;

      const resultado = {
        unidades,
        total: response.totalElements
      };

      return resultado;
    });
}

  listarTodas(): Promise<any> {
    return this.http
      .get<any>(`${this.unidadesUrl}/lista`)
      .toPromise()
      .then(response => response.content);
  }

  adicionar(Unidade: Unidade): Promise<Unidade> {
    return this.http.post<Unidade>(this.unidadesUrl, Unidade).toPromise();
  }

  atualizar(Unidade: Unidade): Promise<Unidade> {
    return this.http
      .put<Unidade>(`${this.unidadesUrl}/${Unidade.id}`, Unidade)
      .toPromise();
  }

  buscarPorId(id: number): Promise<Unidade> {
    return this.http.get<Unidade>(`${this.unidadesUrl}/${id}`).toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.unidadesUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }

  buscarPorCodigo(codigo: string): Promise<Unidade> {
    return this.http.get<Unidade>(`${this.unidadesUrl}/porCodigo/${codigo}`).toPromise();
  }

}
