import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import 'rxjs/add/operator/toPromise';
import { Observable } from 'rxjs';

import { environment } from './../../environments/environment';
import { Vendedor } from './../core/models/Vendedor';
import { NotaRelatorioDTO } from 'app/core/models/dtos/NotaRelatorioDTO';
import { MoneyHttp } from 'app/seguranca/money-http';
import * as moment from 'moment';
import {SelectItem} from 'primeng/api';

export class VendedorFiltro {
  nome: string;
  codigo: string;
  pagina = 0;
  itensPorPagina = 10;
}

export class VendedorRelatorioDTOFiltro {
  empresa: string;
  codigo: string;
  dtInicio: Date;
  dtFim: Date;
  series: string;
}

@Injectable()
export class VendedorService {

  vendedoresUrl: string;

  constructor(private http: MoneyHttp) {
    this.vendedoresUrl = `${environment.apiUrl}/vendedores`;
  }

  pesquisar(filtro: VendedorFiltro): Promise<any> {

    let vendedoresUrlPesquisar: string = `${this.vendedoresUrl}/filter/nome`;

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    if (filtro.nome) {
      params = params.append('nome', filtro.nome);
    } else if (filtro.codigo) {
      params = params.append('codigo', filtro.codigo);
      vendedoresUrlPesquisar = `${this.vendedoresUrl}/filter/codigo`;
    }

    return this.http
    .get<any>(vendedoresUrlPesquisar, { params })
    .toPromise()
    .then(response => {
      const vendedores = response.content;

      const resultado = {
        vendedores,
        total: response.totalElements
      };

      return resultado;
    });
}

next(cod_vend: string): Promise<Vendedor> {
  let params = new HttpParams({
    fromObject: {
      codigo: cod_vend
    }
  });
  return this.http.get<Vendedor>(`${this.vendedoresUrl}?next`, {params}).toPromise();
}

previous(cod_vend: string): Promise<Vendedor> {
  let params = new HttpParams({
    fromObject: {
      codigo: cod_vend
    }
  });
  return this.http.get<Vendedor>(`${this.vendedoresUrl}?previous`, {params}).toPromise();
}

  listarTodas(): Promise<any> {
    return this.http
      .get<any>(`${this.vendedoresUrl}/lista`)
      .toPromise()
      .then(response => response);
  }

  adicionar(vendedor: Vendedor): Promise<Vendedor> {
    return this.http.post<Vendedor>(this.vendedoresUrl, vendedor).toPromise();
  }

  atualizar(vendedor: Vendedor): Promise<Vendedor> {
    return this.http
      .put<Vendedor>(`${this.vendedoresUrl}/${vendedor.id}`, Vendedor)
      .toPromise();
  }

  buscarPorId(id: number): Promise<Vendedor> {
    return this.http.get<Vendedor>(`${this.vendedoresUrl}/${id}`).toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.vendedoresUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }

  listarTodosPorEmpresa(empresa:string): Promise<Array<Vendedor>> {
    return this.http
      .get<Array<Vendedor>>(`${this.vendedoresUrl}/listaPorEmpresa/${empresa}`)
      .toPromise();
  }

  buscarPorNomeUsuarioVendedor(nome: String): Promise<Vendedor> {
   return this.http.get<Vendedor>(`${this.vendedoresUrl}/filter/usuario/${nome}`).toPromise();
  }

  buscarPeloCodigo(codigo: string): Observable<Vendedor> {
    return this.http.get<Vendedor>(`${this.vendedoresUrl}/buscarPeloCodigo/${codigo}`);
  }

  buscarPeloCodigoVendedor(codigo: string): Promise<Vendedor> {
    return this.http.get<Vendedor>(`${this.vendedoresUrl}/buscarPeloCodigo/${codigo}`).toPromise();
  }

  pesquisarNotaAbertaeNaoPaga(empresa: String, dtInicio: Date, dtFim: Date, series: String) {
    return this.http
      .get<any>(`${this.vendedoresUrl}/filtrar/notasAbertasEnaoPagas/${empresa}/${dtInicio}/${dtFim}/${series}`)
      .toPromise()
      .then(response => {
        const notasDTO = response.content;
  
        const resultado = {
          notasDTO,
          total: response.totalElements
        };
  
        return resultado;
      });
  }

  listarTotaisPorVendedorRelatorio(filtro: VendedorRelatorioDTOFiltro, totVF: boolean, ncVF: boolean  ): Promise<Blob> {
    return this.http
        .get<Blob>(`${this.vendedoresUrl}/relatorio/filtraPorNotasFechadasEpagas?dtInicio=${moment(filtro.dtInicio).format('YYYY-MM-DD')}&dtFim=${moment(filtro.dtFim).format('YYYY-MM-DD')}&coEmpresa=${filtro.empresa}&coVendedor=${filtro.codigo}&incluirSerieNC=${ncVF}&totalizar=${totVF}`,{responseType: 'blob' as 'json'}).toPromise()
        .then(response => {
           return response;
        });
  }

  listarTotaisPorVendedor(filtro: VendedorRelatorioDTOFiltro, ncVF: boolean  ) {
    return this.http
        .get<any[]>(`${this.vendedoresUrl}/filtrar/filtraPorNotasFechadasEpagas?dtInicio=${moment(filtro.dtInicio).format('YYYY-MM-DD')}&dtFim=${moment(filtro.dtFim).format('YYYY-MM-DD')}&coEmpresa=${filtro.empresa}&coVendedor=${filtro.codigo}&incluirSerieNC=${ncVF}`)
        .toPromise()
        .then(response => {
           return response;
        });
  }

  getVendedores(nome: string) {
    return this.http
      .get<any>(`${this.vendedoresUrl}/vendedores/${nome}`)
      .toPromise()
      .then(response => {
        return response;
      });
  }

  getVendedoresSelect(): Promise<SelectItem[]> {
    return new Promise((resolve, reject) => {
      this.listarTodas().then(ss => {
          resolve(ss.map(vendedor => {
              return {
                label: `${vendedor.codigo} - ${vendedor.nome}`,
                value: vendedor.codigo
              }
            })
          );
        }
      ).catch(error => reject(error));
    });
  }

}

