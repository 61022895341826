import {Parametro} from 'app/core/models/Parametro';
import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';

import 'rxjs/add/operator/toPromise';

import {environment} from '../../../environments/environment';
import {MoneyHttp} from 'app/seguranca/money-http';
import * as moment from 'moment';
import {PreVenda} from './../../core/models/PreVenda';
import {DetImp2} from './../../core/models/DetImp2';
import {CodigoLeiNcm} from 'app/core/models/CodigoLeiNcm';
import {ProdutoCautela} from './../../core/models/ProdutoCautela';
import {Liberacao} from './../../core/models/Liberacao';
import {AuthService} from '../../seguranca/auth.service';


export class PreVendaFiltro {
  empresa: string;
  sequencia: string;
  valorMinimo: number;
  dataDe: Date;
  dataAte: Date;
  codigoNatOper: string;
  documento: string;
  vendedor: string;
  pagina = 0;
  itensPorPagina = 10;
}

export class DetImp2Filtro {
  origem: string;
  destino: string;
  contribuinte: string;
  revendedor: string;
  suframa: string;
  produtorRural: string;
  codigoNat: string;
  rte: string;
  cfop: string;
  derivadoPetroleo: string;
  codigoLei: string;
}

@Injectable()
export class PreVendaService {

  preVendasUrl: string;
  parametrosUrl: string;
  detimp2Url: string;
  codigoLeiNcmUrl: string;
  produtoCautelaUrl: string;
  liberacaoUrl: string;

  constructor(
    private auth: AuthService,
    private http: MoneyHttp
  ) {
    this.preVendasUrl = `${environment.apiUrl}/preVendas`;
    this.parametrosUrl = `${environment.apiUrl}/parametros`;
    this.detimp2Url = `${environment.apiUrl}/detImp2`;
    this.codigoLeiNcmUrl = `${environment.apiUrl}/codigosLeiNcm`
    this.produtoCautelaUrl = `${environment.apiUrl}/produtosCautelas`;
    this.liberacaoUrl = `${environment.apiUrl}/liberacoes`;
  }

  pesquisar(filtro: PreVendaFiltro): Promise<any> {
    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    if (filtro.dataDe) {
      params = params.append(
        'dataDe',
        moment(filtro.dataDe).format('YYYY-MM-DD')
      );
    }

    if (filtro.dataAte) {
      params = params.append(
        'dataAte',
        moment(filtro.dataAte).format('YYYY-MM-DD')
      );
    }

    if (filtro.dataDe && filtro.dataAte) {
      this.preVendasUrl = `${environment.apiUrl}/preVendas/?periodo`;
    }

    if (filtro.sequencia) {
      params = new HttpParams({
        fromObject: {
          page: filtro.pagina.toString(),
          size: filtro.itensPorPagina.toString()
        }
      });
      params = params.append('sequencia', filtro.sequencia);
      this.preVendasUrl = `${environment.apiUrl}/preVendas/?sequencial`;
    }

    if (filtro.empresa) {
      params = params.append('empresa', filtro.empresa);
    }

    if (filtro.vendedor) {
      params = params.append('vendedor', filtro.vendedor);
    }

    return this.http
      .get<any>(`${this.preVendasUrl}`, {params})
      .toPromise()
      .then(response => {
        const preVendas = response.content;

        const resultado = {
          preVendas,
          total: response.totalElements
        };

        return resultado;
      });
  }

  listarTodas(): Promise<any> {
    this.preVendasUrl = `${environment.apiUrl}/preVendas`;
    return this.http
      .get<any>(this.preVendasUrl)
      .toPromise()
      .then(response => response.content);
  }

  adicionar(preVenda: PreVenda): Promise<PreVenda> {
    this.preVendasUrl = `${environment.apiUrl}/preVendas`;
    return this.http.post<PreVenda>(this.preVendasUrl, preVenda).toPromise();
  }

  atualizar(preVenda: PreVenda): Promise<PreVenda> {
    this.preVendasUrl = `${environment.apiUrl}/preVendas`;
    return this.http.put<PreVenda>(`${this.preVendasUrl}/${preVenda.id}`, preVenda).toPromise();
  }

  recalcularPrecos(preVenda: PreVenda): Promise<PreVenda> {
    this.preVendasUrl = `${environment.apiUrl}/preVendas`;
    return this.http.put<PreVenda>(`${this.preVendasUrl}/recalcular/${preVenda.id}`, preVenda).toPromise();
  }

  verificarTotaisDiferenteItens(preVenda: PreVenda) {
    this.preVendasUrl = `${environment.apiUrl}/preVendas`;
    this.http.get<number>(`${this.preVendasUrl}/checarTotais/${preVenda.empresa}/${preVenda.sequencia}`).toPromise()
      .then(response => {
        const resultado = {
          numero: response,
        };
        return resultado;
      });
  }

  buscarParametros(empresaLetra: string): Promise<Parametro> {
    const params = new HttpParams({
      fromObject: {
        empresaLetra: empresaLetra
      }
    });
    return this.http.get<Parametro>(`${this.parametrosUrl}?empresa`, {params}).toPromise();
  }

  buscarCodigoTexto(filtro: DetImp2Filtro): Promise<DetImp2> {
    const params = new HttpParams({
      fromObject: {
        origem: filtro.origem,
        destino: filtro.destino,
        contribuinte: filtro.contribuinte,
        suframa: filtro.suframa,
        produtoRural: filtro.produtorRural,
        codigoLei: filtro.codigoLei,
        codigoNat: filtro.codigoNat,
        rte: filtro.rte,
        derivadoPetroleo: filtro.derivadoPetroleo
      }
    });
    return this.http.get<DetImp2>(`${this.detimp2Url}?codtexto`, {params}).toPromise();
  }

  buscarCodigoFiscalOperacao(filtro: DetImp2Filtro): Promise<DetImp2> {
    const params = new HttpParams({
      fromObject: {
        origem: filtro.origem,
        destino: filtro.destino,
        codigoNat: filtro.codigoNat,
        rte: filtro.rte,
        contribuinte: filtro.contribuinte,
        revendedor: filtro.revendedor,
        suframa: filtro.suframa,
        produtoRural: filtro.produtorRural,
        codigoLei: filtro.codigoLei,
        derivadoPetroleo: filtro.derivadoPetroleo
      }
    });
    return this.http.get<DetImp2>(`${this.detimp2Url}?cfop`, {params}).toPromise();
  }


  buscarCodigoLeiNcm(ncm: string): Promise<CodigoLeiNcm[]> {
    return this.http.get<CodigoLeiNcm[]>(`${this.codigoLeiNcmUrl}/lista/${ncm}`).toPromise();
  }

  next(seq_pre: string, vendedor: string): Promise<PreVenda> {
    this.preVendasUrl = `${environment.apiUrl}/preVendas`;
    const params = new HttpParams({
      fromObject: {
        sequencia: seq_pre,
        Vendedor: vendedor
      }
    });
    return this.http.get<PreVenda>(`${this.preVendasUrl}?next`, {params}).toPromise();
  }

  previous(seq_pre: string, vendedor: string): Promise<PreVenda> {
    this.preVendasUrl = `${environment.apiUrl}/preVendas`;
    const params = new HttpParams({
      fromObject: {
        sequencia: seq_pre,
        vendedor: vendedor
      }
    });
    return this.http.get<PreVenda>(`${this.preVendasUrl}?previous`, {params}).toPromise();
  }

  buscarPorId(id: number): Promise<PreVenda> {
    this.preVendasUrl = `${environment.apiUrl}/preVendas`;
    return this.http
      .get<PreVenda>(`${this.preVendasUrl}/${id}`)
      .toPromise()
      .then(response => {
        const preVenda = response;

        this.converterStringsParaDatas([preVenda]);

        return preVenda;
      });
  }

  private converterStringsParaDatas(preVendas: PreVenda[]) {
    for (const preVenda of preVendas) {
      preVenda.data = moment(
        preVenda.data,
        'YYYY-MM-DD'
      ).toDate();

    }
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.preVendasUrl}/${id}`)
      .toPromise()
      .then(() => null);

  }

  gerarPreVendaDevolucao(documento: string, serie: string, cliente: string) {
    this.preVendasUrl = `${environment.apiUrl}/preVendas`;
    const empresa = this.auth.jwtPayload.empresa;

    const params = new HttpParams({
      fromObject: {
        empresa,
        documento,
        serie,
        cliente
      }
    });

    return this.http
      .get<any>(`${this.preVendasUrl}/gerarDevolucao/empresa/documento/serie/cliente`, {params})
      .toPromise()
      .then(response => {
        const resultado = {
          preVenda: response,
        };
        return resultado;
      });
  }

  gerarNE(preVenda: PreVenda): Promise<PreVenda> {
    this.preVendasUrl = `${environment.apiUrl}/preVendas/gerarNE`;
    return this.http.get<PreVenda>(`${this.preVendasUrl}/${preVenda.id}`).toPromise();
  }

  produtoCautela(id: string): Promise<any> {
    let params = new HttpParams({
      fromObject: {
        page: '0',
        size: '5'
      }
    });
    params = params.append('id', id);
    return this.http
      .get<any>(`${this.produtoCautelaUrl}/id`, {params})
      .toPromise()
      .then(response => {
        const produtosCautelas = response.content;
        const resultado = {
          produtosCautelas,
          total: response.totalElements
        };
        return resultado;
      });
  }

  verificarCautelasAbertas(item: ProdutoCautela): Promise<ProdutoCautela> {
    return this.buscarCautelasEmAberto(item)
      .then(resultado => {
        return resultado;
      });
  }

  buscarCautelasPreVenda(preVenda: PreVenda): Promise<ProdutoCautela[]> {
    return this.http.get<ProdutoCautela[]>(`${this.produtoCautelaUrl}/listarAbertas/${preVenda.empresa}/${preVenda.sequencia}`).toPromise();
  }

  buscarCautelasPorEmpresaRetirante(empresa: string, retirante: string): Promise<ProdutoCautela[]> {
    return this.http.get<ProdutoCautela[]>(`${this.produtoCautelaUrl}/listarAbertasPorRetirante/${empresa}/${retirante}`).toPromise();
  }

  buscarCautelasEmAberto(pc: ProdutoCautela): Promise<ProdutoCautela> {
    const params = new HttpParams({
      fromObject: {
        empresa: pc.empresa,
        codigo: pc.codigo,
        retirante: pc.retirante
      }
    });
    return this.http.get<ProdutoCautela>(`${this.produtoCautelaUrl}?abertas`, {params}).toPromise();
  }

  buscarCautelasEmAbertoUltimaUnidade(pc: ProdutoCautela): Promise<ProdutoCautela> {
    const params = new HttpParams({
      fromObject: {
        empresa: pc.empresa,
        codigo: pc.codigo
      }
    });
    return this.http.get<ProdutoCautela>(`${this.produtoCautelaUrl}?ultimaunidade`, {params}).toPromise();
  }

  buscarCautelasEmAbertoTempoExcedido(pc: ProdutoCautela): Promise<ProdutoCautela> {
    const params = new HttpParams({
      fromObject: {
        empresa: pc.empresa,
        retirante: pc.retirante
      }
    });
    return this.http.get<ProdutoCautela>(`${this.produtoCautelaUrl}?tempoexcedido`, {params}).toPromise();
  }

  adicionarCautela(produtoCautela: ProdutoCautela): Promise<ProdutoCautela> {
    return this.http.post<ProdutoCautela>(this.produtoCautelaUrl, produtoCautela).toPromise();
  }

  atualizarCautela(produtoCautela: ProdutoCautela): Promise<ProdutoCautela> {
    return this.http.put<ProdutoCautela>(`${this.produtoCautelaUrl}/${produtoCautela.id}`, produtoCautela).toPromise();
  }

  adicionarLiberacao(liberacao: Liberacao): Promise<Liberacao> {
    return this.http.post<Liberacao>(this.liberacaoUrl, liberacao).toPromise();
  }

  impressaoPreVenda(preVenda: PreVenda, imprimeCodigoFabricante: boolean): Promise<Blob> {
    this.preVendasUrl = `${environment.apiUrl}/preVendas`;
    return this.http.get<Blob>(`${this.preVendasUrl}/impressao/${imprimeCodigoFabricante}/${preVenda.id}`, {responseType: 'blob' as 'json'}).toPromise();
  }

  impressaoOrcamento(preVenda: PreVenda, imprimeCodigoInterno: boolean): Promise<Blob> {
    this.preVendasUrl = `${environment.apiUrl}/preVendas`;
    return this.http.get<Blob>(`${this.preVendasUrl}/orcamento/${imprimeCodigoInterno}/${preVenda.id}`, {responseType: 'blob' as 'json'}).toPromise();
  }

  impressaoCautela(preVenda: PreVenda): Promise<Blob> {
    this.preVendasUrl = `${environment.apiUrl}/preVendas`;
    return this.http.get<Blob>(`${this.preVendasUrl}/cautela/${preVenda.id}`, {responseType: 'blob' as 'json'}).toPromise();
  }

  formataValores(n) {
    return 'R$ ' + n.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, '$1.');
  }

  incluirUsuarioPreVenda(preVenda: PreVenda) {
    preVenda.incluirUsuario = this.auth.jwtPayload.login;
    preVenda.incluirData = new Date();
    preVenda.modificarUsuario = this.auth.jwtPayload.login;
    preVenda.modificarData = new Date();

    preVenda.preVendaItens = preVenda.preVendaItens.map(item => {
      item.incluirUsuario = preVenda.incluirUsuario;
      item.incluirData = preVenda.incluirData;
      item.modificarUsuario = item.incluirUsuario;
      item.modificarData = item.incluirData;
      return item;
    });
  }

  calcularPrazoMedio(desdobramento: string): number {
    const desdobra = desdobramento;
    const prazos = [];
    let totalDias = 0;
    let qtdParcelas = 1;

    totalDias = parseFloat(desdobra.substring(0, 3));
    prazos.push(totalDias);

    for (let i = 3; i < desdobra.length; ++i) {
      if (desdobra.substr(i, 1) === '/') {
        prazos.push(desdobra.substring(i + 1, i + 4));
        if (desdobra.substring(i + 1, i + 4) !== '   ') {
          totalDias = totalDias + parseFloat(desdobra.substring(i + 1, i + 4));
          qtdParcelas++;
        }
      }
    }

    return (totalDias / qtdParcelas) <= 0 ? 0 : (totalDias / qtdParcelas);
  }

  buscarPorDocumentoSerie(documento: string, serie: string): Promise<PreVenda> {
    this.preVendasUrl = `${environment.apiUrl}/preVendas`;

    const params = new HttpParams({
      fromObject: {
        documento,
        serie
      }
    });

    return this.http
      .get<PreVenda>(`${this.preVendasUrl}/buscarDocumento/documento/serie`, {params})
      .toPromise();
  }


  buscarPorSequencia(empresa: string, sequencia: string): Promise<PreVenda> {
    this.preVendasUrl = `${environment.apiUrl}/preVendas`;

    const params = new HttpParams({
      fromObject: {
        empresa,
        sequencia
      }
    });

    return this.http
      .get<PreVenda>(`${this.preVendasUrl}/buscarSequencia/empresa/sequencia`, {params})
      .toPromise();
  }

  reimpressaoCautela(produtoCautela: ProdutoCautela): Promise<Blob> {
    return this.http.get<Blob>(`${this.produtoCautelaUrl}/reimpressaoCautela/${produtoCautela.id}`, {responseType: 'blob' as 'json'}).toPromise();
  }

  impressaoDocumentosGeradosPeloOrcamento(documentos: string): Promise<Blob> {
    this.preVendasUrl = `${environment.apiUrl}/preVendas`;

    const params = new HttpParams({
      fromObject: {
        documentos
      }
    });

    return this.http.get<Blob>(`${this.preVendasUrl}/impressaoDocumentos/documentos`,
      {
        responseType: 'blob' as 'json',
        params
      }
    ).toPromise();
  }

}
