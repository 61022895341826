import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { MoneyHttp } from 'app/seguranca/money-http';

@Injectable()
export class AcertoEstoqueService {

  acertoUrl: string;

  constructor(
    private http: MoneyHttp
  ) {
    this.acertoUrl = `${environment.apiUrl}/acerto`;
  }

}