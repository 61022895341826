import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import "rxjs/add/operator/toPromise";

import { environment } from "../../../environments/environment";
import { MoneyHttp } from "app/seguranca/money-http";

import { TabAnpProduto } from "app/core/models/TabAnpProduto";

export class TabAnpProdutoFiltro {
  produto: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class TabAnpProdutoService {
    tabAnpProdutosUrl: string;

  constructor(private http: MoneyHttp) {
    this.tabAnpProdutosUrl = `${environment.apiUrl}/tabAnpProdutos`;
  }

  pesquisar(filtro: TabAnpProdutoFiltro): Promise<any> {

    let tabAnpProdutosUrlPesquisar = `${this.tabAnpProdutosUrl}/filter/produto`;

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    if (filtro.produto) {
      params = params.append('produto', filtro.produto);
    }

    return this.http
    .get<any>(tabAnpProdutosUrlPesquisar, { params })
    .toPromise()
    .then(response => {
      const tabAnpProdutos = response.content;

      const resultado = {
        tabAnpProdutos,
        total: response.totalElements
      };

      return resultado;
    });
}

  listarTodas(): Promise<any> {
    return this.http
      .get<any>(`${this.tabAnpProdutosUrl}/lista`)
      .toPromise()
      .then(response => response.content);
  }

  adicionar(TabAnpProdutos: TabAnpProduto): Promise<TabAnpProduto> {
    return this.http.post<TabAnpProduto>(this.tabAnpProdutosUrl, TabAnpProdutos).toPromise();
  }

  atualizar(TabAnpProdutos: TabAnpProduto): Promise<TabAnpProduto> {
    return this.http
      .put<TabAnpProduto>(
        `${this.tabAnpProdutosUrl}/${TabAnpProdutos.id}`,
        TabAnpProdutos      )
      .toPromise();
  }

  buscarPorId(id: number): Promise<TabAnpProduto> {
    return this.http
      .get<TabAnpProduto>(`${this.tabAnpProdutosUrl}/${id}`)
      .toPromise();
  }

  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.tabAnpProdutosUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }
}
