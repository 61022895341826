import {Injectable} from '@angular/core';

import 'rxjs/add/operator/toPromise';

import {MoneyHttp} from 'app/seguranca/money-http';

import {environment} from '../../../../environments/environment';
import {TituloCobranca} from '../../../core/models/TituloCobranca';
import {Titulo} from '../../../core/models/Titulo';


@Injectable()
export class TituloCobrancaService{

  url: string;

  constructor(private http: MoneyHttp) {
    this.url = `${environment.apiUrl}/tituloCobrancas`;
  }


  adicionar(tituloCobranca: TituloCobranca): Promise<TituloCobranca> {
    return this.http.post<TituloCobranca>(this.url, tituloCobranca).toPromise();
  }

  atualizar(tituloCobranca: TituloCobranca): Promise<TituloCobranca> {
    return this.http
      .put<TituloCobranca>(`${this.url}/${tituloCobranca.id}`, tituloCobranca)
      .toPromise();
  }

  buscarPorId(id: number): Promise<TituloCobranca> {
    return this.http.get<TituloCobranca>(`${this.url}/${id}`).toPromise();
  }


  listarTodasPorEmpresaDocumentoSerie(empresa: string, documento: string, serie: string): Promise<Array<TituloCobranca>> {
    return this.http.get<Array<TituloCobranca>>(`${this.url}/porEmpresaDocumentoSerie/${empresa}/${documento}/${serie}`).toPromise();
  }

}
