import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';

import 'rxjs/add/operator/toPromise';

import {environment} from './../../environments/environment';
import {MoneyHttp} from 'app/seguranca/money-http';

import {Cliente} from './../core/models/Cliente';
import {ClienteTipo} from './../core/models/ClienteTipo';
import {ClienteHistorico} from './../core/models/ClienteHistorico';
import {AuthService} from '../seguranca/auth.service';
import * as moment from 'moment';
import {SelectItem} from 'primeng/api';
import {Nota} from '../core/models/Nota';
import {Titulo} from '../core/models/Titulo';
import {Cheque} from '../core/models/Cheque';
import {ClienteFormaPagamento} from '../core/models/ClienteFormaPagamento';
import {FormaPagamento} from '../core/models/FormaPagamento';


export class ClienteFiltro {
  nome: string;
  codigo: string;
  cnpjCpf: string;
  pagina = 0;
  itensPorPagina = 10;
}

export class SituacaoFinanceiraCliente {

  cliente: Cliente;

  notas: number;
  titulos: number;
  cheques: number;
  notasAcimaToleracia: number;
  titulosAcimaTolerancia: number;
  chequesAcimaTolerancia: number;
  saldoValeCredito: number;
  totalAberto: number;

  notasAbertas: Array<Nota>;
  titulosAbertos: Array<Titulo>;
  notasEmitidas: Array<Nota>;
  titulosEmitidos: Array<Titulo>;
  chequesAbertos: Array<Cheque>;
  clienteFormaPagamentos: Array<FormaPagamento>;
  clienteHistoricos: Array<ClienteHistorico>;

  notasAbertasAcimaTolerancia: Array<Nota>;
  titulosAbertosAcimaTolerancia: Array<Titulo>;
  chequesAbertosAcimaTolerancia: Array<Cheque>;

  dataCalculo: Date;
  jurosCalculo: number;

  diasAtrasoNotas: number;
  diasAtrasoTitulos: number;
  diasMaximoAtrasoTitulo: number;

}

@Injectable()
export class ClienteService {
  clientesUrl: string;
  clientesTiposUrl: string;
  clientesHistoricoUrl: string;
  clienteStatusUrl: string;
  viaCepUrl: string;

  constructor(
    private http: MoneyHttp,
    private auth: AuthService
  ) {
    this.clientesUrl = `${environment.apiUrl}/clientes`;
    this.clientesTiposUrl = `${environment.apiUrl}/clientesTipos/lista`;
    this.clientesHistoricoUrl = `${environment.apiUrl}/clientesHistoricos`;
    this.clienteStatusUrl = `${environment.apiUrl}/clientes/listaClienteStatus`;
    this.viaCepUrl = `https://viacep.com.br/ws/`;

  }

  pesquisar(filtro: ClienteFiltro): Promise<any> {

    let clientesUrlPesquisar = `${this.clientesUrl}/filter/nome`;

    let params = new HttpParams({
      fromObject: {
        page: filtro.pagina.toString(),
        size: filtro.itensPorPagina.toString()
      }
    });

    if (filtro.nome) {
      params = params.append('nome', filtro.nome);
    } else if (filtro.codigo) {
      params = params.append('codigo', filtro.codigo);
      clientesUrlPesquisar = `${this.clientesUrl}/filter/codigo`;
    } else if (filtro.cnpjCpf) {
      params = params.append('cnpjCpf', filtro.cnpjCpf);
      clientesUrlPesquisar = `${this.clientesUrl}/filter/cnpjCpf`;
    }

    return this.http
      .get<any>(clientesUrlPesquisar, {params})
      .toPromise()
      .then(response => {
        const clientes = response.content;

        const resultado = {
          clientes,
          total: response.totalElements
        };

        return resultado;
      });
  }

  clienteHistorico(codigo: string): Promise<any> {

    let params = new HttpParams({
      fromObject: {
        page: '0',
        size: '5'
      }
    });
    params = params.append('codigo', codigo);
    return this.http
      .get<any>(`${this.clientesHistoricoUrl}/codigo`, {params})
      .toPromise()
      .then(response => {
        const clientesHistoricos = response.content;
        const resultado = {
          clientesHistoricos,
          total: response.totalElements
        };
        return resultado;
      });
  }

  pesquisarCep() {

  }

  buscarCep(cep: string): Promise<any> {
    const pesquisarCep = this.viaCepUrl + '/' + cep + '/json';
    return this.http
      .get<any>(pesquisarCep)
      .toPromise()
      .then(response => {
        return response;
      });
  }

  listarTodas(): Promise<any> {
    return this.http
      .get<any>(this.clientesUrl)
      .toPromise()
      .then(response => response.content);
  }

  adicionar(cliente: Cliente): Promise<Cliente> {
    return this.http.post<Cliente>(this.clientesUrl, cliente).toPromise();
  }

  adicionarHistorico(clienteHistorico: ClienteHistorico): Promise<ClienteHistorico> {
    return this.http.post<ClienteHistorico>(this.clientesHistoricoUrl, clienteHistorico).toPromise();
  }

  atualizar(cliente: Cliente): Promise<Cliente> {
    return this.http
      .put<Cliente>(`${this.clientesUrl}/${cliente.id}`, cliente)
      .toPromise();
  }

  buscarPorId(id: number): Promise<Cliente> {
    return this.http.get<Cliente>(`${this.clientesUrl}/${id}`).toPromise();
  }

  buscarPorCodigo(codigo: string): Promise<Cliente> {
    return this.http.get<Cliente>(`${this.clientesUrl}/pesquisar/${codigo}`).toPromise();
  }

  verificaLimiteCreditoEstourado(empresa: string, cliente: string) {
    return this.http.get<boolean>(`${this.clientesUrl}/limiteEstourado/${empresa}/${cliente}`).toPromise();
  }

  verificaTitulosEmAtraso(empresa: string, cliente: string) {
    return this.http.get<boolean>(`${this.clientesUrl}/dividasAbertas/${empresa}/${cliente}`).toPromise();
  }

  next(cod_cli: string): Promise<Cliente> {
    let params = new HttpParams({
      fromObject: {
        codigo: cod_cli
      }
    });
    return this.http.get<Cliente>(`${this.clientesUrl}?next`, {params}).toPromise();
  }

  previous(cod_cli: string): Promise<Cliente> {
    let params = new HttpParams({
      fromObject: {
        codigo: cod_cli
      }
    });
    return this.http.get<Cliente>(`${this.clientesUrl}?previous`, {params}).toPromise();
  }


  excluir(id: number): Promise<void> {
    return this.http
      .delete(`${this.clientesUrl}/${id}`)
      .toPromise()
      .then(() => null);
  }

  listarClientesTipos(): Promise<ClienteTipo[]> {
    return this.http.get<ClienteTipo[]>(this.clientesTiposUrl).toPromise();
  }

  listarClientesStatus(): Promise<any[]> {
    return this.http.get<any[]>(this.clienteStatusUrl).toPromise();
  }

  listarClientesHistorico(): Promise<ClienteHistorico[]> {
    return this.http.get<ClienteHistorico[]>(this.clientesHistoricoUrl).toPromise();
  }

  getClientes(nome: string) {
    return this.http
      .get<any>(`${this.clientesUrl}/clientes/${nome}`)
      .toPromise()
      .then(response => {
        return response;
      });
  }

  salvar(cliente: Cliente): Promise<Cliente> {
    if (!cliente.id)
      return this.adicionar(cliente);
    else {
      this.buscarPorId(cliente.id).then(c => {
        c.cnpjCpf = cliente.cnpjCpf;
        c.email = cliente.email;
        c.telefoneResidencial = cliente.telefoneResidencial;
        c.endereco = cliente.endereco;
        c.bairro = cliente.bairro;
        c.cep = cliente.cep;
        c.cidade = cliente.cidade;
        c.estado = cliente.estado;
        cliente = c;
      }).catch();
      return this.atualizar(cliente);
    }
  }

  setarDadosIniciaisCliente(cliente: Cliente) {
    cliente.empresa = this.auth.jwtPayload.empresa;
    cliente.incluirUsuario = this.auth.jwtPayload.login;
    cliente.tipoPessoa = 'A';
    cliente.posicao = 'AN';
    cliente.filial = 'N';
    cliente.suframa = 'N';
    cliente.contribuinte = 'N';
    cliente.revendedor = 'N';
    cliente.produtorRural = 'N';
    cliente.descontoAtacado = 'N';
    cliente.descontoVarejo = 'S';
  }


  processarGeracaoDeTitulos(cliente: Cliente): Promise<Cliente> {

    return this.http
      .put<Cliente>(`${this.clientesUrl}/processarGeracaoTitulos/${this.auth.jwtPayload.empresa}/${this.auth.jwtPayload.login}`, cliente)
      .toPromise();
  }


  calcularDiasAtraso(dt: Date): any {
    const now = moment(new Date());
    const emissao = moment(dt);
    const duration = moment.duration(now.diff(emissao));
    return Math.round(duration.asDays());
  }


  public static compareDate(date1: Date, date2: Date): number {

    let d1 = new Date(date1);
    let d2 = new Date(date2);

    let iguais = d1.getTime() === d2.getTime();
    if (iguais) return 0;
    if (d1 > d2) return 1;
    if (d1 < d2) return -1;

  }

  buscarClientePeloCpfCnpj(cnpjCpf: string): Promise<Cliente> {
    return this.http.get<Cliente>(`${this.clientesUrl}/cnpjCpf/${cnpjCpf}`).toPromise();
  }

  processarParcelamentoTitulos(cliente: Cliente): Promise<Cliente> {
    return this.http
      .put<Cliente>(`${this.clientesUrl}/processarParcelamentoTitulos/${this.auth.jwtPayload.empresa}/${this.auth.jwtPayload.login}`, cliente)
      .toPromise();
  }

  gerarTitulos(cliente: Cliente): Promise<Cliente> {
    return this.http
      .put<Cliente>(`${this.clientesUrl}/gerarTitulos/${this.auth.jwtPayload.empresa}/${this.auth.jwtPayload.login}`, cliente)
      .toPromise();
  }


  listarTodosBoletosConf(): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/boletosConfs/lista`)
      .toPromise()
      .then(response => response);
  }

  listarTodasClientesTipos(): Promise<any> {
    return this.http
      .get<any>(`${this.clientesTiposUrl}`)
      .toPromise()
      .then(response => response);
  }

  getTiposClientes(): Promise<SelectItem[]> {
    return new Promise((resolve, reject) => {
      this.listarTodasClientesTipos().then(ss => {
          resolve(ss.map(clienteTipo => {
              return {
                label: `${clienteTipo.tipoId} - ${clienteTipo.tipoNome}`,
                value: clienteTipo.tipoId
              }
            })
          );
        }
      ).catch(error => reject(error));
    });
  }

  alterarCodigoCliente(cliente: Cliente, codigoNovo: string): Promise<Cliente> {
    cliente.modificarUsuario = this.auth.jwtPayload.login;
    return this.http
      .put<Cliente>(`${this.clientesUrl}/alterarCodigo/${codigoNovo}`, cliente)
      .toPromise();
  }


  gerarCodigoClienteNovo(empresa: string, tipo: string) {
    return this.http.get<string>(`${this.clientesUrl}/gerarCodigoNovo/${empresa}/${tipo}`, {responseType: 'text'}).toPromise();
  }


  pesquisarClienteTipo(codigo: string, descricao: string, pagina: number, itensPorPagina: number): Promise<any> {

    let urlPesquisar = `${environment.apiUrl}/clientesTipos/filter/descricao`;

    let params = new HttpParams({
      fromObject: {
        page: pagina.toString(),
        size: itensPorPagina.toString()
      }
    });

    if (descricao)
      params = params.append('descricao', descricao);
    else {
      params = params.append('codigo', codigo);
      urlPesquisar = `${environment.apiUrl}/clientesTipos/filter/codigo`;
    }

    return this.http
      .get<any>(urlPesquisar, {params})
      .toPromise()
      .then(response => {
        const clienteTipos = response.content;

        const resultado = {
          clienteTipos,
          total: response.totalElements
        };

        return resultado;
      });

  }

  buscarPorCodigoClienteTipo(codigo: string): Promise<ClienteTipo> {
    return this.http.get<ClienteTipo>(`${environment.apiUrl}/clientesTipos/codigo/${codigo}`).toPromise();
  }


  consultarSituacaoFinanceiraCliente(id: number, dataCalculo: Date): Promise<SituacaoFinanceiraCliente> {
    return this.http.get<SituacaoFinanceiraCliente>(
      `${this.clientesUrl}/situacaoFinanceira/${this.auth.jwtPayload.empresa}/${id}/${moment(dataCalculo).format('YYYY-MM-DD')}`)
      .toPromise();
  }

  recalcularTitulosSituacaoFinanceiraCliente(situacaoFinanceiraCliente: SituacaoFinanceiraCliente): Promise<SituacaoFinanceiraCliente> {
    return this.http
      .put<SituacaoFinanceiraCliente>(`${this.clientesUrl}/situacaoFinanceiraRecalculo/${this.auth.jwtPayload.empresa}`
        , situacaoFinanceiraCliente).toPromise();
  }

}
