import {NotaMsg} from './NotaMsg';
import {Cliente} from 'app/core/models/Cliente';
import {MovimentoCancelado} from './MovimentoCancelado';
import {Movimento} from './Movimento';
import {MovimentoEditado} from './MovimentoEditado';


export class Nota {
  id: number;
  empresa: string;
  documento: string;
  serie: string;
  codigoNatureza: string;
  cliente: Cliente;
  desdobra: string;
  codigoVendedor: string;
  valorPecas: number;
  valor: number;
  comissiona: number;
  nfsePis: number;
  observacoes: string;
  emissao: Date;
  nfseCofins: number;
  codigoPagamento: string;
  incluirUsuario: string;
  incluirData: Date
  modificarUsuario: string;
  modificarData: Date;
  modificarOperacao: string;
  codigoTexto: string;
  numeroFormsegNfe: string;
  movimentoItens: Array<Movimento>;
  movimentosCancelados: Array<MovimentoCancelado>;
  numero: string;
  tipo: string;
  fechamento: Date;
  valorDesconto: number;
  nfRef: string;
  bcalculo: number;
  icm: number;
  iss: number;
  codigoFiscal: string;
  protocoloAutorizaNfe: string;
  chaveNfe: string;
  notasMsg: Array<NotaMsg>;
  excluirUsuario: string;
  motivoCancelamentoNfe: string;
  obervadiccontrib: string;
  dataHoraAutorizaNfe: Date;
  statusNfe: string;
  reciboCancelamentoNfe: string;
  valorMaoObra: number;
  nfseCodigoVerificacao: string;
  excluirData: Date;
  nfRefSerie: string;
  nfRefModelo: string;
  nfRefTipo: string;
  icmFonte: number;
  preVenda: string;
  nfseDeducoes: number;
  nfseBaseIss: number;
  nfseAliquota: number;
  nfseIssRetido: number;
  codigoRetNfe: string;
  tipoEmissaoInicial: string;
  tipoEmissaoFinal: string;
  loteNfe: string;
  reciboEnvioLoteNfe: string;
  dataHoraEnvioLoteNfe: Date;
  nfseCodigoServico: string;
  mensagens: Array<string>;
  movimentosEditado: Array<MovimentoEditado>;

  nfseRps: string;
  nfseRpsSerie: string;
  nfseRpsTipo: string;
  nfseRpsStatus: string;
  nfseInss: string;

  constructor(
    id?: number,
    empresa?: string,
    documento?: string,
    serie?: string,
    numeroFormsegNfe?: string,
    codigoNatureza?: string,
    cliente?: Cliente,
    desdobra?: string,
    codigoVendedor?: string,
    valorPecas?: number,
    valor?: number,
    comissiona?: number,
    nfRef?: string,
    nfseCofins?: number,
    observacoes?: string,
    emissao?: Date,
    codigoPagamento?: string,
    nfsePis?: number,
    incluirUsuario?: string,
    incluirData?: Date,
    obervadiccontrib?: string,
    modificarUsuario?: string,
    modificarData?: Date,
    modificarOperacao?: string,
    codigoTexto?: string,
    movimentoItens = new Array<Movimento>(),
    movimentosCancelados = new Array<MovimentoCancelado>(),
    numero?: string,
    tipo?: string,
    fechamento?: Date,
    valorDesconto?: number,
    bcalculo?: number,
    icm?: number,
    iss?: number,
    codigoFiscal?: string,
    protocoloAutorizaNfe?: string,
    chaveNfe?: string,
    notasMsg = new Array<NotaMsg>(),
    excluirUsuario?: string,
    motivoCancelamentoNfe?: string,
    dataHoraAutorizaNfe?: Date,
    statusNfe?: string,
    reciboCancelamentoNfe?: string,
    valorMaoObra?: number,
    nfseCodigoVerificacao?: string,
    nfRefSerie?: string,
    nfRefModelo?: string,
    nfRefTipo?: string,
    icmFonte?: number,
    preVenda?: string,
    nfseDeducoes?: number,
    nfseBaseIss?: number,
    nfseAliquota?: number,
    nfseIssRetido?: number,
    codigoRetNfe?: string,
    tipoEmissaoInicial?: string,
    tipoEmissaoFinal?: string,
    loteNfe?: string,
    reciboEnvioLoteNfe?: string,
    dataHoraEnvioLoteNfe?: Date,
    nfseCodigoServico?: string,
    mensagens?: Array<string>,
    movimentosEditado?: Array<MovimentoEditado>,
    nfseRps?: string,
    nfseRpsSerie?: string,
    nfseRpsTipo?: string,
    nfseRpsStatus?: string,
    nfseInss?: string,

    ) {
    this.id = id;
    this.empresa = empresa;
    this.documento = documento;
    this.serie = serie;
    this.emissao = emissao;
    this.codigoVendedor = codigoVendedor;
    this.valorPecas = valorPecas;
    this.valor = valor;
    this.numeroFormsegNfe = numeroFormsegNfe;
    this.codigoNatureza = codigoNatureza;
    this.cliente = cliente;
    this.nfsePis = nfsePis;
    this.nfseCofins = nfseCofins;
    this.desdobra = desdobra;
    this.comissiona = comissiona;
    this.obervadiccontrib = obervadiccontrib;
    this.codigoPagamento = codigoPagamento;
    this.observacoes = observacoes;
    this.emissao = emissao;
    this.incluirUsuario = incluirUsuario
    this.incluirData = incluirData;
    this.nfRef = nfRef;
    this.modificarUsuario = modificarUsuario;
    this.modificarData = modificarData;
    this.modificarOperacao = modificarOperacao;
    this.codigoTexto = codigoTexto;
    this.movimentoItens = movimentoItens;
    this.movimentosCancelados = movimentosCancelados;
    this.numero = numero;
    this.tipo = tipo;
    this.fechamento = fechamento;
    this.valorDesconto = valorDesconto;
    this.bcalculo = bcalculo;
    this.iss = iss;
    this.icm = icm;
    this.codigoFiscal = codigoFiscal;
    this.protocoloAutorizaNfe = protocoloAutorizaNfe;
    this.chaveNfe = chaveNfe;
    this.notasMsg = notasMsg;
    this.excluirUsuario = excluirUsuario;
    this.motivoCancelamentoNfe = motivoCancelamentoNfe;
    this.dataHoraAutorizaNfe = dataHoraAutorizaNfe;
    this.statusNfe = statusNfe;
    this.reciboCancelamentoNfe = reciboCancelamentoNfe;
    this.valorMaoObra = valorMaoObra;
    this.nfseCodigoVerificacao = nfseCodigoVerificacao;
    this.nfRefSerie = nfRefSerie;
    this.nfRefModelo = nfRefModelo;
    this.nfRefTipo = nfRefTipo;
    this.icmFonte = icmFonte;
    this.preVenda = preVenda;
    this.nfseDeducoes = nfseDeducoes;
    this.nfseBaseIss = nfseBaseIss;
    this.nfseAliquota = nfseAliquota;
    this.nfseIssRetido = nfseIssRetido;
    this.codigoRetNfe = codigoRetNfe;
    this.tipoEmissaoInicial = tipoEmissaoInicial;
    this.tipoEmissaoFinal = tipoEmissaoFinal;
    this.loteNfe = loteNfe;
    this.reciboEnvioLoteNfe = reciboEnvioLoteNfe;
    this.dataHoraEnvioLoteNfe = dataHoraEnvioLoteNfe;
    this.nfseCodigoServico = nfseCodigoServico;
    this.mensagens = mensagens;
    this.movimentosEditado = movimentosEditado;
    this.nfseRps = nfseRps;
    this.nfseRpsSerie = nfseRpsSerie;
    this.nfseRpsTipo = nfseRpsTipo;
    this.nfseRpsStatus = nfseRpsStatus;
    this.nfseInss = nfseInss;
  }

}
