import { Injectable } from '@angular/core';
import { MoneyHttp } from 'app/seguranca/money-http';
import { Vehicle } from 'app/core/models/Vehicle';

export class BibiFiltro {
  placa: string;
  produto: string;
  pagina = 0;
  itensPorPagina = 10;
}

@Injectable()
export class BibiService {

  bibiUrl: string;
  veiculo = new Vehicle();

  veiculos = new Array<Vehicle>();

  constructor(
    private http: MoneyHttp
  )
  {
  }

  pesquisar(filtro: BibiFiltro): Promise<any> {
    this.bibiUrl = `https://bibipecasapi.azurewebsites.net/api/v1/parts/plate/`;
    this.bibiUrl = this.bibiUrl + filtro.placa;
    this.bibiUrl = this.bibiUrl + `?partName=`;
    this.bibiUrl = this.bibiUrl + filtro.produto;
    this.bibiUrl = this.bibiUrl + `&key=340ebc395764400899da4db3503e5716&`;
    this.bibiUrl = this.bibiUrl + `token=f577258bdb07479d9d2b3c250bb39d7ac23bcb6b660f4e15b13a6b1e2047810a`;

    const bibiUrlPesquisar = `${this.bibiUrl}`;

    return this.http
      .get<any>(bibiUrlPesquisar)
      .toPromise()
      .then(response => {
        return response;
      });
  }

}
