import {Injectable} from '@angular/core';

import 'rxjs/add/operator/toPromise';

import {environment} from '../../environments/environment';
import {MoneyHttp} from 'app/seguranca/money-http';
import {Serie} from '../core/models/Serie';
import {SelectItem} from 'primeng/api';
import {HttpParams} from '@angular/common/http';

@Injectable()
export class SerieService {

  url: string;

  constructor(private http: MoneyHttp) {
    this.url = `${environment.apiUrl}/series`;
  }

  listarTodas(): Promise<Array<Serie>> {
    return this.http
      .get<Array<Serie>>(`${this.url}/lista`)
      .toPromise()
      .then(response => response);
  }

  getSeries(): Promise<SelectItem[]> {
    return new Promise((resolve, reject) => {
      this.listarTodas().then(ss => {
          resolve(ss.map(serie => {
              return {
                label: `${serie.codigo} - ${serie.descricao}`,
                value: serie.codigo
              }
            })
          );
        }
      ).catch(error => reject(error));
    });
  }

  buscarPorCodigo(codigo: string): Promise<Serie> {
    return this.http.get<Serie>(`${this.url}/codigo/${codigo}`).toPromise();
  }


  pesquisar(codigo: string, descricao: string, pagina: number, itensPorPagina: number): Promise<any> {

    let urlPesquisar = `${this.url}/filter/descricao`;

    let params = new HttpParams({
      fromObject: {
        page: pagina.toString(),
        size: itensPorPagina.toString()
      }
    });

    if (descricao)
      params = params.append('descricao', descricao);
    else{
      params = params.append('codigo', codigo);
      urlPesquisar = `${this.url}/filter/codigo`;
    }

    return this.http
      .get<any>(urlPesquisar, {params})
      .toPromise()
      .then(response => {
        const series = response.content;

        const resultado = {
          series,
          total: response.totalElements
        };

        return resultado;
      });

  }


}


