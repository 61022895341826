import {Injectable} from '@angular/core';

import {environment} from '../../environments/environment';
import {MoneyHttp} from '../seguranca/money-http';
import {Locais} from '../core/models/dtos/Locais';
import {Local} from '../core/models/Local';

@Injectable()
export class LocalService {

  localUrl:string;

  constructor(private http: MoneyHttp){
    this.localUrl = `${environment.apiUrl}/local`;
  }

  processarGeracaoLocalPorFaixa(locais: Locais): Promise<Blob> {
    return this.http.post<Blob>(this.localUrl, locais, {responseType: 'blob' as 'json'}).toPromise();
  }

  buscarPorEndereco(endereco: string): Promise<Local> {
    return this.http.get<Local>(`${this.localUrl}/${endereco}`).toPromise();
  }


  validarCampos(locais:Locais): boolean{
    if (
      locais.empresa == null
      || locais.empresa == undefined
      || locais.empresa.trim() == ''
      || locais.bairro == null
      || locais.bairro == undefined
      || locais.bairro == 0
      || locais.rua == null
      || locais.rua == undefined
      || locais.rua == 0
      || locais.edificio == null
      || locais.edificio == undefined
      || locais.edificio == 0
      || locais.blocoInicial == null
      || locais.blocoInicial == undefined
      || locais.blocoInicial == 0
      || locais.blocoFinal == null
      || locais.blocoFinal == undefined
      || locais.blocoFinal == 0
      || locais.andarInicial == null
      || locais.andarInicial == undefined
      || locais.andarInicial == 0
      || locais.andarFinal == null
      || locais.andarFinal == undefined
      || locais.andarFinal == 0
      || locais.apartamentoInicial == null
      || locais.apartamentoInicial == undefined
      || locais.apartamentoInicial == 0
      || locais.apartamentoFinal == null
      || locais.apartamentoFinal == undefined
      || locais.apartamentoFinal == 0
    )
      return false;

    return true;
  }
}
