import {Injectable} from '@angular/core';

import 'rxjs/add/operator/toPromise';

import {environment} from '../../environments/environment';
import {MoneyHttp} from 'app/seguranca/money-http';
import {FluxoCaixaControle} from '../core/models/FluxoCaixaControle';
import {ControleFluxoCaixa} from './fluxoCaixa.service';


@Injectable()
export class FluxoCaixaControleService {


  url: string;

  constructor(private http: MoneyHttp) {
    this.url = `${environment.apiUrl}/fluxosCaixasControles`;
  }

  buscarStatusCaixa(empresa: string, caixa: string): Promise<FluxoCaixaControle> {

    return this.http
      .get<FluxoCaixaControle>(`${this.url}/statusCaixa/${empresa}/${caixa}`)
      .toPromise();
  }


  executarFechamentoCaixa(fluxoControleCaixa: FluxoCaixaControle): Promise<FluxoCaixaControle> {
    return this.http.put<FluxoCaixaControle>(`${this.url}/fecharCaixaFinal`, fluxoControleCaixa).toPromise();
  }

  executarConferenciaCaixa(fluxoControleCaixa: FluxoCaixaControle): Promise<FluxoCaixaControle> {
    return this.http.put<FluxoCaixaControle>(`${this.url}/conferenciaCaixa`, fluxoControleCaixa).toPromise();
  }

  desfazerConferenciaCaixa(fluxoControleCaixa: FluxoCaixaControle): Promise<FluxoCaixaControle> {
    return this.http.put<FluxoCaixaControle>(`${this.url}/desfazerConferenciaCaixa`, fluxoControleCaixa).toPromise();
  }

}


